import {
  selectArticleFavorites,
  selectFilteredArticles,
  selectIsFetchingArticles,
  selectUnReadArticles,
} from '@/store/articles/articlesSlice'
import { listArticles } from '@/store/articles/useCases/listArticles.ts'
import { AppStore } from '@/store/configureStore.ts'
import { useAppSelector } from '@/store/hooks.ts'

export const legalWatchLoader =
  ({ store }: { store: AppStore }) =>
  () => {
    store.dispatch(listArticles())
    return null
  }
export function useLegalWatchArticleListHook() {
  const filteredArticles = useAppSelector(selectFilteredArticles)
  const unReadArticles = useAppSelector(selectUnReadArticles)
  const favoritesArticles = useAppSelector(selectArticleFavorites)
  const isFetchingArticles = useAppSelector(selectIsFetchingArticles)

  return {
    articles: filteredArticles,
    unReadArticles,
    favoritesArticles,
    isFetchingArticles,
  }
}
