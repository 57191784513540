import { Conversation, DeleteFilesInConversationError } from '../../../types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const deleteFilesInConversation = createAppAsyncThunk(
  'conversations/deleteFilesInConversation',
  async (conversationId: Conversation['id'], { extra: { conversationGateway } }) => {
    const response = await conversationGateway.deleteFilesInConversation(conversationId)
    if (!response.ok) {
      const error: DeleteFilesInConversationError = await response.json()
      throw new Error(error.message)
    }
    return Promise.resolve(conversationId)
  },
)
