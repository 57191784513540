import ReactMarkdown from 'react-markdown'
import AvatarIcon from '../../components/AvatarIcon'
import { TheSourcesListItem } from '../../components/TheSourcesListItem'
import { BaseButton } from '../../components/atomics/BaseButton'
import { BaseModal } from '../../components/atomics/BaseModal'
import { ConverstionSnapshotMessage } from '../../types/conversationSnapshot'
import { SourceReadingInfos } from '../../types/source'

function ConversationSnapshotMessageItem({
  message,
  toggleSourcesList,
  isSourcesListVisible,
}: {
  message: ConverstionSnapshotMessage
  toggleSourcesList: () => void
  isSourcesListVisible: boolean
}) {
  return (
    <div className={`flex p-4 ${message.type === 'HUMAN' && 'flex-row-reverse'} gap-4`}>
      <div className="w-10">
        <AvatarIcon type={message.type} />
      </div>
      <div>
        <ReactMarkdown className={` text-center text-sm leading-8 sm:text-left`}>{message.content}</ReactMarkdown>
        {message.type === 'AI' && message.sources && message.sources.length > 0 && (
          <div className="pt-2">
            <BaseButton
              onClick={toggleSourcesList}
              label={`Voir les ${message.sources.length} sources officielles`}
            ></BaseButton>
            <BaseModal
              contentVisible={isSourcesListVisible}
              onClick={toggleSourcesList}
              positionClass={'right-0'}
              styleBasedOnScreenSize={'h-full w-96 bg-brightGray'}
              animationClass={'translate-x-full'}
            >
              <div className={'my-5 flex flex-col items-center justify-center'}>
                {message.sources.map((source: SourceReadingInfos) => (
                  <TheSourcesListItem
                    key={message.sources.indexOf(source)}
                    sourceNumber={message.sources.indexOf(source) + 1}
                    source={source}
                  />
                ))}
              </div>
            </BaseModal>
          </div>
        )}
      </div>
    </div>
  )
}

export default ConversationSnapshotMessageItem
