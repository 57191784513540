import { LoaderFunction, Navigate, Outlet } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { CreateUserResponseData } from '../../types/user.ts'
import { olympeGptApiWretch } from '../../gateways/olympeGptApiWretch.ts'
import { WretchAccountGateway } from '../../gateways/WretchAccount.gateway.ts'
import { AppStore } from '../../store/configureStore.ts'
import { setCurrentUser } from '../../store/auth/authSlice.ts'

export let currentUser: CreateUserResponseData | null = null

export const currentUserLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    if (currentUser) {
      return currentUser
    }
    const accountGateway = new WretchAccountGateway(olympeGptApiWretch)
    try {
      currentUser = await accountGateway.getCurrentUser()
      store.dispatch(setCurrentUser(currentUser))
      return currentUser
    } catch (error) {
      window.location.href = ROUTE_PATHS.login
    }
    return null
  }

export function ProtectedRoutes() {
  if (!currentUser) {
    return <Navigate to={ROUTE_PATHS.login} />
  }

  return <Outlet />
}
