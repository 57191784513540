import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'

interface FilePreviewModalState {
  isOpen: boolean
  pdfData: string
}

interface ModalsState {
  filePreviewModal: FilePreviewModalState
  authModal: {
    isOpen: boolean
    isSignupFormDisplayed: boolean
  }
  calendlyModal: {
    isOpen: boolean
  }
}

const initialState: ModalsState = {
  filePreviewModal: {
    isOpen: false,
    pdfData: '',
  },
  authModal: { isOpen: false, isSignupFormDisplayed: true },
  calendlyModal: {
    isOpen: false,
  },
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleFilePreviewModal: (state) => {
      state.filePreviewModal.isOpen = !state.filePreviewModal.isOpen
    },
    toggleAuthModalForm: (state) => {
      state.authModal.isSignupFormDisplayed = !state.authModal.isSignupFormDisplayed
    },
    setPdfData: (state, action) => {
      state.filePreviewModal.pdfData = action.payload
    },
    closeAuthModal: (state) => {
      state.authModal.isOpen = false
    },
    openAuthModal: (state) => {
      state.authModal.isOpen = true
    },
    resetAuthModal: (state) => {
      state.authModal.isSignupFormDisplayed = true
    },
    closeCalendlyModal: (state) => {
      state.calendlyModal.isOpen = false
    },
    openCalendlyModal: (state) => {
      state.calendlyModal.isOpen = true
    },
  },
})

export const {
  openAuthModal,
  toggleAuthModalForm,
  resetAuthModal,
  closeAuthModal,
  toggleFilePreviewModal,
  setPdfData,
  closeCalendlyModal,
  openCalendlyModal,
} = modalsSlice.actions

export const selectFilePreviewModal = (state: RootState) => state.entities.modals.filePreviewModal
export const selectIsAuthModalOpen = (state: RootState) => state.entities.modals.authModal.isOpen
export const selectIsCalendlyModalOpen = (state: RootState) => state.entities.modals.calendlyModal.isOpen
export const selectIsSignupFormDisplayed = (state: RootState) => state.entities.modals.authModal.isSignupFormDisplayed
