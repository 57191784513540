import { ReactNode } from 'react'
import { TheSidebarConversationsListItem } from './TheSidebarConversationsListItem.tsx'
import { useParams } from 'react-router-dom'
import { useSidebarHook } from './use-sidebar.hook.ts'

export function TheSidebarConversationList() {
  const { conversationId } = useParams()
  const { conversations } = useSidebarHook()

  function renderTchatList(): ReactNode {
    if (!conversations.length) return
    return conversations.map((conversation) => {
      return (
        <TheSidebarConversationsListItem
          key={conversation.id}
          isCurrent={conversationId === conversation.id}
          conversation={conversation}
        />
      )
    })
  }

  return (
    <div className={`no-scrollbar h-full w-full overflow-auto pt-5 lg:h-3/4 lg:pt-0`} data-testid="conversation-list">
      {renderTchatList()}
    </div>
  )
}
