import { TheIconFolder } from '@/components/icons/TheIconFolder.tsx'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { TheIconTripleDotsVertical } from '@/components/icons/TheIconTripleDotsVertical.tsx'
import React from 'react'
import { TagWithFileCountAndActiveStatus } from '@/types/tag.ts'
import { TheIconRename } from '@/components/icons/TheIconRename.tsx'
import { TheIconTrash } from '../icons/TheIconTrash.tsx'

interface Props {
  tag: TagWithFileCountAndActiveStatus
  onRename: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onDelete: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function TheDocumentsFolderHeader({ tag, onRename, onDelete }: Readonly<Props>) {
  return (
    <div className={'flex w-full items-center justify-between'}>
      <TheIconFolder isActive={tag.isTagActive} />
      {tag.id !== 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <TheIconTripleDotsVertical className={'h-5 w-5 fill-primary hover:cursor-pointer hover:fill-label'} />
          </DropdownMenuTrigger>
          <DropdownMenuContent side={'top'}>
            <DropdownMenuItem onClick={onRename} className={'group items-center gap-2 hover:cursor-pointer'}>
              <TheIconRename className={'stroke-label group-hover:stroke-primary'} />
              <span className={'text-label group-hover:text-primary'}>Renommer</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={onDelete} className={'group items-center gap-2 text-label hover:cursor-pointer'}>
              <TheIconTrash className={'stroke-label group-hover:stroke-primary'} />
              <span className={'text-label group-hover:text-primary'}>Supprimer</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}
