import { OlympeGptApiWretch } from '@/gateways/olympeGptApiWretch.ts'
import { LegalResourceGateway } from './interface/legalResource.gateway.ts'
import { GetLegalResourceByIdResponse, LegalResource, ListLegalResourcesResponse } from '@/types/legalResource.ts'

export class WretchLegalResourceGateway implements LegalResourceGateway {
  private readonly endpoint = '/legal-resources'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async getAllTexts(): Promise<LegalResource[]> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListLegalResourcesResponse>()
    return response.data
  }

  async getTextById(articleId: LegalResource['id']): Promise<LegalResource> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${articleId}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetLegalResourceByIdResponse>()
    return response.data
  }

  async getTextBySlug(slug: LegalResource['slug']): Promise<LegalResource[]> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}?slug=${slug}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListLegalResourcesResponse>()
    return response.data
  }
}
