import { Conversation } from '@/types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const createConversationMessage = createAppAsyncThunk<
  Promise<ReadableStreamDefaultReader<Uint8Array> | null>,
  { conversationId: Conversation['id']; content: string; sourceCollections: string[] | undefined }
>('messages/createConversationMessage', async (payload, { extra: { conversationGateway } }) => {
  try {
    return await conversationGateway.createMessage(payload.conversationId, payload.content, payload.sourceCollections)
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
