import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime' // adding props is needed since TheIconBin will be used in various contexts,

export function TheIconUpdate(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 20 22" fill="none">
      <g clipPath="url(#clip0_1780_8130)">
        <path d="M17.3362 11.7312V18.9976C17.3262 20.1706 16.6006 21.1546 15.5071 21.433C15.2984 21.4827 15.0698 21.5125 14.8511 21.5026C12.2169 21.5125 9.55287 21.5125 6.91868 21.5125H2.4654C1.27256 21.5125 0.397805 20.8167 0.0697732 19.604C0.0498925 19.5841 0.0399522 19.5642 0.0300118 19.5443L0.000190735 19.4548L0.0200714 3.81867L0.0399522 3.74908C0.0399522 3.74908 0.0697732 3.66956 0.089654 3.6298C0.218879 3.13278 0.487268 2.69541 0.865002 2.36737C1.25268 2.03934 1.71987 1.85048 2.20695 1.82065C2.32623 1.81071 2.43558 1.81071 2.53498 1.81071H10.5966C10.7358 1.80077 10.8849 1.81071 11.024 1.85048C11.2527 1.92006 11.4415 2.05922 11.5708 2.26797C11.7 2.45684 11.7497 2.70535 11.7298 2.93397C11.7 3.17254 11.6006 3.39123 11.4316 3.56022C11.2726 3.7292 11.0539 3.82861 10.8252 3.83855C10.7557 3.84849 10.6861 3.83855 10.6364 3.84849H2.52504C2.08767 3.84849 1.95844 3.98765 1.95844 4.44491V18.8584C1.9485 18.9578 1.95844 19.0672 1.97832 19.1666C1.9982 19.2362 2.05784 19.4648 2.44552 19.4648H14.8908C15.2288 19.4648 15.368 19.3057 15.368 18.9081V16.8902C15.368 15.2203 15.368 13.5006 15.3779 11.8107C15.3779 11.602 15.4177 11.3932 15.4972 11.1944C15.6563 10.8167 16.0539 10.588 16.4614 10.6378C16.6602 10.6676 16.8392 10.7471 16.9982 10.8863C17.1374 11.0254 17.2467 11.2044 17.2964 11.4032C17.3163 11.5125 17.3362 11.6218 17.3362 11.7312Z" />
        <path d="M19.9405 2.85461C19.861 2.39735 19.6721 1.96992 19.3938 1.60213C19.1254 1.24427 18.7775 0.946062 18.3799 0.757195C18.191 0.667732 17.9922 0.598149 17.7835 0.538507C17.694 0.518627 17.6046 0.488805 17.525 0.458984H16.69C16.69 0.458984 16.6503 0.488805 16.6403 0.498746C16.6205 0.508686 16.6006 0.518627 16.5708 0.528567C16.024 0.657791 15.527 0.946062 15.1393 1.3735L12.8928 3.72936C10.6463 6.10511 8.32026 8.55043 6.03398 10.956C5.91469 11.0753 5.81529 11.2244 5.76559 11.3834C5.25863 12.954 4.80137 14.4053 4.364 15.797C4.3143 15.9759 4.3143 16.1647 4.364 16.3437C4.39382 16.453 4.44352 16.5524 4.5131 16.6419C4.57275 16.7313 4.66221 16.8009 4.75167 16.8606C4.8809 16.9202 5.02006 16.96 5.15923 16.96C5.28845 16.95 5.41768 16.9302 5.5469 16.8805L6.85903 16.4232C7.7139 16.125 8.59859 15.8168 9.4634 15.5087C9.70197 15.4391 9.91072 15.2999 10.0896 15.1111C12.3362 12.7453 14.5827 10.3795 16.8292 8.00371L19.0558 5.66773C19.1254 5.58821 19.2049 5.50869 19.2745 5.42916C19.5628 5.08125 19.7815 4.6737 19.9008 4.22638C20.0201 3.77906 20.03 3.31187 19.9405 2.85461ZM18.0519 4.16674C17.9823 4.24626 17.9226 4.32578 17.8431 4.40531C15.4276 6.95004 12.2368 10.3099 9.00614 13.6995C8.96638 13.7492 8.91668 13.7989 8.85704 13.8188C8.17115 14.0673 7.50515 14.3059 6.79939 14.5544L6.52105 14.6538L6.60058 14.4053C6.83915 13.6399 7.06777 12.9143 7.30634 12.1787C7.32622 12.129 7.35604 12.0991 7.38587 12.0594C8.36002 11.0455 9.32424 10.0315 10.2885 9.00769C12.2566 6.9401 14.3044 4.79298 16.3024 2.67568C16.4216 2.54646 16.5509 2.44706 16.7099 2.37747C16.859 2.30789 17.0181 2.26813 17.1871 2.26813C17.2467 2.26813 17.3063 2.26813 17.366 2.27807C17.5449 2.30789 17.704 2.38741 17.8431 2.49676C17.9922 2.6061 18.1016 2.75521 18.1712 2.91425C18.37 3.35163 18.3302 3.77906 18.0519 4.16674Z" />
      </g>
      <defs>
        <clipPath id="clip0_1780_8130">
          <rect width="20" height="21.0537" fill="white" transform="translate(0 0.458984)" />
        </clipPath>
      </defs>
    </svg>
  )
}
