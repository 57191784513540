import {
  CreateUserBodyData,
  CreateUserResponse,
  CreateUserResponseData,
  LogUserBody,
  LogUserBodyData,
  User,
} from '../types/user.ts'
import { AuthGateway } from './interface/auth.gateway.ts'
import { WretchResponse } from 'wretch'
import { ERROR_MESSAGES } from '../utils/errorMessages.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'

export class WretchAuthGateway implements AuthGateway {
  private readonly endpoint = '/auth'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  refreshToken(): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/refresh-token`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .get()
      .res()
  }

  async logUser({ email, password }: LogUserBody): Promise<WretchResponse> {
    const body: LogUserBodyData = {
      data: {
        email,
        password,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/login`

    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .post(body)
      .error(401, (error) => {
        if (error.json.message === 'Invalid credentials') {
          throw new Error(ERROR_MESSAGES.CREDENTIALS)
        }
        throw error
      })
      .res()
      .catch((error) => {
        if (error.message !== ERROR_MESSAGES.CREDENTIALS) {
          throw new Error(ERROR_MESSAGES.GENERIC)
        }
        throw error
      })
  }

  logout(): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/logout`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .get()
      .res()
  }

  async createUser(createUserBody: User): Promise<CreateUserResponseData> {
    const body: CreateUserBodyData = {
      data: createUserBody,
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/register`
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .post(body)
      .error(409, (error) => {
        if (error.json.message === 'User already exists') {
          throw new Error(ERROR_MESSAGES.USER_ALREADY_EXISTS)
        }
        throw error
      })
      .json<CreateUserResponse>()
      .catch((error) => {
        if (error.message !== ERROR_MESSAGES.USER_ALREADY_EXISTS) {
          throw new Error(ERROR_MESSAGES.GENERIC)
        }
        throw error
      })
    return response.data
  }
}
