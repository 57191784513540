import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { selectConversationById } from '@/store/conversations/conversationsSlice.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { deleteFilesInConversation } from '@/store/conversations/useCases/deleteFilesInConversation.ts'
import { updateConversation } from '@/store/conversations/useCases/updateConversation.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectAllMessages } from '@/store/messages/messagesSlice.ts'
import { Conversation, ConversationType, Title } from '@/types/conversation.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'

type ConversationSelectorUIElement = {
  title: Title
  description: string
}

export const useTheConversationTypesListHook = () => {
  const dispatch = useAppDispatch()
  const { conversationId } = useParams() as { conversationId: Conversation['id'] }
  const currentConversation = useAppSelector(selectConversationById(conversationId))
  const messages = useAppSelector(selectAllMessages)
  const navigate = useNavigate()
  const targetRef = useRef<HTMLDivElement>(null)
  const { isUserConnected } = useCurrentUserHook()
  const typesOfConversationListWithoutArticleType: Record<ConversationType, ConversationSelectorUIElement> = {
    MESSAGE_ONLY: {
      title: 'Rechercher',
      description: 'Chattez avec Olympe via le module de conversation.',
    },
    SINGLE_FILE: {
      title: 'Analyser',
      description: 'Joignez un document et posez une question à Olympe à son sujet.',
    },
    FILE_COMPARISON: {
      title: 'Comparer',
      description: 'Joignez deux documents à la fois et comparez-les grâce à Olympe.',
    },
  }

  const handleClick = async (type: ConversationType) => {
    const restrictedTypes = ['SINGLE_FILE', 'FILE_COMPARISON']
    if (restrictedTypes.includes(type) && !isUserConnected) {
      return dispatch(openAuthModal())
    }
    if (messages.length) {
      const newConversation = await dispatch(createConversation({ type })).unwrap()
      const conversationUpdated = {
        ...newConversation,
        type,
      } as Conversation

      await dispatch(updateConversation(conversationUpdated))
      navigate(`/conversations/${newConversation.id}`)
    } else {
      if (!currentConversation) {
        return
      }
      await dispatch(updateConversation({ ...currentConversation, type }))
      await dispatch(deleteFilesInConversation(conversationId))
    }
  }
  return {
    targetRef,
    handleClick,
    typesOfConversationList: typesOfConversationListWithoutArticleType,
    messages,
    currentConversation,
  }
}
