import { Conversation, DeleteConversationByIdError } from '../../../types/conversation'
import { createAppAsyncThunk } from '../../createAppThunk'

export const deleteConversationSnapshotById = createAppAsyncThunk(
  'conversationSnapshot/deleteConversationSnapshotById',
  async (conversationId: Conversation['id'], { extra: { conversationSnaphotGateway } }) => {
    const response = await conversationSnaphotGateway.deleteConversationSnapshot(conversationId)

    if (!response.ok) {
      const error: DeleteConversationByIdError = await response.json()
      throw new Error(error.message)
    }
    return Promise.resolve()
  },
)
