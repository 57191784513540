import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconSignout(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg className={props.className} {...props} viewBox="0 0 22 24" fill="none">
      <g clipPath="url(#clip0_558_7078)">
        <path
          d="M19.9999 10.2119C19.8571 10.416 19.7407 10.6469 19.5667 10.8202C17.9955 12.3882 16.4078 13.9391 14.8406 15.5106C14.342 16.0104 13.8274 15.8864 13.5535 15.6057C13.2718 15.3163 13.1739 14.8017 13.6754 14.3175C14.7865 13.2449 15.8756 12.1495 16.9765 11.0662C17.0248 11.0189 17.1018 11.0013 17.1656 10.97C17.1427 10.9403 17.1203 10.911 17.0974 10.8812H16.8722C13.7923 10.8812 10.7124 10.8812 7.63247 10.8812C7.12857 10.8812 6.80693 10.6459 6.71531 10.2158C6.61444 9.74326 6.96678 9.27169 7.44777 9.23751C7.53793 9.23117 7.62906 9.23214 7.7197 9.23214C10.7675 9.23214 13.8147 9.23214 16.8625 9.23214C16.939 9.23214 17.0155 9.23214 17.1456 9.23214C17.0662 9.1472 17.0184 9.09301 16.9673 9.04224C15.8601 7.94678 14.7533 6.85083 13.6437 5.75781C13.4024 5.52007 13.2606 5.25353 13.3391 4.90399C13.4716 4.31477 14.1612 4.06287 14.6412 4.43095C14.708 4.48221 14.7694 4.54079 14.8293 4.59986C16.4356 6.1874 18.0428 7.77397 19.6437 9.36737C19.7855 9.50894 19.882 9.69591 19.9994 9.86238V10.2134L19.9999 10.2119Z"
          fill="#FDFDFD"
        />
        <path
          d="M1.73396 10.0639C1.73396 12.4931 1.73201 14.9223 1.73834 17.3514C1.73834 17.5345 1.76369 17.7336 1.83873 17.8972C1.99809 18.2438 2.31339 18.3487 2.67597 18.3478C3.64185 18.3458 4.60823 18.3473 5.57411 18.3473C7.00053 18.3473 8.42694 18.3468 9.85335 18.3483C9.96983 18.3483 10.0892 18.3473 10.2013 18.3722C10.6019 18.4615 10.8568 18.834 10.8212 19.2611C10.7885 19.6522 10.464 19.968 10.0658 19.9954C10.0142 19.9988 9.96203 19.9993 9.91037 19.9993C7.47908 19.9993 5.04731 20.0022 2.61602 19.9983C1.38406 19.9963 0.405985 19.2226 0.139904 18.0427C0.0975059 17.8547 0.0809367 17.657 0.0809367 17.4637C0.0780127 12.5273 0.0765507 7.59086 0.0794747 2.65444C0.079962 1.20701 1.15306 0.119844 2.59166 0.115938C5.04244 0.108616 7.4937 0.114962 9.94448 0.113009C10.3178 0.113009 10.6038 0.261414 10.7559 0.605577C10.8923 0.914591 10.8504 1.21628 10.6282 1.47941C10.4888 1.6449 10.3095 1.74253 10.0912 1.75718C9.99419 1.76401 9.89673 1.76548 9.79975 1.76548C7.43961 1.76548 5.07947 1.76255 2.71934 1.77182C2.52002 1.7728 2.3056 1.82259 2.12528 1.90754C1.89088 2.01786 1.77051 2.24145 1.74614 2.50457C1.73737 2.60123 1.73445 2.69887 1.73445 2.7965C1.73396 5.21931 1.73445 7.64212 1.73445 10.0644L1.73396 10.0639Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_558_7078">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
