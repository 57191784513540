import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'
import { ListSourceCollectionsResponseData } from '@/types/sourceCollection.ts'
import { listSourceCollections } from './use Cases/listSourceCollections.ts'

export const sourceCollectionsSlice = createSlice({
  name: 'sourceCollections',
  initialState: {
    isLoading: false,
    sourceCollections: [] as ListSourceCollectionsResponseData,
    selectedSourceCollectionsLabels: [] as string[],
    listSourceCollectionsError: '',
  },
  reducers: {
    setSelectedSourceCollectionsLabels: (state, action: PayloadAction<string[]>) => {
      state.selectedSourceCollectionsLabels = [...action.payload]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSourceCollections.fulfilled, (state, action) => {
        state.sourceCollections = action.payload
      })
      .addCase(listSourceCollections.rejected, (state, action) => {
        state.listSourceCollectionsError = action.error.message ?? ''
      })
  },
})

export const { setSelectedSourceCollectionsLabels } = sourceCollectionsSlice.actions

export const selectSourceCollections = (state: RootState) => state.entities.sourceCollections.sourceCollections
export const selectSelectedSourcesCollectionLabels = (state: RootState) =>
  state.entities.sourceCollections.selectedSourceCollectionsLabels
export const selectSourceCollectionsLabels = createSelector(
  [selectSourceCollections],
  (sourceCollections) =>
    sourceCollections?.flatMap((sourceCollection) => {
      let sourceCollectionIds: string[]
      sourceCollectionIds = sourceCollection.collections.map((collection) => collection.id)
      if (sourceCollection.idAsSourceCollection) {
        sourceCollectionIds = [sourceCollection.idAsSourceCollection, ...sourceCollectionIds]
      }
      return sourceCollectionIds
    }) ?? [],
)
