import React, { DragEvent, FormEvent } from 'react'
import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import '../assets/scss/dot-animation.scss'
import { TheInputMessageOnlyType } from './TheInputMessageOnlyType.tsx'
import { TheInputSingleFileType } from './TheInputSingleFileType.tsx'
import { TheInputFileComparisonType } from './TheInputFileComparisonType.tsx'
import { useAppSelector } from '../store/hooks.ts'
import { selectAllMessages } from '../store/messages/messagesSlice.ts'

export function TheConversationMessageInputDesktop({
  isQuestionLoading,
  upLoadFile,
  handleStopFileContext,
  userInput,
  onInput,
  handleSend,
  currentConversation,
}: Readonly<TheConversationMessageInputProps>) {
  const messages = useAppSelector(selectAllMessages)
  const handleInputFileOpening = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const handleFileInputChange = (event: FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files?.length) return
    upLoadFile(event.currentTarget.files[0])
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    upLoadFile(e.dataTransfer.files[0])
  }

  const handleKeyDown = async (e: { key: string; shiftKey: boolean }) => {
    if (e.key === 'Enter' && !e.shiftKey && userInput.trim() !== '') {
      await handleSend()
    }
  }

  const renderMessageInput = () => {
    if (currentConversation?.type === 'MESSAGE_ONLY' || messages.length) {
      return (
        <TheInputMessageOnlyType
          messages={messages}
          handleSend={handleSend}
          handleStopFileContext={handleStopFileContext}
          isQuestionLoading={isQuestionLoading}
          onInput={onInput}
          upLoadFile={upLoadFile}
          userInput={userInput}
          handleKeyDown={handleKeyDown}
          handleFileInputChange={handleFileInputChange}
          handleInputFileOpening={handleInputFileOpening}
        />
      )
    } else if (currentConversation?.type === 'FILE_COMPARISON') {
      return (
        <TheInputFileComparisonType
          messages={messages}
          handleSend={handleSend}
          handleStopFileContext={handleStopFileContext}
          isQuestionLoading={isQuestionLoading}
          onInput={onInput}
          upLoadFile={upLoadFile}
          currentConversation={currentConversation}
          userInput={userInput}
          handleDropFile={handleDrop}
          handleInputFileOpening={handleInputFileOpening}
          handleKeyDown={handleKeyDown}
          handleFileInputChange={handleFileInputChange}
        />
      )
    } else {
      return (
        <TheInputSingleFileType
          messages={messages}
          handleSend={handleSend}
          handleStopFileContext={handleStopFileContext}
          isQuestionLoading={isQuestionLoading}
          onInput={onInput}
          upLoadFile={upLoadFile}
          userInput={userInput}
          currentConversation={currentConversation}
          handleInputFileOpening={handleInputFileOpening}
          handleKeyDown={handleKeyDown}
          handleFileInputChange={handleFileInputChange}
        />
      )
    }
  }

  return <>{renderMessageInput()}</>
}
