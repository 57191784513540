import { Badge } from '@/components/ui/badge.tsx'
import { useArticleNotifications } from '@/utils/useArticleNotifications.ts'

export function ArticleNotificationsBadgeContainer() {
  const { totalNewArticlesLength, displayNotifications } = useArticleNotifications()

  return (
    displayNotifications && (
      <Badge variant={'custom'} className={'flex h-6 w-6 items-center justify-center self-start bg-mintGreen p-0'}>
        {totalNewArticlesLength}
      </Badge>
    )
  )
}
