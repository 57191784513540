import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconWithoutName(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { color = '#FDFDFD', ...rest } = props
  return (
    <svg {...rest} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6.4629L14 2.4629M14 2.4629H9.99999M14 2.4629L8 8.46289M6.66667 2.46289H5.2C4.0799 2.46289 3.51984 2.46289 3.09202 2.68088C2.71569 2.87262 2.40973 3.17859 2.21799 3.55491C2 3.98273 2 4.54279 2 5.66289V11.2629C2 12.383 2 12.943 2.21799 13.3709C2.40973 13.7472 2.71569 14.0532 3.09202 14.2449C3.51984 14.4629 4.07989 14.4629 5.2 14.4629H10.8C11.9201 14.4629 12.4802 14.4629 12.908 14.2449C13.2843 14.0532 13.5903 13.7472 13.782 13.3709C14 12.943 14 12.383 14 11.2629V9.79622"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
