import ReactMarkdown, { ExtraProps } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { AnchorHTMLAttributes, ClassAttributes } from 'react'

interface Props {
  content: string
}
export function MarkdownLinkEnhancer({ content }: Readonly<Props>) {
  return (
    <ReactMarkdown
      className={'prose text-base'}
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({
          ...props
        }: ClassAttributes<HTMLAnchorElement> & AnchorHTMLAttributes<HTMLAnchorElement> & ExtraProps) => {
          if (props.href?.includes('./')) {
            return <a {...props} aria-label={''} />
          }
          return <a {...props} aria-label={''} target={'_blank'} rel={'noreferrer'} />
        },
      }}
    >
      {content.replace(/{:target="_blank"}/g, '')}
    </ReactMarkdown>
  )
}
