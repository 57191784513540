import { UploadedFile } from '../../../types/file.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

// Define the action creator function with the correct parameter types
export const updateFile = createAppAsyncThunk(
  'files/updateFile',
  async ({ id, title }: { id: UploadedFile['id']; title: UploadedFile['title'] }, { extra: { fileGateway } }) => {
    try {
      return await fileGateway.updateFileById(id, title)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
