import { ConversationOptionsBarContainer } from '@/components/ConversationOptionsBarContainer.tsx'
import { ConversationPageHeaderContainer } from '@/components/ConversationPageHeaderContainer.tsx'
import { LegalWatchDrawerContainer } from '@/components/LegalWatchDrawerContainer.tsx'
import { MessagesListContainer } from '@/components/MessagesListContainer.tsx'
import { NestedCheckboxList } from '@/components/NestedCheckboxList.tsx'
import { TheConversationMessageInput } from '@/components/TheConversationMessageInput.tsx'
import { BaseModal } from '@/components/atomics/BaseModal.tsx'
import { useLegalWatchDrawerHook } from '@/components/hooks/use-LegalWatchDrawer.hook.tsx'
import { useMessagesListHook } from '@/components/hooks/use-MessagesList.hook.tsx'
import ProfilInfoContainer from '@/components/profileInfos/ProfilInfoContainer.tsx'
import { hideUpdateUserModal, selectShowUpdateUserModal } from '@/store/conversations/conversationsSlice.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectIsSendingMessage } from '@/store/messages/messagesSlice.ts'
import { useAskQuestion } from '@/utils/useAskQuestion.ts'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'
import { useConversationPageHook } from './use-conversation-page.hook.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export function ConversationPage() {
  const {
    userInput,
    targetRef,
    displaySourceFilterMobile,
    handleUpload,
    handleStopFileContext,
    handleUserInput,
    handleDisplayFilterMobile,
    resetUserInput,
  } = useConversationPageHook()
  const { askQuestion } = useAskQuestion()
  const { toggleDisplaySourceFilter, groups, sourceCollectionIds, handleSelectedSourceCollectionsChange } =
    useSourceCollections()
  const { isLegalWatchDrawerDisplayed } = useLegalWatchDrawerHook()
  const { messages, conversation, conversationId } = useMessagesListHook()
  const showUpdateUserModalOpen = useAppSelector(selectShowUpdateUserModal)
  const dispatch = useAppDispatch()
  const isSendingMessage = useAppSelector(selectIsSendingMessage)
  const { isUserConnected } = useCurrentUserHook()

  return (
    <div
      ref={targetRef}
      id={`${ConversationPage.name}-${conversationId}`}
      className={`flex h-[calc(100vh-3.5rem)] flex-col items-center justify-between px-4 pb-4 pt-10 sm:h-full lg:transition-all lg:duration-300 lg:ease-in-out ${isLegalWatchDrawerDisplayed ? 'lg:w-[calc(100%-20rem)]' : 'lg:w-[calc(100%-15rem)]'} ${!messages.length && 'gap-5 sm:justify-start'} md:h-screen lg:pb-6`}
    >
      <div className="no-scrollbar md:no-scrollbar-lg xl:no-scrollbar-xl lg-mt-6 w-full self-start overflow-y-scroll p-4 lg:w-4/5 lg:self-center lg:px-10 xl:pt-10">
        <div className={`${messages.length ? 'flex flex-col gap-5' : ''} h-[${window.innerHeight}px]`}>
          <ConversationPageHeaderContainer />
          <ConversationOptionsBarContainer />
          <MessagesListContainer />
        </div>
      </div>

      {showUpdateUserModalOpen && <ProfilInfoContainer onExit={() => dispatch(hideUpdateUserModal())} />}

      <div
        className={`g:min-w-[40rem] flex w-full flex-col items-center gap-7 ${!messages.length && 'sm:fixed'} sm:bottom-6 sm:w-[500px] lg:w-fit`}
      >
        <div className={'relative w-full'}>
          <TheConversationMessageInput
            handleDisplayFilterMobile={handleDisplayFilterMobile}
            messages={messages}
            toggleDisplaySourceFilter={toggleDisplaySourceFilter}
            userInput={userInput}
            onInput={handleUserInput}
            handleSend={async () => {
              resetUserInput()
              await askQuestion(userInput, conversation, isSendingMessage, sourceCollectionIds)
            }}
            isQuestionLoading={isSendingMessage}
            upLoadFile={handleUpload}
            handleStopFileContext={handleStopFileContext}
            currentConversation={conversation}
          />
        </div>
        <LegalWatchDrawerContainer />
      </div>
      {groups.length > 0 && (
        <BaseModal
          contentVisible={displaySourceFilterMobile}
          onClick={handleDisplayFilterMobile}
          animationClass={'translate-y-full'}
          positionClass={'bottom-0'}
          styleBasedOnScreenSize={'w-full bg-bright shadow-softOutline'}
        >
          <NestedCheckboxList
            title={'FILTRER LES SOURCES'}
            className={
              'm-auto my-0 flex max-h-halfVh w-full flex-col justify-center gap-3 overflow-scroll overflow-y-auto rounded bg-bright pb-8 pl-3 pt-8 lg:hidden'
            }
            groups={groups}
            selectedItems={sourceCollectionIds}
            onSelectedItemsChange={handleSelectedSourceCollectionsChange}
            isUserConnected={isUserConnected}
          />
        </BaseModal>
      )}
    </div>
  )
}
