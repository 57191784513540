import { TheIconChevronUp } from './icons/TheIconChevronUp.tsx'
import { TheIconChevronDown } from './icons/TheIconChevronDown.tsx'

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  status: 'up' | 'down'
}

export function SwitchChevronIcons({ onClick, status }: Readonly<Props>) {
  const IconComponent = status === 'up' ? TheIconChevronUp : TheIconChevronDown
  return <IconComponent width="14" height="8" onClick={onClick} />
}
