import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconChevronFilled(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.78872 6.43253C5.38836 6.94619 4.61164 6.94619 4.21128 6.43253L1.06242 2.39254C0.550566 1.73583 1.01852 0.77779 1.85114 0.77779L8.14886 0.77779C8.98148 0.77779 9.44943 1.73583 8.93758 2.39254L5.78872 6.43253Z" />
    </svg>
  )
}
