import { Conversation, DeleteConversationByIdError } from '../../../types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const deleteConversationById = createAppAsyncThunk(
  'conversations/deleteConversationById',
  async (conversationId: Conversation['id'], { extra: { conversationGateway } }) => {
    const response = await conversationGateway.deleteConversationById(conversationId)

    if (!response.ok) {
      const error: DeleteConversationByIdError = await response.json()
      throw new Error(error.message)
    }
    return Promise.resolve()
  },
)
