import { HTMLAttributes, ReactElement } from 'react'

interface Props extends HTMLAttributes<HTMLButtonElement> {
  titleLeftIcon: ReactElement
  titleRightIcon: ReactElement
  children: ReactElement
  title: string
}
export function SortableOptionsWithIcons({ titleLeftIcon, title, children, titleRightIcon, onClick }: Readonly<Props>) {
  return (
    <div className={'flex flex-col'}>
      <button onClick={onClick} className={'flex items-center justify-between px-3 py-2'}>
        <div className={'flex items-center gap-2'}>
          {titleLeftIcon}
          <span className={`text-base font-normal text-primary`}>{title}</span>
        </div>
        {titleRightIcon}
      </button>
      {children}
    </div>
  )
}
