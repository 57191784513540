import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconDocumentsFolder(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { color = '#FDFDFD', ...rest } = props
  return (
    <svg {...rest} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8334 6.29622L9.90374 4.43699C9.63619 3.90189 9.50241 3.63433 9.30283 3.43886C9.12634 3.266 8.91362 3.13454 8.68008 3.05398C8.41599 2.96289 8.11686 2.96289 7.5186 2.96289H4.33335C3.39993 2.96289 2.93322 2.96289 2.5767 3.14455C2.2631 3.30434 2.00813 3.5593 1.84834 3.87291C1.66669 4.22943 1.66669 4.69614 1.66669 5.62956V6.29622M1.66669 6.29622H14.3334C15.7335 6.29622 16.4336 6.29622 16.9683 6.56871C17.4387 6.80839 17.8212 7.19084 18.0609 7.66125C18.3334 8.19603 18.3334 8.89609 18.3334 10.2962V13.9629C18.3334 15.363 18.3334 16.0631 18.0609 16.5979C17.8212 17.0683 17.4387 17.4507 16.9683 17.6904C16.4336 17.9629 15.7335 17.9629 14.3334 17.9629H5.66669C4.26656 17.9629 3.56649 17.9629 3.03171 17.6904C2.56131 17.4507 2.17885 17.0683 1.93917 16.5979C1.66669 16.0631 1.66669 15.363 1.66669 13.9629V6.29622Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
