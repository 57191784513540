import { BackButtonContainer } from '@/components/BackButtonContainer.tsx'
import { CustomizeWatchPanel } from '@/components/ds/CustomizeWatchPanel.tsx'

interface Props {
  navigateToFavorites: () => void
}
export function LegalWatchDetailsHeader({ navigateToFavorites }: Readonly<Props>) {
  return (
    <div className={'flex items-start justify-between'}>
      <BackButtonContainer label={'Olympe'} />
      <CustomizeWatchPanel
        className={'hidden h-full rounded-lg sm:flex sm:flex-col sm:items-end sm:gap-3.5'}
        onClick={navigateToFavorites}
      />
    </div>
  )
}
