import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const listSourceCollections = createAppAsyncThunk(
  'conversations/getSourceCollections',
  async (_, { extra: { sourceCollectionGateway } }) => {
    try {
      return await sourceCollectionGateway.listSourceCollections()
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
