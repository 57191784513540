import { UploadedFile } from '../../../types/file.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { Tag } from '@/types/tag.ts'
import { ALLOWED_FILE_TYPES } from '@/utils/FileTypes.ts'

export const uploadFile = createAppAsyncThunk(
  'files/uploadFile',
  async (
    { file, title, tags }: { file: File; title: UploadedFile['title']; tags: Tag['id'][] },
    { extra: { fileGateway } },
  ) => {
    try {
      const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''
      if (!ALLOWED_FILE_TYPES.includes('.' + fileExtension)) {
        throw new Error('File type not allowed')
      }
      const response = await fileGateway.uploadFile(file, title, tags)
      const extension = fileExtension.toUpperCase()
      return {
        ...response,
        extension,
      }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
