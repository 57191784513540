import { User } from '../../../types/user.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const createUser = createAppAsyncThunk(
  'auth/createUser',
  async (payload: Pick<User, 'email' | 'password'>, { extra: { authGateway } }) => {
    try {
      const { email, password } = payload
      return await authGateway.createUser({
        email,
        password,
      })
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
