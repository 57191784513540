import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { Tag } from '@/types/tag.ts'

export const updateTag = createAppAsyncThunk(
  'tags/updateTag',
  async (payload: { tagId: Tag['id']; fileTagName: string }, { extra: { tagGateway } }) => {
    try {
      return tagGateway.updateTag(payload)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
