import React from 'react'

export function TheIconExpand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={'hover:cursor-pointer'}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13 7L1 7" stroke="#6E7682" strokeWidth="1.16667" strokeLinecap="round" />
      <path d="M7 1V13" stroke="#6E7682" strokeWidth="1.16667" strokeLinecap="round" />
    </svg>
  )
}
