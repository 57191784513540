import { JobTitle } from '@/store/stepper/steppersSlice'
import { JobTitleItem } from '../registerSteps/jobTitle/right/jobTitleList/JobTitleItem'
import { JOB_TITLES } from '../registerSteps/jobTitle/right/use-jobTitle.hook'
import { Button } from '../ui/button'
import { StepPageProps } from './ProfilInfoContainer'

function FunctionPage({ onPrevious, onNext, state, setState }: StepPageProps) {
  const selectJobTitle = (job: JobTitle) => {
    setState({ ...state, jobTitle: job, otherJobTitle: '' })
  }

  const nextDisabled = (state.jobTitle === 'Autre' && !state.otherJobTitle) || !state.jobTitle

  return (
    <div className="flex  flex-col items-center justify-center gap-9 ">
      <div className="flex flex-col items-center justify-center gap-7 ">
        <h1 className="text-4xl font-semibold">Quelle est votre fonction ?</h1>
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-6">
            <JobTitleItem
              onClick={() => selectJobTitle(JOB_TITLES[0].value)}
              checked={state.jobTitle === JOB_TITLES[0].value}
              jobType={JOB_TITLES[0].jobType}
              size={'large'}
              value={JOB_TITLES[0].value}
            />
            <JobTitleItem
              onClick={() => selectJobTitle(JOB_TITLES[1].value)}
              checked={state.jobTitle === JOB_TITLES[1].value}
              jobType={JOB_TITLES[1].jobType}
              size={'large'}
              value={JOB_TITLES[1].value}
            />
            <div className={'no-wrap flex  items-center gap-3'}>
              <JobTitleItem
                onClick={() => selectJobTitle(JOB_TITLES[2].value)}
                checked={state.jobTitle === JOB_TITLES[2].value}
                jobType={JOB_TITLES[2].jobType}
                size={'default'}
                value={JOB_TITLES[2].value}
              />
              {state.jobTitle === JOB_TITLES[2].value && (
                <div className={'flex w-full flex-col items-start gap-[0.75rem]'}>
                  <p className={'w-[7.5rem] text-[0.875rem] leading-[1.25rem] text-[#6E7682]'}>Précisez...</p>
                  <div
                    className={
                      'flex items-center gap-[2.5rem] self-stretch rounded-[0.5rem] border border-[#E4E4E4] px-[1rem] py-[0.5rem]'
                    }
                  >
                    <input
                      onChange={(e) => setState({ ...state, otherJobTitle: e.target.value })}
                      type={'text'}
                      value={state.otherJobTitle}
                      className={
                        'flex w-full flex-shrink-0 flex-grow basis-0 text-[1rem] leading-[1.5rem] text-[#C1C1C1] outline-none'
                      }
                      placeholder={'Ex. : juriste'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <Button variant={'ghost'} className="w-1/4" type="submit" onClick={onPrevious}>
              Précédent
            </Button>
            <Button
              variant={nextDisabled ? 'disabled' : 'default'}
              disabled={nextDisabled}
              className="w-1/4"
              type="submit"
              onClick={() => {
                setState({ ...state, jobTitle: state.jobTitle === 'Autre' ? state.otherJobTitle : state.jobTitle })
                onNext && onNext()
              }}
            >
              Suivant
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FunctionPage
