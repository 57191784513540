import { Group } from '@/components/NestedCheckboxList.tsx'

export interface ListItem {
  id: string
  label: string
}

export interface UiGroup extends Group {
  index: number
  collapsed: boolean
}

export function getInitialState(groups: Group[]): UiGroup[] {
  return groups.map<UiGroup>((group, index) => ({
    ...group,
    index,
    collapsed: true, // Initially collapsed
  }))
}

export function getIndeterminateGroups(groups: Group[], selectedItems: string[]): number[] {
  return groups
    .map((group, index) => ({
      index,
      children: group.items.map((item) => item.id),
    }))
    .map(({ index, children }) => ({
      index,
      children,
      selectedChildren: children.filter((id) => selectedItems.includes(id)),
    }))
    .filter(
      ({ selectedChildren, children }) => selectedChildren.length > 0 && selectedChildren.length < children.length,
    )
    .map(({ index }) => index)
}

export function getItemIdsByGroupId(groups: Group[]): Map<string, string[]> {
  return groups
    .filter((group) => group.idAsItem)
    .map((group) => [group.idAsItem, group.items] as [string, ListItem[]])
    .map(([groupId, items]) => [groupId, items.map((item) => item.id)] as [string, string[]])
    .reduce((accumulator, [groupId, itemIds]) => {
      accumulator.set(groupId, itemIds)
      return accumulator
    }, new Map<string, string[]>())
}
