import { useState } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../store/hooks.ts'
import { TheIconSuccess } from './icons/TheIconSuccess.tsx'
import { deleteManyFiles } from '../store/files/useCases/deleteManyFiles.ts'
import { selectAllFiles } from '../store/files/filesSlice.ts'

export type FilterKey = 'Date' | 'Extension' | 'Alphabetic-Order'

export type Filter = {
  key: FilterKey
  label: string
}

type Props = {
  sortFiles: (filterKey: FilterKey) => void
}

/**
 * Mobile file filter panel component for sorting and filtering files.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.sortFiles - A function to sort files based on a selected filter key.
 */
export function TheMobileFileFilterPanel({ sortFiles }: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const allUserFiles = useAppSelector(selectAllFiles)
  const [activeFilter, setActiveFilter] = useState<Filter>({ key: 'Date', label: 'Plus récent' })

  /**
   * Array of available filters.
   *
   * @type {Filter[]}
   */
  const filters: Filter[] = [
    { key: 'Date', label: 'Plus récent' },
    { key: 'Extension', label: 'Format' },
    { key: 'Alphabetic-Order', label: 'Ordre alphabétique' },
  ]

  /**
   * Get the CSS class for a filter item based on its activation state.
   *
   * @param {Filter} filter - The filter object.
   * @returns {string} - The CSS class for the filter item.
   */
  const setFilterStyle = (filter: Filter) => {
    return filter.key === activeFilter.key
      ? 'text-base leading-5 text-normal font-bold text-primary'
      : 'text-base leading-5 text-primary font-normal'
  }

  /**
   * Handles the click event when a filter is selected.
   *
   * @param {Filter} filter - The selected filter.
   */
  const handleClick = (filter: Filter) => {
    setActiveFilter(filter)
    switch (filter.key) {
      case 'Date':
        sortFiles('Date')
        break
      case 'Extension':
        sortFiles('Extension')
        break
      default:
        sortFiles('Alphabetic-Order')
        break
    }
  }

  /**
   * Handles the click event when the "Supprimer tous les documents" button is clicked.
   */
  const handleDeleteAllUserFiles = () => {
    const ids = allUserFiles.map((file) => file.id)
    dispatch(deleteManyFiles(ids))
  }

  return (
    <div
      className={
        'h-50 absolute -bottom-0 left-0 hidden w-full -translate-y-0 transform flex-col gap-4 rounded-tl-2xl rounded-tr-2xl bg-bright px-3 pb-8 pt-6'
      }
    >
      <h4 className={'text-sm text-label'}>Trier par</h4>
      <ul className={'flex flex-col items-start justify-between gap-5'}>
        {filters.map((filter) => {
          return (
            <li className={'w-full'} onClick={() => handleClick(filter)} key={nanoid()}>
              <div className={'flex w-full items-center justify-between'}>
                <span className={` ${setFilterStyle(filter)}`}>{filter.label}</span>
                {filter.key === activeFilter?.key && <TheIconSuccess className={'h-4 w-4 fill-primary'} />}
              </div>
            </li>
          )
        })}
      </ul>
      <div
        className={
          'text-normal border-t-solid border-t border-t-primary pt-5 text-base font-bold leading-5 text-primary'
        }
        onClick={handleDeleteAllUserFiles}
      >
        Supprimer tous les documents
      </div>
    </div>
  )
}
