import { FormEventHandler } from 'react'
import { TheIconRefresh } from '@/components/icons/TheIconRefresh.tsx'
import TextareaAutosize from 'react-textarea-autosize'
import { SubmitButtonOrLoading } from '@/components/SubmitButtonOrLoading.tsx'
import { Message } from '@/types/message'

interface Props {
  inputValue: string
  onInputChange: FormEventHandler<HTMLTextAreaElement>
  onSubmit: () => void
  onRestartConversation: () => void
  isSendingMessage: boolean
  messages: Message[]
  handleKeyDown?: (e: { key: string; shiftKey: boolean }) => void
}

export function DiscussionPanelInput({
  inputValue,
  onInputChange,
  onSubmit,
  isSendingMessage,
  messages,
  onRestartConversation,
  handleKeyDown,
}: Readonly<Props>) {
  return (
    <div className={'flex w-full'}>
      <div
        className={
          'flex w-4/5 flex-col items-end justify-between gap-2 rounded-lg border border-solid border-mistySerenity py-4 pl-4 pr-3.5'
        }
      >
        <div className={'w-full'}>
          <TextareaAutosize
            placeholder={'Vous pouvez poser votre question'}
            value={inputValue}
            disabled={isSendingMessage}
            onInput={onInputChange}
            onKeyDown={handleKeyDown}
            className={
              'my-4 max-h-[148px] w-full resize-none rounded-lg bg-bright text-base leading-5 outline-none sm:my-0 sm:py-2.5'
            }
          />
        </div>
        <SubmitButtonOrLoading
          handleSend={onSubmit}
          isQuestionLoading={isSendingMessage}
          messagesLength={messages?.length ?? 0}
          userInputLength={inputValue.length}
        />
      </div>
      <div className={'flex w-1/5 items-center justify-center'}>
        <TheIconRefresh
          onClick={onRestartConversation}
          className={'stroke-primary hover:cursor-pointer hover:stroke-label'}
        />
      </div>
    </div>
  )
}
