import { ReactElement, RefObject } from 'react'
import { RoutePathsItem } from '@/ROUTE_PATHS.ts'
import { HybridLink } from './HybridLink.tsx'
import { LabelAndIcons } from './LabelAndIcons.tsx'

interface TheSideBarItemProps {
  forwardedRef?: RefObject<HTMLDivElement>
  label: string
  to?: RoutePathsItem
  href?: string
  leftIcon: React.FC
  rightIcon?: React.FC
  disabled?: boolean
  isDisabledButton?: boolean
  children?: false | ReactElement
}

export function TheSideBarItem({
  leftIcon,
  to,
  forwardedRef,
  label,
  href,
  rightIcon,
  disabled,
  isDisabledButton,
  children,
}: Readonly<TheSideBarItemProps>) {
  return (
    <div className={'w-full'} ref={forwardedRef}>
      <HybridLink isDisabledButton={isDisabledButton} disabled={disabled} href={href} to={to}>
        <LabelAndIcons disabled={disabled} label={label} leftIcon={leftIcon} rightIcon={rightIcon} />
        {children}
      </HybridLink>
    </div>
  )
}
