import { CreateConversationResponseData } from '@/types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const createConversation = createAppAsyncThunk<
  CreateConversationResponseData,
  | {
      type: 'MESSAGE_ONLY' | 'FILE_COMPARISON' | 'SINGLE_FILE'
    }
  | {
      type: 'ARTICLE'
      articleId: string
    }
>('conversations/createConversation', async (monObj, { extra: { conversationGateway } }) => {
  try {
    return await conversationGateway.createConversation(monObj)
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
