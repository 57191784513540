import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Button } from '@/components/ui/button.tsx'
import { UseFormReturn } from 'react-hook-form'
import { clsx } from 'clsx'
import { AddDocumentSchema } from '@/components/modals/hooks/use-createDocumentModal.hook.tsx'

interface FormValues {
  name: string // Add more fields as necessary
}

interface Props {
  handleSubmit: (values: AddDocumentSchema) => void
  handleCancel: () => void
  form: UseFormReturn<FormValues>
  buttonLabel: string
}

export function DocumentForm({ handleSubmit, handleCancel, form, buttonLabel }: Readonly<Props>) {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  placeholder={'Ex. : Droit suisse'}
                  className={clsx(
                    'h-full w-full rounded-lg border border-solid px-6 py-3.5 text-base leading-5 text-primary outline-none placeholder:text-label focus-visible:ring-0 focus-visible:ring-offset-2',
                    {
                      'border-error': form.formState.errors.name,
                      'focus-visible:border-primary': !form.formState.errors.name,
                    },
                  )}
                />
              </FormControl>
              <FormMessage className={'text-sm text-error'}>{form.formState.errors.name?.message}</FormMessage>{' '}
            </FormItem>
          )}
        />
        <div className={'flex flex-col items-center'}>
          <Button type={'submit'} className={'text-sm'}>
            {buttonLabel}
          </Button>
          <Button
            variant={'secondary'}
            type={'button'}
            onClick={handleCancel}
            className={'text-sm font-semibold text-primary hover:cursor-pointer'}
          >
            Annuler
          </Button>
        </div>
      </form>
    </Form>
  )
}
