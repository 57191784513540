import { ContextualLabel } from '@/components/ContextualLabel.tsx'
import { HTMLAttributes } from 'react'
import { Button } from '@/components/ui/button.tsx'
import { Bookmark } from '@/components/icons/Bookmark.tsx'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void
}
export function CustomizeWatchPanel({ onClick, ...props }: Readonly<Props>) {
  return (
    <div {...props}>
      <ContextualLabel badgeLabel={'Bientôt disponible'} label={'Personnaliser ma veille'} />
      <Button
        onClick={onClick}
        className={`group flex w-[8,43rem] items-center gap-2 rounded-3xl border-2 border-solid border-transparent bg-mintGreen px-4 py-2 hover:cursor-pointer hover:border-mintGreen hover:bg-bright`}
      >
        <span className={'text-sm font-semibold text-bright group-hover:text-mintGreen'}>Mes favoris</span>
        <Bookmark className={'fill-mintGreen stroke-bright group-hover:bg-bright'} />
      </Button>
    </div>
  )
}
