import { ChevronRightIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import { generatePath } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS'
import { createConversationSnapshotByConversationId } from '../../store/conversationSnapshot/useCases/createConversationSnapshot'
import { deleteConversationSnapshotById } from '../../store/conversationSnapshot/useCases/deleteConversationSnapshotById'
import { updateConversationSnapshotByConversationId } from '../../store/conversations/conversationsSlice'
import { useAppDispatch } from '../../store/hooks'
import { useToaster } from '../../utils/useToaster'
import { BaseButton } from '../atomics/BaseButton'
import { RevokeShareIcon } from '../icons/RevokeShareIcon'
import { ShareIcon } from '../icons/ShareIcon'
import { Button } from '../ui/button'
import ConversationDropDown from './ConversationDropDown'
import ConversationTooltip from './ConversationTooltip'

interface ConversationShareMenuProps {
  id: string
  snapshotId?: string
}

function ConversationShareMenu({ snapshotId, id }: ConversationShareMenuProps) {
  const dispatch = useAppDispatch()

  const copyLinkToClipboard = (snapshotId: string) => {
    navigator.clipboard.writeText(`${window.location.origin}${idToLink(snapshotId)}`)
    showToast({
      toasterPosition: 'top',
      toasterMessage: 'Le lien de partage de conversation a été copié.',
      toasterType: 'success',
    })
  }

  const share = async (id: string) => {
    const conversationSnapshot = await dispatch(createConversationSnapshotByConversationId(id)).unwrap()
    dispatch(updateConversationSnapshotByConversationId({ id, snapshotId: conversationSnapshot.id }))
    copyLinkToClipboard(conversationSnapshot.id)
  }

  const idToLink = (snapshotId: string) => generatePath(ROUTE_PATHS.sharedConversations, { snapshotId })
  const { showToast } = useToaster()
  return (
    <div className="flex items-center justify-center gap-4">
      <div className="flex  items-center justify-center ">
        <ConversationTooltip />
      </div>
      {!snapshotId ? (
        <BaseButton
          color="tertiary"
          className="gap-3 border border-primary p-2"
          onClick={() => {
            share(id)
          }}
          icon={<ShareIcon className="h-5 w-5" />}
          label="Partager"
        ></BaseButton>
      ) : (
        <ConversationDropDown
          toggle={
            <Button color="secondary">
              Partagé
              <ChevronRightIcon className="ml-1 h-4 w-4 fill-white" />
            </Button>
          }
          items={[
            {
              name: 'Copier le lien du partage',
              icon: <ClipboardDocumentCheckIcon className="h-4 w-4" />,
              action: () => {
                if (snapshotId) {
                  copyLinkToClipboard(snapshotId)
                  return
                }
                showToast({
                  toasterMessage: "Le lien de partage de conversation n'a pas été copié.",
                  toasterPosition: 'top',
                  toasterType: 'success',
                })
              },
            },
            {
              name: 'Révoquer le partage',
              icon: <RevokeShareIcon className="h-4 w-4" />,
              action: () => {
                dispatch(deleteConversationSnapshotById(snapshotId))
                dispatch(updateConversationSnapshotByConversationId({ id, snapshotId: undefined }))
                showToast({
                  toasterMessage: 'L’accès à votre conversation par des tiers à été révoqué.',
                  toasterPosition: 'top',
                  toasterType: 'success',
                })
              },
            },
          ]}
        />
      )}
    </div>
  )
}

export default ConversationShareMenu
