import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconPromptTutorial(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33333 8.04809C8.48016 7.63069 8.76998 7.27873 9.15144 7.05454C9.53291 6.83035 9.98141 6.74839 10.4175 6.8232C10.8536 6.898 11.2492 7.12473 11.5341 7.46323C11.8191 7.80173 11.975 8.23015 11.9744 8.67262C11.9744 9.92169 10.1008 10.5462 10.1008 10.5462M10.1249 13.0462H10.1333M8.25 16.9629L9.46667 18.5851C9.6476 18.8264 9.73807 18.947 9.84897 18.9901C9.94611 19.0279 10.0539 19.0279 10.151 18.9901C10.2619 18.947 10.3524 18.8264 10.5333 18.5851L11.75 16.9629C11.9943 16.6372 12.1164 16.4743 12.2654 16.35C12.4641 16.1842 12.6986 16.0669 12.9504 16.0075C13.1393 15.9629 13.3429 15.9629 13.75 15.9629C14.9149 15.9629 15.4973 15.9629 15.9567 15.7726C16.5693 15.5189 17.056 15.0322 17.3097 14.4196C17.5 13.9602 17.5 13.3777 17.5 12.2129V7.46289C17.5 6.06276 17.5 5.36269 17.2275 4.82791C16.9878 4.35751 16.6054 3.97506 16.135 3.73537C15.6002 3.46289 14.9001 3.46289 13.5 3.46289H6.5C5.09987 3.46289 4.3998 3.46289 3.86502 3.73537C3.39462 3.97506 3.01217 4.35751 2.77248 4.82791C2.5 5.36269 2.5 6.06276 2.5 7.46289V12.2129C2.5 13.3777 2.5 13.9602 2.6903 14.4196C2.94404 15.0322 3.43072 15.5189 4.04329 15.7726C4.50272 15.9629 5.08515 15.9629 6.25 15.9629C6.65715 15.9629 6.86072 15.9629 7.04959 16.0075C7.30141 16.0669 7.53593 16.1842 7.73458 16.35C7.88357 16.4743 8.00571 16.6372 8.25 16.9629Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
