import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { ResetPasswordForm } from '@/pages/reset-password/ResetPasswordForm.tsx'

export function ResetPassword() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-10">
      <div className={'flex w-11/12 flex-col items-center justify-center sm:w-1/2'}>
        <div className="mb-10 flex flex-col items-center gap-3 sm:w-full">
          <TheIconOlympeLogoWithText className={'fill-primary'} />
          <h1 className="text-center text-xl font-semibold text-primary sm:text-3xl">Mot de passe oublié ?</h1>
          <p className={'text-center text-base font-normal text-label'}>
            ✉️ Entrez {"l'adresse"} email associée à votre compte et nous vous enverrons un email avec un lien de
            réinitialisation.
          </p>
        </div>
        <ResetPasswordForm />
      </div>
    </div>
  )
}
