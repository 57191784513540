import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconFilterMobile(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="3" fill="#212121" />
      <g clipPath="url(#clip0_2023_13029)">
        <path
          d="M7 8.87226C7.12349 8.63257 7.32918 8.57492 7.58676 8.57528C9.70179 8.58072 11.8168 8.57855 13.9315 8.57855H14.0966C14.2479 7.95304 14.6067 7.49253 15.177 7.20752C15.5412 7.02549 15.9289 6.96167 16.3414 7.02331C17.2332 7.15712 17.7882 7.66404 18.0505 8.50421C18.0797 8.59813 18.1393 8.57746 18.197 8.57746C18.9628 8.57855 19.7286 8.57818 20.4944 8.57818C20.6482 8.57818 20.7808 8.6217 20.8893 8.73954C21.0077 8.86863 21.0194 9.01622 20.966 9.16634C20.9105 9.32153 20.7907 9.42451 20.6215 9.44083C20.4816 9.45461 20.3391 9.44627 20.198 9.44663C19.5327 9.44663 18.8671 9.44772 18.2018 9.44555C18.1148 9.44555 18.0746 9.46186 18.0454 9.56013C17.8718 10.1465 17.5072 10.5856 16.9475 10.842C16.2084 11.1803 15.5025 11.1027 14.8478 10.6182C14.4938 10.3561 14.2585 10.0105 14.1357 9.58986C14.1233 9.5478 14.1116 9.50574 14.0952 9.447H13.9417C11.7993 9.447 9.65722 9.44446 7.51478 9.45062C7.26634 9.45135 7.09938 9.36577 7.00073 9.14349V8.8719L7 8.87226ZM17.2306 9.02238C17.2829 8.38745 16.7016 7.8885 16.1123 7.86529C15.4693 7.84027 14.9201 8.39978 14.9245 9.01658C14.9293 9.64281 15.4539 10.1762 16.0655 10.1737C16.6801 10.1711 17.2632 9.67943 17.2303 9.02202L17.2306 9.02238Z"
          fill="#FDFDFD"
        />
        <path
          d="M7 18.8229C7.12349 18.5833 7.32918 18.5256 7.58676 18.526C9.70179 18.5314 11.8168 18.5292 13.9315 18.5292H14.0966C14.2479 17.9037 14.6067 17.4432 15.177 17.1582C15.5412 16.9762 15.9289 16.9124 16.3414 16.974C17.2332 17.1078 17.7882 17.6147 18.0505 18.4549C18.0797 18.5488 18.1393 18.5281 18.197 18.5281C18.9628 18.5292 19.7286 18.5289 20.4944 18.5289C20.6482 18.5289 20.7808 18.5724 20.8893 18.6902C21.0077 18.8193 21.0194 18.9669 20.966 19.117C20.9105 19.2722 20.7907 19.3752 20.6215 19.3915C20.4816 19.4053 20.3391 19.397 20.198 19.3973C19.5327 19.3973 18.8671 19.3984 18.2018 19.3962C18.1148 19.3962 18.0746 19.4125 18.0454 19.5108C17.8718 20.0972 17.5072 20.5363 16.9475 20.7926C16.2084 21.131 15.5025 21.0534 14.8478 20.5689C14.4938 20.3067 14.2585 19.9612 14.1357 19.5405C14.1233 19.4985 14.1116 19.4564 14.0952 19.3977H13.9417C11.7993 19.3977 9.65722 19.3951 7.51478 19.4013C7.26634 19.402 7.09938 19.3165 7.00073 19.0942V18.8226L7 18.8229ZM17.2306 18.9731C17.2829 18.3381 16.7016 17.8392 16.1123 17.816C15.4693 17.791 14.9201 18.3505 14.9245 18.9673C14.9293 19.5935 15.4539 20.1269 16.0655 20.1243C16.6801 20.1218 17.2632 19.6301 17.2303 18.9727L17.2306 18.9731Z"
          fill="#FDFDFD"
        />
        <path
          d="M7 13.812C7.12239 13.5857 7.30836 13.5041 7.56995 13.5085C8.34889 13.5219 9.12855 13.5132 9.90931 13.5132C10.0796 12.871 10.4504 12.3916 11.058 12.1106C11.4179 11.9442 11.796 11.9068 12.1928 11.9601C12.5611 12.0098 12.8785 12.1494 13.1694 12.3702C13.5154 12.6331 13.7408 12.978 13.8613 13.3866C13.8928 13.4932 13.9304 13.5146 14.029 13.5146C16.181 13.5124 18.3325 13.5128 20.4845 13.5135C20.6529 13.5135 20.8045 13.5509 20.9119 13.7006C21.0121 13.8399 21.0289 13.9853 20.951 14.1314C20.8681 14.2877 20.7362 14.382 20.5481 14.382C18.3779 14.3816 16.208 14.382 14.0378 14.3802C13.9355 14.3802 13.8939 14.3968 13.8599 14.5125C13.7137 15.006 13.4142 15.3929 12.9699 15.6656C12.6908 15.8371 12.3897 15.9263 12.0612 15.9604C11.4781 16.021 10.9933 15.8252 10.5615 15.4571C10.2279 15.1728 10.0193 14.8146 9.91406 14.3816H9.40184C8.79097 14.3816 8.17973 14.3693 7.56922 14.3874C7.30324 14.395 7.12312 14.3076 7 14.0832V13.8116L7 13.812ZM10.7474 13.949C10.7295 14.5647 11.2648 15.1134 11.8709 15.1079C12.5505 15.1021 13.059 14.6325 13.0671 13.9476C13.0744 13.3373 12.6001 12.7666 11.7953 12.8057C11.226 12.8333 10.7237 13.3685 10.7474 13.9487V13.949Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_2023_13029">
          <rect width="14" height="14" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  )
}
