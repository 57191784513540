import { TheIconOlympeLogoWithoutText } from '../../../icons/TheIconOlympeLogoWithoutText.tsx'
import { useCredentialsStepHook } from './use-credentialsStep.hook.tsx'
import { SignupForm } from '@/components/SignupForm.tsx'
import { FooterLinks } from '@/components/FooterLinks.tsx'
import { useAuthFormModal } from '@/components/modals/hooks/use-authFormModal.tsx'
import { useNavigate } from 'react-router-dom'

export function RegisterStepCredentialsRightPart() {
  const navigate = useNavigate()
  const { navigationStyle, headerStyle } = useCredentialsStepHook()
  const { onSignupSubmit } = useAuthFormModal()

  return (
    <div
      className={
        'flex h-[44.5rem] w-full flex-shrink-0 flex-grow basis-0 flex-col items-center justify-center gap-[1rem] rounded-[0.5rem] px-4 md:px-[7rem]'
      }
    >
      <div
        className={
          'flex max-h-full flex-shrink-0 flex-grow basis-0 flex-col items-center justify-center gap-5 self-stretch pt-[2rem] md:w-[26.2rem]'
        }
      >
        <div
          className={`flex flex-shrink-0 flex-grow basis-0 flex-col items-center justify-center ${headerStyle} self-stretch`}
        >
          <div className={'flex flex-col items-center justify-center gap-4 self-stretch'}>
            <TheIconOlympeLogoWithoutText width="41" height="40" />
            <h1 className="h-14 text-center text-4xl font-semibold text-primary">Inscription</h1>
          </div>
          <SignupForm onSubmit={onSignupSubmit} />
        </div>
        <div className={`flex flex-col items-center justify-center ${navigationStyle} self-stretch`}>
          <FooterLinks
            onClick={() => navigate('/login')}
            catchphrase={'Vous avez déjà un compte'}
            linkLabel={'Connectez-vous'}
          />
        </div>
      </div>
    </div>
  )
}
