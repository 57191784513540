import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

// adding props is needed since TheIconError will be used in various contexts,
// including the BaseButton context, which injects use Cases props programmatically
export function TheIconError(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.12 110.24" {...props}>
      <path d="m55.45,0C28.04-.48-2.48,22.93.16,59.63c1.79,24.9,22.73,50.42,54.93,50.6,28.44.16,54.89-22.54,55.03-55.24C110.25,26.54,87.21.57,55.45,0Zm-.26,103.31c-23.99,1.23-46.78-19.98-48.31-44.5C5.12,30.73,26.38,8.7,51.37,7.02c28.38-1.91,49.89,19.41,51.81,44.34,2.09,27.15-19.01,51.96-47.99,51.96Z" />
      <path d="m33.9,30.22c2.89.72,4.44,3.5,6.54,5.52,4.66,4.49,9.22,9.08,13.71,13.74,1.12,1.17,1.48.2,2.03-.35,5.49-5.48,10.94-10.99,16.45-16.45,1.28-1.27,2.49-2.9,4.7-2.28,1.37.38,2.25,1.28,2.5,2.71.44,2.48-1.6,3.58-2.94,4.94-5.53,5.61-11.16,11.11-16.74,16.67-.11.11-.09.52.02.64,6.21,6.23,12.47,12.42,18.65,18.69,1.4,1.42,1.59,3.28.17,4.78-1.59,1.67-3.51,1.5-5.09-.07-6.24-6.18-12.42-12.43-18.62-18.66-.62-.63-.62.21-.8.38-6.18,6.14-12.31,12.32-18.5,18.44-1.5,1.48-3.32,1.49-4.78-.02-1.53-1.59-1.33-3.44.17-4.97,5.99-6.06,12-12.1,18.08-18.07.84-.83.54-1.06-.07-1.67-5.87-5.82-11.71-11.67-17.56-17.51-1.21-1.21-2.11-2.5-1.35-4.32.58-1.38,1.67-2,3.43-2.17Z" />
    </svg>
  )
}
