import { cn } from '@/lib/utils.ts'
import { LegalWatchArticlePreviewContainer } from '@/components/LegalWatchArticlePreviewContainer.tsx'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'

interface Props {
  articles: LegalWatchArticle[]
  className?: string
}
export function LegalWatchArticleList({ articles, className }: Readonly<Props>) {
  return (
    <div className={cn(`grid grid-cols-1 ${className}`)}>
      {articles.map((article) => (
        <LegalWatchArticlePreviewContainer key={article.id} article={article} />
      ))}
    </div>
  )
}
