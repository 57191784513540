import { transformISOToDDMMYYYY } from '@/utils/date.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalResource } from '@/types/legalResource.ts'

export const getTextById = createAppAsyncThunk<LegalResource, LegalResource['id']>(
  'texts/getTextById',
  async (articleId: LegalResource['id'], { extra: { legalResourceGateway } }) => {
    try {
      const { createdAt, ...article } = await legalResourceGateway.getTextById(articleId)
      return { ...article, createdAt: transformISOToDDMMYYYY(createdAt) }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
