import * as React from 'react'

import { cn } from '@/lib/utils'
import { TheIconHideEye } from '../icons/TheIconHideEye'
import { TheIconVisibleEye } from '../icons/TheIconVisibleEye'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const inputType = type === 'password' && showPassword ? 'text' : type

  return (
    <div className="relative">
      <input
        type={inputType}
        className={cn(
          'flex h-10 w-full rounded-md border border-solid border-input px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-silver focus:border-primary focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
      />
      {type === 'password' && (
        <button type="button" className="absolute right-4 top-1/2 -translate-y-1/2" onClick={togglePasswordVisibility}>
          {!showPassword ? (
            <TheIconHideEye className="h-4 w-4 fill-label" />
          ) : (
            <TheIconVisibleEye className="h-4 w-4 fill-label" />
          )}
        </button>
      )}
    </div>
  )
})

Input.displayName = 'Input'

export { Input }
