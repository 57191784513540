import { Message } from '@/types/message.ts'
import { updateMessageFeedback } from '@/store/messages/useCases/updateMessageFeedback.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { useState } from 'react'
import { Conversation } from '@/types/conversation.ts'
export const useMessageFeedBack = () => {
  const dispatch = useAppDispatch()
  const [showAppointmentButton, setShowAppointmentButton] = useState(false)

  const updateFeedback = (
    feedbackFromIcon: Message['feedback'],
    feedbackFromMessage?: Message['feedback'],
    conversationId?: Conversation['id'],
    messageId?: Message['id'],
  ) => {
    // Show the appointment button only on the first thumb down click
    if (feedbackFromIcon === 'THUMBS_DOWN' && feedbackFromMessage !== 'THUMBS_DOWN') {
      setShowAppointmentButton(true)
    } else {
      setShowAppointmentButton(false) // Hide it if the feedback is changed to any other value
    }
    if (!conversationId) return
    if (!messageId) return
    dispatch(updateMessageFeedback({ conversationId, messageId, feedback: feedbackFromIcon }))
  }

  const handleThumbDownClick = (
    feedbackFromIcon: 'THUMBS_UP' | 'THUMBS_DOWN',
    feedbackFromMessage: Message['feedback'],
    conversationId: Conversation['id'],
    messageId: Message['id'],
  ) => {
    if (feedbackFromIcon === 'THUMBS_DOWN') return () => updateFeedback('NULL')
    updateFeedback('THUMBS_DOWN', feedbackFromMessage, conversationId, messageId)
  }
  const handleThumbDownUpClick = (
    feedbackFromIcon: 'THUMBS_UP' | 'THUMBS_DOWN',
    feedbackFromMessage: Message['feedback'],
    conversationId: Conversation['id'],
    messageId: Message['id'],
  ) => {
    if (feedbackFromIcon === 'THUMBS_UP') return () => updateFeedback('NULL')
    updateFeedback('THUMBS_UP', feedbackFromMessage, conversationId, messageId)
  }

  return { showAppointmentButton, handleThumbDownUpClick, handleThumbDownClick }
}
