import { AppDispatch } from '@/store/configureStore.ts'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { Group } from '@/components/NestedCheckboxList.tsx'

/**
 * Maps item IDs to group IDs.
 * @param {Group[]} groups - The array of groups containing items.
 * @returns {Map<string, string>} A map where keys are item IDs and values are group IDs.
 * @example
 * const groups = [
 *   { idAsItem: 'group1', items: [{ id: 'item1' }, { id: 'item2' }] },
 *   { idAsItem: 'group2', items: [{ id: 'item3' }] }
 * ];
 * const result = createGroupIdByItemIdMap(groups);
 * // result will be Map { 'item1' => 'group1', 'item2' => 'group1', 'item3' => 'group2' }
 */
export function createGroupIdByItemIdMap(groups: Group[]): Map<string, string> {
  return groups
    .filter((group) => group.idAsItem)
    .flatMap((group) => group.items.map((item) => [group.idAsItem, item.id] as [string, string]))
    .reduce((accumulator, [groupId, itemId]) => {
      accumulator.set(itemId, groupId)
      return accumulator
    }, new Map<string, string>())
}

/**
 * Ensures that all items within a group are selected if the group's `idAsItem` is selected.
 * @param {Group[]} groups - The array of groups containing items.
 * @param {string[]} selectedItems - The currently selected item IDs.
 * @returns {string[]} An array of selected item IDs after ensuring group items are selected.
 * @example
 * const groups = [
 *   { idAsItem: 'group1', items: [{ id: 'item1' }, { id: 'item2' }] }
 * ];
 * const selectedItems = ['group1'];
 * const result = ensureGroupItemsSelected(groups, selectedItems);
 * // result will be ['group1', 'item1', 'item2']
 */
export function ensureGroupItemsSelected(groups: Group[], selectedItems: string[]): string[] {
  const updatedSelectedItems = new Set(selectedItems)

  groups.forEach((group) => {
    if (group.idAsItem && updatedSelectedItems.has(group.idAsItem)) {
      group.items.forEach((item) => updatedSelectedItems.add(item.id))
    }
  })

  return Array.from(updatedSelectedItems)
}

/**
 * Handles checkbox change for individual items.
 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the checkbox.
 * @param {string} itemId - The ID of the item whose checkbox was changed.
 * @param {string[]} selectedItems - The currently selected item IDs.
 * @param {Map<string, string>} groupIdByItemId - A map of item IDs to group IDs.
 * @param {Map<string, string[]>} itemIdsByGroupId - A map of group IDs to their item IDs.
 * @param {Group[]} groups - The array of groups containing items.
 * @returns {string[]} An updated array of selected item IDs.
 * @example
 * const event = { target: { checked: true } } as React.ChangeEvent<HTMLInputElement>;
 * const selectedItems = ['item1'];
 * const result = handleItemCheckboxChange(event, 'item2', selectedItems, groupIdByItemId, itemIdsByGroupId, groups);
 * // result will include 'item1' and 'item2' if 'item2' is checked
 */
export function handleItemCheckboxChange(
  event: React.ChangeEvent<HTMLInputElement>,
  itemId: string,
  selectedItems: string[],
  groupIdByItemId: Map<string, string>,
  itemIdsByGroupId: Map<string, string[]>,
  groups: Group[],
): string[] {
  const newSelectedItems = new Set(selectedItems)

  if (groupIdByItemId.has(itemId)) {
    const groupId = groupIdByItemId.get(itemId) as string
    if (event.target.checked) {
      newSelectedItems.add(itemId)
      const allChildrenItemIds = itemIdsByGroupId.get(groupId) ?? []
      if (allChildrenItemIds.every((childItemId) => newSelectedItems.has(childItemId))) {
        newSelectedItems.add(groupId)
      }
    } else {
      newSelectedItems.delete(itemId)
      newSelectedItems.delete(groupId)
    }
  } else {
    event.target.checked ? newSelectedItems.add(itemId) : newSelectedItems.delete(itemId)
  }

  return ensureGroupItemsSelected(groups, Array.from(newSelectedItems))
}

/**
 * Handles checkbox change for entire groups.
 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the checkbox.
 * @param {Group} group - The group whose checkbox was changed.
 * @param {string[]} selectedItems - The currently selected item IDs.
 * @param {boolean} isUserConnected - Indicates if the user is connected.
 * @param {AppDispatch} dispatch - The Redux dispatch function.
 * @param {ActionCreatorWithoutPayload<'modals/openAuthModal'>} onAuthModalOpen - Action creator for opening the auth modal.
 * @returns {string[]} An updated array of selected item IDs.
 * @example
 * const event = { target: { checked: true } } as React.ChangeEvent<HTMLInputElement>;
 * const group = { idAsItem: 'group1', items: [{ id: 'item1' }, { id: 'item2' }] };
 * const selectedItems = [];
 * const result = handleGroupCheckboxChange(event, group, selectedItems, true, dispatch, onAuthModalOpen);
 * // result will include 'item1', 'item2', and 'group1' if the group checkbox is checked
 */
export function handleGroupCheckboxChange(
  event: React.ChangeEvent<HTMLInputElement>,
  group: Group,
  selectedItems: string[],
  isUserConnected: boolean,
  dispatch: AppDispatch,
  onAuthModalOpen: ActionCreatorWithoutPayload<'modals/openAuthModal'>,
): string[] {
  if (group.idAsItem === '_user-files-all' && !isUserConnected) {
    dispatch(onAuthModalOpen())
    return selectedItems
  }

  const itemIdsFromGroup = group.items.map((item) => item.id)
  const newSelectedItems = new Set(selectedItems)

  if (group.idAsItem) {
    if (event.target.checked) {
      itemIdsFromGroup.forEach((id) => newSelectedItems.add(id))
      newSelectedItems.add(group.idAsItem)
    } else {
      itemIdsFromGroup.forEach((id) => newSelectedItems.delete(id))
      newSelectedItems.delete(group.idAsItem)
    }
  } else {
    event.target.checked
      ? itemIdsFromGroup.forEach((id) => newSelectedItems.add(id))
      : itemIdsFromGroup.forEach((id) => newSelectedItems.delete(id))
  }

  return ensureGroupItemsSelected([group], Array.from(newSelectedItems))
}
