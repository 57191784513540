interface TheIconThumbDownProps extends React.SVGProps<SVGSVGElement> {
  isSelected?: boolean
}

export function TheIconThumbDown({ className, isSelected, ...rest }: Readonly<TheIconThumbDownProps>) {
  const strokeColorClass = isSelected ? 'stroke-primary' : 'stroke-[#6E7682]'
  return (
    <svg
      {...rest}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      className={`${strokeColorClass} ${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6665 2.1665V11.3332M18.8332 8.6665V4.83317C18.8332 3.89975 18.8332 3.43304 18.6516 3.07652C18.4918 2.76292 18.2368 2.50795 17.9232 2.34816C17.5667 2.1665 17.1 2.1665 16.1665 2.1665H7.26489C6.04699 2.1665 5.43804 2.1665 4.9462 2.38937C4.51271 2.58579 4.1443 2.90186 3.88425 3.30043C3.58919 3.75267 3.49659 4.35453 3.3114 5.55827L2.87551 8.39161C2.63125 9.97926 2.50912 10.7731 2.74472 11.3908C2.9515 11.9329 3.34042 12.3862 3.84482 12.6731C4.41949 12.9998 5.22266 12.9998 6.82899 12.9998H7.49988C7.96659 12.9998 8.19995 12.9998 8.37821 13.0907C8.53501 13.1706 8.66249 13.298 8.74239 13.4548C8.83321 13.6331 8.83321 13.8665 8.83321 14.3332V16.7783C8.83321 17.9132 9.75321 18.8332 10.8881 18.8332C11.1588 18.8332 11.4041 18.6738 11.514 18.4264L14.3146 12.125C14.442 11.8384 14.5057 11.6951 14.6064 11.59C14.6954 11.4971 14.8047 11.4261 14.9257 11.3825C15.0626 11.3332 15.2194 11.3332 15.533 11.3332H16.1665C17.1 11.3332 17.5667 11.3332 17.9232 11.1515C18.2368 10.9917 18.4918 10.7368 18.6516 10.4232C18.8332 10.0666 18.8332 9.59993 18.8332 8.6665Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
