import { transformISOToDDMMYYYY } from '@/utils/date.ts'

interface Props {
  updateDate: string
  label: string
  sourceLink: string
  sourceLinkLabel: string
  creativeCommonLicenceLabel?: string
}
export function LegalResourceSourceLink({
  updateDate,
  label,
  sourceLink,
  sourceLinkLabel,
  creativeCommonLicenceLabel,
}: Readonly<Props>) {
  return (
    <p className="text-bold text-sm text-[#6E7682]">
      {label}{' '}
      {creativeCommonLicenceLabel && creativeCommonLicenceLabel.length > 0 && (
        <a
          className="text-[#6E7682] underline"
          href="https://creativecommons.org/licenses/by/4.0/deed.fr"
          target={'_blank'}
          rel="noreferrer"
        >
          {creativeCommonLicenceLabel}{' '}
        </a>
      )}
      via{' '}
      <a href={sourceLink} className="text-[#6E7682] underline" target={'_blank'} rel="noreferrer">
        {sourceLinkLabel}
      </a>
      <br />
      <br />
      {`Dernière mise à jour le ${transformISOToDDMMYYYY(updateDate)}`}
    </p>
  )
}
