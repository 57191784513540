type Props = { onClick: () => void; disabled: boolean }

export function TheIconArrowSubmission({ onClick, disabled }: Readonly<Props>) {
  const style = () => {
    if (!disabled) {
      return 'hover:cursor-pointer hover:opacity-70'
    }
  }
  return (
    <button onClick={onClick} disabled={disabled} className={style()}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.7931 10.0004C13.3988 10.0681 13.005 10.1376 12.6108 10.2039C9.72813 10.687 6.8455 11.1691 3.9624 11.6512C3.5625 11.7179 3.1612 11.7762 2.76411 11.857C2.67795 11.8744 2.56651 11.9557 2.53701 12.0342C1.71005 14.2352 0.891529 16.4401 0.0725357 18.6444C-0.065602 19.0166 -0.00894216 19.3587 0.264056 19.6458C0.58388 19.9818 0.983308 20.0663 1.42394 19.9517C1.54288 19.9207 1.6562 19.8615 1.76718 19.8055C7.61533 16.8569 13.4635 13.9073 19.3093 10.9534C19.4727 10.8707 19.6375 10.7612 19.7565 10.6245C20.1788 10.1367 20.0341 9.45769 19.4591 9.16775C16.9328 7.89195 14.4056 6.61662 11.8789 5.34175C8.46803 3.62048 5.0558 1.90108 1.64637 0.176987C1.35698 0.0308437 1.06525 -0.0537376 0.746835 0.0383644C0.168062 0.205652 -0.110555 0.804783 0.113275 1.41566C0.493036 2.44993 0.881695 3.48045 1.26614 4.51284C1.68289 5.6331 2.10105 6.75289 2.51266 7.87503C2.5637 8.0146 2.62972 8.07897 2.78706 8.10482C6.41657 8.7063 10.0447 9.31624 13.6728 9.92477C13.7135 9.93182 13.7533 9.94451 13.7936 9.95438C13.7936 9.96989 13.7936 9.98539 13.7931 10.0004Z"
          fill={disabled ? '#C1C1C1' : '#212121'}
        />
      </svg>
    </button>
  )
}
