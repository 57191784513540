import { ChangeEvent, useState } from 'react'
import { useAskQuestion } from '@/utils/useAskQuestion.ts'
import { selectConversationsForArticle } from '@/store/conversations/conversationsSlice.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { selectAllMessages, selectIsSendingMessage } from '@/store/messages/messagesSlice'
import { deleteConversationById } from '@/store/conversations/useCases/deleteConversationById.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { useParams } from 'react-router-dom'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { selectArticleSlug } from '@/store/articles/articlesSlice.ts'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'

export const useDiscussionPanelInput = () => {
  const dispatch = useAppDispatch()
  const [inputValue, setInputValue] = useState('')
  const isSendingMessage = useAppSelector(selectIsSendingMessage)
  const messages = useAppSelector(selectAllMessages)
  const { slug } = useParams() as { slug: LegalWatchArticle['slug'] }
  const articleBySlug = useAppSelector(selectArticleSlug(slug))
  const { sourceCollectionIds } = useSourceCollections()
  const { askQuestion } = useAskQuestion()
  const conversationsForArticle = useAppSelector(selectConversationsForArticle)
  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
  }

  const onSubmit = async () => {
    setInputValue('')
    await askQuestion(inputValue, conversationsForArticle[0], isSendingMessage, sourceCollectionIds)
  }

  const onRestartConversation = async () => {
    dispatch(deleteConversationById(conversationsForArticle[0].id))
    if (!articleBySlug) return
    const newConversation = await dispatch(
      createConversation({ type: 'ARTICLE', articleId: articleBySlug?.id }),
    ).unwrap()
    dispatch(listConversationMessages(newConversation.id))
  }

  const handleKeyDown = async (e: { key: string; shiftKey: boolean }) => {
    if (e.key === 'Enter' && !e.shiftKey && inputValue.trim() !== '') {
      await onSubmit()
    }
  }

  return {
    inputValue,
    onInputChange,
    onSubmit,
    isSendingMessage,
    messages,
    onRestartConversation,
    handleKeyDown,
  }
}
