import { EmailValidationConfirmation } from '../../components/EmailValidationConfirmation.tsx'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { verifyEmail } from '../../store/auth/useCases/verifyEmail.ts'
import { selectCurrentUser, selectTokenStatus } from '../../store/auth/authSlice.ts'
import { EmailError } from '../../components/EmailError.tsx'

export function EmailVerification() {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const tokenStatus = useAppSelector(selectTokenStatus)
  const currentUser = useAppSelector(selectCurrentUser)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      setPending(true)
      setTimeout(() => {
        dispatch(verifyEmail(token)).finally(() => setPending(false))
      }, 2000) // Fake timeout of 2 seconds
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderConfirmedOrError = () => {
    if (pending) {
      return <div className={'text-xl font-semibold text-primary sm:text-3xl'}>Verification en cours...</div>
    }
    return tokenStatus === 'validated' ? (
      <EmailValidationConfirmation />
    ) : (
      <EmailError tokenIssue={tokenStatus} email={currentUser?.email || null} />
    )
  }

  return <>{renderConfirmedOrError()}</>
}
