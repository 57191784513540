import wretch, { WretchResponse } from 'wretch'
import { AppStore } from '../store/configureStore'
import { setToasterMessage } from '../store/toasterSlice.ts'
import { ERROR_MESSAGES } from '../utils/errorMessages.ts'

const URL = import.meta.env.VITE_OLYMPE_GPT_API_URL + '/v1/api' // Base url
const REFRESH_TOKEN_ENDPOINT = '/auth/refresh-token'

let currentPromiseRefreshToken: ReturnType<typeof refreshToken> | null = null

// importing store directly in a non-component file is not recommended
// because it can lead to circular references
// the correct way to do it, according to Redux documentation
// is to create an injectStore function called in the entrypoint of the application
// in our case, it is in main.tsx
// See https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
let store: AppStore
export const injectStore = (_store: AppStore) => {
  store = _store
}

type ResponseTypeHandler = 'res' | 'json' | 'text' | 'blob' | 'arrayBuffer' | 'formData'
declare module 'wretch' {
  export interface WretchOptions {
    credentials?: 'include'
    context?: {
      responseType?: ResponseTypeHandler
    }
  }
}

export const simpleGPTApiWretch = wretch(URL)
  .options({ credentials: 'include' })
  .resolve((_) => _.internalError(onUnexpectedError))

export const olympeGptApiWretch = wretch(URL)
  .options({
    credentials: 'include',
    context: {},
  })
  .resolve((_) =>
    _
      // Handle 403 errors
      .unauthorized(async (_error, originalRequest) => {
        if (currentPromiseRefreshToken === null) {
          // No other request => set the variable to the Promise
          // All other concurrent wretch calls will just await it and thus there is no need to call #refreshToken() multiple times
          currentPromiseRefreshToken = refreshToken()
        }
        await currentPromiseRefreshToken
        currentPromiseRefreshToken = null

        const replayedChain = originalRequest
          .fetch() // replay the original request
          .unauthorized(makeLogoutEffective) // If 401 again, there was something wrong with the new access token, force re-login
        const responseTypeHandler = originalRequest._options.context?.responseType ?? 'json'
        const responseTypeHandlerFn = replayedChain[responseTypeHandler]
        return responseTypeHandlerFn()
      })
      // Handle 500 errors
      .internalError(onUnexpectedError)
      // Handle any fetch error (endpoint cannot be reached...)
      .fetchError(onUnexpectedError),
  )

export type OlympeGptApiWretch = typeof olympeGptApiWretch

function refreshToken(): Promise<WretchResponse> {
  return new Promise((resolve) => {
    wretch(URL)
      .url(REFRESH_TOKEN_ENDPOINT)
      .options({
        credentials: 'include',
        context: { responseType: 'res' },
      })
      .get()
      .unauthorized(makeLogoutEffective) // refreshToken must be expired too, force re-login
      .internalError(onUnexpectedError)
      .res((response) => {
        return resolve(response)
      })
  })
}

async function onUnexpectedError() {
  // When an unexpected error occurred, for instance 500
  store.dispatch(
    setToasterMessage({
      toasterMessage: ERROR_MESSAGES.GENERIC,
      toasterType: 'generic',
    }),
  )
}

async function makeLogoutEffective() {
  // Another 401, when requesting a new access token
  // It means the refresh token is also expired, i.e. the user is logged out
  // Redirect to login page
  store.dispatch(
    setToasterMessage({
      toasterMessage: ERROR_MESSAGES.WILL_LOGOUT,
      toasterType: 'generic',
    }),
  )
  // Wait a little before logging out to let the user read the toast
  await new Promise((resolve) => setTimeout(resolve, 1_500))
  window.location.assign(`${window.location.origin}/login`)
}
