import { cn } from '@/lib/utils.ts'
import { NavLink } from 'react-router-dom'
import { RoutePathsItem } from '../../../ROUTE_PATHS.ts'

interface HybridLinkProps {
  children: React.ReactNode
  href?: string
  to?: RoutePathsItem
  disabled?: boolean
  isDisabledButton?: boolean
}

export function HybridLink({ children, href, to, disabled, isDisabledButton }: Readonly<HybridLinkProps>) {
  return href ? (
    <a
      className="flex w-full items-center gap-3 rounded-lg px-3 py-2 hover:bg-payneGrey"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <NavLink
      className={({ isActive }) =>
        cn(
          'flex w-full items-center gap-3  px-3 py-2',
          isActive ? 'rounded-lg bg-payneGrey' : 'hover:rounded-lg hover:bg-payneGrey',
          disabled && 'cursor-default bg-transparent',
          isDisabledButton && 'bg-[#C1C1C1]',
        )
      }
      to={to ?? ''}
    >
      {children}
    </NavLink>
  )
}
