import { toggleAddTagsModal } from '../store/tags/tagSlice.ts'
import { useDispatch } from 'react-redux'

export function useKeyPressEvent() {
  const dispatch = useDispatch()
  const handleKeyDown = (e: { key: string; shiftKey: boolean }) => {
    if (e.key !== 'Enter') return
    dispatch(toggleAddTagsModal())
  }

  return {
    handleKeyDown,
  }
}
