import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalWatchArticle, ListUserConversationsForLegalWatchArticleResponse } from '@/types/legalWatchArticle.ts'

export const listUserConversationsForArticle = createAppAsyncThunk<
  ListUserConversationsForLegalWatchArticleResponse['data'],
  LegalWatchArticle['id']
>('articles/listUserConversationsForArticle', async (articleId, { extra: { legalWatchArticleGateway } }) => {
  try {
    return await legalWatchArticleGateway.listUserConversationsForLegalWatchArticle(articleId)
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
