import React, { RefObject, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.ts'
import {
  nextRegisterStep,
  selectRegisterForm,
  selectRegisterStepper,
  setRegisterForm,
} from '../../../../store/stepper/steppersSlice.ts'
import {
  ConfirmPasswordErrors,
  EmailErrors,
  PasswordDetailedErrors,
  PasswordSimpleErrors,
  hasAnyErrors,
  passwordDetailedErrors,
  validateEmail,
  validatePasswordConfirmation,
  validatePasswordDetailed,
  validatePasswordSimple,
} from '../../../../utils/formValidation.ts'

const confirmPasswordErrorsInitialState: ConfirmPasswordErrors = { mismatch: '' }
const emailErrorsInitialState: EmailErrors = { emptyEmail: '', invalidFormat: '' }
const passwordSimpleErrorsInitialState: PasswordSimpleErrors = { emptyPassword: '' }

const passwordErrorsInitialState: PasswordDetailedErrors = {
  lowerCase: passwordDetailedErrors.lowerCase,
  minLength: passwordDetailedErrors.minLength,
  specialCharacters: passwordDetailedErrors.specialCharacters,
  upperCase: passwordDetailedErrors.upperCase,
  numbers: passwordDetailedErrors.numbers,
}

export const useCredentialsStepHook = () => {
  const steps = useAppSelector(selectRegisterStepper)
  const registerForm = useAppSelector(selectRegisterForm)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const passwordConfirmInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const [credentialsForm, setCredentialsForm] = useState({
    email: registerForm.email,
    password: registerForm.password,
    confirmPassword: '',
  })
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false)

  // Errors local state
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<ConfirmPasswordErrors>(
    confirmPasswordErrorsInitialState,
  )
  const [emailErrors, setEmailErrors] = useState<EmailErrors>(emailErrorsInitialState)
  const [passwordSimpleErrors, setPasswordSimpleErrors] = useState<PasswordSimpleErrors>(
    passwordSimpleErrorsInitialState,
  )
  const [passwordErrors, setPasswordErrors] = useState<PasswordDetailedErrors>(passwordErrorsInitialState)

  // Update state functions
  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('email', e)
    setEmailErrors(validateEmail(e.target.value))
  }
  function handlePasswordChange(e: React.FocusEvent<HTMLInputElement>) {
    getHandler('password', e)
    setPasswordErrors(validatePasswordDetailed(e.target.value))
  }
  function handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('confirmPassword', e)
    setConfirmPasswordErrors(validatePasswordConfirmation(credentialsForm.password, e.target.value))
  }

  // Validation functions for each input
  function handleEmailValidation(event: React.FocusEvent<HTMLInputElement>) {
    const emailErrors = validateEmail(event.target.value)
    setEmailErrors(emailErrors)
  }

  // Validation functions for each section
  const formHasAnyErrors = () => {
    const emailErrors = validateEmail(credentialsForm.email)
    setEmailErrors(emailErrors)
    const passwordErrors = validatePasswordSimple(credentialsForm.password)
    setPasswordSimpleErrors(passwordErrors)
    const passwordConfirmationErrors = validatePasswordConfirmation(
      credentialsForm.password,
      credentialsForm.confirmPassword,
    )
    setConfirmPasswordErrors(passwordConfirmationErrors)
    const passwordDetailsErrors = validatePasswordDetailed(credentialsForm.password)
    setPasswordErrors(passwordDetailsErrors)
    return hasAnyErrors(emailErrors, passwordErrors, passwordConfirmationErrors, passwordDetailsErrors)
  }

  // Utils
  const getHandler = (name: 'email' | 'password' | 'confirmPassword', e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentialsForm({ ...credentialsForm, [name]: e.target.value })
  }
  const togglePasswordInputType = (inputRef: RefObject<HTMLInputElement>) => {
    const inputType = inputRef.current?.type
    if (!inputType) return
    inputRef.current.type = inputType === 'password' ? 'text' : 'password'
  }
  const handlePasswordFocus = () => {
    const passwordErrors = validatePasswordDetailed(credentialsForm.password)
    setPasswordErrors(passwordErrors)
    setShowPasswordRequirements(true)
  }

  const handleNext = () => {
    if (formHasAnyErrors()) return
    dispatch(
      setRegisterForm({
        email: credentialsForm.email,
        password: credentialsForm.password,
      }),
    )
    dispatch(nextRegisterStep())
  }

  const getEmailSectionStyle = () => {
    return !showPasswordRequirements ? 'gap-[4.375rem]' : 'gap-[2.375rem]'
  }

  const getPasswordSectionStyle = () => {
    return !showPasswordRequirements ? 'gap-6' : 'gap-4'
  }

  const getNavigationStyle = () => {
    return !showPasswordRequirements ? 'gap-10' : 'gap-2'
  }

  const getHeaderStyle = () => {
    return !showPasswordRequirements ? 'gap-7' : 'gap-0'
  }

  return {
    emailInputRef,
    passwordInputRef,
    passwordConfirmInputRef,
    handleEmailChange,
    handleConfirmPasswordChange,
    handleEmailValidation,
    confirmPasswordErrors,
    handlePasswordChange,
    togglePasswordInputType,
    handlePasswordFocus,
    emailErrors,
    passwordSimpleErrors,
    formHasAnyErrors,
    passwordErrors,
    setPasswordInputType,
    passwordInputType,
    showPasswordRequirements,
    credentialsForm,
    nextStep: handleNext,
    steps,
    emailSectionStyle: getEmailSectionStyle(),
    passwordSectionStyle: getPasswordSectionStyle(),
    navigationStyle: getNavigationStyle(),
    headerStyle: getHeaderStyle(),
  }
}
