import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { ConversationSnapshot } from '../../types/conversationSnapshot.ts'
import { createConversationSnapshotByConversationId } from './useCases/createConversationSnapshot.ts'
import { deleteConversationSnapshotById } from './useCases/deleteConversationSnapshotById.ts'
import { getConversationSnapshotById } from './useCases/getConversationSnapshotById.ts'

const conversationSnapshotEntityAdapter = createEntityAdapter<ConversationSnapshot>({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})
export const conversationSnapshotSlice = createSlice({
  name: 'conversationSnapshot',
  initialState: conversationSnapshotEntityAdapter.getInitialState({
    isLoading: false,
    createConversationSnapshotError: '',
    getConversationSnapshotByIdError: '',
    deleteConversationSnapshotByIdError: '',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createConversationSnapshotByConversationId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createConversationSnapshotByConversationId.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createConversationSnapshotByConversationId.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(getConversationSnapshotById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getConversationSnapshotById.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getConversationSnapshotById.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteConversationSnapshotById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteConversationSnapshotById.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteConversationSnapshotById.rejected, (state) => {
        state.isLoading = false
      })
  },
})
