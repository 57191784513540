import { Button } from '@/components/ui/button.tsx'
import { HTMLAttributes, MouseEventHandler } from 'react'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined
} & HTMLAttributes<SVGSVGElement>
export function TheIconInputSubmission({ onClick, ...props }: Readonly<Props>) {
  return (
    <Button onClick={onClick} variant={'secondary'} className={'p-0 hover:cursor-default'}>
      <svg {...props} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.014 14.9259L3.18804 16.0638L0.0668693 26.0103C-0.180891 26.8261 0.281393 27.6871 1.09719 27.9339C1.46883 28.0453 1.86766 28.0152 2.21815 27.8466L28.1695 15.3232C28.9037 14.968 29.2089 14.086 28.8493 13.3544C28.7043 13.0594 28.4656 12.8216 28.1695 12.6771L2.21815 0.150679C1.44768 -0.219604 0.52311 0.102512 0.154492 0.870171C-0.0147102 1.21938 -0.0449248 1.61676 0.0668693 1.98704L3.18502 11.9215L15.011 13.0624C15.5247 13.1136 15.8993 13.5682 15.848 14.0799C15.8057 14.5225 15.4552 14.8717 15.011 14.9138V14.9259H15.014Z" />
      </svg>
    </Button>
  )
}
