import { DeleteFileByIdError, UploadedFile } from '../../../types/file.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const deleteFileById = createAppAsyncThunk(
  'files/deleteFileById',
  async (fileId: UploadedFile['id'], { extra: { fileGateway } }) => {
    const response = await fileGateway.deleteFileById(fileId)
    if (!response.ok) {
      const error: DeleteFileByIdError = await response.json()
      return Promise.reject(error.message)
    }
    return Promise.resolve()
  },
)
