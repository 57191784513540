import { ReactNode, useRef } from 'react'
import { InputComparisonFileUploader } from './InputComparisonFileUploader.tsx'
import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { TheFileUploader } from './TheFileUploader.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import TextareaAutosize from 'react-textarea-autosize'
import { ALLOWED_FILE_TYPES_AS_STRING } from '../utils/FileTypes.ts'
import { SourcesPopOverAndIconSubmission } from '@/components/SourcesPopOverAndIconSubmission.tsx'

export function TheInputFileComparisonType({
  isQuestionLoading,
  userInput,
  onInput,
  className,
  handleKeyDown,
  handleFileInputChange,
  handleInputFileOpening,
  currentConversation,
  handleDropFile,
  handleSend,
  messages,
}: Readonly<TheConversationMessageInputProps>) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleSubmit = () => {
    if (currentConversation && currentConversation?.currentFilesInfos?.length < 1) return
    if (!userInput.length) return
    handleSend()
  }

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const renderSubmitOrLoadingIcon = (): ReactNode => {
    if (isQuestionLoading) {
      return <TheDotAnimation />
    } else if (messages?.length) {
      return (
        <div className={'flex h-[40px] gap-4'}>
          <TheIconArrowSubmission
            onClick={handleSubmit}
            disabled={
              !userInput.length || (!!currentConversation && currentConversation?.currentFilesInfos?.length < 2)
            }
          />
        </div>
      )
    } else {
      return (
        <SourcesPopOverAndIconSubmission
          onClick={handleSend}
          className={'flex items-center gap-3.5 fill-error'}
          iconStyle={`${userInput ? 'fill-primary hover:cursor-pointer' : 'fill-silver'}`}
        />
      )
    }
  }

  return (
    <div
      className={`lg:min-w-1/2 hidden gap-4 rounded-lg bg-bright py-4 pl-6 pr-5 shadow-custom sm:flex sm:w-full sm:flex-col sm:gap-2.5 lg:min-w-[40rem] ${className}`}
    >
      <TextareaAutosize
        maxRows={3}
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        className={
          'my-4 max-h-[200px] w-full resize-none rounded-lg bg-bright text-base leading-5 outline-none sm:my-0 sm:py-2.5'
        }
      />
      <div className={'hidden lg:block'}>
        <TheFileUploader
          isClickable={false}
          drop={handleDropFile}
          handleFileInputChange={handleFileInputChange}
          label={'Déposez vos documents ici.'}
        />
      </div>

      <div className={'flex justify-between'}>
        <div className={'basis-2/3'}>
          <div className={'mt-4 flex flex-col gap-4'}>
            <InputComparisonFileUploader
              borderStyle={'rounded border border-solid border-bInput'}
              label={'Ou bien importez votre premier document ici'}
              disabled={isQuestionLoading}
              handleInputFileOpening={() => handleInputFileOpening?.(inputRef)}
              fileInfos={currentConversation?.currentFilesInfos[0]}
            />
          </div>

          <div className={'mt-4 flex flex-col gap-4'}>
            <InputComparisonFileUploader
              borderStyle={'rounded border border-solid border-bInput'}
              label={'Et votre deuxième document ici'}
              disabled={isQuestionLoading}
              handleInputFileOpening={() => handleInputFileOpening?.(inputRef)}
              fileInfos={currentConversation?.currentFilesInfos[1]}
            />
          </div>
        </div>
        <div className={'flex self-end'}>{renderSubmitOrLoadingIcon()}</div>
      </div>

      <input
        onClick={onInputClick}
        ref={inputRef}
        accept={ALLOWED_FILE_TYPES_AS_STRING}
        className="hidden"
        type="file"
        onChange={handleFileInputChange}
      />
    </div>
  )
}
