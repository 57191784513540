import { useState } from 'react'
import { useAppDispatch } from '../store/hooks.ts'
import { Toaster, cloaseToaster, setToasterMessage } from '../store/toasterSlice.ts'

export function useToaster(durantionInMs = 3000) {
  const dispatch = useAppDispatch()
  const [displayToaster, setDisplayToaster] = useState(false)

  const showToast = (toaster: Toaster) => {
    setDisplayToaster(true)
    dispatch(setToasterMessage(toaster))

    setTimeout(() => {
      close()
    }, durantionInMs)
  }

  const close = () => {
    setDisplayToaster(false)
    dispatch(cloaseToaster())
  }
  return {
    displayToaster,
    showToast,
    close,
  }
}
