import { useAppSelector } from '@/store/hooks'
import { selectFilteredTexts } from '@/store/texts/textsSlice'
import { useState } from 'react'
import { FullHeightLayout } from '../prompt-tutorial/FullHeightLayout'
import LibraryItem from './LibraryItem'
import SearchField from './SearchField'

function LibraryPage() {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const texts = useAppSelector(selectFilteredTexts(searchTerm))

  return (
    <FullHeightLayout className="lg:w-100 bg-secondary">
      <div className={`flex h-screen w-full flex-col gap-7 px-4 pt-8 transition-all duration-500 ease-in-out`}>
        <div className="flex flex-col gap-5">
          <h1 className={'text-[1.75rem] font-semibold leading-9'}>Bibliothèque Juridique</h1>
          <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
        <div>
          <div className="flex flex-col gap-4">
            {texts && texts.map((text) => <LibraryItem key={text.id} text={text} />)}
            {texts.length === 0 && <p>Aucun texte</p>}
          </div>
        </div>
      </div>
    </FullHeightLayout>
  )
}

export default LibraryPage
