import { nanoid } from '@reduxjs/toolkit'
import { useState } from 'react'
import ConversationFileList from '../../components/TheConversationTypesList/ConversationFileList'
import { ConversationSnapshot } from '../../types/conversationSnapshot'
import ConversationSnapshotMessageItem from './ConversationSnapshotMessageItem'

function ConversationSnapshotMessageList({ conversationSnapshot }: { conversationSnapshot: ConversationSnapshot }) {
  const [isSourcesListVisible, setIsSourcesListVisible] = useState(false)

  const toggleSourcesList = () => {
    setIsSourcesListVisible((isSourcesListVisible) => !isSourcesListVisible)
  }

  return (
    <div className="flex flex-col items-center justify-center gap-4 p-2">
      {conversationSnapshot.fileTitles && conversationSnapshot.fileTitles.length > 0 && (
        <ConversationFileList
          title={`Analyse d${conversationSnapshot.fileTitles.length > 1 ? 'es' : 'u'} document${conversationSnapshot.fileTitles.length > 1 ? 's' : ''}`}
          files={conversationSnapshot.fileTitles}
        />
      )}
      <ul>
        {conversationSnapshot.messages.map((message) => (
          <ConversationSnapshotMessageItem
            key={nanoid()}
            message={message}
            toggleSourcesList={toggleSourcesList}
            isSourcesListVisible={isSourcesListVisible}
          />
        ))}
      </ul>
    </div>
  )
}

export default ConversationSnapshotMessageList
