import { useAppSelector } from '@/store/hooks.ts'
import { selectUploadFileError } from '@/store/files/filesSlice.ts'

export const useTheUploadInformationsCardHook = () => {
  const uploadFileError = useAppSelector(selectUploadFileError)

  return {
    uploadFileError,
  }
}
