type Props = {
  disabled?: boolean
  onClick: () => void
  className?: string
}

export function TheAddDocumentButton({ onClick, disabled = false, className = 'h-10 w-10' }: Readonly<Props>) {
  const setStyle = () => {
    return disabled ? 'opacity-50' : 'hover:cursor-pointer hover:opacity-70'
  }
  return (
    <button disabled={disabled} onClick={onClick} className={setStyle()}>
      <svg className={className} viewBox="0 0 37 36" fill="none" data-v-c20ffde2="">
        <rect x="0.5" width="36" height="36" rx="18" fill="#E8ECF1"></rect>
        <path
          d="M24.9957 16.3129C25.1824 16.0586 25.5334 16.009 25.779 16.2016C26.025 16.3946 26.0729 16.7575 25.8863 17.0115L19.1643 26.1524C18.4063 27.1799 17.3117 27.7944 16.1593 27.9566C15.0247 28.1164 13.8304 27.8378 12.8427 27.0822C12.8081 27.0604 12.7756 27.035 12.746 27.0064C11.7768 26.2235 11.1965 25.1061 11.042 23.9313C10.8874 22.7573 11.1576 21.5224 11.8879 20.5015C11.908 20.4671 11.9318 20.4347 11.9582 20.405L20.0427 9.41226C20.662 8.66097 21.3489 8.18941 22.1129 8.04603C22.884 7.90109 23.6975 8.09487 24.5593 8.67816L24.596 8.70512L24.6058 8.71293L24.6069 8.71371C25.018 9.04189 25.3252 9.40757 25.5357 9.80138C25.7616 10.2253 25.8742 10.6804 25.8821 11.1563C25.8923 11.7966 25.7454 12.3295 25.5092 12.819C25.292 13.2703 24.9965 13.6789 24.681 14.1079L17.1923 24.2919C16.8757 24.7225 16.4185 24.9799 15.9379 25.0475C15.4785 25.1124 14.9941 25.003 14.5887 24.7057C14.5524 24.6834 14.5184 24.6568 14.4871 24.6256C14.1005 24.3005 13.8689 23.8462 13.8058 23.3695C13.7412 22.8737 13.8587 22.3502 14.1746 21.9205L20.6446 13.1218C20.8309 12.8679 21.1819 12.8183 21.4279 13.0109C21.6734 13.2039 21.7214 13.5668 21.5352 13.8208L15.0651 22.6194C14.9367 22.7944 14.8891 23.0101 14.9159 23.2156C14.9427 23.4195 15.0444 23.6129 15.2129 23.7454L15.2148 23.7473C15.3833 23.8786 15.5911 23.9274 15.7891 23.8997C15.9867 23.8719 16.1737 23.7672 16.3018 23.593L23.7905 13.409C24.0648 13.0359 24.3221 12.68 24.5046 12.3018C24.6682 11.9627 24.7698 11.5993 24.7634 11.1743C24.7588 10.891 24.6901 10.6175 24.5529 10.3597C24.4184 10.1077 24.2151 9.86702 23.937 9.64316C23.3321 9.23567 22.7922 9.09463 22.3101 9.18488C21.8182 9.27747 21.3466 9.61581 20.8932 10.1643L12.8151 21.15C12.2446 21.9255 12.0334 22.8741 12.152 23.777C12.2684 24.6619 12.7029 25.5023 13.428 26.0906C13.4563 26.1086 13.4835 26.1297 13.5088 26.1539C14.252 26.7204 15.1528 26.9294 16.0105 26.8087C16.867 26.6884 17.6801 26.2379 18.2488 25.4874C18.2669 25.4577 18.2877 25.4296 18.3111 25.4026L24.9957 16.3129Z"
          fill="#212121"
        ></path>
      </svg>
    </button>
  )
}
