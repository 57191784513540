import { ReactNode, useRef } from 'react'
import { ALLOWED_FILE_TYPES_AS_STRING } from '../utils/FileTypes.ts'
import { TheAddDocumentButton } from './TheAddDocumentButton.tsx'
import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import TextareaAutosize from 'react-textarea-autosize'
import { SourcesPopOverAndIconSubmission } from '@/components/SourcesPopOverAndIconSubmission.tsx'

export function TheInputSingleFileType({
  isQuestionLoading,
  userInput,
  onInput,
  className,
  handleKeyDown,
  handleFileInputChange,
  handleInputFileOpening,
  currentConversation,
  handleSend,
  messages,
}: Readonly<TheConversationMessageInputProps>) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const handleClickFileIcon = () => {
    if (handleInputFileOpening && inputRef) {
      handleInputFileOpening(inputRef)
    }
  }

  const handleSubmit = () => {
    if (currentConversation && currentConversation?.currentFilesInfos?.length < 1) return
    if (!userInput.length) return
    handleSend()
  }

  const renderSubmitOrLoadingIcon = (): ReactNode => {
    if (isQuestionLoading) {
      return <TheDotAnimation />
    } else if (messages?.length) {
      return (
        <div className={'flex h-[40px] gap-4'}>
          <TheIconArrowSubmission
            onClick={handleSubmit}
            disabled={
              !userInput.length || (!!currentConversation && currentConversation?.currentFilesInfos?.length < 1)
            }
          />
        </div>
      )
    } else {
      return (
        <SourcesPopOverAndIconSubmission
          onClick={handleSend}
          className={'flex items-center gap-3.5 fill-error'}
          iconStyle={`${userInput ? 'fill-primary hover:cursor-pointer' : 'fill-silver'}`}
        />
      )
    }
  }

  return (
    <div
      className={`lg:min-w-1/2 hidden flex-col justify-between rounded-lg bg-bright py-4 pl-6 pr-5 shadow-custom sm:flex sm:w-full sm:gap-2.5 lg:min-w-[40rem] ${className}`}
    >
      <TextareaAutosize
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        className={
          'my-4 max-h-[200px] w-full resize-none rounded-lg bg-bright text-base leading-5 outline-none sm:my-0 sm:py-2.5'
        }
      />
      <div className="flex w-full flex-col items-center gap-2 rounded-md md:flex-row">
        <div className="mr-5 flex w-3/5 items-center gap-2">
          <TheAddDocumentButton
            disabled={isQuestionLoading || (currentConversation && currentConversation?.currentFilesInfos.length === 1)}
            onClick={handleClickFileIcon}
          />
          {!!currentConversation?.currentFilesInfos.length && (
            <TheAddedDocumentTag fileInfos={currentConversation?.currentFilesInfos[0]} />
          )}
        </div>
        <div className="relative flex w-2/5 items-center justify-end gap-2 self-end">{renderSubmitOrLoadingIcon()}</div>
      </div>
      <input
        ref={inputRef}
        accept={ALLOWED_FILE_TYPES_AS_STRING}
        className="hidden"
        type="file"
        onChange={handleFileInputChange}
      />
    </div>
  )
}
