import { ReactNode } from 'react'
import { CustomizeWatchPanel } from '@/components/ds/CustomizeWatchPanel.tsx'

interface Props {
  navigateToFavorites: () => void
  children: ReactNode
}
export function LegalWatchHeader({ navigateToFavorites, children }: Readonly<Props>) {
  return (
    <div className={'flex flex-col gap-6'}>
      <div className={'flex flex-col items-start justify-between gap-4 sm:items-center lg:flex-row lg:gap-0'}>
        <h1 className={'text-[1.75rem] font-semibold leading-9'}>Veille juridique</h1>
        <CustomizeWatchPanel
          className={'hidden h-full sm:flex sm:flex-col sm:gap-3.5 xl:flex-row'}
          onClick={navigateToFavorites}
        />
      </div>
      {children}
    </div>
  )
}
