import { TheIconInputSubmission } from './icons/TheIconInputSubmission'
import { HTMLAttributes } from 'react'
import { SourcesPopoverContainer } from '@/components/SourcesPopoverContainer.tsx'

interface Props extends HTMLAttributes<HTMLDivElement> {
  iconStyle: string
  onClick?: () => void
}
export function SourcesPopOverAndIconSubmission({ iconStyle, onClick, ...props }: Readonly<Props>) {
  return (
    <div {...props}>
      <SourcesPopoverContainer />
      <TheIconInputSubmission onClick={onClick} className={iconStyle} />
    </div>
  )
}
