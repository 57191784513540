import { nanoid } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { ConversationTypeItem } from '../ConversationTypeItem.tsx'
import { useTheConversationTypesListHook } from './use-the-conversation-types-list.hook.ts'
import { ConversationType } from '@/types/conversation.ts'

type Props = {
  subtitle: string
}

export function TheConversationTypesList({ subtitle }: Readonly<Props>) {
  const { targetRef, messages, typesOfConversationList, currentConversation, handleClick } =
    useTheConversationTypesListHook()
  const renderTypesOfConversationCards = (): ReactNode => {
    const typesOfConversation = Object.keys(typesOfConversationList) as ConversationType[]
    return typesOfConversation.map((typeOfConversation) => {
      const type = typesOfConversationList[typeOfConversation]
      return (
        <button
          onClick={() => handleClick(typeOfConversation)}
          className={`group w-full flex-col items-center gap-3 rounded-2xl p-3 hover:cursor-pointer lg:w-1/3 ${
            currentConversation?.type === typeOfConversation && 'bg-brightGray'
          }`}
          onKeyDown={() => handleClick(typeOfConversation)}
          key={nanoid()}
        >
          <ConversationTypeItem
            key={nanoid()}
            title={type.title}
            description={type.description}
            isActive={currentConversation?.type === typeOfConversation}
          />
        </button>
      )
    })
  }

  return (
    <div ref={targetRef} className={`${messages.length ? '' : 'flex-col'}  flex items-center justify-center gap-4`}>
      {!messages.length && subtitle && (
        <h2 className={'mb-5 text-center text-base font-semibold leading-5 text-label'}>{subtitle}</h2>
      )}
      <div
        className={`flex w-full flex-col gap-4 rounded-2xl p-4 shadow-shadowSoft sm:p-2 ${messages.length && 'md:w-3/4'} lg:flex-row`}
      >
        {renderTypesOfConversationCards()}
      </div>
    </div>
  )
}
