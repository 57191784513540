export function useIndustryIconHook(props: { checked: boolean; disabled: boolean }) {
  const style = {
    default: {
      icon: { fill: 'fill-label' },
    },
    hover: {
      icon: { fill: 'fill-label' },
    },
    focus: {
      icon: { fill: 'fill-label' },
    },
    disabled: {
      icon: { fill: 'fill-silver' },
    },
    checked: {
      icon: { fill: 'fill-primary' },
    },
  }

  function getIconFill() {
    if (props.checked) return style.checked.icon.fill
    if (props.disabled) return style.disabled.icon.fill
    return `enabled:hover:${style.hover.icon.fill} focus:${style.focus.icon.fill} ${style.default.icon.fill}`
  }

  return { iconFill: getIconFill() }
}
