import { selectFileCountByTag, toggleAddTagsModal } from '@/store/tags/tagSlice.ts'
import { useKeyPressEvent } from '@/utils/useKeyPressEvent.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'

export const useDocumentsFolderSectionHook = () => {
  const dispatch = useAppDispatch()
  const fileCountByTag = useAppSelector(selectFileCountByTag)
  const { handleKeyDown } = useKeyPressEvent()
  const onToggleAddTagModal = () => {
    dispatch(toggleAddTagsModal())
  }
  return { fileCountByTag, handleKeyDown, onToggleAddTagModal }
}
