import { PromptRecommendation } from './PromptRecommendation.tsx'
import { RecommendationExempleSection, usePromptTutorialHook } from './use-promptTutorial.hook.ts'
import { PromptSubRecommendation } from './PromptSubRecommendation.tsx'
import { PromptCardExemple } from './PromptCardExemple.tsx'
import { PromptCardExempleSection } from './PromptCardExempleSection.tsx'
import { nanoid } from '@reduxjs/toolkit'

export function PromptRecommendationList() {
  const { recommendations } = usePromptTutorialHook()

  const renderPromptCardExemple = (exempleSections: RecommendationExempleSection[] | []) => {
    if (!exempleSections.length) return null
    return (
      <PromptCardExemple>
        <>
          {exempleSections.map((section) => (
            <PromptCardExempleSection key={nanoid()} position={section.position} text={section.text} />
          ))}
        </>
      </PromptCardExemple>
    )
  }

  return recommendations.map((recommendation) => (
    <PromptRecommendation key={nanoid()} title={recommendation.recommendationTitle}>
      {recommendation.subRecommendations.map((subRecommendation) => (
        <PromptSubRecommendation
          key={nanoid()}
          title={subRecommendation.subRecommendationTitle}
          description={subRecommendation.subRecommendationDescription}
        >
          {renderPromptCardExemple(subRecommendation.subRecommendationExempleSections)}
        </PromptSubRecommendation>
      ))}
    </PromptRecommendation>
  ))
}
