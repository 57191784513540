import { cn } from '@/lib/utils'
import { ElementType } from 'react'

interface Props {
  label: string
  color: string
  Icon: ElementType
  onClick: () => void
  inverseOrder?: boolean
}
function NavigationButton({ label, Icon, color, onClick, inverseOrder }: Props) {
  return (
    <div onClick={onClick} className={cn('flex cursor-pointer items-center gap-2', inverseOrder && 'flex-row-reverse')}>
      <Icon className="h-4 w-4 " color={color}></Icon>
      <h1 className={cn(`text-sm text-[${color}]`)}>{label}</h1>
    </div>
  )
}
export default NavigationButton
