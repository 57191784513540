import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { transformISOToDDMMYYYY } from '@/utils/date.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const listArticles = createAppAsyncThunk<LegalWatchArticle[]>(
  'articles/listArticles',
  async (_, { extra: { legalWatchArticleGateway } }) => {
    try {
      const result = await legalWatchArticleGateway.listLegalWatchArticles()
      return result.map((article) => ({
        ...article,
        createdAt: transformISOToDDMMYYYY(article.createdAt),
      }))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
