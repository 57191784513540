import { TheUploadInformationsCard } from '@/components/TheUploadInformationsCard.tsx'
import { useDocumentsHook } from '@/pages/documents/use.documents.hook.tsx'
import { useTheUploadInformationsCardHook } from '@/components/hooks/use-TheUploadInformationsCard.hook.tsx'

export function TheUploadInformationsCardContainer() {
  const { file, isUploading } = useDocumentsHook()
  const { uploadFileError } = useTheUploadInformationsCardHook()
  return (
    isUploading && (
      <TheUploadInformationsCard
        file={file}
        isUploading={isUploading}
        isUploadSuccess={!uploadFileError.length && !isUploading}
      />
    )
  )
}
