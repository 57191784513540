import { getCurrentUser } from '@/store/auth/useCases/getCurrentUser.ts'
import { CONVERSATION_NUMBER_TO_TRIGGER_RESUME_REGISTRATION } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectConversationsNoArticle, showUpdateUserModal } from '@/store/conversations/conversationsSlice.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectAllMessages } from '@/store/messages/messagesSlice.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export const useSidebarHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const messages = useAppSelector(selectAllMessages)
  const conversations = useSelector(selectConversationsNoArticle)
  const { isUserConnected } = useCurrentUserHook()

  async function handleClick() {
    const { totalConversationsCreated } = await dispatch(getCurrentUser()).unwrap()
    if (totalConversationsCreated === CONVERSATION_NUMBER_TO_TRIGGER_RESUME_REGISTRATION - 1) {
      dispatch(showUpdateUserModal())
      return
    }
    await redirectToNewConversation()
  }

  async function redirectToNewConversation() {
    const conversation = await dispatch(
      createConversation({
        type: 'MESSAGE_ONLY',
      }),
    ).unwrap()
    navigate(`/conversations/${conversation.id}`)
  }
  function handleDocumentsButtonClick() {
    if (isUserConnected) {
      navigate(ROUTE_PATHS.documents)
    } else {
      dispatch(openAuthModal())
    }
  }
  return {
    messages,
    conversations,
    handleClick,
    redirectToNewConversation,
    handleDocumentsButtonClick,
  }
}
