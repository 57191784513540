import { MessagesList } from '@/components/MessagesList.tsx'
import { ConversationSystemMessageWithDocuments } from '@/components/ConversationSystemMessageWithDocuments.tsx'
import { useMessagesListHook } from '@/components/hooks/use-MessagesList.hook.tsx'
export function MessagesListContainer() {
  const { messages, conversation } = useMessagesListHook()

  if (!messages) return
  return (
    <MessagesList conversation={conversation} messages={messages} className={'py-4 sm:w-5/6 lg:w-full'}>
      {!!conversation?.currentFilesInfos.length && <ConversationSystemMessageWithDocuments />}
    </MessagesList>
  )
}
