import { useCreateDocumentModal } from '@/components/modals/hooks/use-createDocumentModal.hook.tsx'
import { DocumentForm } from '@/components/forms/DocumentForm.tsx'
import { DocumentModal } from '../DocumentModal'

export function CreateDocumentModalContainer() {
  const { handleSubmit, handleCancel, isOpen, form } = useCreateDocumentModal()
  return (
    <DocumentModal handleCancel={handleCancel} isOpen={isOpen}>
      <DocumentForm form={form} handleCancel={handleCancel} handleSubmit={handleSubmit} buttonLabel={'Créer'} />
    </DocumentModal>
  )
}
