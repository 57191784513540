import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function ChevronDoubleRight(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 14.1668L9.16667 10.0002L5 5.8335M10.8333 14.1668L15 10.0002L10.8333 5.8335"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
