import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const listUserFiles = createAppAsyncThunk('files/listUserFiles', async (_, { extra: { fileGateway } }) => {
  try {
    const response = await fileGateway.listUserFiles()
    return response.map((file) => {
      const titleParts = file.title.split('.')
      const extension = titleParts.length > 1 ? `.${titleParts[titleParts.length - 1].toUpperCase()}` : ''
      return {
        ...file,
        extension,
      }
    })
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
