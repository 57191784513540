import { TheIconOlympeLogoWithText } from '../components/icons/TheIconOlympeLogoWithText.tsx'
import { nanoid } from '@reduxjs/toolkit'

interface ErrorMessage {
  message?: string
}

export function OutagePage({
  message = `Une opération de maintenance est en cours. 🛠
Ne vous inquiétez pas, nous serons de retour en un rien de temps. 
Merci de patienter et à très vite ! 😄`,
}: Readonly<ErrorMessage>) {
  const messageLines = message.split('\n').map((line) => (
    <span key={nanoid()}>
      {line}
      <br />
    </span>
  ))
  return (
    <div className="m-auto flex h-screen w-[40.1875rem] flex-col items-center justify-center gap-[1.9375rem]">
      <TheIconOlympeLogoWithText className={'fill-primary'} />
      <div className={'flex flex-col gap-4'}>
        <h2 className={'text-center text-xl font-bold leading-6'}>Petite interruption temporaire du service</h2>
        <p className={'text-center text-base leading-6'}>{messageLines}</p>
      </div>
      <a
        className={
          'absolute bottom-0 pb-4 text-center text-sm font-semibold text-label hover:cursor-pointer hover:text-primary'
        }
        href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
        target={'_blank'}
        rel="noreferrer"
      >
        Mentions légales
      </a>
    </div>
  )
}
