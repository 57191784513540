import { UpdateUserRequest } from '@/types/user.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

// FIXME: update types
export const updateUser = createAppAsyncThunk(
  'auth/updateUser',
  async (payload: UpdateUserRequest, { extra: { accountGateway } }) => {
    try {
      return await accountGateway.updateUser(payload)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
