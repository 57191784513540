import { Button } from '../ui/button'
import { StepPageProps } from './ProfilInfoContainer'

function EndPage({ onDone }: StepPageProps) {
  return (
    <div className="flex  flex-col items-center justify-center gap-9 ">
      <div className="flex flex-col items-center justify-center gap-3 ">
        <h1 className="text-4xl font-semibold">Votre profil est configuré !</h1>
      </div>
      <Button onClick={onDone}>Utiliser Olympe</Button>
    </div>
  )
}
export default EndPage
