import { ChangeEvent, MutableRefObject, ReactElement, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.ts'
import {
  IndustryId,
  previousRegisterStep,
  selectRegisterForm,
  selectRegisterStepper,
  setRegisterForm,
} from '../../../../store/stepper/steppersSlice.ts'
import { CGUErrors, IndustryErrors } from '../../../../utils/formValidation.ts'
import { TheIconAdministration } from '../../../icons/TheIconAdministration.tsx'
import { TheIconAgro } from '../../../icons/TheIconAgro.tsx'
import { TheIconBank } from '../../../icons/TheIconBank.tsx'
import { TheIconCie } from '../../../icons/TheIconCie.tsx'
import { TheIconCommerce } from '../../../icons/TheIconCommerce.tsx'
import { TheIconCraftsmanship } from '../../../icons/TheIconCraftsmanship.tsx'
import { TheIconHealth } from '../../../icons/TheIconHealth.tsx'
import { TheIconIndustry } from '../../../icons/TheIconIndustry.tsx'
import { TheIconKey } from '../../../icons/TheIconKey.tsx'
import { TheIconLegal } from '../../../icons/TheIconLegal.tsx'
import { TheIconPeopleService } from '../../../icons/TheIconPeopleService.tsx'
import { TheIconPharma } from '../../../icons/TheIconPharma.tsx'
import { TheIconTransport } from '../../../icons/TheIconTransport.tsx'

export const INDUSTRIES = [
  {
    label: 'Industrie',
    value: 'INDUSTRIE',
    icon: <TheIconIndustry />,
  },
  {
    label: 'Banque et assurance',
    value: 'BANQUE_ASSURANCE',
    icon: <TheIconBank />,
  },
  {
    label: 'Immobilier',
    value: 'IMMOBILIER',
    icon: <TheIconKey />,
  },
  {
    label: 'Santé',
    value: 'SANTE',
    icon: <TheIconHealth />,
  },
  {
    label: 'Pharmaceutique',
    value: 'PHARMACEUTIQUE',
    icon: <TheIconPharma />,
  },
  {
    label: 'Transports',
    value: 'TRANSPORTS',
    icon: <TheIconTransport />,
  },
  {
    label: 'Collectivité et Administration',
    value: 'COLLECTIVITE_ADMINISTRATION',
    icon: <TheIconAdministration />,
  },
  {
    label: 'Services juridiques',
    value: 'SERVICES_JURIDIQUES',
    icon: <TheIconLegal />,
  },
  {
    label: 'Services aux entreprises',
    value: 'SERVICES_ENTREPRISES',
    icon: <TheIconCie />,
  },
  {
    label: 'Services à la personne',
    value: 'SERVICES_PERSONNE',
    icon: <TheIconPeopleService />,
  },
  {
    label: 'Artisanat',
    value: 'ARTISANAT',
    icon: <TheIconCraftsmanship />,
  },
  {
    label: 'Commerce',
    value: 'COMMERCE',
    icon: <TheIconCommerce />,
  },
  {
    label: 'Agriculture et agroalimentaire',
    value: 'AGRICULTURE_AGROALIMENTAIRE',
    icon: <TheIconAgro />,
  },
]

const CGUErrorsInitialState: CGUErrors = { notAccepted: '' }
const IndustryErrorsInitialState: IndustryErrors = { emptyIndustry: '' }

export const useIndustryStepHook = () => {
  const dispatch = useAppDispatch()
  const registerForm = useAppSelector(selectRegisterForm)
  const steps = useAppSelector(selectRegisterStepper)
  const otherIndustryInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const [cguErrors, setCguErrors] = useState<CGUErrors>(CGUErrorsInitialState)
  const [industryErrors] = useState<IndustryErrors>(IndustryErrorsInitialState)

  const matchesIndustryId = (industryId: IndustryId) => {
    return registerForm.industry.id === industryId
  }
  const [industries, setIndustries] = useState<
    {
      checked: boolean
      disabled: boolean
      label: string
      value: IndustryId
      icon: ReactElement
    }[]
  >([
    {
      checked: matchesIndustryId('INDUSTRIE'),
      disabled: false,
      label: 'Industrie',
      value: 'INDUSTRIE',
      icon: <TheIconIndustry className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('BANQUE_ASSURANCE'),
      disabled: false,
      label: 'Banque et assurance',
      value: 'BANQUE_ASSURANCE',
      icon: <TheIconBank className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('IMMOBILIER'),
      disabled: false,
      label: 'Immobilier',
      value: 'IMMOBILIER',
      icon: <TheIconKey className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('SANTE'),
      disabled: false,
      label: 'Santé',
      value: 'SANTE',
      icon: <TheIconHealth className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('PHARMACEUTIQUE'),
      disabled: false,
      label: 'Pharmaceutique',
      value: 'PHARMACEUTIQUE',
      icon: <TheIconPharma className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('TRANSPORTS'),
      disabled: false,
      label: 'Transports',
      value: 'TRANSPORTS',
      icon: <TheIconTransport className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('COLLECTIVITE_ADMINISTRATION'),
      disabled: false,
      label: 'Collectivité et Administration',
      value: 'COLLECTIVITE_ADMINISTRATION',
      icon: <TheIconAdministration className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('SERVICES_JURIDIQUES'),
      disabled: false,
      label: 'Services juridiques',
      value: 'SERVICES_JURIDIQUES',
      icon: <TheIconLegal className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('SERVICES_ENTREPRISES'),
      disabled: false,
      label: 'Services aux entreprises',
      value: 'SERVICES_ENTREPRISES',
      icon: <TheIconCie className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('SERVICES_PERSONNE'),
      disabled: false,
      label: 'Services à la personne',
      value: 'SERVICES_PERSONNE',
      icon: <TheIconPeopleService className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('ARTISANAT'),
      disabled: false,
      label: 'Artisanat',
      value: 'ARTISANAT',
      icon: <TheIconCraftsmanship className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('COMMERCE'),
      disabled: false,
      label: 'Commerce',
      value: 'COMMERCE',
      icon: <TheIconCommerce className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
    {
      checked: matchesIndustryId('AGRICULTURE_AGROALIMENTAIRE'),
      disabled: false,
      label: 'Agriculture et agroalimentaire',
      value: 'AGRICULTURE_AGROALIMENTAIRE',
      icon: <TheIconAgro className={`h-5 w-5 flex-shrink items-center justify-center`} />,
    },
  ])

  const handleOtherIndustry = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setRegisterForm({
        ...registerForm,
        industry: {
          id: 'OTHER',
          otherValue: e.target.value,
        },
      }),
    )
  }

  const selectIndustry = (value: IndustryId) => {
    setIndustries((prevIndustries) => {
      const updatedIndustries = [...prevIndustries].map((industry) => {
        return { ...industry, checked: false }
      })
      const industryToCheck = updatedIndustries.find((industry) => industry.value === value)
      if (!industryToCheck) return updatedIndustries
      const index = updatedIndustries.indexOf(industryToCheck)
      updatedIndustries[index] = { ...industryToCheck, checked: true }
      return updatedIndustries
    })
    dispatch(
      setRegisterForm({
        ...registerForm,
        industry: {
          id: value,
        },
      }),
    )
    if (!otherIndustryInputRef.current) return
    otherIndustryInputRef.current.value = ''
  }

  const handleFocus = () => {
    setIndustries((prevIndustries) => {
      return [...prevIndustries].map((industry) => {
        return { ...industry, checked: false }
      })
    })
  }

  const setCgu = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setRegisterForm({
        ...registerForm,
        cgu: e.target.checked,
      }),
    )
    if (e.target.checked) setCguErrors(CGUErrorsInitialState)
  }

  // Helper function to check if industry is of type 'OTHER'

  return {
    previousStep: () => dispatch(previousRegisterStep()),
    steps,
    handleOtherIndustry,
    handleFocus,
    selectIndustry,
    industries,
    setCgu,
    otherIndustryInputRef,
    cguInPutChecked: registerForm.cgu,
    cguErrors,
    industryErrors,
  }
}
