import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const logoutUser = createAppAsyncThunk('auth/logoutUser', async (_, { extra: { authGateway } }) => {
  try {
    await authGateway.logout()
    Promise.resolve()
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
