import { WretchResponse } from 'wretch'
import { Conversation } from '../types/conversation.ts'
import {
  CreateConversationSnapshotResponse,
  GetConversationSnapshotByIdResponse,
  GetConversationSnapshotByIdResponseData,
} from '../types/conversationSnapshot.ts'
import { ConversationSnapshotGateway } from './interface/conversationSnapshot.gateway.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'

export class WretchConversationSnapshotGateway implements ConversationSnapshotGateway {
  private readonly endpoint = '/conversations/snapshots'

  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  deleteConversationSnapshot(snapshotId: string): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${snapshotId}`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .delete()
      .res()
  }

  async getConversationSnapshot(spanshotId: string): Promise<GetConversationSnapshotByIdResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${spanshotId}`
    return (await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetConversationSnapshotByIdResponse>()).data
  }

  async createConversationSnapshot(
    conversationId: Conversation['id'],
  ): Promise<GetConversationSnapshotByIdResponseData> {
    const body = {
      data: {
        conversationId,
      },
    }
    return (await this.olympeGptApi.url(this.endpoint).post(body).json<CreateConversationSnapshotResponse>()).data
  }
}
