import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconLibrary(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { color = '#FDFDFD', ...rest } = props
  return (
    <svg {...rest} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6717_3142)">
        <path
          d="M16.6663 16.7963V14.2963H5.83301C4.4523 14.2963 3.33301 15.4156 3.33301 16.7963M7.33301 19.2963H13.9997C14.9331 19.2963 15.3998 19.2963 15.7563 19.1147C16.0699 18.9549 16.3249 18.6999 16.4847 18.3863C16.6663 18.0298 16.6663 17.5631 16.6663 16.6296V5.29631C16.6663 4.36288 16.6663 3.89617 16.4847 3.53965C16.3249 3.22605 16.0699 2.97108 15.7563 2.81129C15.3998 2.62964 14.9331 2.62964 13.9997 2.62964H7.33301C5.93288 2.62964 5.23281 2.62964 4.69803 2.90212C4.22763 3.14181 3.84517 3.52426 3.60549 3.99466C3.33301 4.52944 3.33301 5.22951 3.33301 6.62964V15.2963C3.33301 16.6964 3.33301 17.3965 3.60549 17.9313C3.84517 18.4017 4.22763 18.7841 4.69803 19.0238C5.23281 19.2963 5.93288 19.2963 7.33301 19.2963Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6717_3142">
          <rect width="20" height="20" fill="white" transform="translate(0 0.963013)" />
        </clipPath>
      </defs>
    </svg>
  )
}
