import { useEffect, useRef } from 'react'

export function useDocumentTitle(title: string, prevailOnUnmount = false) {
  // Create a ref to store the default title
  const defaultTitle = useRef('Olympe Olympe')

  useEffect(() => {
    // Capture the previous title to be used in cleanup
    const previousTitle = defaultTitle.current

    // Update the document title with the provided title
    document.title = title

    // Define a cleanup function
    if (!prevailOnUnmount) {
      return () => {
        // Restore the document title to the previous value
        // This is important to leave the application in a consistent state
        // in case the component unmounts or the effect is cleaned up.
        document.title = previousTitle
      }
    }
  }, [title, prevailOnUnmount])
}
