import { ReactElement } from 'react'
import { nanoid } from '@reduxjs/toolkit'

type Props = {
  title: string | null
  description: string
  children: ReactElement | null
}
export function PromptSubRecommendation({ title, description, children }: Readonly<Props>) {
  return (
    <div className={'mb-6 flex flex-col gap-4'}>
      <div className={'flex flex-col gap-1'}>
        <h3 className={'text-xl font-semibold'}>{title}</h3>
        {description.split('\n').map((line) => (
          <span className={'text-base font-normal text-primary'} key={nanoid()}>
            {line}
            <br />
          </span>
        ))}
      </div>
      {children}
    </div>
  )
}
