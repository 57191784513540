import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconCirclePlus(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" />
      <path d="M12 8H4" stroke="#FDFDFD" strokeWidth="0.972222" strokeLinecap="round" />
      <path d="M8 4V12" stroke="#FDFDFD" strokeWidth="0.972222" strokeLinecap="round" />
    </svg>
  )
}
