import { PromptExempleHeader } from './PromptExempleHeader.tsx'
import { TheIconWrongPrompt } from '../icons/TheIconWrongPrompt.tsx'
import { TheIconRightPrompt } from '../icons/TheIconRightPrompt.tsx'

type Props = {
  text: string
  position: 'right' | 'left'
}
export function PromptCardExempleSection({ text, position }: Readonly<Props>) {
  return (
    <div className={'flex basis-1/2 flex-col gap-1'}>
      <PromptExempleHeader title={position === 'right' ? 'Reformulation optimale' : 'Prompt trop général'}>
        {position === 'right' ? <TheIconRightPrompt /> : <TheIconWrongPrompt />}
      </PromptExempleHeader>
      <p className={'text-sm font-normal text-primary'}>{text}</p>
    </div>
  )
}
