import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { useEffect } from 'react'
import { listArticles } from '@/store/articles/useCases/listArticles.ts'
import { selectIsDrawerDisplayed } from '@/store/articles/articlesSlice.ts'

export const useLegalWatchDrawerHook = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(listArticles())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isLegalWatchDrawerDisplayed = useAppSelector(selectIsDrawerDisplayed)
  return { isLegalWatchDrawerDisplayed }
}
