import { BaseModal } from '../atomics/BaseModal.tsx'
import { TheIconResizeModal } from '../icons/TheIconResizeModal.tsx'
import { useFilePreviewModalHook } from './hooks/use-filePreviewModal.hook.tsx'

export function FilePreviewModal() {
  const { resizeModal, previewFile, handleSetPreviewFile, style, previewFileModalWidth, pdfData } =
    useFilePreviewModalHook()
  return (
    <BaseModal
      contentVisible={previewFile}
      onClick={handleSetPreviewFile}
      animationClass={'translate-x-full'}
      styleBasedOnScreenSize={style}
    >
      <div className={'relative h-full w-full'}>
        <TheIconResizeModal
          className={`absolute hover:cursor-pointer ${
            previewFileModalWidth === 'small' ? 'left-1/2' : 'right-1/3'
          } translate-y-full`}
          onClick={resizeModal}
        />
        <iframe title="PDF Viewer" src={pdfData} width="100%" height="100%" />
      </div>
    </BaseModal>
  )
}
