import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { LegalWatchArticleListContainer } from '@/components/LegalWatchArticleListContainer.tsx'
import { useLegalWatchArticleListHook } from '@/components/legalWatch/use-legalWatchArticleList.hook.tsx'
import { LegalWatchHeaderContainer } from '@/components/LegalWatchHeaderContainer.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import { LegalWatchFiltersContainer } from '@/components/LegalWatchFiltersContainer'

export function LegalWatch() {
  const { articles, unReadArticles, isFetchingArticles } = useLegalWatchArticleListHook()

  return (
    <FullHeightLayout>
      <div className={`flex w-full flex-col gap-6 px-4 pb-4 pt-8 transition-all duration-500 ease-in-out md:w-5/6`}>
        <LegalWatchHeaderContainer>
          <Tabs defaultValue={'all'} className={'gap-4'}>
            <TabsList className={'mb-5 bg-inherit'}>
              <TabsTrigger
                className={
                  'data-[state=active]:border-x-none text-xl font-normal leading-5 text-label transition-none data-[state=active]:rounded-none data-[state=active]:border-2 data-[state=active]:border-x-transparent data-[state=active]:border-b-mintGreen data-[state=active]:border-t-transparent data-[state=active]:bg-snowMist data-[state=active]:text-mintGreen data-[state=active]:shadow-none'
                }
                value="all"
              >
                Tous
              </TabsTrigger>
              <div>
                <TabsTrigger
                  className={
                    'data-[state=active]:border-x-none group gap-2 text-xl font-normal leading-5 text-label transition-none data-[state=active]:rounded-none data-[state=active]:border-2 data-[state=active]:border-x-transparent data-[state=active]:border-b-mintGreen data-[state=active]:border-t-transparent data-[state=active]:bg-snowMist data-[state=active]:text-mintGreen data-[state=active]:shadow-none'
                  }
                  value="unread"
                >
                  Non lus{' '}
                  <span className={'text-xl text-mistyDawn group-data-[state=active]:text-tealBlue'}>
                    {unReadArticles.length}
                  </span>
                </TabsTrigger>
              </div>
            </TabsList>
            <TabsContent value="all" className={'flex flex-col gap-5'}>
              <LegalWatchFiltersContainer />
              <LegalWatchArticleListContainer isFetchingArticles={isFetchingArticles} articles={articles} />
            </TabsContent>
            <TabsContent value="unread" className={'flex flex-col gap-5'}>
              <LegalWatchArticleListContainer articles={unReadArticles} />
            </TabsContent>
          </Tabs>
        </LegalWatchHeaderContainer>
      </div>
    </FullHeightLayout>
  )
}
