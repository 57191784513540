import { Input } from '@/components/ui/input'
import { X } from 'lucide-react'
import { TheIconMagnifyingGlass } from '@/components/icons/TheIconMagnifyingGlass.tsx'

interface SearchFieldProps {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

function SearchField({ searchTerm, setSearchTerm }: SearchFieldProps) {
  return (
    <div className="flex w-full items-center  ">
      <div className="relative">
        <TheIconMagnifyingGlass
          className={`absolute z-10 size-4 -translate-y-1/2 translate-x-1/2 ${searchTerm.length > 0 ? 'fill-primary' : 'fill-silver'}`}
        />
      </div>
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={'w-96 px-10'}
        placeholder="Rechercher un document"
      ></Input>
      <div className="relative">
        <X
          onClick={() => setSearchTerm('')}
          className={`absolute size-4 -translate-x-6 -translate-y-1/2 cursor-pointer ${searchTerm.length > 0 ? 'absolute' : 'hidden'}`}
        />
      </div>
    </div>
  )
}
export default SearchField
