import { useNavigate, useParams } from 'react-router-dom'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { addTagToFilter, selectArticleById, selectArticleSlug } from '@/store/articles/articlesSlice.ts'
import { useEffect } from 'react'
import { resetMessages } from '@/store/messages/messagesSlice.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'
import { removeLegalWatchArticleFromFavorites } from '@/store/articles/useCases/removeLegalWatchArticleFromFavorites.ts'
import { addLegalWatchArticleToFavorites } from '@/store/articles/useCases/addLegalWatchArticleToFavorites.ts'

export function useLegalWatchArticleDetailsHook() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { articleId } = useParams() as { articleId: LegalWatchArticle['id'] }
  const { slug } = useParams() as { slug: LegalWatchArticle['slug'] }
  const articleById = useAppSelector(selectArticleById(articleId))
  const articleBySlug = useAppSelector(selectArticleSlug(slug))

  useEffect(() => {
    return () => {
      dispatch(resetMessages())
    }
  }, [dispatch, slug])

  const filterArticlesByTag = (tag: string) => {
    dispatch(addTagToFilter({ value: tag }))
    return navigate(`/legal-watch`)
  }

  const onStartConversation = async (articleId: LegalWatchArticle['id']) => {
    const conv = await dispatch(createConversation({ type: 'ARTICLE', articleId })).unwrap()
    if (conv) {
      dispatch(listConversationMessages(conv.id))
    }
  }
  const toggleFavorite = (id: LegalWatchArticle['id'], isFavorite: LegalWatchArticle['isFavorite']) => {
    isFavorite ? dispatch(removeLegalWatchArticleFromFavorites(id)) : dispatch(addLegalWatchArticleToFavorites(id))
  }
  return {
    article: articleId ? articleById : articleBySlug,
    filterArticlesByTag,
    onStartConversation,
    toggleFavorite,
  }
}
