import React from 'react'

export function TheIconCollapse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'hover:cursor-pointer'}
      {...props}
    >
      <path d="M16 10H4" stroke="#6E7682" strokeWidth="1.16667" strokeLinecap="round" />
    </svg>
  )
}
