import { DatePickerFilter } from './ds/DatePickerFilter'
import { useDatePickerFilterContainerHook } from './hooks/use-DatePickerFilterContainer.hook'

export function DatePickerFilterContainer() {
  const { dates, onSetDate, isOpen, onOpenChange } = useDatePickerFilterContainerHook()
  return (
    <DatePickerFilter
      isOpen={isOpen}
      dates={dates}
      onSetDate={(dates) => onSetDate({ from: dates?.from, to: dates?.to })}
      onOpenChange={onOpenChange}
    />
  )
}
