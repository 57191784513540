import { ReactElement } from 'react'

export function PromptRecommendation({ title, children }: Readonly<{ title: string; children: ReactElement[] }>) {
  return (
    <div className={'flex flex-col gap-6'}>
      <h2 className={'text-2xl font-semibold'}>{title}</h2>
      <div>{children}</div>
    </div>
  )
}
