import { Badge } from '@/components/ui/badge.tsx'
import { nanoid } from '@reduxjs/toolkit'

export type ArticleTagProps = {
  tag: string
  filterByTag?: (tag: string) => void
  className?: string
}
export function ArticleTag({ tag, filterByTag, className }: Readonly<ArticleTagProps>) {
  return (
    <Badge
      key={nanoid()}
      onClick={() => {
        if (!filterByTag) return
        filterByTag(tag)
      }}
      variant={'custom'}
      className={`block max-w-[11.75rem] truncate border-mintGreen bg-bright text-xs font-normal text-mintGreen hover:cursor-pointer group-hover:bg-inherit ${className}`}
    >
      {tag}
    </Badge>
  )
}
