import { BackArrowIcon } from '@/components/icons/BackArrowIcon.tsx'

interface Props {
  navigateBack: () => void
  label: string
}
export function BackButton({ navigateBack, label }: Readonly<Props>) {
  return (
    <button onClick={navigateBack} className={'group flex items-center gap-3.5'}>
      <BackArrowIcon className={'stroke-primary group-hover:stroke-label'} />
      <h1 className={'mt-0 text-base font-normal leading-5 group-hover:text-label'}>{label}</h1>
    </button>
  )
}
