import { DeleteManyFilesError, UploadedFile } from '../../../types/file.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const deleteManyFiles = createAppAsyncThunk(
  'files/deleteManyFiles',
  async (ids: UploadedFile['id'][], { extra: { fileGateway } }) => {
    const response = await fileGateway.deleteManyFiles(ids)
    if (!response.ok) {
      const error: DeleteManyFilesError = await response.json()
      return Promise.reject(error.message)
    }
    return Promise.resolve()
  },
)
