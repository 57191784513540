import { useIndustryItemHook } from './use-industryItem.hook.tsx'
import React from 'react'

export interface IndustryItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean
  label: string
  disabled?: boolean
  value?: string
  children?: React.ReactNode
}

export function IndustryItem({ checked, disabled, value, label, ...rest }: Readonly<IndustryItemProps>) {
  const { borderColor, borderThickness, textColor, backgroundColor } = useIndustryItemHook({
    checked,
    disabled: disabled ?? false,
  })

  const children = React.Children.map(rest.children, (child) => {
    // Ensure child is a valid React element before cloning
    if (React.isValidElement(child)) {
      const additionalProps = { disabled, checked }

      return React.cloneElement(child, additionalProps)
    }

    // Return the child directly if it's not a valid React element
    return child
  })
  return (
    <button className={'h-[50px] md:h-fit'} {...rest}>
      <label
        className={`flex h-full w-[12rem] items-center gap-2 rounded-lg px-3 py-2 hover:cursor-pointer md:h-fit md:w-[16.75rem] ${borderThickness} ${borderColor} ${backgroundColor}`}
      >
        {children}
        <div className={'flex w-full items-start gap-5'}>
          <span className={`flex w-full flex-col items-start gap-0.5 text-left text-base leading-5 ${textColor}`}>
            {label}
          </span>
        </div>
        <input type="radio" value={value} hidden={true} />
      </label>
    </button>
  )
}
