import { TheAddDocumentButton } from './TheAddDocumentButton.tsx'
import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { FileInfos } from '../types/file.ts'

type Props = {
  handleInputFileOpening: () => void
  fileInfos: FileInfos | undefined
  label?: string
  borderStyle?: string
  iconStyle?: string
  disabled: boolean
}

export function InputComparisonFileUploader({
  borderStyle,
  label,
  handleInputFileOpening,
  fileInfos,
  iconStyle,
  disabled,
}: Readonly<Props>) {
  return (
    <>
      {!!label?.length && <span className={'text-sm text-label'}>{label} : </span>}
      <div className={`flex gap-4 ${borderStyle} px-2 py-1`}>
        <TheAddDocumentButton className={iconStyle} disabled={disabled} onClick={handleInputFileOpening} />
        {fileInfos && <TheAddedDocumentTag fileInfos={fileInfos} />}
      </div>
    </>
  )
}
