import { SVGProps } from 'react'
import { useIndustryIconHook } from '../registerSteps/industry/right/industryList/use-industryIcon.hook.tsx'

interface TheIconCieProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean
  checked?: boolean
}

export function TheIconCie({ className, checked, disabled, ...props }: Readonly<TheIconCieProps>) {
  const { iconFill } = useIndustryIconHook({ checked: checked ?? false, disabled: disabled ?? false })
  const style = iconFill + ' ' + className
  return (
    <svg
      {...props}
      className={style}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4477_2172)">
        <path d="M17.2243 18.9467C17.9743 18.9467 18.6902 18.9443 19.406 18.9479C19.7605 18.9497 19.9989 19.184 20 19.516C20.0006 19.795 19.8277 20.0179 19.5603 20.071C19.4687 20.0889 19.3735 20.0919 19.2796 20.0919C13.0922 20.093 6.90434 20.0925 0.71703 20.0919C0.645098 20.0919 0.572006 20.0919 0.500654 20.0823C0.185081 20.0394 -0.0109918 19.8045 0.000610124 19.4904C0.0122121 19.1857 0.23903 18.9533 0.553442 18.9497C1.20489 18.9425 1.85576 18.9473 2.50721 18.9467C2.5861 18.9467 2.66558 18.9467 2.77579 18.9467V18.629C2.77579 13.4963 2.77579 8.36369 2.77579 3.23165C2.77579 3.14223 2.77347 3.05281 2.77753 2.96399C2.7932 2.60214 3.00377 2.38456 3.35589 2.38098C3.89132 2.37561 4.42617 2.37979 4.99989 2.37979C4.99989 2.28381 4.99989 2.19857 4.99989 2.11332C4.99989 1.66682 4.99873 1.22092 4.99989 0.774422C5.00105 0.297522 5.1948 0.0942433 5.65946 0.0942433C8.00421 0.0924549 10.349 0.093051 12.6937 0.093051C13.2436 0.093051 13.7936 0.0912627 14.3435 0.0936472C14.8053 0.0960317 14.9984 0.298714 14.9996 0.777403C15.0008 1.29782 14.9996 1.81824 14.9996 2.36727C15.0843 2.37144 15.1603 2.378 15.2363 2.37859C15.6992 2.37979 16.1627 2.37621 16.6256 2.38038C17.0044 2.38396 17.2103 2.59737 17.2225 2.98962C17.2248 3.07129 17.2237 3.15296 17.2237 3.23523C17.2237 8.36786 17.2237 13.4999 17.2237 18.6325C17.2237 18.7279 17.2237 18.8233 17.2237 18.9473L17.2243 18.9467ZM6.10555 18.9342C6.10555 18.8346 6.10555 18.747 6.10555 18.6594C6.10555 17.4767 6.10555 16.2939 6.10555 15.1112C6.10555 14.5759 6.29408 14.3786 6.80689 14.3786C8.9341 14.378 11.0619 14.378 13.1891 14.3786C13.7025 14.3786 13.8934 14.5765 13.8939 15.1077C13.8945 16.2975 13.8939 17.488 13.8939 18.6778C13.8939 18.7631 13.8939 18.8483 13.8939 18.9282H16.1064V3.53687H3.89306V18.9342H6.10555ZM6.11657 2.3631H13.8777V1.24596H6.11657V2.3631ZM9.42951 18.9395V15.5279H7.23558V18.9395H9.42951ZM10.5746 15.5279V18.9324H12.761V15.5279H10.5746Z" />
        <path d="M13.3248 6.37264C12.9773 6.37264 12.6298 6.37621 12.2829 6.37144C11.9099 6.36608 11.6657 6.13419 11.6686 5.79916C11.6715 5.46414 11.918 5.23821 12.2922 5.23702C12.9872 5.23463 13.6815 5.23463 14.3765 5.23702C14.7536 5.23821 14.9955 5.45937 14.9978 5.79738C15.0007 6.14015 14.7652 6.36191 14.3887 6.37204C14.3742 6.37204 14.3597 6.37204 14.3452 6.37204C14.0052 6.37204 13.6647 6.37204 13.3248 6.37204V6.37264Z" />
        <path d="M13.3225 9.2292C12.9751 9.2292 12.6276 9.23338 12.2807 9.22801C11.9088 9.22265 11.6646 8.98837 11.6693 8.65335C11.6739 8.31832 11.9199 8.09537 12.2952 8.09359C12.9901 8.0912 13.6845 8.0912 14.3795 8.09359C14.7554 8.09478 14.9967 8.31832 14.9984 8.65692C15.0002 8.9985 14.7641 9.22265 14.3864 9.22801C14.032 9.23278 13.6775 9.2292 13.3225 9.2292Z" />
        <path d="M13.344 12.0865C12.9895 12.0865 12.6345 12.0907 12.2801 12.0853C11.9082 12.0799 11.664 11.8457 11.6686 11.5101C11.6733 11.175 11.9192 10.9521 12.2951 10.9509C12.9901 10.9485 13.6845 10.9485 14.3794 10.9509C14.7553 10.9521 14.9967 11.1756 14.9984 11.5142C15.0001 11.8558 14.764 12.0799 14.3864 12.0853C14.0389 12.0901 13.6914 12.0865 13.3445 12.0865H13.344Z" />
        <path d="M6.65372 6.37203C6.30624 6.37203 5.95876 6.3756 5.61187 6.37083C5.23712 6.36547 4.99928 6.13834 5.0016 5.79796C5.00392 5.45995 5.24582 5.2376 5.62231 5.23641C6.31726 5.23402 7.01164 5.23402 7.7066 5.23641C8.08134 5.2376 8.3273 5.46234 8.33078 5.79736C8.33426 6.13358 8.0912 6.36547 7.71762 6.37083C7.36318 6.3762 7.00874 6.37203 6.65372 6.37203Z" />
        <path d="M6.65545 9.2292C6.30797 9.2292 5.96049 9.23278 5.61359 9.22801C5.23827 9.22265 5.00043 8.99731 5.00159 8.65692C5.00275 8.31892 5.24523 8.09478 5.62055 8.09359C6.31551 8.0912 7.00989 8.0912 7.70484 8.09359C8.08017 8.09478 8.32555 8.31773 8.33019 8.65275C8.33483 8.98897 8.09177 9.21847 7.71935 9.22861C7.70484 9.22861 7.69034 9.22861 7.67584 9.22861C7.3359 9.22861 6.99538 9.22861 6.65545 9.22861V9.2292Z" />
        <path d="M6.65018 12.0865C6.3027 12.0865 5.95523 12.0906 5.60833 12.0853C5.23417 12.0799 4.99807 11.8504 5.00213 11.5088C5.00619 11.1792 5.24577 10.9532 5.60485 10.9514C6.31373 10.9485 7.02318 10.9479 7.73206 10.9514C8.0865 10.9532 8.33014 11.1881 8.33072 11.516C8.33188 11.8444 8.09288 12.0781 7.73554 12.0847C7.37356 12.0912 7.01216 12.0859 6.65018 12.0865Z" />
      </g>
      <defs>
        <clipPath id="clip0_4477_2172">
          <rect width="20" height="20" fill="white" transform="translate(0 0.0924072)" />
        </clipPath>
      </defs>
    </svg>
  )
}
