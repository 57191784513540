export function PaymentModalServiceDetails() {
  return (
    <article className={'space-y-2'}>
      <h2 className={'text-base font-semibold'}>Profitez pleinement de nos services</h2>
      <ul className={'list-inside list-disc text-base'}>
        <li>Recherche juridique sourcée</li>
        <li>Veille juridique</li>
        <li>Analyse de documents</li>
        <li>Votre bibliothèque juridique toujours à jour</li>
      </ul>
    </article>
  )
}
