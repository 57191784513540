import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { selectDateRange, setDateRangeFilter } from '@/store/articles/articlesSlice'

export const useDatePickerFilterContainerHook = () => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const dates = useAppSelector(selectDateRange)

  const onSetDate = (dates: { from: Date | undefined; to: Date | undefined }) => {
    if (dates.from) dispatch(setDateRangeFilter({ ...dates, from: dates.from }))
    if (dates.to) dispatch(setDateRangeFilter({ ...dates, to: dates.to }))
    if (dates.from && dates.to) setIsOpen(false)
  }

  const onOpenChange = () => {
    setIsOpen(!isOpen)
  }

  return {
    dates,
    onSetDate,
    isOpen,
    onOpenChange,
  }
}
