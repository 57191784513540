import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { InfoIcon } from '../icons/InfoIcon'

function ConversationTooltip() {
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button size={'roundedIcon'} color="secondary" variant={'outline'}>
            <InfoIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          <p className=" w-80 rounded-md text-sm text-[#6E7682]">
            Après avoir copié le lien, les messages générés dans cette conversation ne seront plus partagés.
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default ConversationTooltip
