import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconChevronUp(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0001 7.15463C13.9387 7.40215 13.8407 7.62398 13.6429 7.789C13.3113 8.06571 12.8299 8.07155 12.4975 7.7925C12.4332 7.7384 12.3729 7.67886 12.3145 7.61776C10.6936 5.91196 9.07314 4.20617 7.45264 2.49959C7.41604 2.46106 7.38462 2.41747 7.31104 2.32757C7.27259 2.39607 7.25447 2.45211 7.21861 2.48986C5.58739 4.21045 3.95358 5.9287 2.32199 7.64889C2.03434 7.95207 1.70529 8.07933 1.30525 7.94934C0.711103 7.75592 0.469674 6.9577 0.84938 6.43892C0.891528 6.38132 0.937744 6.32566 0.986547 6.27429C2.86474 4.29646 4.74294 2.31901 6.62188 0.341954C6.97311 -0.0273819 7.40939 -0.103272 7.80536 0.142303C7.90112 0.201458 7.98616 0.284744 8.06528 0.368029C9.89578 2.29255 11.7241 4.2194 13.556 6.14236C13.759 6.35563 13.9402 6.57552 14.0001 6.88025V7.15463Z"
        fill="black"
      />
    </svg>
  )
}
