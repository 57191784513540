import { FileData } from '../types/file.ts'

export const formatDateFileData = (data: FileData[]) => {
  const options: {
    hour: 'numeric' | '2-digit' | undefined
    minute: 'numeric' | '2-digit' | undefined
  } = {
    hour: 'numeric',
    minute: 'numeric',
  }
  return data.map((file) => ({
    ...file,
    createdAt: new Date(file.createdAt).toLocaleDateString('fr-FR', options),
  }))
}
