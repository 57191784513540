import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  selectDisplayDeleteTagWarning,
  selectFileCountByTagId,
  selectTagToUpdateOrDelete,
  toggleDeleteTagWarning,
} from '@/store/tags/tagSlice.ts'
import { deleteTag } from '@/store/tags/useCases/deleteTag.ts'
export const useWarningModalHook = () => {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectDisplayDeleteTagWarning)
  const tagToDelete = useAppSelector(selectTagToUpdateOrDelete)
  const tagFilesCount = useAppSelector(selectFileCountByTagId(tagToDelete?.id ?? 0))

  const onDeleteTag = () => {
    if (!tagToDelete) return
    dispatch(deleteTag(tagToDelete?.id))
    dispatch(toggleDeleteTagWarning())
  }

  const onOpenChange = () => {
    dispatch(toggleDeleteTagWarning())
  }

  const getSubtitle = () => {
    return tagFilesCount === 1
      ? 'Votre document sera définitivement supprimé.'
      : 'Vos documents seront définitivement supprimés.'
  }

  return {
    isOpen,
    onDeleteTag,
    onOpenChange,
    subtitle: getSubtitle(),
  }
}
