import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getConversationSnapshotById } from '../../store/conversationSnapshot/useCases/getConversationSnapshotById'
import { useAppDispatch } from '../../store/hooks'
import { ConversationSnapshot } from '../../types/conversationSnapshot'
import ConversationSnapshotMessageList from './ConversationSnapshotMessageList'
import ConversationSnapshotSideBar from './ConversationSnapshotSideBar'
import LoginRegisterInfoSection from './LoginRegisterInfoSection'

function SharedConversationsPage() {
  const snapshotId = useParams().snapshotId
  const dispatch = useAppDispatch()

  const [conversationSnapshot, setConversationSnapshot] = useState<ConversationSnapshot>()
  useEffect(() => {
    async function fetchData() {
      const conversationSnapshot = await dispatch(getConversationSnapshotById(snapshotId!)).unwrap()
      setConversationSnapshot(conversationSnapshot)
    }
    fetchData()
  }, [snapshotId, dispatch])
  return (
    <div className="flex h-screen w-screen  gap-4">
      <ConversationSnapshotSideBar />
      {/* Messages */}
      <div className="flex flex-1 flex-col gap-10 overflow-y-scroll px-40 py-8">
        {conversationSnapshot && <ConversationSnapshotMessageList conversationSnapshot={conversationSnapshot} />}
        <LoginRegisterInfoSection />
      </div>
    </div>
  )
}

export default SharedConversationsPage
