import { ArticleNotificationsBadgeContainer } from '@/components/ArticleNotificationsBadgeContainer.tsx'
import { ClockIcon } from '@/components/icons/ClockIcon.tsx'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { TheAuditIcon } from '../icons/TheAuditIcon.tsx'
import { TheIconDocumentsFolder } from '../icons/TheIconDocumentsFolder.tsx'
import { TheIconOlympeLogoWithText } from '../icons/TheIconOlympeLogoWithText.tsx'
import { TheIconPlus } from '../icons/TheIconPlus.tsx'
import { TheIconWithoutName } from '../icons/TheIconWithoutName.tsx'
import { TheQuestionBubbleIcon } from '../icons/TheQuestionBubbleIcon.tsx'
import { TheSideBarItem } from './TheSideBarItem/TheSideBarItem.tsx'
import { useSidebarHook } from './use-sidebar.hook.ts'
import { TheIconLibrary } from '@/components/icons/TheIconLibrary.tsx'

export function TheSidebarDesktopHeader() {
  const { handleClick, handleDocumentsButtonClick } = useSidebarHook()

  return (
    <div
      className={
        'border-b-solid flex w-full flex-col items-center gap-4 border-b border-b-label pb-4 hover:cursor-pointer'
      }
    >
      <TheIconOlympeLogoWithText className={'fill-bright'} />
      <div className="flex w-full flex-col items-center gap-3">
        <button
          className={'flex w-full items-center gap-3 rounded-lg bg-label px-3 py-2'}
          onKeyDown={handleClick}
          onClick={handleClick}
        >
          <TheIconPlus className={'h-3 w-3'} />
          <span className="text-sm text-bright" data-testid="create-conversation-button">
            Nouvelle question
          </span>
        </button>

        <TheSideBarItem label={'Bibliothèque'} to={ROUTE_PATHS.library} leftIcon={TheIconLibrary} />
        <button
          className={'flex w-full items-center gap-3 px-3 py-2 hover:rounded-lg hover:bg-payneGrey'}
          onClick={handleDocumentsButtonClick}
        >
          <TheIconDocumentsFolder className={'h-[1.5rem] w-[1.5rem]'} />
          <span className={'stroke-slate-100 text-sm text-bright'}>Mes documents</span>
        </button>
        <TheSideBarItem label={'Veille juridique'} to={ROUTE_PATHS.legalWatch} leftIcon={ClockIcon}>
          <ArticleNotificationsBadgeContainer />
        </TheSideBarItem>
        <TheSideBarItem
          label={'Aide à la rédaction'}
          to={ROUTE_PATHS.promptTutorial}
          leftIcon={TheQuestionBubbleIcon}
        />
        <TheSideBarItem
          label={'Auditez votre site'}
          href={import.meta.env.VITE_ORION_URL}
          leftIcon={TheAuditIcon}
          rightIcon={TheIconWithoutName}
        />
      </div>
    </div>
  )
}
