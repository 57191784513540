import { Conversation } from '../../../types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const getConversationById = createAppAsyncThunk(
  'conversations/getConversationById',
  async (conversationId: Conversation['id'], { extra: { conversationGateway } }) => {
    try {
      return await conversationGateway.getConversationById(conversationId)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
