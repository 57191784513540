import { TagPickerFilter } from '@/components/ds/TagPickerFilter.tsx'
import { useTagPickerFilter } from '@/components/hooks/use-TagPickerFilter.hook.tsx'

export function TagPickerFilterContainer() {
  const {
    isOpen,
    onOpenChange,
    onSelectedTags,
    onUnSelectedTags,
    selectedTags,
    onChangeInput,
    onResetInput,
    tags,
    inputValue,
  } = useTagPickerFilter()
  return (
    <TagPickerFilter
      onChangeInput={onChangeInput}
      inputValue={inputValue}
      onResetInput={onResetInput}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSelect={onSelectedTags}
      onUnSelect={onUnSelectedTags}
      selectedTags={selectedTags}
      tags={tags}
    />
  )
}
