import { TreeDataItem } from '@/components/ui/tree'

export function flattenArticles(node: TreeDataItem, list: TreeDataItem[] = []) {
  if (node.type === 'article') {
    const newNode = { ...node, content: node.children?.[0].content ?? '' }
    list.push(newNode)
  }
  if (node.children) {
    node.children.forEach((child) => flattenArticles(child, list))
  }
  return list
}

type Article = Omit<TreeDataItem, 'children'>

export function findNextArticle(articles: Article[], currentArticleId: Article['id']) {
  const currentIndex = articles.findIndex((article) => article.id === currentArticleId)
  if (currentIndex !== -1 && currentIndex + 1 < articles.length) {
    return articles[currentIndex + 1]
  }
  return null
}

export function findPreviousArticle(articles: Article[], currentArticleId: Article['id']) {
  const currentIndex = articles.findIndex((article) => article.id === currentArticleId)
  if (currentIndex > 0) {
    return articles[currentIndex - 1]
  }
  return null
}

export function getParentIds(
  tree: TreeDataItem,
  targetId: Article['id'],
  path: Article['id'][] = [],
): Article['id'][] | null {
  if (tree.id === targetId) {
    return [...path, tree.id]
  }

  if (tree.children && tree.type !== 'article') {
    for (const child of tree.children) {
      const result = getParentIds(child, targetId, [...path, tree.id])
      if (result) {
        return result.filter(Boolean)
      }
    }
  }

  return null
}

export function getFirstChildArticle(tree: TreeDataItem) {
  if (tree.type === 'article') return tree
  if (tree.children) {
    return getFirstChildArticle(tree.children[0])
  }
  return null
}
