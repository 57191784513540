import { LegalWatchArticleList } from '@/components/legalWatch/LegalWatchArticleList.tsx'
import { useAppSelector } from '@/store/hooks.ts'
import { selectAllArticles } from '@/store/articles/articlesSlice.ts'
import { TheIconSpinner } from '@/components/icons/TheIconSpinner.tsx'

export function LegalWatchDrawerList() {
  const articles = useAppSelector(selectAllArticles)
  if (!articles.length)
    return (
      <div className={'flex w-full items-center justify-center'}>
        <TheIconSpinner className={'w-10 animate-spin fill-mintGreen text-white dark:text-white'} />
      </div>
    )

  return (
    <div className={'animation-slide-in -translate-x-full transition-all duration-300 ease-out'}>
      <LegalWatchArticleList className={'gap-4 px-4'} articles={articles} />
    </div>
  )
}
