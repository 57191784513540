import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  selectSelectedSourcesCollectionLabels,
  selectSourceCollections,
  setSelectedSourceCollectionsLabels,
} from '@/store/sourceCollections/sourceCollectionsSlice.ts'
import { Group } from '@/components/NestedCheckboxList.tsx'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Conversation } from '@/types/conversation.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import {
  filterRedundantSourceCollectionIds,
  getGroups,
  getSelectedSourceCollections,
} from '@/utils/sourceCollectionGroupUtils.ts'

export const useSourceCollections = () => {
  const dispatch = useAppDispatch()
  const sourceCollections = useAppSelector(selectSourceCollections)
  const selectedSourcesCollections = useAppSelector(selectSelectedSourcesCollectionLabels)
  const { isUserConnected } = useCurrentUserHook()
  const [, setDisplaySourceFilter] = useState(false)
  const [groups, setGroups] = useState<Group[]>([])
  const { conversationId } = useParams() as {
    conversationId: Conversation['id']
  }

  const setSourceCollections = useCallback(() => {
    const actualSourceCollections = sourceCollections ?? []
    const groups: Group[] = getGroups(actualSourceCollections)
    setGroups(groups)
    const selectedSourceCollections = getSelectedSourceCollections(groups, isUserConnected)
    dispatch(setSelectedSourceCollectionsLabels(selectedSourceCollections))
  }, [sourceCollections, isUserConnected, dispatch])

  useEffect(() => {
    setSourceCollections()
  }, [conversationId, setSourceCollections])

  const toggleDisplaySourceFilter = () => {
    setDisplaySourceFilter((prev) => !prev)
  }

  const handleSelectedSourceCollectionsChange = (newSelectedSourceCollections: string[]) => {
    dispatch(setSelectedSourceCollectionsLabels(newSelectedSourceCollections))
  }

  return {
    groups,
    sourceCollectionIds: filterRedundantSourceCollectionIds(groups, selectedSourcesCollections),
    toggleDisplaySourceFilter,
    handleSelectedSourceCollectionsChange,
  }
}
