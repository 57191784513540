import { updateConversation } from '@/store/conversations/useCases/updateConversation.ts'
import { addMessage, streamLastMessageInConversation } from '@/store/messages/messagesSlice.ts'
import { TEMPORARY_AI_ID, TEMPORARY_HUMAN_ID } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'
import { Conversation } from '@/types/conversation.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { createConversationMessage } from '@/store/messages/useCases/createConversationMessage.ts'

export const useAskQuestion = () => {
  const dispatch = useAppDispatch()

  const askQuestion = async (
    userInput: string,
    conversation: Conversation,
    isQuestionLoading: boolean,
    sourceCollectionIds: string[],
  ) => {
    const conversationId = conversation.id
    const question = userInput
    if (!userInput.length || isQuestionLoading) {
      return
    }
    if (!conversation) {
      return
    }

    await dispatch(updateConversation(conversation))

    dispatch(
      addMessage({
        content: userInput,
        id: TEMPORARY_HUMAN_ID,
        sources: [],
        type: 'HUMAN',
        createdAt: new Date().toISOString(),
      }),
    )

    if (!conversation) {
      return
    }

    const reader = await dispatch(
      createConversationMessage({
        conversationId,
        content: question,
        sourceCollections: sourceCollectionIds,
      }),
    ).unwrap()

    if (!reader) {
      return
    }

    dispatch(
      addMessage({
        content: '',
        id: TEMPORARY_AI_ID,
        sources: [],
        type: 'AI',
        createdAt: new Date().toISOString(),
      }),
    )

    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        reader.releaseLock()
        // when the stream is done, we fetch the messages to get the sources
        dispatch(listConversationMessages(conversationId))
        break
      }

      const token = await new Response(value).text()
      dispatch(
        streamLastMessageInConversation({
          conversationId,
          token,
        }),
      )
    }
  }

  return {
    askQuestion,
  }
}
