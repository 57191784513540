import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { useConversationPageHook } from '@/pages/conversations/[conversationId]/use-conversation-page.hook.ts'
import { useEffect } from 'react'
import { SUBSCRIPTION_CONFIRMATION_WAIT_TIME } from '@/utils/CONVERSATION_CONSTANTS.ts'

export function ConfirmSubscription() {
  const { navigateToFirstConversationOrCreateOneIfNoneExists } = useConversationPageHook()

  useEffect(() => {
    async function redirect() {
      await navigateToFirstConversationOrCreateOneIfNoneExists()
    }
    const timeout = setTimeout(redirect, SUBSCRIPTION_CONFIRMATION_WAIT_TIME)
    return () => {
      clearTimeout(timeout)
    }
  }, [navigateToFirstConversationOrCreateOneIfNoneExists])

  return (
    <section className={'flex flex-col items-center justify-center gap-5'}>
      <TheIconOlympeLogoWithText className={'h-[3.3125rem] w-[8.75rem] fill-primary'} />
      <main className={'space-y-10'}>
        <div className={'flex flex-col items-center justify-center gap-3'}>
          <h1 className={'text-[2.125rem] font-semibold leading-[2.5rem] text-primary'}>
            Votre abonnement a bien été pris en compte.
          </h1>
          <div className={'text-4xl font-semibold'}>✅</div>
        </div>
        <footer className={'text-center text-2xl font-normal'}>
          Vous allez automatiquement être redirigé vers Olympe.
        </footer>
      </main>
    </section>
  )
}
