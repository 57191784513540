import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  closeAuthModal,
  selectIsSignupFormDisplayed,
  selectIsAuthModalOpen,
  toggleAuthModalForm,
  resetAuthModal,
} from '@/store/modal/modalsSlice.ts'
import { RegistrationFormValues } from '@/components/registerSteps/credentials/right/registrationSchema.ts'
import { useRegisterHook } from '@/components/registerSteps/credentials/right/use-register.hook.ts'
import { logUser } from '@/store/auth/useCases/logUser.ts'

export const useAuthFormModal = () => {
  const dispatch = useAppDispatch()
  const isAuthModalOpen = useAppSelector(selectIsAuthModalOpen)
  const isSignupFormDisplayed = useAppSelector(selectIsSignupFormDisplayed)
  const { submit: signupSubmit } = useRegisterHook()

  const signinSubmit = async ({ email, password }: { email: string; password: string }) => {
    await dispatch(logUser({ email, password }))
  }

  async function onSigninSubmit(values: RegistrationFormValues) {
    if (isAuthModalOpen) dispatch(closeAuthModal())
    const { email, password } = values
    await signinSubmit({ email, password })
  }

  async function onSignupSubmit(values: RegistrationFormValues) {
    if (isAuthModalOpen) dispatch(closeAuthModal())
    const { email, password } = values
    await signupSubmit({ email, password })
  }

  const handleCloseAuthModal = () => {
    dispatch(resetAuthModal())
    return dispatch(closeAuthModal())
  }

  const toggleAuthForm = () => {
    return dispatch(toggleAuthModalForm())
  }
  return {
    isSignupFormDisplayed,
    isAuthModalOpen,
    handleCloseAuthModal,
    onSigninSubmit,
    onSignupSubmit,
    toggleAuthForm,
  }
}
