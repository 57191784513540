import { Conversation } from '../../../types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const updateConversation = createAppAsyncThunk(
  'conversations/updateConversation',
  async (conversation: Conversation, { extra: { conversationGateway } }) => {
    try {
      return await conversationGateway.updateConversation(conversation)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
