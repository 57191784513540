import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconSortAsc(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.41421 6L9.58579 6C10.4767 6 10.9229 4.92286 10.2929 4.29289L6.70711 0.707107C6.31658 0.316583 5.68342 0.316583 5.29289 0.707107L1.70711 4.29289C1.07714 4.92286 1.52331 6 2.41421 6Z" />
    </svg>
  )
}
