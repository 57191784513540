import { SourceReadingInfos } from '../types/source'
import { TheIconRightArrowRounded } from './icons/TheIconRightArrowRounded.tsx'

type Props = {
  source: SourceReadingInfos
  sourceNumber: number
}

export function TheSourcesListItem(props: Readonly<Props>) {
  return (
    <div className="mt-5 flex w-11/12 flex-col rounded-md shadow-lg sm:mt-10">
      <div className="rounded-t-md bg-veryLightGrey px-5 py-1 uppercase text-label">
        <p className="text-xs font-thin">Source {props.sourceNumber}</p>
      </div>
      <div className="bg-white px-5 py-2 text-sm">
        <h2 className="overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-primary">
          {props.source.title}
        </h2>
      </div>
      <div className="border-t-2 border-veryLightGrey bg-white px-5 py-2 text-sm">
        <p className="text-label">{props.source.content.substring(0, 140)} ...</p>
      </div>
      <a
        href={props.source.url}
        className="rounded-b-md bg-label px-5 py-2 text-white hover:bg-primary"
        target="_blank"
        rel="noreferrer"
      >
        <p className="flex flex-row text-sm text-white">
          Accéder à la source {props.source.pageNumber ? `(p.${props.source.pageNumber})` : ''}
          <TheIconRightArrowRounded className={'ml-2'} />
        </p>
      </a>
    </div>
  )
}
