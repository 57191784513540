import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '@/components/ui/input.tsx'
import { Button } from '@/components/ui/button'
import { clsx } from 'clsx'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'

const resetPasswordSchema = z.object({
  email: z.string().email({ message: 'Veuillez renseigner une adresse email valide' }),
})

type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>

export function ResetPasswordForm() {
  const navigate = useNavigate()
  const form = useForm<ResetPasswordSchema>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  })
  async function onSubmit(values: ResetPasswordSchema) {
    navigate(`${ROUTE_PATHS.resetPasswordInfo}?email=${values.email}`)
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col gap-6 sm:w-96">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className={'space-y-1'}>
              <FormLabel className={'text-sm text-label'}>Adresse email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="exemple@email.com"
                  className={clsx(
                    'h-full w-full rounded-lg border border-solid px-6 py-3.5 text-base leading-5 text-primary outline-none placeholder:text-label focus-visible:ring-0 focus-visible:ring-offset-2',
                    {
                      'border-error': form.formState.errors.email,
                      'focus-visible:border-primary': !form.formState.errors.email,
                    },
                  )}
                />
              </FormControl>
              <FormMessage className={'text-sm text-error'} />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-2/3 self-center" disabled={form.formState.isSubmitting}>
          Envoyer
        </Button>
      </form>
    </Form>
  )
}
