import React, { forwardRef, Ref } from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

function BaseCheckbox({ children, ...props }: Props, ref: Ref<HTMLInputElement>) {
  return (
    <div className={'flex items-center gap-4'}>
      <input className={'h-6 accent-primary'} ref={ref} type="checkbox" {...props} />
      {children}
    </div>
  )
}

export default forwardRef(BaseCheckbox)
