import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx'
import { Button } from '@/components/ui/button.tsx'
import { cn } from '@/lib/utils.ts'
import { TheIconChevronFilled } from '@/components/icons/TheIconChevronFilled.tsx'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command.tsx'
import { ArticleFilterTag } from './ArticleFilterTag'
import { TagFilter } from '@/components/hooks/use-TagPickerFilter.hook.tsx'
import { FilteredItemsLabel } from '@/components/FilteredItemsLabel.tsx'
import { TheIconCross } from '@/components/icons/TheIconCross.tsx'

interface Props {
  selectedTags: TagFilter[]
  tags: TagFilter[]
  onSelect: (value: string) => void
  onUnSelect: (value: string) => void
  isOpen: boolean
  onOpenChange: () => void
  onResetInput: () => void
  onChangeInput: (value: string) => void
  inputValue: string
}

export function TagPickerFilter({
  selectedTags,
  tags,
  onSelect,
  isOpen,
  onOpenChange,
  onResetInput,
  onUnSelect,
  inputValue,
  onChangeInput,
}: Readonly<Props>) {
  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          size={'filter'}
          variant={'filter'}
          className={cn(
            'group w-fit justify-start gap-2 rounded-lg text-left font-normal',
            !selectedTags.length
              ? 'bg-silverLining hover:bg-gainsboroGray'
              : ' bg-softMint text-mintGreen hover:bg-softMint',
          )}
        >
          {selectedTags.length === 0 && <span className={'text-label'}>Thématique</span>}{' '}
          <FilteredItemsLabel items={selectedTags} labelKey="value" />
          <TheIconChevronFilled
            className={`${selectedTags.length ? 'fill-label' : 'fill-silver group-hover:fill-label'}`}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={'flex w-[21.25rem] max-w-[31.25rem] flex-col gap-4 rounded-2xl px-5 py-4'}>
        <Command className={''}>
          <div className={'flex max-h-[120px] flex-wrap gap-2 overflow-y-auto'}>
            {selectedTags.map((selectedTag) => (
              <ArticleFilterTag
                className={'gap-1 rounded bg-mistyJade px-2 py-0 text-sm text-primary'}
                key={selectedTag.value}
                label={selectedTag.value}
                onIconCLick={() => onUnSelect(selectedTag.value)}
                contentColor={'primary'}
              />
            ))}
          </div>
          <CommandInput
            value={inputValue}
            list={'tags'}
            onValueChange={onChangeInput}
            className={'text-sm font-normal text-primary placeholder:text-silver'}
            placeholder="Rechercher..."
          >
            {inputValue.length > 0 && (
              <TheIconCross
                onClick={onResetInput}
                className={'h-5 w-5 fill-label hover:cursor-pointer hover:fill-silver'}
              />
            )}
          </CommandInput>
          <CommandEmpty className={'px-3 py-1 text-sm font-normal text-label'}>Aucun résultat</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {tags.map((tag) => (
                <CommandItem key={tag.value} value={tag.value} onSelect={onSelect}>
                  {tag.value}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
