export function PromptConclusionSection() {
  return (
    <div className={'mt-6'}>
      <h3 className={'mb-4 text-2xl font-semibold text-primary'}>Conclusion</h3>
      <p className={'text-base font-normal text-primary'}>
        L&apos;expertise en rédaction de prompts pour Olympe augmente considérablement l&apos;efficacité des DPO dans
        leur rôle de protection des données. Ce tutorial offre un aperçu initial, mais la pratique régulière et
        l&apos;exploration continue des capacités d’Olympe sont essentielles pour une maîtrise complète.
      </p>
    </div>
  )
}
