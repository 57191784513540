import { SourcesPopover } from '@/components/SourcesPopover.tsx'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'
import { useState } from 'react'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export function SourcesPopoverContainer() {
  const [isOpen, setIsOpen] = useState(false)
  const { groups, sourceCollectionIds, handleSelectedSourceCollectionsChange } = useSourceCollections()
  const { isUserConnected } = useCurrentUserHook()
  return (
    <SourcesPopover
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
      groups={groups}
      handleSelectedSourceCollectionsChange={handleSelectedSourceCollectionsChange}
      selectedSourceCollections={sourceCollectionIds}
      isUserConnected={isUserConnected}
    />
  )
}
