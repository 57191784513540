import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconVisibleEye(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 355.24 238.81">
      <path
        className="cls-1"
        d="m180.46,0c72.7,3.14,130.7,35.88,169.47,101.32,7.14,12.05,7.04,24.52-.04,36.6-32.21,54.95-79.61,88.37-142.58,98.46-81.08,12.99-162.59-27.18-202.32-98.85-6.79-12.25-6.67-24.58.44-36.64C37.69,46.14,85.12,13.3,147.83,2.68c3.19-.54,6.4-1.06,9.63-1.3,6.71-.51,13.43-.82,23-1.38Zm-3.56,22.5c-8.27.83-17.4,1.2-26.34,2.72-55.2,9.41-96.9,38.5-125.41,86.64-3.11,5.24-2.96,10.22.03,15.51,35.15,62.29,106.12,97.68,176.85,87.01,56.19-8.47,98.72-37.91,127.79-86.78,3.29-5.52,3.26-10.65-.05-16.14-34.51-57.31-85.64-86.01-152.88-88.97Z"
      />
      <path
        className="cls-1"
        d="m108.6,119.34c.12-38.13,30.99-68.82,69.13-68.74,38.3.08,69.04,31.08,68.83,69.41-.2,38.11-31.12,68.69-69.32,68.55-38.1-.14-68.77-31.06-68.65-69.22Zm115.4.31c.02-25.57-20.8-46.46-46.34-46.47-25.58-.02-46.46,20.8-46.48,46.33-.02,25.55,20.82,46.46,46.34,46.49,25.55.03,46.46-20.82,46.48-46.34Z"
      />
    </svg>
  )
}
