import { nanoid } from '@reduxjs/toolkit'
import ReactMarkdown from 'react-markdown'
import { TheAddedDocumentTag } from '../TheAddedDocumentTag'
import { TheIconAvatarAI } from '../icons/TheIconAvatarAI'

interface ConversationFileListProps {
  title: string
  files: string[]
}

function ConversationFileList({ title, files }: ConversationFileListProps) {
  return (
    <div className={`flex  flex-row rounded-lg bg-brightGray p-3 sm:justify-normal  lg:w-fit lg:gap-4`}>
      <TheIconAvatarAI className={'ml-2 h-[45px] w-[45px]'} />
      <div className={'flex flex-col gap-4 text-center'}>
        <ReactMarkdown className={`prose text-center text-sm leading-8 sm:text-left`}>{title}</ReactMarkdown>
        <div className={'flex flex-col justify-between gap-2 lg:flex-row'}>
          {files.map((file) => (
            <TheAddedDocumentTag
              key={nanoid()}
              fileInfos={{
                uploadStatus: 'UPLOADED',
                title: file,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ConversationFileList
