import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { Tag } from '@/types/tag.ts'

export const createTag = createAppAsyncThunk('tags/createTag', async (name: Tag['name'], { extra: { tagGateway } }) => {
  try {
    return tagGateway.createTag(name)
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
