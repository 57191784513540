import { ChangeEvent, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.ts'
import {
  JobTitle,
  nextRegisterStep,
  previousRegisterStep,
  selectRegisterForm,
  selectRegisterStepper,
  setRegisterForm,
} from '../../../../store/stepper/steppersSlice.ts'
import { JobTitleErrors, hasAnyErrors, validateJobTitle } from '../../../../utils/formValidation.ts'
import { useToaster } from '../../../../utils/useToaster.ts'

const jobTitleErrorsInitialState: JobTitleErrors = { emptyJobTitle: '' }

type JobType = 'internal' | 'external' | 'other'

export const JOB_TITLES = [
  {
    jobType: 'internal' as JobType,
    value: 'DPO Interne' as JobTitle,
  },
  {
    jobType: 'external' as JobType,
    value: 'DPO Externe' as JobTitle,
  },
  {
    jobType: 'other' as JobType,
    value: 'Autre' as JobTitle,
  },
]

export const useJobTitleHook = () => {
  const { showToast } = useToaster()
  const dispatch = useAppDispatch()
  const steps = useAppSelector(selectRegisterStepper)
  const registerForm = useAppSelector(selectRegisterForm)
  const [jobTitleForm, setJobTitleForm] = useState<{ jobTitle: string }>({
    jobTitle: registerForm.jobTitle,
  })
  const [otherJobTitle, setOtherJobTitle] = useState('')
  const [jobTitleErrors, setJobTitleErrors] = useState<JobTitleErrors>(jobTitleErrorsInitialState)
  const matchesJobTitle = (jobTitle: JobTitle) => {
    return registerForm.jobTitle === jobTitle
  }

  const [jobTitles, setJobTitles] = useState<
    {
      jobType: JobType
      value: JobTitle
      checked: boolean
    }[]
  >(JOB_TITLES.map((jobTitle) => ({ ...jobTitle, checked: matchesJobTitle(jobTitle.value) })))

  function handleOtherJobTitleChange(e: ChangeEvent<HTMLInputElement>) {
    setOtherJobTitle(e.target.value)
    setJobTitleErrors(validateJobTitle(e.target.value))
  }
  const selectJobTitle = (jobTitle: JobTitle, jobType: 'internal' | 'external' | 'other') => {
    const selectedJobTitle = jobTitle

    // Update jobTitles array
    const updatedJobTitles = jobTitles.map((jobTitle) => ({
      ...jobTitle,
      checked: jobTitle.jobType === jobType,
    }))

    // Set the updated jobTitles array
    setJobTitles(updatedJobTitles)

    // Update jobTitleForm
    setJobTitleForm({
      jobTitle: selectedJobTitle,
    })
    setJobTitleErrors({ emptyJobTitle: '' })
  }

  const formHasAnyErrors = () => {
    const jobTitleErrors = validateJobTitle(jobTitleForm.jobTitle)
    if (jobTitleErrors.emptyJobTitle.length) {
      setJobTitleErrors(jobTitleErrors)
      showToast({ toasterMessage: 'Veuillez sélectionner une fonction', toasterType: 'identified' })
    }

    if (!otherJobTitle.length && jobTitleForm.jobTitle === 'Autre') {
      showToast({ toasterMessage: 'Veuillez saisir une fonction', toasterType: 'identified' })
    }
    return hasAnyErrors(jobTitleErrors)
  }

  const validateOtherJobTitle = () => {
    return !otherJobTitle.length && jobTitleForm.jobTitle === 'Autre'
  }

  const handleNext = () => {
    if (formHasAnyErrors() || validateOtherJobTitle()) return

    const jobTitle = otherJobTitle.length && jobTitleForm.jobTitle === 'Autre' ? otherJobTitle : jobTitleForm.jobTitle
    dispatch(setRegisterForm({ ...jobTitleForm, jobTitle }))
    dispatch(nextRegisterStep())
  }

  return {
    next: handleNext,
    previousStep: () => dispatch(previousRegisterStep()),
    steps,
    selectJobTitle,
    jobTitles,
    jobTitleErrors,
    handleOtherJobTitleChange,
  }
}
