import { ChangeEvent, KeyboardEvent, MouseEvent, ReactElement, ReactNode, SetStateAction, useState } from 'react'

import { nanoid } from '@reduxjs/toolkit'
import { TheIconSortAsc } from '../icons/TheIconSortAsc.tsx'
import { TheIconSortDesc } from '../icons/TheIconSortDesc.tsx'
import { TheIconBin } from '../icons/TheIconBin.tsx'
import BaseCheckbox from './BaseCheckbox.tsx'
import { TheIconTripleDotsVertical } from '../icons/TheIconTripleDotsVertical.tsx'
import { BaseOverlay } from './BaseOverlay.tsx'
import { TheTagsSelect } from '../TheTagsSelect.tsx'
import { Tag } from '@/types/tag.ts'

export type TableData<T> = T[]
export type TableColumn<T> = {
  key: keyof T
  render?: (item: T) => ReactNode
  title: string
  sortable: boolean
  editable: boolean
}

type Sorting<T> = {
  columnKey: keyof T
  order: 'asc' | 'desc'
} | null

// Props for the ReusableTable component
interface ReusableTableProps<T> {
  filteredData: TableData<T>
  columns: TableColumn<T>[]
  handleExtraCell?: (rowIndex: number) => void
  toggleRowSelection?: () => void
  handleSelectAll?: (files: TableData<T>) => void
  handleDeleteAll?: () => void
  selectedRowsLength?: number
  handleUpdateCell?: (inputValue: string, item: T | null) => void
  children?: ReactNode
  emptyArrayMessage?: string
  defaultSorting: Sorting<T>
  onClick: () => void
  isBulkTagSelectVisible: boolean
  handleBulkTagSelectOption: (tag: Tag) => void
}

/**
 * Represents a table component that can be reused with different data and columns.
 * @template T - The type of data objects in the table.
 * @param {Object} props - The component's props.
 * @param {TableColumn<T>[]} props.columns - The columns configuration for the table.
 * @returns {ReactElement} - The ReusableTable component.
 */
export function ReusableTable<T>({
  columns,
  handleSelectAll,
  handleDeleteAll,
  selectedRowsLength,
  handleUpdateCell,
  children,
  filteredData,
  defaultSorting,
  isBulkTagSelectVisible,
  emptyArrayMessage = 'Vous n’avez pas de document dans votre dossier.',
  onClick,
  handleBulkTagSelectOption,
}: Readonly<ReusableTableProps<T>>): ReactElement {
  /**
   * Renders the header row of the table including an extra column for actions if there is an action cell prop.
   *
   * @returns {ReactElement} - The rendered header row.
   */
  const [sorting, setSorting] = useState<Sorting<T>>(defaultSorting) // State for sorting configuration
  const [updatingItem, setUpdatingItem] = useState<T | null>(null)
  const [updatingColumnKey, setUpdatingColumnKey] = useState<keyof T | null>()
  const [updateCellInputValue, setUpdateCellInputValue] = useState('')

  const handleColumnClick = (columnKey: keyof T) => {
    if (sorting && sorting.columnKey === columnKey) {
      // Toggle the sorting order if the same column is clicked
      setSorting({
        columnKey,
        order: sorting.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      // Default to ascending order when clicking a use Cases column
      setSorting({ columnKey, order: 'asc' })
    }
  }
  const deleteAll = () => {
    if (handleDeleteAll) {
      handleDeleteAll()
    }
  }
  const handleChangeMainCheckbox = () => {
    handleSelectAll?.(filteredData)
  }
  const renderHeaderCheckbox = (): ReactElement | null => {
    if (filteredData.length < 2) {
      return <span className={'flex w-[50px] gap-4'} />
    }

    return (
      <span className={'flex items-center gap-4'}>
        <BaseCheckbox
          data-testid="checkbox-all"
          ref={(checkbox) => {
            if (checkbox) {
              checkbox.indeterminate = !!(selectedRowsLength && selectedRowsLength < filteredData.length)
            }
          }}
          checked={selectedRowsLength === filteredData.length}
          onChange={handleChangeMainCheckbox}
        />
        <BaseOverlay contentVisible={isBulkTagSelectVisible} onClick={onClick}>
          <TheTagsSelect onClick={handleBulkTagSelectOption} />
        </BaseOverlay>
        <TheIconTripleDotsVertical
          onClick={onClick}
          itemId={'bulk'}
          className={`${
            selectedRowsLength && selectedRowsLength > 1 ? 'visible' : 'hidden'
          } h-5 fill-label hover:cursor-pointer hover:fill-primary`}
        />
        <TheIconBin
          data-testid="delete-all"
          onClick={deleteAll}
          className={`${
            selectedRowsLength && selectedRowsLength > 1 ? 'visible' : 'hidden'
          } h-4 fill-label hover:cursor-pointer hover:fill-primary`}
        />
      </span>
    )
  }

  const renderColumnTitle = (column: TableColumn<T>): ReactElement => (
    <>
      {column.title}
      {sorting && column.sortable && sorting.columnKey === column.key && (
        <span className="ml-1">
          {sorting.order === 'asc' ? (
            <TheIconSortAsc className={'ml-0.5 inline h-3.5 w-3 fill-silver'} />
          ) : (
            <TheIconSortDesc className={'ml-0.5 inline h-3.5 w-3 fill-silver'} />
          )}
        </span>
      )}
    </>
  )

  const renderHeaderRow = (): ReactElement => {
    return (
      <tr>
        {columns.map((column, index) => {
          const isFirstColumn = index === 0
          const isLastColumn = index === columns.length - 1

          const headerClasses = [
            'px-3 py-3 text-left text-xs uppercase text-silver',
            column.sortable && 'hover:cursor-pointer',
            setCellStyleBasedOnIndex(index, columns),
          ]
            .filter(Boolean)
            .join(' ')

          if (isFirstColumn) {
            return (
              <th className={headerClasses} key={nanoid()}>
                {renderHeaderCheckbox()}
              </th>
            )
          } else {
            return (
              <th
                className={headerClasses}
                key={nanoid()}
                onClick={() => handleColumnClick(column.key)} // Add onClick handler
              >
                {isLastColumn ? (
                  /* Render content for the last column based on its index */
                  <>{column.title}</>
                ) : (
                  /* Render content for other columns */
                  renderColumnTitle(column)
                )}
              </th>
            )
          }
        })}
      </tr>
    )
  }

  const sortData = (filteredData: TableData<T>, sorting: Sorting<T>) => {
    if (!sorting) {
      return filteredData
    }

    return [...filteredData].sort((a, b) => {
      const valueA = a[sorting.columnKey]
      const valueB = b[sorting.columnKey]
      const sortOrder = sorting.order === 'asc' ? 1 : -1

      if (valueA > valueB) {
        return sortOrder
      } else if (valueA < valueB) {
        return -sortOrder
      } else {
        return 0
      }
    })
  }

  const setUpdateCell = (
    e: MouseEvent,
    isClickable: boolean,
    isUpdatingCell: boolean,
    item: SetStateAction<T | null>,
    column: TableColumn<T>,
  ) => {
    if (e.detail === 2 && isClickable && column.editable) {
      setUpdatingItem(isUpdatingCell ? null : item)
      setUpdatingColumnKey(isUpdatingCell ? null : column.key)
    }
  }

  const handleChangeUpdateInput = (e: ChangeEvent<HTMLInputElement>) => {
    setUpdateCellInputValue(e.target.value)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape':
        setUpdatingItem(null)
        break
      case 'Enter':
        if (handleUpdateCell) {
          handleUpdateCell(updateCellInputValue, updatingItem)
          setUpdateCellInputValue('')
        }
        setUpdatingItem(null)
        break
      default:
        // Handle other key presses if needed
        break
    }
  }

  const renderDataOrUpdateInput = (index: number, item: T, column: TableColumn<T>) => {
    const isClickable = index !== 0 && index !== columns.length - 1 // Check if the cell is clickable
    const isUpdatingCell = updatingItem === item && updatingColumnKey === column.key
    return (
      <td
        className={`overflow-hidden text-ellipsis whitespace-nowrap px-3 py-4 text-left text-sm font-normal text-primary ${setCellStyleBasedOnIndex(
          index,
          columns,
        )} ${index === columns.length - 1 && 'flex w-full justify-end hover:cursor-pointer'}`}
        key={nanoid()}
        onClick={(e) => setUpdateCell(e, isClickable, isUpdatingCell, item, column)}
      >
        {isUpdatingCell ? (
          <input
            className={'w-full focus:border-solid focus:border-primary focus:outline-none focus:ring-0'}
            autoFocus={isUpdatingCell}
            onChange={handleChangeUpdateInput}
            type="text"
            onKeyUp={handleKeyPress}
            onBlur={() => {
              setUpdatingItem(null)
              setUpdateCellInputValue('')
            }}
            value={updateCellInputValue}
          />
        ) : (
          renderCell(item[column.key], item, column)
        )}
      </td>
    )
  }

  const setUpdatingStyle = (item: T) => {
    return updatingItem && updatingItem === item && 'bg-brightGray'
  }

  /**
   * Renders the body rows of a table based on the data and sorting configuration.
   *
   * @returns {ReactElement | ReactElement[]} The rendered table rows as React elements.
   */
  const renderBodyRows = (): ReactElement | ReactElement[] => {
    // Sort the data based on the provided sorting configuration
    const sortedData = sortData(filteredData, sorting)

    // Check if there is no data to display
    if (!filteredData.length) {
      return (
        <tr className="w-full">
          <td colSpan={columns.length} className="py-4 pl-[5rem] pr-3 text-sm text-label">
            {emptyArrayMessage}
          </td>
        </tr>
      )
    }

    // Map over the sorted data and generate table rows
    return sortedData.map((item) => {
      const tableColumns = columns.map((column, index) => renderDataOrUpdateInput(index, item, column))

      // Return a table row with the generated table cells
      return (
        <tr
          className={`border-b-solid relative w-full border-b border-brightGray hover:bg-brightGray ${setUpdatingStyle(
            item,
          )}`}
          key={nanoid()}
        >
          {tableColumns}
        </tr>
      )
    })
  }

  /**
   * Renders a cell based on the provided value.
   * @param {any} value - The value to be rendered in the cell.
   * @param item
   * @param column
   * @returns {ReactNode} - The rendered cell content.
   */
  const renderCell = (value: unknown, item: T, column: TableColumn<T>): ReactNode => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      // Handle rendering of objects here (e.g., React components, complex structures)
      return JSON.stringify(value)
    } else if (typeof value === 'string') {
      // Handle rendering of other types (e.g., strings, numbers, booleans)
      return String(value)
    } else if (typeof column.render === 'function') {
      return column.render(item)
    }
  }

  const setCellStyleBasedOnIndex = (index: number, columns: TableColumn<T>[]) => {
    if (index === 0) {
      return 'w-[100px] max-w-[100px]'
    } else if (index === columns.length - 1) {
      return 'w-1/4 max-w-[200px] xl:max-w-full text-right '
    } else {
      return 'w-1/4 max-w-[200px]'
    }
  }

  return (
    <div className={'hidden flex-col gap-5 sm:flex'}>
      {children}
      <table className={'h-full overflow-auto bg-bright shadow-softElevatedShadow sm:inline-block '}>
        <thead>{renderHeaderRow()}</thead>
        <tbody>{renderBodyRows()}</tbody>
      </table>
    </div>
  )
}
