import React, { useState } from 'react'
import BaseCheckbox from '../components/atomics/BaseCheckbox.tsx'
import { TheIconCollapse } from './TheIconCollapse.tsx'
import { TheIconExpand } from './icons/TheIconExpand.tsx'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import {
  getIndeterminateGroups,
  getInitialState,
  getItemIdsByGroupId,
  ListItem,
  UiGroup,
} from '@/components/groupUtils.ts'
import {
  createGroupIdByItemIdMap,
  handleGroupCheckboxChange,
  handleItemCheckboxChange,
} from '@/components/NestedCheckboxListUtils.ts'

export interface Group {
  label: string
  items: ListItem[]
  idAsItem?: string // Optional property
}

interface NestedCheckboxListProps {
  groups: Group[]
  selectedItems: string[]
  className: string
  title: string
  onSelectedItemsChange: (selectedItems: string[]) => void
  isUserConnected: boolean
}

export function NestedCheckboxList({
  groups,
  selectedItems,
  title,
  onSelectedItemsChange,
  className,
  isUserConnected,
}: Readonly<NestedCheckboxListProps>) {
  const dispatch = useAppDispatch()

  const indeterminateGroups: number[] = getIndeterminateGroups(groups, selectedItems)
  const [uiGroups, setUiGroups] = useState(getInitialState(groups))
  const itemIdsByGroupId = getItemIdsByGroupId(groups)
  const groupIdByItemId = createGroupIdByItemIdMap(groups)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const newSelectedItems = handleItemCheckboxChange(
      event,
      itemId,
      selectedItems,
      groupIdByItemId,
      itemIdsByGroupId,
      groups,
    )
    onSelectedItemsChange(newSelectedItems)
  }

  const handleGroupCheckboxChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>, group: Group) => {
    const newSelectedItems = handleGroupCheckboxChange(
      event,
      group,
      selectedItems,
      isUserConnected,
      dispatch,
      openAuthModal,
    )
    onSelectedItemsChange(newSelectedItems)
  }

  const renderGroupItems = (group: UiGroup) => {
    if (group.collapsed || group.items.length === 0) {
      return null
    }
    return group.items.map((item) => (
      <div className={`ml-4 rounded px-3 py-1 hover:bg-brightGray`} key={item.id}>
        <label className={'group flex'}>
          <BaseCheckbox
            type="checkbox"
            className={'group-hover:bg-red accent-primary'}
            onChange={(e) => handleCheckboxChange(e, item.id)}
            checked={selectedItems.includes(item.id)}
          />
          <span className={'ml-3 text-sm font-normal text-primary'}>{item.label}</span>
        </label>
      </div>
    ))
  }

  const toggleGroup = (groupIndex: number) => {
    setUiGroups((uiGroups) =>
      [...uiGroups].map((uiGroup) => {
        if (uiGroup.index === groupIndex) {
          return {
            ...uiGroup,
            collapsed: !uiGroup.collapsed,
          }
        }
        return uiGroup
      }),
    )
  }

  const renderGroups = (groups: UiGroup[]) => {
    function getTheIconExpandOrCollapse(group: UiGroup) {
      if (group.items.length === 0) {
        return null
      }
      if (group.collapsed) {
        return <TheIconExpand onClick={() => toggleGroup(group.index)} />
      } else {
        return <TheIconCollapse onClick={() => toggleGroup(group.index)} />
      }
    }

    return groups.map((group) => (
      <div className={'flex flex-col'} key={group.index}>
        <div className={'flex w-full items-center justify-between hover:bg-brightGray'}>
          <label className={'flex px-3 py-1'}>
            <BaseCheckbox
              type="checkbox"
              className={`accent-primary`}
              onChange={(e) => handleGroupCheckboxChangeWrapper(e, group)}
              checked={
                selectedItems.some((itemId) => group.items.some((item) => item.id === itemId)) ||
                (!!group.idAsItem && selectedItems.includes(group.idAsItem))
              }
              ref={(checkbox) => {
                if (checkbox) {
                  checkbox.indeterminate = indeterminateGroups.includes(group.index)
                }
              }}
            />
            <span className={'ml-3 text-sm font-semibold text-primary'}>{group.label}</span>
          </label>
          <div className={'mr-2 flex h-6 w-6 items-center justify-center'}>{getTheIconExpandOrCollapse(group)}</div>
        </div>
        {renderGroupItems(group)}
      </div>
    ))
  }

  return (
    <div className={className}>
      <h2 className={'px-3 text-sm font-medium text-silver'}>{title}</h2>
      {renderGroups(uiGroups)}
    </div>
  )
}
