import { useLegalWatchArticleDetailsHook } from '@/components/hooks/use-LegalWatchArticleDetails.hook.tsx'
import { LegalWatchArticleDetails } from '@/components/ds/LegalWatchArticleDetails.tsx'
import { useAppSelector } from '@/store/hooks.ts'
import { selectConversationsForArticle } from '@/store/conversations/conversationsSlice.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export function LegalWatchArticleDetailsContainer() {
  const { article, filterArticlesByTag, onStartConversation, toggleFavorite } = useLegalWatchArticleDetailsHook()
  const conversationsForArticle = useAppSelector(selectConversationsForArticle)
  const { isUserConnected } = useCurrentUserHook()

  if (!article) {
    return <div className={'mt-10 text-lg font-normal text-label'}>Aucun article trouvé</div>
  }

  return (
    <LegalWatchArticleDetails
      filterArticlesByTag={filterArticlesByTag}
      onStartConversation={onStartConversation}
      article={article}
      conversationsForArticle={conversationsForArticle}
      onToggleFavorite={toggleFavorite}
      isUserConnected={isUserConnected}
    />
  )
}
