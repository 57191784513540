import { TreeDataItem } from '@/components/ui/tree'
import { useEffect, useState } from 'react'
import { findNextArticle, findPreviousArticle, flattenArticles, getParentIds } from './utils'
import { LegalResource } from '@/types/legalResource.ts'

export const useArticlesHook = (text: LegalResource) => {
  const [articles, setArticles] = useState<TreeDataItem[]>([])
  const [currentArticle, setCurrentArticle] = useState<TreeDataItem>()

  const [hasNext, setHasNext] = useState(false)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [path, setPath] = useState([] as string[])

  useEffect(() => {
    const node = { children: text?.content || [] } as unknown as TreeDataItem
    const articles = flattenArticles(node)
    setArticles(articles)
    setCurrentArticle(articles[0])
  }, [text])

  useEffect(() => {
    const node = { children: text?.content || [] } as unknown as TreeDataItem
    if (!currentArticle) {
      setHasNext(false)
      setHasPrevious(false)
      return
    }
    const hasNext = findNextArticle(articles, currentArticle?.id) !== null
    setHasNext(hasNext)
    const hasPrevious = findPreviousArticle(articles, currentArticle?.id) !== null
    setHasPrevious(hasPrevious)
    setPath(getParentIds(node, currentArticle.id, []) || [])
  }, [currentArticle, articles, text])

  const setNextArticle = () => {
    if (!hasNext) return
    if (!currentArticle) return
    const nextArticle = findNextArticle(articles, currentArticle?.id)
    if (!nextArticle) return
    setCurrentArticle(nextArticle)
  }

  const setPreviousArticle = () => {
    if (!hasPrevious) return
    if (!currentArticle) return
    const previousArticle = findPreviousArticle(articles, currentArticle?.id)
    if (!previousArticle) return
    setCurrentArticle(previousArticle)
  }

  return {
    currentArticle,
    setCurrentArticle,
    hasNext,
    hasPrevious,
    setNextArticle,
    setPreviousArticle,
    path,
  }
}
