import { TagWithFileCountAndActiveStatus } from '@/types/tag'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  selectFileCountByTagId,
  setDisplayTagModal,
  setTagToUpdateOrDelete,
  toggleDeleteTagWarning,
} from '@/store/tags/tagSlice.ts'
import { deleteTag } from '@/store/tags/useCases/deleteTag.ts'

export const useDocumentsFolderHook = ({ tag }: { tag: TagWithFileCountAndActiveStatus }) => {
  const dispatch = useAppDispatch()
  const tagFilesCount = useAppSelector(selectFileCountByTagId(tag.id))

  const activeTagContainerStyle = () => {
    return tag.isTagActive
      ? 'shadow-softElevatedShadow border border-solid border-brightGray bg-bright'
      : 'border border-solid border-mistySerenity bg-mistySkyGray hover:cursor-pointer'
  }

  const onRename = (tag: TagWithFileCountAndActiveStatus) => {
    dispatch(setDisplayTagModal())
    dispatch(setTagToUpdateOrDelete(tag))
  }

  const onDelete = (tag: TagWithFileCountAndActiveStatus) => {
    if (tagFilesCount === 0) {
      dispatch(deleteTag(tag.id))
      return
    }
    dispatch(toggleDeleteTagWarning())
    dispatch(setTagToUpdateOrDelete(tag))
  }

  const getDocumentLabel = () => {
    return tag.count > 1 ? 'documents' : 'document'
  }

  const getFilesLength = () => {
    return tag.count
  }

  return {
    activeTagContainerStyle,
    getDocumentLabel,
    getFilesLength,
    onRename,
    onDelete,
  }
}
