import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { TheIconChevronFilled } from '@/components/icons/TheIconChevronFilled.tsx'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { ArticleFilterTag } from '@/components/ds/ArticleFilterTag.tsx'
import { RegionFilter } from '@/utils/filterArticle.ts'
import { FilteredItemsLabel } from '@/components/FilteredItemsLabel.tsx'
import { TheIconCross } from '@/components/icons/TheIconCross.tsx'

interface Props {
  selectedRegions: RegionFilter[]
  regions: RegionFilter[]
  onSelect: (value: string) => void
  onUnSelect: (value: string) => void
  isOpen: boolean
  onOpenChange: () => void
  onResetInput: () => void
  onChangeInput: (value: string) => void
  inputValue: string
}

export function RegionPickerFilter({
  selectedRegions,
  onSelect,
  regions,
  isOpen,
  onOpenChange,
  onResetInput,
  onUnSelect,
  inputValue,
  onChangeInput,
}: Readonly<Props>) {
  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          size={'filter'}
          variant={'filter'}
          className={cn(
            'group w-fit justify-start gap-2 rounded-lg text-left font-normal',
            !selectedRegions.length
              ? 'bg-silverLining hover:bg-gainsboroGray'
              : ' bg-softMint text-mintGreen hover:bg-softMint',
          )}
        >
          {selectedRegions.length === 0 && <span className={'text-label'}>Région</span>}{' '}
          <FilteredItemsLabel items={selectedRegions} labelKey="iso" />
          <TheIconChevronFilled
            className={`${selectedRegions.length ? 'fill-label' : 'fill-silver group-hover:fill-label'}`}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={'flex w-[21.25rem] max-w-[31.25rem] flex-col gap-4 rounded-2xl px-5 py-4'}>
        <Command>
          <div className={'flex max-h-[120px] flex-wrap gap-2 overflow-y-auto'}>
            {selectedRegions.map((selectedRegion) => (
              <ArticleFilterTag
                className={'gap-1 rounded bg-mistyJade px-2 py-0 text-sm text-primary'}
                key={selectedRegion.iso}
                label={selectedRegion.name}
                onIconCLick={() => onUnSelect(selectedRegion.name)}
                contentColor={'primary'}
              />
            ))}
          </div>
          <CommandInput
            value={inputValue}
            onValueChange={onChangeInput}
            className={'text-sm font-normal text-primary placeholder:text-silver'}
            placeholder="Rechercher..."
            list={'regions'}
          >
            {inputValue.length > 0 && (
              <TheIconCross
                onClick={onResetInput}
                className={'h-5 w-5 fill-label hover:cursor-pointer hover:fill-silver'}
              />
            )}
          </CommandInput>
          <CommandEmpty className={'px-3 py-1 text-sm font-normal text-label'}>Aucun résultat</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {regions.map((region) => (
                <CommandItem key={region.iso} value={region.name} onSelect={onSelect}>
                  {region.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
