type Props = {
  counter: number
  title: string
}

export function EmailConfirmationPageHeader({ counter, title }: Readonly<Props>) {
  const countDownLabel = () => {
    return counter !== 1 ? `${counter} secondes.` : `${counter} seconde.`
  }
  return (
    <div className={'flex flex-col gap-4'}>
      <h1 className={'text-xl font-semibold text-primary sm:text-3xl'}>{title}</h1>

      <p className={'text-base text-primary'}>
        🚀 Vous allez automatiquement être redirigé vers notre site dans{' '}
        <span className={'inline-block w-[90px] italic'}>{countDownLabel()}</span>
      </p>
    </div>
  )
}
