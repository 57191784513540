import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconMoveFiles(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3368_18813)">
        <path
          d="M8.94445 8.67879C8.95279 8.70465 8.96197 8.73051 8.97031 8.75554C8.34459 9.38043 7.71888 10.0045 7.09399 10.6294C6.87374 10.8504 6.64348 11.0632 6.44158 11.3001C6.20548 11.5771 6.24052 11.9609 6.49497 12.2012C6.74443 12.4373 7.09566 12.4481 7.37515 12.2254C7.44022 12.1736 7.49779 12.1136 7.55702 12.0543C8.73337 10.8755 9.90972 9.69746 11.0852 8.51777C11.4081 8.19323 11.4156 7.82781 11.0927 7.50494C9.87802 6.29105 8.66246 5.07883 7.43856 3.87412C7.3276 3.76483 7.16407 3.67556 7.01223 3.64636C6.71773 3.58962 6.48413 3.72061 6.34063 3.98841C6.19797 4.25455 6.2522 4.5599 6.50248 4.81269C7.25084 5.56522 8.00504 6.31275 8.75673 7.06194C8.83098 7.13619 8.90023 7.21461 9.0162 7.33892C8.81013 7.33892 8.68582 7.33892 8.56151 7.33892C6.02193 7.33976 3.48236 7.34142 0.942779 7.34226C0.838493 7.34226 0.734207 7.33725 0.630755 7.3481C0.26617 7.38898 0.00336918 7.66763 3.20257e-05 8.00969C-0.00330513 8.35175 0.25449 8.6304 0.619909 8.67128C0.743384 8.68463 0.869362 8.68213 0.993671 8.68213C3.52324 8.68213 6.05197 8.68046 8.58153 8.67962C8.7025 8.67962 8.82348 8.67962 8.94445 8.67962V8.67879ZM15.9884 8.02888C15.9842 5.66701 15.98 3.30513 15.9758 0.942425C15.9758 0.817281 15.9792 0.692138 15.9658 0.568663C15.9291 0.235782 15.6688 0.0021806 15.3368 -0.00699658C14.9063 -0.0186766 14.6476 0.282502 14.6485 0.804767C14.656 5.60193 14.6643 10.3983 14.6727 15.1954C14.6727 15.268 14.6693 15.3414 14.6785 15.414C14.7269 15.8061 15.0648 16.0581 15.4569 15.9972C15.8098 15.9421 16 15.6726 15.9992 15.2096C15.9967 12.816 15.9917 10.4233 15.9875 8.02971L15.9884 8.02888Z"
          fill="white"
        />
        <path
          d="M8.94445 8.67871C8.82348 8.67871 8.7025 8.67871 8.58153 8.67871C6.05197 8.67955 3.52324 8.68038 0.993671 8.68122C0.868527 8.68122 0.743384 8.68372 0.619909 8.67037C0.25449 8.63032 -0.00330513 8.35084 3.20257e-05 8.00961C0.00336918 7.66755 0.26617 7.38974 0.629086 7.35052C0.731704 7.33968 0.836824 7.34468 0.94111 7.34468C3.48069 7.34302 6.02026 7.34218 8.55984 7.34135C8.68415 7.34135 8.80846 7.34135 9.01453 7.34135C8.89856 7.21704 8.82932 7.13862 8.75506 7.06436C8.00337 6.31434 7.24917 5.56765 6.50081 4.81512C6.24969 4.56316 6.1963 4.25781 6.33896 3.99084C6.48246 3.72303 6.71606 3.59205 7.01057 3.64878C7.16324 3.67798 7.32593 3.76725 7.43689 3.87654C8.66079 5.08126 9.87718 6.29348 11.0911 7.50737C11.4148 7.83108 11.4073 8.19649 11.0836 8.5202C9.90805 9.69988 8.7317 10.8779 7.55536 12.0568C7.49696 12.116 7.43856 12.1761 7.37348 12.2278C7.09399 12.4505 6.74276 12.4397 6.49331 12.2036C6.23885 11.9633 6.20381 11.5787 6.43991 11.3026C6.64181 11.0656 6.87207 10.8529 7.09233 10.6318C7.71721 10.0061 8.34293 9.38202 8.96864 8.75797C8.9603 8.73211 8.95112 8.70624 8.94278 8.68122L8.94445 8.67871Z"
          fill="black"
        />
        <path
          d="M15.9883 8.02922C15.9925 10.4228 15.9975 12.8155 16 15.2091C16 15.6721 15.8106 15.9425 15.4577 15.9967C15.0664 16.0576 14.7285 15.8056 14.6793 15.4135C14.6701 15.3418 14.6735 15.2684 14.6735 15.1949C14.6651 10.3978 14.6568 5.60144 14.6493 0.804279C14.6493 0.282014 14.9063 -0.0191649 15.3376 -0.00748486C15.6696 0.00169232 15.9299 0.235293 15.9666 0.568175C15.98 0.691649 15.9766 0.817627 15.9766 0.941936C15.9808 3.30381 15.985 5.66568 15.9892 8.02839L15.9883 8.02922Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="16" height="16.0133" fill="white" transform="translate(0 -0.00683594)" />
        </clipPath>
      </defs>
    </svg>
  )
}
