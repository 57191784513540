import { useAppSelector } from '../store/hooks.ts'
import { useRef } from 'react'
import { selectAllMessages } from '../store/messages/messagesSlice.ts'
import { Title } from '../types/conversation.ts'

type Props = {
  title: Title
  description: string
  isActive: boolean
}

export function ConversationTypeItem({ title, description, isActive }: Readonly<Props>) {
  const messages = useAppSelector(selectAllMessages)
  const conversationTypeRef = useRef<HTMLDivElement>(null)

  const setTitleStyle = () => {
    return isActive ? 'text-primary' : 'text-label'
  }

  const setDescriptionStyle = () => {
    return isActive ? 'text-label' : 'text-silver'
  }

  const setMarginBottom = () => {
    return !messages.length && 'mb-4'
  }

  return (
    <div className="group" ref={conversationTypeRef}>
      <h3
        className={`${setMarginBottom()} text-center text-base font-semibold group-hover:text-primary ${setTitleStyle()}`}
      >
        {title}
      </h3>
      {!messages.length && (
        <p className={`text-center text-sm group-hover:text-[#6E7682] ${setDescriptionStyle()} `}>{description}</p>
      )}
    </div>
  )
}
