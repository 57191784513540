import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Conversation } from '@/types/conversation.ts'
import { listConversationMessages } from './useCases/listConversationMessages.ts'
import { updateMessageFeedback } from './useCases/updateMessageFeedback.ts'
import { RootState } from '../configureStore.ts'
import { Message } from '@/types/message.ts'
import { createConversationMessage } from '@/store/messages/useCases/createConversationMessage.ts'

export const messagesEntityAdapter = createEntityAdapter<Message>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
})
export const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesEntityAdapter.getInitialState({
    isLoading: false,
    createMessageError: '',
    listConversationMessagesError: '',
  }),
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      messagesEntityAdapter.addOne(state, action.payload)
    },
    resetMessages: (state) => {
      messagesEntityAdapter.setAll(state, [])
    },
    streamLastMessageInConversation: (
      state,
      action: PayloadAction<{ conversationId: Conversation['id']; token: string }>,
    ) => {
      const lastMessageId = state.ids.pop()
      if (!lastMessageId) return
      const lastMessage = state.entities[lastMessageId]
      if (!lastMessageId) return
      messagesEntityAdapter.updateOne(state, {
        id: lastMessageId,
        changes: {
          content: lastMessage?.content + action.payload.token,
        },
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listConversationMessages.fulfilled, (state, action) => {
      state.isLoading = false
      messagesEntityAdapter.setAll(state, action.payload)
    })
    builder.addCase(listConversationMessages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(listConversationMessages.rejected, (state, action) => {
      state.isLoading = false
      state.listConversationMessagesError = action.error.message ?? ''
    })
    builder.addCase(updateMessageFeedback.fulfilled, (state, action) => {
      state.isLoading = false
      messagesEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    })
    builder.addCase(createConversationMessage.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createConversationMessage.pending, (state) => {
      state.isLoading = true
    })
  },
})

export const { addMessage, resetMessages, streamLastMessageInConversation } = messagesSlice.actions

export const messagesSelector = messagesEntityAdapter.getSelectors((state: RootState) => state.entities.messages)

export const selectAllMessages = (state: RootState) => messagesSelector.selectAll(state)
export const selectIsSendingMessage = (state: RootState) => state.entities.messages.isLoading
