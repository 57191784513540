import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function useRedirect(path: string, timeOut: number) {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate(path)
    }, timeOut)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
