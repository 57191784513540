import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconHideEye(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} id="Calque_1" data-name="Calque 1" viewBox="0 0 383.43 287.43">
      <path
        className="cls-1"
        d="m.51,140.72C34.79,84.14,82.05,44.3,147.43,28.97c42.91-10.06,84.97-5.68,125.52,11.76,2.94,1.26,4.54.84,6.67-1.32,11.72-11.92,23.59-23.69,35.41-35.51,3.46-3.46,7.54-4.77,12.3-3.33,4.45,1.34,7.21,4.41,8.21,8.88,1.08,4.84-.84,8.68-4.26,12.09-16.19,16.1-32.31,32.26-48.45,48.41-70.75,70.74-141.5,141.48-212.21,212.26-3.45,3.45-7.15,5.95-12.21,5.03-4.65-.85-7.9-3.58-9.36-8.16-1.51-4.74-.3-8.86,3.16-12.34,9.95-9.99,19.92-19.95,29.9-29.9.97-.97,2.11-1.76,3.61-2.99C50.13,211.22,21.36,182.8.51,146.71c-.73-2.31-.63-4.27,0-5.99ZM257.69,60.73c-.42-.28-.71-.54-1.04-.67-40.97-15.58-82.33-16.57-123.64-1.99-44.39,15.67-78.08,45.2-104.88,83.24-1.34,1.9-1.23,3.24.15,4.87,9.73,11.5,18.93,23.51,29.29,34.41,13.28,13.96,28.77,25.35,45.33,34.7,10.2-10.19,20.24-20.21,30.31-30.27-22.13-32.82-13-69.13,5.53-89.35,10.33-11.27,22.87-18.83,37.8-22.1,20.67-4.52,39.75-.27,56.95,11.35,8.2-8.2,16.13-16.12,24.21-24.2Zm-106.74,106.25c21.48-21.48,43.02-43.02,64.52-64.51-14.89-10.15-40.55-9.78-57.74,7.99-17.2,17.78-16.32,43.22-6.77,56.52Z"
      />
      <path
        className="cls-1"
        d="m302.2,85.16c5.68-5.62,11.32-11.21,17.52-17.35,6.48,5.63,13.51,11.35,20.08,17.55,15.57,14.7,29.04,31.15,40.57,49.22,4.08,6.38,4.07,11.98-.05,18.32-29.95,46.1-69.13,81.01-121.55,99.41-40.98,14.38-82.54,14.79-124.25,2.83-.58-.17-1.13-.48-2.22-.95,6.32-6.26,12.35-12.33,18.53-18.25.71-.68,2.32-.9,3.38-.67,59.76,12.48,112.16-3.24,158.59-41.49,16.65-13.72,30.85-29.73,43.18-47.43,1.36-1.95,1.36-3.26,0-5.2-14.15-20.14-30.79-37.9-50.28-52.97-1.08-.84-2.16-1.68-3.23-2.55-.18-.15-.27-.41-.3-.46Z"
      />
      <path
        className="cls-1"
        d="m261.57,124.98c6.03,27.09.31,50.24-18.62,69.27-19.05,19.14-42.29,24.94-69.89,18.82,4.65-4.45,8.57-8.2,12.49-11.96.72-.69,1.47-1.36,2.11-2.12,4.26-5.08,9.31-7.88,16.16-9.3,19.01-3.93,33.17-20.65,35.6-40.11.16-1.28.6-2.79,1.45-3.67,6.58-6.8,13.28-13.46,20.71-20.93Z"
      />
    </svg>
  )
}
