import { Conversation } from '@/types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const listConversationMessages = createAppAsyncThunk(
  'messages/listConversationMessages',
  async (conversationId: Conversation['id'], { extra: { conversationGateway } }) => {
    try {
      return await conversationGateway.listConversationMessages(conversationId)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
