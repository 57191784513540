import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function RevokeShareIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M7.16732 2.83317V1.1665M12.1673 16.1665V17.8332M3.00065 6.99984H1.33398M16.334 11.9998H18.0007M3.7625 3.59502L2.58398 2.4165M15.5721 15.4047L16.7507 16.5832M9.66732 14.2139L7.89955 15.9816C6.5978 17.2834 4.48725 17.2834 3.18551 15.9816C1.88376 14.6799 1.88376 12.5694 3.18551 11.2676L4.95327 9.49984M14.3814 9.49984L16.1491 7.73207C17.4509 6.43032 17.4509 4.31977 16.1491 3.01803C14.8474 1.71628 12.7368 1.71628 11.4351 3.01803L9.66732 4.78579"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
