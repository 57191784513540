import { Badge } from '@/components/ui/badge.tsx'

interface Props {
  label: string
  badgeLabel: string
}
export function ContextualLabel({ label, badgeLabel }: Readonly<Props>) {
  return (
    <div className={'flex items-center gap-2'}>
      <span className={'text-sm text-label'}>{label}</span>
      <Badge variant={'custom'} className={'self-start border-mintGreen bg-bright text-xs font-normal text-mintGreen'}>
        {badgeLabel}
      </Badge>
    </div>
  )
}
