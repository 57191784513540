import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { ReactNode, useRef } from 'react'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { InputComparisonFileUploader } from './InputComparisonFileUploader.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import { TheIconFilterMobile } from './icons/TheIconFilterMobile.tsx'
import TextareaAutosize from 'react-textarea-autosize'
import { ALLOWED_FILE_TYPES_AS_STRING } from '../utils/FileTypes.ts'

export function TheInputFileComparisonTypeMobile({
  isQuestionLoading,
  handleDisplayFilterMobile,
  userInput,
  onInput,
  handleKeyDown,
  handleFileInputChange,
  handleInputFileOpening,
  currentConversation,
  handleSend,
  messages,
}: Readonly<TheConversationMessageInputProps>) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const renderSubmitOrLoadingIcon = (): ReactNode => {
    return isQuestionLoading ? (
      <TheDotAnimation />
    ) : (
      <div className={`flex items-center gap-4 self-start ${messages?.length && 'justify-center'}`}>
        {!messages?.length && <TheIconFilterMobile onClick={handleDisplayFilterMobile} className={'h-7 w-7'} />}
        <TheIconArrowSubmission
          onClick={handleSend}
          disabled={userInput.length === 0 || currentConversation?.currentFilesInfos.length === 0}
        />
      </div>
    )
  }

  return (
    <div className={`flex w-full gap-4 bg-bright sm:hidden`}>
      <div
        className={
          'flex basis-4/5 flex-col justify-between gap-4 rounded border border-solid border-fadedSilver pt-2 shadow-subtleElevation'
        }
      >
        <TextareaAutosize
          maxRows={3}
          placeholder="Vous pouvez poser votre question"
          value={userInput}
          disabled={isQuestionLoading}
          onInput={onInput}
          onKeyDown={handleKeyDown}
          className={'w-full resize-none rounded-lg bg-bright px-2 text-base leading-5 outline-none'}
        />
        <div className={'flex flex-col gap-2'}>
          <InputComparisonFileUploader
            iconStyle={'h-10 w-8'}
            disabled={isQuestionLoading}
            handleInputFileOpening={() => handleInputFileOpening?.(inputRef)}
            fileInfos={currentConversation?.currentFilesInfos[0]}
          />

          <InputComparisonFileUploader
            iconStyle={'h-10 w-8'}
            disabled={isQuestionLoading}
            handleInputFileOpening={() => handleInputFileOpening?.(inputRef)}
            fileInfos={currentConversation?.currentFilesInfos[1]}
          />
        </div>
      </div>
      {renderSubmitOrLoadingIcon()}
      <input
        ref={inputRef}
        accept={ALLOWED_FILE_TYPES_AS_STRING}
        className="hidden"
        type="file"
        onChange={handleFileInputChange}
      />
    </div>
  )
}
