import { ReactElement, ReactNode } from 'react'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu'

interface ConversationDropDownProps {
  items: { name: string; icon: ReactElement; action: () => void }[]
  toggle?: ReactNode
}

function ConversationDropDown({ toggle, items }: ConversationDropDownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{toggle}</DropdownMenuTrigger>
      <DropdownMenuContent>
        {items.map((item) => {
          return (
            <DropdownMenuItem key={item.name} onClick={item.action} className="cursor-pointer">
              <div className="flex w-full justify-between gap-4">
                <div>{item.name}</div>
                <div>{item.icon}</div>
              </div>
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ConversationDropDown
