import { TheConversationTypesList } from '@/components/TheConversationTypesList/TheConversationTypesList.tsx'
import ConversationShareMenu from '@/components/TheConversationTypesList/ConversationShareMenu.tsx'
import { Conversation } from '@/types/conversation.ts'
import { Message } from '@/types/message.ts'

interface Props {
  messages: Message[]
  conversationId: string
  conversation: Conversation
}
export function ConversationOptionsBar({ messages, conversationId, conversation }: Readonly<Props>) {
  return (
    <div className="flex items-center justify-between">
      <div style={{ flex: 1 }}>
        <TheConversationTypesList subtitle={'Choisissez votre type de conversation'} />
      </div>
      {!!messages.length && <ConversationShareMenu id={conversationId} snapshotId={conversation?.snapshotId} />}
    </div>
  )
}
