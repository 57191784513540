import {
  GetLegalWatchArticleByIdResponse,
  LegalWatchArticle,
  ListLegalWatchArticlesResponse,
  ListUserConversationsForLegalWatchArticleResponse,
} from '@/types/legalWatchArticle.ts'
import { LegalWatchArticleGateway } from '@/gateways/interface/legalWatchArticle.gateway.ts'
import { OlympeGptApiWretch } from '@/gateways/olympeGptApiWretch.ts'

export class WretchLegalWatchArticleGateway implements LegalWatchArticleGateway {
  private readonly endpoint = '/legal-watch-articles'
  private olympeGptApi: OlympeGptApiWretch
  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async listUserConversationsForLegalWatchArticle(
    articleId: string,
  ): Promise<ListUserConversationsForLegalWatchArticleResponse['data']> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${articleId}/conversations`
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .get()
      .json<ListUserConversationsForLegalWatchArticleResponse>()
    return response.data
  }

  async addLegalWatchArticleToFavorites(articleId: string): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${articleId}/favorites`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .post()
      .res()
  }

  async removeLegalWatchArticleFromFavorites(articleId: string): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${articleId}/favorites`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .delete()
      .res()
  }

  async getLegalWatchArticleById(articleId: LegalWatchArticle['id']): Promise<LegalWatchArticle> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${articleId}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetLegalWatchArticleByIdResponse>()
    return response.data
  }

  async getLegalWatchArticleBySlug(slug: LegalWatchArticle['slug']): Promise<LegalWatchArticle[]> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}?slug=${slug}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListLegalWatchArticlesResponse>()
    return response.data
  }

  async listLegalWatchArticles(): Promise<LegalWatchArticle[]> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListLegalWatchArticlesResponse>()
    return response.data
  }
}
