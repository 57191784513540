import React from 'react'
import { useJobTitleItemHook } from './use-jobTitleItem.hook.tsx'
import { TheIconExternalDPO } from '../../../../icons/TheIconExternalDPO.tsx'
import { TheIconInternalDPO } from '../../../../icons/TheIconInternalDPO.tsx'
import { TheIconMinimalistHarmony } from '../../../../icons/TheIconMinimalistHarmony.tsx'

export interface JobTitleItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  jobType: 'internal' | 'external' | 'other'
  disabled?: boolean
  size: 'large' | 'default'
  checked: boolean
  value: string
}

export function JobTitleItem({ checked, disabled, value, size, jobType, ...rest }: Readonly<JobTitleItemProps>) {
  const { titleColor, backgroundColor, width, borderColor, subtitleColor, flex } = useJobTitleItemHook({
    size,
    checked: checked ?? false,
    disabled: disabled ?? false,
  })

  const cardContent: Record<
    JobTitleItemProps['jobType'],
    { title: string; subtitle: string; icon: React.ReactNode; checked: boolean }
  > = {
    internal: {
      title: 'DPO interne',
      subtitle: "Vous travaillez au sein d'une structure",
      icon: <TheIconInternalDPO />,
      checked: false,
    },
    external: {
      title: 'DPO externe',
      subtitle: 'Vous travaillez pour plusieurs structures.',
      icon: <TheIconExternalDPO />,
      checked: false,
    },
    other: {
      title: 'Autre profession',
      subtitle: 'Juriste, étudiant, etc.',
      icon: <TheIconMinimalistHarmony />,
      checked: false,
    },
  }

  const { title, subtitle, icon } = cardContent[jobType]
  const iconWithProps = React.Children.map(icon, (child) => {
    const additionalProps = {
      checked,
      disabled,
    }
    return React.cloneElement(child as React.ReactElement, additionalProps)
  })
  return (
    <button className={'flex w-full self-start'} {...rest}>
      <label
        className={`${flex} ${width} items-start gap-[1.25rem] self-stretch rounded-[0.5rem] border hover:cursor-pointer ${borderColor} ${backgroundColor} px-[1rem] py-[0.75rem]`}
      >
        {/* icon onboarding */}
        {iconWithProps}
        {/* content */}
        <div className={'flex items-start gap-[1.25rem]'}>
          <div className={'items-star flex flex-col'}>
            <p className={`self-stretch text-left text-[1rem] font-medium leading-[1.25rem] ${titleColor}`}>{title}</p>
            <p className={`text-left text-[0.875rem] leading-[1.25rem] ${subtitleColor}`}>{subtitle}</p>
          </div>
        </div>
        <input type="radio" value={value} hidden={true} />
      </label>
    </button>
  )
}
