export type RecommendationExempleSection = {
  text: string
  position: 'left' | 'right'
}

export type SubRecommendation = {
  subRecommendationTitle: string | null
  subRecommendationDescription: string
  subRecommendationExempleSections: RecommendationExempleSection[] | []
}

type Recommendation = {
  recommendationTitle: string
  subRecommendations: SubRecommendation[]
}

type Recommendations = Recommendation[]

export const usePromptTutorialHook = () => {
  const recommendations: Recommendations = [
    {
      recommendationTitle: 'A. Donner du contexte',
      subRecommendations: [
        {
          subRecommendationTitle: '1. Rédigez le plus de détails possible',
          subRecommendationDescription:
            'Incluez tous les détails dans votre requête, même ceux qui vous paraissent à priori évidents, pour écarter toute ambiguïté. Sinon, vous laissez à Olympe la tâche de deviner votre intention.',
          subRecommendationExempleSections: [
            {
              text: 'Comment gérer les données pour être conforme au RGPD ?',
              position: 'left',
            },
            {
              text: 'Dans le cadre d’une activité de [nom de l’activité] menée par une entreprise qui utilise des outils informatiques et de l’archivage papier, liste en 10 points les étapes méthodologiques à suivre pour mener à bien un plan de mise en conformité de ses activités au RGPD. Ces 10 points doivent êtres listés dans un ordre chronologique.',
              position: 'right',
            },
          ],
        },
        {
          subRecommendationTitle: '2. Demandez au modèle de s’adapter à votre personne en particulier',
          subRecommendationDescription:
            'Dans le corps de votre question ou de votre requête, vous pouvez rédiger quelques mots ou lignes vous décrivant (vos fonctions, votre domaine d’expertise, etc.) et les proposer à Olympe afin d’obtenir une réponse appropriée à votre contexte professionnel précis.',
          subRecommendationExempleSections: [
            {
              text: 'Que dois je faire pour gérer les cookies sur mon site ?',
              position: 'left',
            },
            {
              text: 'Je suis [fonction professionnelle] au sein d’une entreprise dont l’activité est [nom activité]. Cet entreprise dispose d’un site internet [marchand / vitrine / communautaire]. Explique en étapes listées les actions à mener pour vérifier la conformité de nos pratiques en matière d’utilisation de cookies et de traceurs, ainsi que les mesures correctives à appliquer pour chaque cas de non conformité.',
              position: 'right',
            },
          ],
        },
        {
          subRecommendationTitle: '3. Incluez des superlatifs pour hiérarchiser l’information',
          subRecommendationDescription:
            "L'ajout de superlatifs dans les prompts contribue à rendre les réponses plus précises et permet de hiérarchiser les éléments clés.\n" +
            '"Quels sont les éléments les plus importants à intégrer dans ma demande de droit d\'accès ?" permet à Olympe de fournir des informations plus ciblées, évitant ainsi les réponses trop générales liées à des requêtes plus vagues comme "Comment faire une demande d\'accès en droit des données personnelles ?".',
          subRecommendationExempleSections: [
            {
              text: 'Quelle base légale pertinente pour une activité d’emailing ?',
              position: 'left',
            },
            {
              position: 'right',
              text: 'Dans le cadre d’une activité de traitement consistant en l’envoi d’email à des fins de promotion et de marketing d’une audience composée de particuliers, indique quelle est la base légale la plus appropriée pour fonder juridiquement ce traitement.',
            },
          ],
        },
        {
          subRecommendationTitle: '4. Donnez des exemples et des scénarios pratiques',
          subRecommendationDescription:
            'Fournir des exemples ou des cas pratiques dans un prompt améliore la qualité de la réponse en offrant à Olympe un contexte concret et spécifique. Cela réduit les réponses génériques ou peu spécifiques.',
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Dans le texte suivant, indique quelles sont les données à caractère personnel qui peuvent être sensibles au sens du RGPD : [texte].',
            },
            {
              position: 'right',
              text:
                'Voici ta mission : ton but est de trouver le plus de données sensibles au sein du texte suivant. Pour réussir ta mission tu dois les lister comme suit : <extrait de donnée> - <catégorie de donnée> - <justification de la sensibilité>.\n' +
                '\n' +
                'Par exemple : avec le texte “Jenny a eu la varicelle quand elle avait 3 ans. Aujourd’hui elle pratique la natation tous les dimanches”, les données sensibles à détecter sont : \n' +
                '- donnée de santé - “varicelle à 3 ans” - “information sur la santé et l’antécédent médical” Voici le texte à analyser pour y trouver les données sensibles : [texte].',
            },
          ],
        },
        {
          subRecommendationTitle: "5. Spécifier les étapes nécessaires à l'accomplissement d'une tâche",
          subRecommendationDescription:
            "Certaines tâches sont mieux détaillées sous forme de séquence d'étapes. Énoncer et lister explicitement ces étapes facilite la compréhension et le suivi par Olympe.",
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Rédige une fiche de traitement pour un traitement de gestion de liste email pour envoi d’email marketing.',
            },
            {
              position: 'right',
              text:
                'Tu dois rédiger une fiche de traitement conformément aux exigences du RGPD.\n' +
                'Cette liste de traitement concerne l’activité de traitement suivante : Gestion d’une liste d’email de personnes inscrites pour recevoir des communications promotionnelles relevant d’une activité marketing.\n' +
                'La procédure à suivre pour rédiger la fiche est la suivante :\n' +
                '- écrire le nom de l’activité de traitement\n' +
                '- déterminer la finalité de l’activité de traitement (si besoin, il te faut me solliciter pour avoir des éléments contextuels afin d’être pertinent)\n' +
                '- définir une seule base légale appropriée pour mener le traitement pour cette finalité définie précédemment\n' +
                '- lister les catégories de données traitées à cet effet\n' +
                '- pour chaque catégorie de données, détermine la durée de conservation appropriée en prenant en compte la finalité poursuivie\n' +
                '- lister les mesures de sécurité (poser des questions complémentaires pour les déterminer)\n' +
                '- lister les destinataires de ces données (poser des questions complémentaires pour les déterminer)\n' +
                '- lister les transferts de données hors Union européenne et définir la garantie appropriée.',
            },
          ],
        },
        {
          subRecommendationTitle: '6. Indiquez un contexte juridique précis',
          subRecommendationDescription:
            'Incluez le contexte juridique ou réglementaire pertinent. Par exemple, "Sous le cadre du RGPD, quelle est la procédure à suivre en cas de fuite de données personnelles ?".',
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Comment répondre à une requête d’effacement des données sans accéder à la requête ?',
            },
            {
              position: 'right',
              text: 'En tant que DPO, j’ai reçu une requête d’exercice de droit d’effacement de données personnelles d’une personne qui est cliente de l’entreprise pour laquelle j’ai été nommée DPO. Conformément à l’article 17 du RGPD, ce droit à l’effacement est susceptible de pouvoir se voir opposer des exceptions. Liste ces exceptions sous forme de liste à puce en expliquant pourquoi chacune de ces exceptions est opposable.',
            },
          ],
        },
        {
          subRecommendationTitle: '7. Terminologie juridique',
          subRecommendationDescription:
            'Utilisez la terminologie juridique appropriée pour obtenir des réponses précises. Par exemple, \n' +
            '"Définissez \'consentement explicite\' dans le contexte du RGPD".\n' +
            "ou \"Définissez la différence entre 'données personnelles' et 'données sensibles' selon la Directive 95/46/EC\".",
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Existe t’il une décision d’adéquation UE-USA pour les traitements de données ?',
            },
            {
              position: 'right',
              text: 'Conformément au droit en vigueur, indique quel traité international a fait l’objet d’une décision d’adéquation de la part de commission européenne, conformément au chapitre 5 du RGPD pour justifier l’existence d’une garantie relative à des traitements impliquant des transferts de données personnelles en dehors de l’Union européenne.',
            },
          ],
        },
        {
          subRecommendationTitle: '8. Spécifier la forme de réponse souhaitée',
          subRecommendationDescription:
            "Vous pouvez demander à Olympe de produire des réponses d'une longueur déterminée. Cela peut être un nombre de mots, de phrases, de paragraphes, de points de puces, etc.",
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Écris un tweet que je peux publier pour annoncer une actualité sur nos réseaux sociaux',
            },
            {
              position: 'right',
              text: 'Tu disposes de 146 caractères pour rédiger un texte à destination de personnes suivant mon activité sur Twitter. Mon activité est celle de conseil en protection des données personnelle. Ce texte est relatif à l’actualité suivante : [thème actualité]. Écris ce texte afin de donner envie à mon audience de me contacter pour en savoir plus.',
            },
          ],
        },
      ],
    },
    {
      recommendationTitle: 'B. Appuyer la réponse sur des textes existants',
      subRecommendations: [
        {
          subRecommendationTitle: '1. Demander à s’appuyer sur un texte',
          subRecommendationDescription:
            'Vous pouvez demander à Olympe de s’appuyer sur un texte de référence pour renforcer la précision et la fiabilité de la réponse attendue.\n' +
            'Pour cela, vous pouvez insérer le texte de référence dans votre question, et le délimiter par des triples guillemets. Les triples guillemets peuvent être utilisés pour mettre en exergue une information particulière dans votre requête, et l’utiliser efficacement pour fournir une réponse.',
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Dans l’article suivant, liste les éléments importants : [texte].',
            },
            {
              position: 'right',
              text:
                'J’ai trouvé cet extrait de texte sur [source]. Liste en 5 points les informations essentielles pour expliquer le sujet de cet extrait.\n' +
                'Voici l’extrait : “””[extrait]”””. ',
            },
          ],
        },
        {
          subRecommendationTitle: '2. Demander à Olympe de répondre en citant des passages d’un texte de référence',
          subRecommendationDescription:
            'Vous pouvez demander à Olympe de rajouter des citations à ses réponses, faisant référence à des passages provenant des documents fournis (voir point précédent).',
          subRecommendationExempleSections: [
            {
              position: 'left',
              text: 'Voici un texte : [texte] Réponds à cette question : [question].',
            },
            {
              position: 'right',
              text:
                'Je vais te poser une question et te fournir un document délimité par des triples guillemets. Ta tâche consiste à répondre à la question en utilisant uniquement le document fourni et à citer le(s) passage(s) du document utilisé(s) pour répondre à la question. Si le document ne contient pas les informations nécessaires pour répondre à la question, écris simplement : "Informations insuffisantes".\n' +
                'Voici le texte : “””[texte]”””.',
            },
          ],
        },
      ],
    },
    {
      recommendationTitle: 'C. Quelques idées d’utilisations pratiques',
      subRecommendations: [
        {
          subRecommendationTitle: '1. Sollicitez une interprétation de recommandations appliquée à votre cas',
          subRecommendationDescription:
            'Bien que la CNIL publie des normes de références et autres bonnes pratiques, il n’est parfois par aisé de s’approprier ces recommandations de manière objective car nous sommes influencé par notre culture d’entreprise ou nos collègues. Il s’agit là d’un écueil récurrent. Pour pallier cela, vous pouvez demander à Olympe son avis sur des points spécifiques d’une recommandation en expliquant votre cas d’usage. ',
          subRecommendationExempleSections: [],
        },
        {
          subRecommendationTitle: '2. Effectuez une veille règlementaire',
          subRecommendationDescription:
            'Interrogez sur les mises à jour législatives. Se tenir informé des dernières évolutions législatives assure une conformité continue. \n' +
            '"Quelles sont les implications des récentes décisions de la CJUE relatives à l’établissement de la responsabilité d’une entreprise en cas d’omission ou de faute non intentionnelle ? Cette position jurisprudentielle est-elle différente de celle tenue jusqu’à lors ? L’interprétation retenue par les juges est elle contra legem si on s’en tient à la lettre du texte du RGPD ?".',
          subRecommendationExempleSections: [],
        },
        {
          subRecommendationTitle: '3. Demandez des validations de conformité',
          subRecommendationDescription:
            'Obtenez un avis objectif sur la conformité des pratiques actuelles prévient les risques légaux. Utilisez des prompts pour valider la conformité de vos pratiques actuelles.\n' +
            '“Nous disposons d’une procédure d’alerte en cas de violation de données personnelles. Voici la procédure point par point : [procédure]. Est-ce que cette procédure permet de respecter le RGPD et les recommandations de la CNIL à ce sujet ?”',
          subRecommendationExempleSections: [],
        },
      ],
    },
    {
      recommendationTitle: 'D. Aspects Juridiques et Éthiques',
      subRecommendations: [
        {
          subRecommendationTitle: null,
          subRecommendationDescription: '-  Veillez à ce que vos prompts respectent la législation en vigueur.',
          subRecommendationExempleSections: [],
        },
        {
          subRecommendationTitle: null,
          subRecommendationDescription:
            '-  Ne demandez pas à Olympe de traiter des données personnelles sans consentement approprié.',
          subRecommendationExempleSections: [],
        },
        {
          subRecommendationTitle: null,
          subRecommendationDescription:
            "-   Respectez les principes éthiques de la profession, notamment en matière de confidentialité et d'intégrité.",
          subRecommendationExempleSections: [],
        },
      ],
    },
  ]
  return {
    recommendations,
  }
}
