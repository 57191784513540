import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const sendPasswordResetEmail = createAppAsyncThunk(
  'auth/sendPasswordResetEmail',
  async (email: string, { extra: { accountGateway } }) => {
    try {
      return accountGateway.sendPasswordResetEmail(email)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
