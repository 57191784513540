import { TheIconChevronRight } from './icons/TheIconChevronRight.tsx'
import { useAppDispatch, useAppSelector } from '../store/hooks.ts'
import {
  selectTagSelectPositionByFileId,
  selectTagWithoutDefaultAndCurrent,
  setTagSelectHeight,
} from '../store/tags/tagSlice.ts'
import { Tag } from '../types/tag.ts'
import { FileData } from '../types/file.ts'
import { useEffect, useRef, useState } from 'react'

type Props = {
  onClick: (tag: Tag) => void
  file?: FileData
}

export const theTagSelectWidth = 'w-52'
export function TheTagsSelect({ onClick, file }: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const targetRef = useRef<HTMLDivElement>(null)
  const tags = useAppSelector(selectTagWithoutDefaultAndCurrent)
  const tagsWithoutTagFromFiles = tags.filter((tag) => !file?.tags.includes(tag.id))
  const currentTagSelectPosition = useAppSelector(selectTagSelectPositionByFileId(file?.id ?? 'bulk'))
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const targetRect = targetRef.current?.getBoundingClientRect()
    const height = targetRect?.height
    dispatch(setTagSelectHeight(height))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY])

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  const getPosition = () => {
    if (!file) {
      return {
        left: (currentTagSelectPosition?.left ?? 0) + 200,
        top: (currentTagSelectPosition?.top ?? 0) + scrollY - 35,
      }
    }
    return { ...currentTagSelectPosition, top: (currentTagSelectPosition?.top ?? 0) + scrollY }
  }

  return (
    <div
      ref={targetRef}
      className={`absolute z-10 ${theTagSelectWidth} rounded-lg border border-solid border-brightGray bg-bright px-3.5 pb-3.5 pt-4`}
      style={getPosition()}
    >
      <h4 className={'mb-2 text-left text-sm font-bold text-label'}>Déplacer vers</h4>
      <ul className={'no-scrollbar max-h-[200px] overflow-auto'}>
        {tagsWithoutTagFromFiles.map((tag) => (
          <li
            onClick={() => onClick(tag)}
            className={
              'group relative flex items-center justify-between gap-2 px-3.5 py-2 text-sm text-primary hover:cursor-pointer hover:rounded-lg hover:bg-brightGray'
            }
            key={tag.id}
          >
            <p className={'w-4/5 overflow-hidden text-ellipsis whitespace-nowrap text-left lowercase'}>{tag.name}</p>
            <TheIconChevronRight className={'hidden group-hover:inline'} />
          </li>
        ))}
      </ul>
    </div>
  )
}
