import { ReactNode } from 'react'

type Props = {
  contentVisible: boolean
  onClick: () => void
  children: ReactNode
}

export function BaseOverlay(props: Readonly<Props>) {
  return (
    <div
      onClick={props.onClick}
      className={`fixed inset-0 z-20 flex h-full w-full ${props.contentVisible ? '' : 'invisible'}`}
    >
      {props.children}
    </div>
  )
}
