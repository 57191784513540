import { format, parse, parseISO } from 'date-fns'

export const DATE_FORMAT = 'dd/MM/yyyy'

export function transformISOToDDMMYYYY(isoDateString: string): string {
  try {
    const date = parseISO(isoDateString)
    return format(date, DATE_FORMAT)
  } catch (error) {
    return isoDateString
  }
}

export function dateStringToDate(dateString: string): Date {
  return parse(dateString, DATE_FORMAT, new Date())
}
