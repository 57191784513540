import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import TextareaAutosize from 'react-textarea-autosize'
import { SubmitButtonOrLoading } from '@/components/SubmitButtonOrLoading.tsx'

export function TheInputMessageOnlyType({
  isQuestionLoading,
  userInput,
  onInput,
  className,
  handleKeyDown,
  handleSend,
  messages,
}: Readonly<TheConversationMessageInputProps>) {
  return (
    <div
      className={`lg:min-w-1/2 hidden justify-between rounded-lg bg-bright py-4 pl-6 pr-5 shadow-custom sm:flex sm:w-full sm:gap-2.5 lg:min-w-[40rem] ${className ?? ''}`}
    >
      <TextareaAutosize
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        className={
          'my-4 max-h-[200px] w-full resize-none rounded-lg bg-bright text-base leading-5 outline-none sm:my-0 sm:py-2.5'
        }
      />
      <div className={'self-end'}>
        <SubmitButtonOrLoading
          handleSend={handleSend}
          isQuestionLoading={isQuestionLoading}
          messagesLength={messages?.length ?? 0}
          userInputLength={userInput.length}
        />
      </div>
    </div>
  )
}
