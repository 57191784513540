import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { dateStringToDate } from './date'

export type RegionFilter = {
  iso: string
  name: string
}
export type DateRangeFilter = { from: Date | undefined; to: Date | undefined }
export type TagFilter = { value: string }
export type FilterState = {
  regions: RegionFilter[]
  tags: TagFilter[]
  dateRange: DateRangeFilter
}
export type Countries = {
  [key: string]: string
}

export const filterByRegion = (articles: LegalWatchArticle[], regions: RegionFilter[]) => {
  if (regions.length === 0) return articles
  const regionSet = new Set(regions.map((region) => region.iso))
  return articles.filter((article) => regionSet.has(article.region))
}

export const filterByTags = (articles: LegalWatchArticle[], tags: TagFilter[]) => {
  if (tags.length === 0) return articles
  const tagSet = new Set(tags.map((tag) => tag.value))
  return articles.filter((article) => article.tags.some((tag) => tagSet.has(tag)))
}

export const filterByDateRange = (articles: LegalWatchArticle[], dateRange: DateRangeFilter) => {
  const { from, to } = dateRange
  if (!from && !to) return articles
  const fromDate = from?.setHours(0, 0, 0, 0)
  const toDate = to?.setHours(0, 0, 0, 0)
  return articles.filter((article) => {
    const articleDate = dateStringToDate(article.createdAt).setHours(0, 0, 0, 0)
    if (fromDate === undefined || toDate === undefined) {
      return articles
    }
    return articleDate >= fromDate && articleDate <= toDate
  })
}

// utility function to collect unique regions from articles
export const extractUniqueRegions = (articles: LegalWatchArticle[]) => {
  return new Set(articles.map((article) => article.region).filter(Boolean))
}

// utility function to sort regions based on a given order
export const sortRegions = (regions: Set<string>, order: RegionFilter['iso'][]) => {
  return Array.from(regions).sort((a, b) => {
    return order.indexOf(a) - order.indexOf(b)
  })
}

// utility function to map ISO codes to country details
export const mapToCountryDetails = (regions: RegionFilter['iso'][], countries: Countries) => {
  return regions.map((iso) => ({
    iso,
    name: countries[iso],
  }))
}
