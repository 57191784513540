import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconChevronRight(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3095_32105)">
        <path
          d="M0.882947 12C0.671317 11.9448 0.481649 11.8566 0.340563 11.6786C0.103977 11.3801 0.0989855 10.9468 0.337568 10.6477C0.38382 10.5898 0.434731 10.5356 0.486973 10.483C1.94542 9.0242 3.40387 7.56575 4.86298 6.1073C4.89592 6.07436 4.93319 6.04608 5.01006 5.97986C4.95149 5.94525 4.90358 5.92895 4.8713 5.89667C3.40021 4.42857 1.93111 2.95815 0.460353 1.48972C0.20114 1.23084 0.0923305 0.934687 0.203469 0.57465C0.368847 0.0399195 1.05132 -0.177367 1.49488 0.164368C1.54412 0.202302 1.59171 0.243896 1.63563 0.287819C3.32667 1.97819 5.01738 3.66857 6.70775 5.35961C7.02353 5.67572 7.08842 6.06837 6.87845 6.42475C6.82788 6.51093 6.75667 6.58746 6.68546 6.65867C5.04001 8.30612 3.39255 9.95157 1.74843 11.6004C1.56608 11.783 1.37808 11.9461 1.11754 12H0.882947Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3095_32105">
          <rect width="6.8397" height="12" fill="white" transform="translate(0.160156)" />
        </clipPath>
      </defs>
    </svg>
  )
}
