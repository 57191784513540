import { LegalWatchHeader } from '@/components/legalWatch/LegalWatchHeader.tsx'
import { useLegalWatchHeaderContainerHook } from '@/components/hooks/use-LegalWatchHeaderContainer.hook.tsx'
import { ReactNode } from 'react'
interface Props {
  children: ReactNode
}
export function LegalWatchHeaderContainer({ children }: Readonly<Props>) {
  const { navigateToFavorites } = useLegalWatchHeaderContainerHook()
  return <LegalWatchHeader navigateToFavorites={navigateToFavorites}>{children}</LegalWatchHeader>
}
