import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'
import { useHoverIconHook } from '../sidebar/use-hoverIcon.hook.tsx' // adding props is needed since TheIconBin will be used in various contexts,

// adding props is needed since TheIconBin will be used in various contexts,
// including the BaseButton context, which injects use Cases props programmatically
export function TheIconBin(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { setIsHovered, hoverStyle } = useHoverIconHook()
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        d="M10.6667 4.46305V3.92972C10.6667 3.18298 10.6667 2.80961 10.5213 2.5244C10.3935 2.27352 10.1895 2.06954 9.93865 1.94171C9.65344 1.79639 9.28007 1.79639 8.53333 1.79639H7.46667C6.71993 1.79639 6.34656 1.79639 6.06135 1.94171C5.81046 2.06954 5.60649 2.27352 5.47866 2.5244C5.33333 2.80961 5.33333 3.18298 5.33333 3.92972V4.46305M6.66667 8.12972V11.4631M9.33333 8.12972V11.4631M2 4.46305H14M12.6667 4.46305V11.9297C12.6667 13.0498 12.6667 13.6099 12.4487 14.0377C12.2569 14.414 11.951 14.72 11.5746 14.9117C11.1468 15.1297 10.5868 15.1297 9.46667 15.1297H6.53333C5.41323 15.1297 4.85318 15.1297 4.42535 14.9117C4.04903 14.72 3.74307 14.414 3.55132 14.0377C3.33333 13.6099 3.33333 13.0498 3.33333 11.9297V4.46305"
        style={hoverStyle}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
