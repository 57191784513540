import { DiscussionPanel } from '@/components/ds/DiscussionPanel.tsx'
import { useAppSelector } from '@/store/hooks'
import { selectConversationsForArticle } from '@/store/conversations/conversationsSlice.ts'

export function DiscussionPanelContainer() {
  const conversationsForArticle = useAppSelector(selectConversationsForArticle)

  if (conversationsForArticle.length === 0) return
  return <DiscussionPanel />
}
