import { useState } from 'react'

export function useHoverIconHook() {
  const [isHovered, setIsHovered] = useState(false)

  const hoverStyle = {
    stroke: isHovered ? '#c1c1c1' : '#fdfdfd',
  }
  return { setIsHovered, hoverStyle }
}
