import { DocumentsFolderGenerator } from '@/components/ds/DocumentsFolderGenerator.tsx'
import { TheIconCirclePlus } from '@/components/icons/TheIconCirclePlus.tsx'
import { DocumentsFolderList } from '@/components/DocumentsFolderList.tsx'
import { useDocumentsFolderSectionHook } from '@/components/hooks/use-DocumentsFolderSection.hook.tsx'
import { TheDocumentsFolderContainer } from '@/components/TheDocumentsFolderContainer.tsx'

export function DocumentsFolderSectionMobile() {
  const { fileCountByTag, handleKeyDown, onToggleAddTagModal } = useDocumentsFolderSectionHook()
  return (
    <>
      <DocumentsFolderGenerator label={'Dossiers'} onKeyDown={handleKeyDown}>
        <TheIconCirclePlus
          onKeyDown={handleKeyDown}
          onClick={onToggleAddTagModal}
          className={'w-6 fill-primary hover:cursor-pointer hover:fill-primary'}
        />
      </DocumentsFolderGenerator>
      <DocumentsFolderList>
        {fileCountByTag.map((tag) => (
          <TheDocumentsFolderContainer key={tag.id} tag={tag} />
        ))}
      </DocumentsFolderList>
    </>
  )
}
