import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'

interface Props {
  onDelete: () => void
  onOpenChange: () => void
  isOpen: boolean
  title: string
  subtitle: string
}
export function WarningModal({ onDelete, isOpen, onOpenChange, title, subtitle }: Readonly<Props>) {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className={'w-auto rounded-2xl p-0'}>
        <div
          className={
            'fixed left-1/2 top-1/2 z-10 flex w-60 -translate-x-1/2 -translate-y-1/2 transform flex-col gap-5 rounded-lg bg-bright px-5 pb-4 pt-5'
          }
        >
          <div className={'flex flex-col gap-3'}>
            <h4 className={'text-center text-base font-semibold text-primary'}>{title}</h4>
            <p className={'text-center text-base font-normal text-label'}>{subtitle}</p>
          </div>
          <div className={'flex flex-col items-center gap-3'}>
            <Button onClick={onDelete}>Supprimer</Button>
            <Button
              variant={'secondary'}
              className={'text-sm font-semibold text-primary hover:cursor-pointer hover:text-label'}
              type={'button'}
              onClick={onOpenChange}
            >
              Annuler
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
