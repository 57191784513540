import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export const useLegalWatchHeaderContainerHook = () => {
  const { isUserConnected } = useCurrentUserHook()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigateToFavorites = () => {
    if (isUserConnected) {
      navigate(ROUTE_PATHS.legalWatchFavorites)
    } else {
      dispatch(openAuthModal())
    }
  }
  return { navigateToFavorites }
}
