export type DocumentsPageHeaderProps = {
  title: string
  subtitle: string
}
export function DocumentsPageHeader({ title, subtitle }: Readonly<DocumentsPageHeaderProps>) {
  return (
    <div className="w-full lg:w-1/2">
      <h1 className="mb-3 text-[1.75rem] font-semibold leading-9 text-primary">{title}</h1>
      <p className="text-sm font-normal text-label lg:text-base">{subtitle}</p>
    </div>
  )
}
