import { Crisp } from 'crisp-sdk-web'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { BaseToaster } from './components/atomics/BaseToaster.tsx'
import { ToasterIcon } from './components/atomics/ToasterIcon.tsx'
import { AppRouter } from './main.tsx'
import { AppStore } from './store/configureStore.ts'
import { useAppSelector } from './store/hooks.ts'
import { selectToasterMessage } from './store/toasterSlice.ts'

export function App() {
  const crispWebsiteId = import.meta.env.VITE_CRISP_WEBSITE_ID
  const toaster = useAppSelector(selectToasterMessage)
  if (crispWebsiteId) Crisp.configure(crispWebsiteId, { cookieExpire: 30 * 24 * 3600 })

  function reload() {
    window.location.reload()
  }

  return (
    <BaseToaster
      onClick={reload}
      toasterPosition={toaster.toasterPosition}
      toasterType={toaster.toasterType}
      message={toaster.toasterMessage}
    >
      <ToasterIcon toasterType={toaster.toasterType} />
    </BaseToaster>
  )
}

type ProviderProps = { readonly store: AppStore; readonly router: AppRouter }

export function Provider({ store, router }: ProviderProps) {
  return (
    <ReduxProvider store={store}>
      <App />
      <RouterProvider router={router} />
    </ReduxProvider>
  )
}
