import { LegalWatchDrawerHeader } from '@/components/LegalWatchDrawerHeader.tsx'
import { LegalWatchDrawerList } from '@/components/LegalWatchDrawerList.tsx'
interface Props {
  isLegalWatchDrawerDisplayed: boolean
}
export function LegalWatchDrawer({ isLegalWatchDrawerDisplayed }: Readonly<Props>) {
  return (
    <div
      className={
        'no-scrollbar fixed right-10 top-20 z-30 hidden h-screen max-w-[22rem] overflow-auto pb-20 pt-2.5 lg:flex lg:flex-col lg:gap-5'
      }
    >
      <LegalWatchDrawerHeader />
      {isLegalWatchDrawerDisplayed && <LegalWatchDrawerList />}
    </div>
  )
}
