import { TheIconOlympeLogoWithText } from '../../../icons/TheIconOlympeLogoWithText.tsx'
import FooterLink from '../../FooterLink.tsx'

export function RegisterStepCredentialsLeftPart() {
  return (
    <div className={'hidden flex-shrink-0 flex-grow basis-0 flex-col items-center gap-[0.25rem] md:flex'}>
      <div
        className={
          'flex h-[44.5rem] w-[40.1875rem] flex-col items-start gap-[3.5rem] rounded-2xl bg-gradient-301 pb-0 pl-[1.9375rem] pr-7 pt-7'
        }
      >
        <TheIconOlympeLogoWithText width="88" className={'fill-primary'} height="34" />
        {/* frame 1342 */}
        <div className={'flex flex-col gap-[2.25rem]'}>
          {/* frame 1334 */}
          <div className={'flex flex-col gap-4'}>
            {/* frame 1299 */}
            <div className={'flex flex-col items-start gap-4 self-stretch'}>
              <p className={'text-2xl font-normal text-label'}>
                Une mémoire riche de{' '}
                <span className={'text-2xl font-semibold text-label'}>
                  1 million d’heures de références juridiques..
                </span>
              </p>
            </div>
            {/* frame 1332 */}
            <div className={'flex flex-col items-start gap-3 self-stretch'}>
              <p className={'text-sm font-normal text-primary'}>
                Des avis, des règlements, des arrêtés, des jurisprudences et des conclusions{' '}
                <span className={'text-sm font-semibold text-primary'}>CNIL, </span>
                <span className={'text-sm font-semibold text-primary'}>CEPD, </span>
                <span className={'text-sm font-semibold text-primary'}>CJUE.</span>
              </p>
            </div>
          </div>
          {/* frame 1341 */}
          <div className={'relative flex flex-wrap content-center items-center'}>
            <img
              src={'/assets/images/inscription-screenshot-1.jpg'}
              alt={'placeholder'}
              className={'h-[17.04288rem] w-[31.8125rem] self-stretch rounded-xl shadow-SubtleDepthShadow'}
            />
            <div className={'absolute right-0 top-1/2 -translate-y-1/2 transform'}>
              <img
                src={'/assets/images/inscription-screenshot-2.png'}
                alt={'placeholder'}
                className={'h-[15.60538rem] w-[9.11869rem] self-stretch rounded-xl shadow-SubtleDepthShadow'}
              />
            </div>
          </div>
        </div>
      </div>
      <FooterLink />
    </div>
  )
}
