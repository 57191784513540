export function PromptTutorialHeader() {
  return (
    <>
      <h1 className={'text-[1.75rem] font-semibold leading-9'}>Guide de rédaction des prompts</h1>
      <div>
        <p>
          Vous trouverez ici un guide pour mieux rédiger vos prompts et ainsi améliorer la pertinence des réponses
          fournies par Olympe. Un prompt, {"c'est"} une question ou une instruction que vous envoyez à Olympe pour
          solliciter une réponse. La qualité du prompt influence la précision de la réponse générée.
        </p>
        <p>
          Il n’y a pas de solution clé-en-main pour toutes vos questions. Le mieux est de suivre au maximum ces
          recommandations, d’expérimenter et de trouver ainsi les instruction qui marchent le mieux pour vous, selon vos
          besoins.
        </p>
      </div>
    </>
  )
}
