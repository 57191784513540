import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { ReactNode } from 'react'

interface Props {
  isOpen: boolean
  children: ReactNode
}

export function PaymentModal({ isOpen, children }: Readonly<Props>) {
  return (
    <Dialog open={isOpen}>
      <DialogContent
        className="max-h-[80vh] max-w-md gap-0 overflow-auto rounded-2xl px-5 py-6 md:max-h-full md:max-w-[26rem]"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}
