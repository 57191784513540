import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'

export function PublicLayout({ withLegalMention = true }: { withLegalMention?: boolean }) {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/') navigate(ROUTE_PATHS.login)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={'relative flex h-full min-h-screen flex-col items-center justify-center gap-4 sm:m-0 sm:gap-10'}>
      <Outlet />
      {withLegalMention && (
        <a
          className={
            'absolute bottom-0 pb-4 text-center text-sm font-semibold text-label hover:cursor-pointer hover:text-primary'
          }
          href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
          target={'_blank'}
          rel="noreferrer"
        >
          Mentions légales
        </a>
      )}
    </div>
  )
}
