import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconMenuBurger(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="43" height="32">
      <path d="M8 8H35" stroke="#212121" strokeWidth="3" strokeLinecap="round" />
      <path d="M8 16H35" stroke="#212121" strokeWidth="3" strokeLinecap="round" />
      <path d="M8 24H35" stroke="#212121" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}
