import { TheIconOlympeLogoWithText } from './icons/TheIconOlympeLogoWithText.tsx'
import BaseInput from './atomics/BaseInput.tsx'
import { BaseButton } from './atomics/BaseButton.tsx'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TheIconHideEye } from './icons/TheIconHideEye.tsx'
import { TheIconVisibleEye } from './icons/TheIconVisibleEye.tsx'
import { useAppDispatch } from '../store/hooks.ts'
import { resetPassword } from '../store/auth/useCases/resetPassword.ts'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import {
  ConfirmPasswordErrors,
  getErrorMessages,
  hasAnyErrors,
  passwordDetailedErrors,
  PasswordDetailedErrors,
  PasswordSimpleErrors,
  validatePasswordConfirmation,
  validatePasswordDetailed,
  validatePasswordSimple,
} from '../utils/formValidation.ts'
import { PasswordRequirements } from './PasswordRequirements.tsx'

interface FormElements extends HTMLFormControlsCollection {
  password: HTMLInputElement
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

interface Props {
  token: string
}

export function ForgottenPasswordForm(props: Readonly<Props>) {
  const navigate = useNavigate()
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const confirmPasswordErrorsInitialState: ConfirmPasswordErrors = { mismatch: '' }
  const [passwordErrors, setPasswordErrors] = useState<PasswordDetailedErrors>(passwordDetailedErrors)
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<ConfirmPasswordErrors>(
    confirmPasswordErrorsInitialState,
  )
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false)
  const passwordSimpleErrorsInitialState: PasswordSimpleErrors = {
    emptyPassword: '',
  }
  const [passwordSimpleErrors, setPasswordSimpleErrors] = useState(passwordSimpleErrorsInitialState)
  const dispatch = useAppDispatch()
  async function handleSubmit(e: React.FormEvent<LoginFormElement>) {
    setPasswordInputType('password')
    e.preventDefault()
    const form = e.currentTarget
    const newPassword = form.password.value
    const confirmPasswordInput = form.confirmPassword.value
    !newPassword.length && setShowPasswordRequirements(false)
    setPasswordErrors(validatePasswordDetailed(newPassword))
    setConfirmPasswordErrors(validatePasswordConfirmation(newPassword, confirmPasswordInput))
    setPasswordSimpleErrors(validatePasswordSimple(password))

    if (newPassword.length && confirmPasswordInput.length && !hasAnyErrors(passwordErrors, confirmPasswordErrors)) {
      setPassword('')
      setConfirmPassword('')
      setPasswordErrors(passwordDetailedErrors)
      setConfirmPasswordErrors(confirmPasswordErrorsInitialState)
      const response = await dispatch(resetPassword({ newPassword, token: props.token })).unwrap()
      if (response.reset) {
        navigate(ROUTE_PATHS.resetPasswordConfirmation)
      } else if (!response.reset && response.user) {
        navigate(ROUTE_PATHS.resetPasswordTokenExpiredError + `?email=${response.user.email}`)
      } else {
        navigate(ROUTE_PATHS.resetPasswordTokenInvalidError)
      }
    }
  }

  const togglePasswordInputType = () => {
    return passwordInputType === 'password' ? setPasswordInputType('text') : setPasswordInputType('password')
  }
  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newPassword = event.target.value
    setPassword(newPassword)

    setPasswordErrors(validatePasswordDetailed(newPassword))
  }

  function handleConfirmPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newConfirmPassword = event.target.value
    setConfirmPassword(newConfirmPassword)
    setConfirmPasswordErrors(validatePasswordConfirmation(password, newConfirmPassword))
  }

  function handlePasswordFocus() {
    setShowPasswordRequirements(true)
  }

  return (
    <div className={'flex h-screen w-full flex-col items-center justify-center gap-10'}>
      <div className="flex flex-col items-center gap-3">
        <TheIconOlympeLogoWithText className={'fill-primary'} />
        <h1 className="w-11/12 text-center text-xl font-semibold text-primary sm:text-3xl">
          Choisissez un nouveau mot de passe 🔒
        </h1>
      </div>

      <form className="flex w-11/12 flex-col gap-6 sm:w-96" method={'post'} onSubmit={handleSubmit}>
        <BaseInput
          autoComplete={'new-password'}
          label={'Nouveau mot de passe'}
          placeholder="Entrez votre mot de passe"
          name="password"
          type={passwordInputType}
          value={password}
          onChange={handlePasswordChange}
          onFocus={handlePasswordFocus}
          errormessage={showPasswordRequirements ? '' : getErrorMessages(passwordSimpleErrors)[0]}
        >
          {passwordInputType === 'password' ? (
            <TheIconHideEye className={'w-6 fill-primary hover:cursor-pointer'} onClick={togglePasswordInputType} />
          ) : (
            <TheIconVisibleEye className={'w-6 fill-primary hover:cursor-pointer'} onClick={togglePasswordInputType} />
          )}
        </BaseInput>
        {showPasswordRequirements && <PasswordRequirements passwordErrors={passwordErrors} />}
        <BaseInput
          autoComplete={'new-password'}
          label={'Confirmez le mot de passe'}
          placeholder="Saisissez à nouveau le mot de passe"
          name="confirmPassword"
          value={confirmPassword}
          type={passwordInputType}
          errormessage={confirmPasswordErrors.mismatch}
          onChange={handleConfirmPasswordChange}
        >
          {passwordInputType === 'password' ? (
            <TheIconHideEye className={'w-6 fill-primary hover:cursor-pointer'} onClick={togglePasswordInputType} />
          ) : (
            <TheIconVisibleEye className={'w-6 fill-primary hover:cursor-pointer'} onClick={togglePasswordInputType} />
          )}
        </BaseInput>
        <BaseButton className="mt-10 self-center sm:w-2/3" label="Réinitialiser le mot de passe" type="submit" />
      </form>
    </div>
  )
}
