import { useRef } from 'react'
import { ALLOWED_FILE_TYPES_AS_STRING } from '../utils/FileTypes.ts'
import { TheAddDocumentButton } from './TheAddDocumentButton.tsx'
import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import { TheIconFilterMobile } from './icons/TheIconFilterMobile.tsx'
import TextareaAutosize from 'react-textarea-autosize'

export function TheInputSingleFileTypeMobile({
  isQuestionLoading,
  userInput,
  className,
  handleFileInputChange,
  handleInputFileOpening,
  currentConversation,
  handleSend,
  messages,
  onInput,
  handleKeyDown,
  handleDisplayFilterMobile,
}: Readonly<TheConversationMessageInputProps>) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (handleInputFileOpening && inputRef) {
      handleInputFileOpening(inputRef)
    }
  }

  const renderSubmitOrLoadingIcon = () => {
    return isQuestionLoading ? (
      <TheDotAnimation size="small" />
    ) : (
      <div
        className={`flex items-center gap-4 ${
          currentConversation?.currentFilesInfos.length ? 'self-end' : 'currentConversation?.currentFilesInfos.length'
        } ${messages?.length && 'justify-center'}`}
      >
        {!messages?.length && <TheIconFilterMobile onClick={handleDisplayFilterMobile} className={'h-7 w-7'} />}{' '}
        <TheIconArrowSubmission
          onClick={handleSend}
          disabled={userInput.length === 0 || currentConversation?.currentFilesInfos.length === 0}
        />
      </div>
    )
  }

  return (
    <div className={`flex h-full w-full items-end justify-evenly gap-4 sm:hidden sm:px-3.5 ${className}`}>
      <div className="flex h-full w-10 items-end justify-center self-start">
        <TheAddDocumentButton
          disabled={isQuestionLoading || (currentConversation && currentConversation?.currentFilesInfos.length === 1)}
          onClick={handleClick}
        />
      </div>
      <div className={'flex w-9/12 flex-col gap-1 px-1'}>
        <TextareaAutosize
          placeholder="Vous pouvez poser votre question"
          value={userInput}
          disabled={isQuestionLoading}
          onInput={onInput}
          onKeyDown={handleKeyDown}
          className="max-h-[200px] w-full resize-none rounded border border-solid border-fadedSilver bg-bright p-3 text-base leading-[18px] shadow-subtleElevation outline-none sm:my-0 sm:py-2.5"
        />
        {!!currentConversation?.currentFilesInfos.length && (
          <TheAddedDocumentTag fileInfos={currentConversation?.currentFilesInfos[0]} />
        )}
      </div>
      {renderSubmitOrLoadingIcon()}
      <input
        id="fileInputRefMobile"
        ref={inputRef}
        accept={ALLOWED_FILE_TYPES_AS_STRING}
        className="hidden"
        type="file"
        onChange={handleFileInputChange}
      />
    </div>
  )
}
