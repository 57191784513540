import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useState } from 'react'
import {
  selectRegionsFilter,
  addRegionToFilter,
  removeRegionToFilter,
  selectLegalWatchRegions,
} from '@/store/articles/articlesSlice.ts'
import { RegionFilter } from '@/utils/filterArticle.ts'

export const useRegionPickerFilterHook = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const dispatch = useAppDispatch()
  const selectedRegions = useAppSelector(selectRegionsFilter)
  const regions = useAppSelector(selectLegalWatchRegions)

  const getIsoFromCountryLabel = (name: string, countries: RegionFilter[]) => {
    const country = countries.find((country) => country.name.toLowerCase() === name.toLowerCase())
    return country ? country.iso : null
  }

  const onSelectedRegions = (name: string) => {
    if (!selectedRegions) return
    const iso = getIsoFromCountryLabel(name, regions)
    const index = selectedRegions.findIndex((selectedRegion) => selectedRegion.iso === iso)
    if (index < 0) {
      const region = regions.filter((region) => region.iso === iso)[0]
      dispatch(addRegionToFilter(region))
    }
  }

  const onUnSelectedRegions = (name: string) => {
    if (!selectedRegions) return
    const iso = getIsoFromCountryLabel(name, regions)
    const index = selectedRegions.findIndex((selectedRegion) => selectedRegion.iso === iso)
    if (index >= 0) {
      const region = regions.filter((region) => region.iso === iso)[0]
      dispatch(removeRegionToFilter(region))
    }
  }

  const onOpenChange = () => {
    setIsOpen(!isOpen)
  }

  const onResetInput = () => {
    setInputValue('')
  }

  const onChangeInput = (value: string) => {
    setInputValue(value)
  }

  return {
    onSelectedRegions,
    onUnSelectedRegions,
    regions,
    selectedRegions,
    isOpen,
    onOpenChange,
    inputValue,
    onChangeInput,
    onResetInput,
  }
}
