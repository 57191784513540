import { Message } from '@/types/message'

interface Props {
  isMobile: boolean
  messages: Message[]
}
export function ConversationPageHeader({ isMobile, messages }: Readonly<Props>) {
  return (
    <div className={'flex flex-col items-center gap-4'}>
      {!messages.length && (
        <h1 className="mb-4 text-center text-4xl font-semibold text-primary sm:mb-14 sm:text-2xl sm:font-normal sm:leading-5">
          {`${isMobile ? 'Olympe' : 'Bienvenue sur Olympe'}`}
        </h1>
      )}
    </div>
  )
}
