import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LogUserBody } from '../../../types/user.ts'

export const logUser = createAppAsyncThunk(
  'auth/logUser',
  async ({ email, password }: LogUserBody, { extra: { authGateway } }) => {
    const payload = {
      email,
      password,
    }

    try {
      await authGateway.logUser(payload)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
