import { Conversation } from '@/types/conversation.ts'
import { Message } from '@/types/message.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const updateMessageFeedback = createAppAsyncThunk(
  'messages/updateMessageFeedback',
  async (
    {
      conversationId,
      messageId,
      feedback,
    }: { conversationId: Conversation['id']; messageId: Message['id']; feedback: Message['feedback'] },
    { extra: { conversationGateway } },
  ) => {
    try {
      return await conversationGateway.updateMessageFeedback(conversationId, messageId, feedback)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
