import { FileGateway } from './interface/file.gateway.ts'
import {
  ListUserFilesResponse,
  ListUserFilesResponseData,
  UpdateFileForUserResponse,
  UpdateFileForUserResponseData,
  UploadedFile,
  UploadFileForUserResponse,
  UploadFileResponseData,
} from '../types/file.ts'
import { Tag } from '../types/tag.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'
import FormDataAddon from 'wretch/addons/formData'
import { WretchResponse } from 'wretch'

export class WretchFileGateway implements FileGateway {
  private readonly endpoint = '/files'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  readFile(fileId: UploadedFile['id']): Promise<Blob> {
    const url = `${this.endpoint}/${fileId}`
    const blob = this.olympeGptApi
      .url(url)
      .options({
        context: {
          responseType: 'blob',
        },
      })
      .get()
      .blob()
    return blob
  }

  async uploadFile(file: File, title: string, tags: Tag['id'][]): Promise<UploadFileResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = this.endpoint
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .addon(FormDataAddon)
      .formData({ file, title, tags })
      .post()
      .json<UploadFileForUserResponse>()
    return response.data
  }

  async updateFileById(id: UploadedFile['id'], title: UploadedFile['title']): Promise<UpdateFileForUserResponseData> {
    const fileTitleWithoutExtension = title.split('.')[0]
    const body = {
      data: {
        title: fileTitleWithoutExtension,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${id}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).put(body).json<UpdateFileForUserResponse>()
    return response.data
  }

  async listUserFiles(): Promise<ListUserFilesResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListUserFilesResponse>()
    return response.data
  }

  deleteFileById(fileId: string): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${fileId}`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .delete()
      .res()
  }

  async deleteManyFiles(ids: string[]): Promise<WretchResponse> {
    const payload = {
      data: {
        ids,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = this.endpoint

    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .json(payload)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .delete()
      .res()
  }
}
