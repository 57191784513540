import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { selectIsLoading } from '../conversationsSlice.ts'

export const listUserConversations = createAppAsyncThunk(
  'conversations/listUserConversations',
  async (_, { extra: { conversationGateway } }) => {
    try {
      return await conversationGateway.listUserConversations()
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
  {
    condition(_, { getState }) {
      const isLoading = selectIsLoading(getState())
      return !isLoading
    },
  },
)
