export type DocumentsPageHeaderMobileProps = {
  title: string
  subtitle: string
}
export function DocumentsPageHeaderMobile({ title, subtitle }: Readonly<DocumentsPageHeaderMobileProps>) {
  return (
    <div className="w-full rounded-b-2xl px-4 pb-5 pt-4 shadow-mysticGlowShadow lg:w-1/2">
      <h1 className="mb-3 text-2xl font-semibold text-primary">{title}</h1>
      <p className="text-sm font-normal text-label lg:text-base">{subtitle}</p>
    </div>
  )
}
