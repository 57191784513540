import { ROUTE_PATHS } from '@/ROUTE_PATHS'
import { createUser } from '@/store/auth/useCases/createUser'
import { getCurrentUser } from '@/store/auth/useCases/getCurrentUser'
import { logUser } from '@/store/auth/useCases/logUser'
import { useAppDispatch } from '@/store/hooks'
import { useToaster } from '@/utils/useToaster'
import { useNavigate } from 'react-router-dom'

const isEmailVerificationFeatureDisabled = import.meta.env.VITE_FEATURES_DISABLE_EMAIL_VERIFICATION === 'true'
export const useRegisterHook = () => {
  const dispatch = useAppDispatch()
  const { showToast } = useToaster()
  const navigate = useNavigate()

  const submit = async ({ email, password }: { email: string; password: string }) => {
    const createUserResponse = await dispatch(createUser({ email, password }))
    if (createUser.fulfilled.match(createUserResponse)) {
      await handleSuccessfulUserCreation(email, password)
    } else {
      handleFailedUserCreation(createUserResponse.error.message)
    }
  }

  async function handleSuccessfulUserCreation(email: string, password: string) {
    if (isEmailVerificationFeatureDisabled) {
      const logUserResponse = await dispatch(logUser({ email, password }))
      if (logUser.fulfilled.match(logUserResponse)) {
        const currentUserResponse = await dispatch(getCurrentUser())
        if (getCurrentUser.fulfilled.match(currentUserResponse)) {
          navigate({
            pathname: ROUTE_PATHS.greetingUser,
          })
        }
      }
    } else {
      navigate({ pathname: ROUTE_PATHS.accountVerificationInfo, search: `?email=${email}` })
    }
  }

  function handleFailedUserCreation(errorMessage: string | undefined) {
    showToast({ toasterMessage: errorMessage ?? '', toasterType: 'identified' })
  }

  return {
    submit,
  }
}
