import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconCross({
  className = 'w-6 fill-primary',
  ...props
}: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.75377 4.00602C5.33384 4.15048 5.64494 4.70825 6.06644 5.11354C7.00176 6.0144 7.91702 6.93532 8.81822 7.87029C9.04302 8.10503 9.11528 7.91042 9.22567 7.80006C10.3276 6.70057 11.4215 5.59506 12.5274 4.49959C12.7843 4.24478 13.0272 3.91774 13.4708 4.04213C13.7457 4.11838 13.9224 4.29895 13.9725 4.58586C14.0609 5.08344 13.6514 5.30414 13.3824 5.57701C12.2725 6.70258 11.1425 7.80608 10.0225 8.92163C10.0004 8.9437 10.0044 9.02596 10.0265 9.05003C11.2729 10.3 12.5294 11.5419 13.7698 12.7999C14.0508 13.0848 14.089 13.458 13.8039 13.759C13.4848 14.094 13.0994 14.0599 12.7823 13.7449C11.5299 12.505 10.2895 11.251 9.04503 10.0011C8.92059 9.87465 8.92059 10.0432 8.88446 10.0773C7.64405 11.3092 6.41367 12.5491 5.17126 13.777C4.87019 14.074 4.50489 14.076 4.21185 13.773C3.90476 13.454 3.9449 13.0828 4.24597 12.7759C5.44824 11.56 6.65453 10.3482 7.87487 9.15035C8.04347 8.98382 7.98325 8.93768 7.86082 8.81529C6.68263 7.64758 5.51046 6.47385 4.33629 5.30213C4.09343 5.05936 3.91278 4.80054 4.06533 4.43538C4.18174 4.1585 4.40052 4.03411 4.75377 4V4.00602Z" />
    </svg>
  )
}
