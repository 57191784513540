import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { LegalWatchDetailsContentLayout } from '@/components/legalWatch/LegalWatchDetailsContentLayout.tsx'
import { LegalWatchArticleDetailsContainer } from '@/components/LegalWatchArticleDetailsContainer.tsx'
import { LegalWatchDetailsHeaderContainer } from '@/components/LegalWatchDetailsHeaderContainer.tsx'
import { DiscussionPanelContainer } from '@/components/DiscussionPanelContainer.tsx'
import { useAppSelector } from '@/store/hooks.ts'
import { selectConversationsForArticle } from '@/store/conversations/conversationsSlice.ts'
import { useEffect, useState } from 'react'

export function LegalWatchDetails() {
  const conversationsForArticle = useAppSelector(selectConversationsForArticle)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 300) // Delay can be adjusted or removed as needed
    return () => clearTimeout(timer)
  }, [])
  return (
    <FullHeightLayout className={'relative sm:py-8'}>
      <LegalWatchDetailsContentLayout>
        <div
          className={`tr flex min-h-full w-[43rem] flex-col flex-col gap-4 transition-all duration-300 ease-in-out sm:min-w-[30rem] ${conversationsForArticle.length === 0 && 'lg:pl-24'}`}
        >
          <LegalWatchDetailsHeaderContainer />
          <LegalWatchArticleDetailsContainer />
        </div>
        <div
          className={`right-10 h-[calc(100vh-64px)] transition-opacity duration-300 ease-in-out min-[1510px]:fixed ${isVisible ? 'opacity-100' : 'opacity-0'}`}
        >
          <DiscussionPanelContainer />
        </div>
      </LegalWatchDetailsContentLayout>
    </FullHeightLayout>
  )
}
