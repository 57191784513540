import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconOlympeLogoWithText(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="88" height="34" viewBox="0 0 88 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3021 26.6552C14.574 23.8775 16.9974 21.7833 19.9451 20.9684C21.8529 20.4076 24.7318 20.6355 26.5451 20.4164C30.1201 19.8907 32.6638 15.7023 32.3373 12.5479C32.3287 12.4515 32.1998 12.4252 32.1568 12.504L31.7701 13.17V13.1787C29.888 16.4821 23.3826 15.2028 19.5154 16.0265C15.9146 16.9115 13.4396 19.952 12.9412 23.1765C12.8294 23.7548 12.7607 24.3156 12.7349 24.8326C12.6318 26.4624 12.8037 27.4876 12.8037 27.4876C12.838 27.6278 12.8982 27.6103 12.9498 27.4701C13.0271 27.2598 13.113 27.0495 13.2076 26.8479L13.3021 26.6552Z" />
      <path d="M28.8498 21.3449C28.8498 21.2397 28.7295 21.1872 28.6522 21.2573C27.1569 22.4927 24.5444 22.4226 24.5444 22.4226C17.678 21.9407 15.392 26.4183 14.9881 27.3733C14.9537 27.4522 15.0139 27.5486 15.0998 27.5486C15.0998 27.5486 19.1303 25.5946 23.049 25.4632C26.9678 25.323 28.8498 21.3536 28.8498 21.3449Z" />
      <path d="M25.1032 14.082C27.4063 13.5387 31.7032 13.6877 30.861 10.2967C30.8438 10.209 30.7321 10.2003 30.6891 10.2704C29.2712 12.7589 26.7962 10.6209 25.0344 13.9768C25.0087 14.0382 25.0516 14.0907 25.1032 14.082Z" />
      <path d="M24.1234 14.0296C23.1179 8.63206 18.4773 4.70654 13.089 4.70654C6.89294 4.70654 1.8656 9.84125 1.8656 16.1501C1.8656 21.8982 6.07654 26.7788 11.6539 27.5061L12.032 27.5586L11.9804 27.1731C11.9203 26.7613 11.7914 25.666 11.8859 24.264L11.9031 24.0099L11.6539 23.9573C7.97576 23.2476 5.31169 19.9705 5.31169 16.1501C5.31169 11.769 8.80935 8.2027 13.1062 8.2027C16.6812 8.2027 19.7922 10.6737 20.6601 14.2136L20.7289 14.4765L20.9867 14.4327C21.0898 14.4152 21.6656 14.3538 22.4047 14.31L24.175 14.2224L24.1234 14.0296Z" />
      <path d="M50.8476 27.5931C50.7273 27.5931 50.6328 27.5493 50.5468 27.4617C50.4609 27.3741 50.4179 27.2777 50.4179 27.155C50.4179 27.0323 50.4609 26.9272 50.5468 26.8396C50.6328 26.7519 50.7359 26.7081 50.8476 26.7081C50.9593 26.7081 51.071 26.7519 51.157 26.8396C51.2429 26.9272 51.2859 27.0323 51.2859 27.155C51.2859 27.2777 51.2429 27.3741 51.157 27.4617C51.071 27.5493 50.9593 27.5931 50.8476 27.5931Z" />
      <path d="M52.2411 27.5058V23.1334H52.9544V27.5058H52.2411ZM52.7396 27.5058V26.8486H54.8966V27.5058H52.7396Z" />
      <path d="M55.6687 27.5058V23.1334H56.382V27.5058H55.6687ZM56.2273 23.7906V23.1334H58.307V23.7906H56.2273ZM56.2273 25.6044V24.956H58.075V25.6044H56.2273ZM56.2273 27.5058V26.8486H58.3242V27.5058H56.2273Z" />
      <path d="M61.4179 27.5756C60.9882 27.5756 60.6015 27.4792 60.2664 27.2864C59.9312 27.0936 59.6648 26.8308 59.4757 26.489C59.2867 26.1473 59.1921 25.7618 59.1921 25.3237C59.1921 24.8855 59.2867 24.4825 59.4757 24.1495C59.6648 23.8165 59.9312 23.5449 60.2664 23.3521C60.6015 23.1594 60.9882 23.063 61.4179 23.063C61.6671 23.063 61.8992 23.1068 62.1226 23.1857C62.346 23.2645 62.5351 23.3697 62.7156 23.5099C62.8875 23.6413 63.0335 23.799 63.1453 23.983L62.5695 24.3598C62.4921 24.2371 62.389 24.132 62.2687 24.0356C62.1484 23.9392 62.0109 23.8691 61.8648 23.8165C61.7187 23.764 61.5726 23.7377 61.4179 23.7377C61.1257 23.7377 60.8593 23.8078 60.6359 23.948C60.4125 24.0882 60.232 24.2722 60.1031 24.5175C59.9742 24.7541 59.9054 25.0345 59.9054 25.3499C59.9054 25.6654 59.9742 25.9283 60.0945 26.1736C60.2234 26.419 60.4039 26.603 60.6359 26.7432C60.8679 26.8834 61.1343 26.9535 61.4351 26.9535C61.6757 26.9535 61.882 26.9009 62.071 26.8045C62.2601 26.7081 62.4062 26.5679 62.5179 26.3839C62.6296 26.2087 62.6812 25.9984 62.6812 25.7705L63.3601 25.6829C63.3601 26.0772 63.2742 26.4189 63.1109 26.7081C62.9476 26.9973 62.7156 27.2163 62.4234 27.374C62.1312 27.4967 61.796 27.5756 61.4179 27.5756ZM61.65 25.7968V25.2098H63.3601V25.7004L62.982 25.7968H61.65Z" />
      <path d="M63.7719 27.5058L65.4821 23.1334H66.1953L64.5196 27.5058H63.7719ZM64.5711 26.428V25.7884H67.1321V26.428H64.5711ZM67.1922 27.5058L65.5164 23.1334H66.2297L67.9485 27.5058H67.1922Z" />
      <path d="M68.6804 27.5058V23.1334H69.3936V27.5058H68.6804ZM69.1788 27.5058V26.8486H71.3358V27.5058H69.1788Z" />
      <path d="M41.7459 17.9539C40.8092 17.9539 39.9842 17.7436 39.2709 17.323C38.549 16.9024 37.9904 16.3153 37.5779 15.5617C37.1654 14.8082 36.9678 13.9495 36.9678 12.9769C36.9678 12.0043 37.174 11.1455 37.5779 10.4007C37.9904 9.65595 38.549 9.06888 39.2537 8.63953C39.9584 8.21017 40.7834 7.99988 41.7029 7.99988C42.6225 7.99988 43.4646 8.21017 44.1779 8.63953C44.8912 9.06888 45.4498 9.65595 45.8451 10.4007C46.249 11.1455 46.4467 12.0043 46.4467 12.9769C46.4467 13.9495 46.249 14.8082 45.8451 15.5617C45.4412 16.3153 44.8912 16.9024 44.1865 17.323C43.4818 17.7436 42.6654 17.9539 41.7459 17.9539ZM41.7459 16.4993C42.3561 16.4993 42.8975 16.3504 43.3615 16.0437C43.8256 15.7458 44.1951 15.3252 44.4615 14.7994C44.7279 14.2737 44.8568 13.6691 44.8568 12.9769C44.8568 12.2846 44.7193 11.6976 44.4529 11.1631C44.1865 10.6286 43.817 10.2167 43.3443 9.91882C42.8717 9.6209 42.3303 9.47194 41.7115 9.47194C41.0928 9.47194 40.56 9.6209 40.0873 9.91882C39.6146 10.2167 39.2451 10.6286 38.9701 11.1543C38.6951 11.68 38.5576 12.2846 38.5576 12.9769C38.5576 13.6691 38.6951 14.2737 38.9701 14.7994C39.2451 15.3252 39.6232 15.737 40.1045 16.0437C40.5771 16.3504 41.1271 16.4993 41.7459 16.4993Z" />
      <path d="M47.8211 17.7884V8.17615H49.325V17.7884H47.8211Z" />
      <path d="M53.2602 17.7881L50.2438 10.9185H51.8852L54.2313 16.7016L54.7126 17.7881H53.2602ZM52.0571 20.706C51.8423 20.706 51.6016 20.6621 51.3352 20.5833C51.0688 20.5044 50.8196 20.3993 50.5704 20.2678L51.1376 19.0148C51.3095 19.0937 51.4641 19.1638 51.6102 19.2164C51.7563 19.2689 51.8681 19.2952 51.9626 19.2952C52.1774 19.2952 52.3579 19.2426 52.504 19.12C52.6501 19.0061 52.7704 18.8396 52.8649 18.6205L53.6556 16.7279L55.9329 10.936H57.5743L54.4462 18.5329C54.2571 18.971 54.0681 19.3566 53.8704 19.6895C53.6813 20.0225 53.4407 20.2766 53.1485 20.4518C52.8735 20.6183 52.5126 20.706 52.0571 20.706Z" />
      <path d="M58.4856 17.7866V10.9169H59.895L59.9895 12.1787V17.7866H58.4856ZM63.0489 14.2729C63.0489 13.7208 62.9887 13.3002 62.8684 13.0023C62.7481 12.7044 62.5762 12.4941 62.3528 12.3802C62.1293 12.2663 61.8715 12.205 61.5793 12.205C61.0809 12.1962 60.6856 12.3627 60.4106 12.7132C60.1356 13.0637 59.9895 13.5631 59.9895 14.2115H59.4223C59.4223 13.493 59.5254 12.8709 59.7317 12.3539C59.9379 11.8369 60.2301 11.4426 60.6168 11.171C61.0036 10.8994 61.459 10.7679 61.9918 10.7679C62.8083 10.7679 63.4356 11.0221 63.8825 11.5303C64.3293 12.0385 64.5528 12.8183 64.5528 13.8698L63.0489 14.2729ZM63.0489 17.7866V14.2729L64.5528 13.861V17.7866H63.0489ZM67.6293 14.2729C67.6293 13.7208 67.5692 13.3002 67.4403 13.0023C67.3114 12.7044 67.1395 12.4941 66.9247 12.3802C66.7012 12.2575 66.452 12.205 66.1598 12.205C65.6442 12.1962 65.2575 12.3627 64.9739 12.7132C64.6903 13.0637 64.5528 13.5631 64.5528 14.2115H63.9856C63.9856 13.493 64.0887 12.8709 64.295 12.3539C64.5012 11.8369 64.7934 11.4426 65.1801 11.171C65.5668 10.8994 66.0223 10.7679 66.5551 10.7679C67.3715 10.7679 68.0075 11.0221 68.4543 11.5303C68.9012 12.0385 69.1247 12.8183 69.1161 13.8698L67.6293 14.2729ZM67.6293 17.7866V14.2729L69.1247 13.861V17.7866H67.6293Z" />
      <path d="M70.7223 20.5385V10.9262H72.1403L72.2348 12.188V20.5385H70.7223ZM74.366 17.9536C73.7989 17.9536 73.3176 17.8047 72.9051 17.5067C72.4926 17.2088 72.1832 16.7882 71.9684 16.2537C71.7535 15.7105 71.6418 15.0796 71.6418 14.3611C71.6418 13.6426 71.7449 12.9942 71.9684 12.4597C72.1918 11.9252 72.4926 11.5046 72.9051 11.2066C73.3176 10.9087 73.7989 10.7598 74.366 10.7598C74.9762 10.7598 75.509 10.9087 75.9645 11.2066C76.4199 11.5046 76.7723 11.9164 77.0301 12.4597C77.2879 13.0029 77.4168 13.6338 77.4168 14.3611C77.4168 15.0883 77.2879 15.7192 77.0301 16.2537C76.7723 16.7882 76.4199 17.2088 75.9645 17.5067C75.5004 17.8047 74.9762 17.9536 74.366 17.9536ZM73.9879 16.5517C74.3489 16.5517 74.6668 16.4553 74.9418 16.2713C75.2168 16.0872 75.4403 15.8244 75.6121 15.4914C75.7754 15.1584 75.8614 14.7817 75.8614 14.3523C75.8614 13.923 75.784 13.5462 75.6293 13.2132C75.4746 12.8802 75.2512 12.6261 74.9762 12.4421C74.7012 12.2581 74.3746 12.1705 74.0051 12.1705C73.6614 12.1705 73.3606 12.2669 73.0856 12.4421C72.8192 12.6261 72.6043 12.8802 72.4582 13.2132C72.3121 13.5462 72.2348 13.923 72.2348 14.3523C72.2348 14.7817 72.3121 15.1584 72.4582 15.4914C72.6043 15.8244 72.8106 16.0785 73.077 16.2713C73.3434 16.464 73.6442 16.5517 73.9879 16.5517Z" />
      <path d="M81.7089 17.953C81.0557 17.953 80.4714 17.7953 79.9643 17.4974C79.4573 17.1907 79.062 16.7701 78.7612 16.2269C78.469 15.6836 78.3229 15.0615 78.3229 14.3605C78.3229 13.6595 78.469 13.0374 78.7698 12.4941C79.0706 11.9509 79.4745 11.5303 79.9901 11.2236C80.5057 10.9169 81.0987 10.7679 81.7604 10.7679C82.362 10.7679 82.9034 10.9257 83.3847 11.2411C83.8659 11.5566 84.2526 12.0122 84.5362 12.608C84.8198 13.2039 84.9659 13.9136 84.9659 14.7373H83.4448C83.4448 14.1414 83.3761 13.6507 83.2386 13.2652C83.1011 12.8797 82.9034 12.5993 82.6456 12.4065C82.3878 12.2225 82.0784 12.1261 81.7089 12.1261C81.3222 12.1261 80.987 12.2137 80.712 12.389C80.437 12.5642 80.2222 12.8183 80.0761 13.1513C79.93 13.4843 79.8612 13.9048 79.8612 14.4043C79.8612 14.8512 79.9472 15.2367 80.1276 15.5609C80.3081 15.8851 80.5401 16.1217 80.8409 16.297C81.1417 16.4634 81.4768 16.5511 81.8464 16.5511C82.2503 16.5511 82.5854 16.4547 82.8432 16.2707C83.1011 16.0867 83.3073 15.8413 83.4448 15.5522L84.8284 16.1568C84.6393 16.5248 84.3987 16.8402 84.1065 17.1206C83.8143 17.3922 83.4706 17.6025 83.0667 17.7515C82.6714 17.8829 82.2159 17.953 81.7089 17.953ZM79.3198 14.7285V13.5105H84.1667V14.7285H79.3198Z" />
    </svg>
  )
}
