import { Button } from '@/components/ui/button.tsx'
import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<SVGSVGElement> {
  onClick: () => void
}

export function TheIconRefresh({ onClick, ...props }: Readonly<Props>) {
  return (
    <Button variant={'secondary'} onClick={onClick} className={'p-0 hover:cursor-default'}>
      <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.3952 26.3552C14.594 27.7744 18.4039 27.6973 21.6666 25.8136C27.0872 22.684 28.9445 15.7526 25.8149 10.332L25.4815 9.75462M6.18435 21.6655C3.05474 16.2448 4.91199 9.31346 10.3326 6.18385C13.5953 4.30015 17.4052 4.22308 20.6041 5.64223M3.32422 21.777L6.96695 22.7531L7.94302 19.1103M24.0565 12.8864L25.0326 9.24366L28.6753 10.2197"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  )
}
