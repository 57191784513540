import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'

export const removeLegalWatchArticleFromFavorites = createAppAsyncThunk<void, LegalWatchArticle['id']>(
  'articles/removeLegalWatchArticleFromFavorites',
  async (articleId: LegalWatchArticle['id'], { extra: { legalWatchArticleGateway } }) => {
    try {
      return await legalWatchArticleGateway.removeLegalWatchArticleFromFavorites(articleId)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
