import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'
import { useHoverIconHook } from '../sidebar/use-hoverIcon.hook.tsx'

export function TheIconPen(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { setIsHovered, hoverStyle } = useHoverIconHook()
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        d="M1.91735 12.54C1.94798 12.2644 1.96329 12.1265 2.005 11.9977C2.042 11.8834 2.09428 11.7746 2.16041 11.6743C2.23496 11.5613 2.33302 11.4632 2.52914 11.2671L11.3333 2.46293C12.0697 1.72655 13.2636 1.72655 14 2.46293C14.7364 3.19931 14.7364 4.39322 14 5.1296L5.19581 13.9338C4.99969 14.1299 4.90163 14.228 4.78858 14.3025C4.68829 14.3686 4.57952 14.4209 4.46522 14.4579C4.33639 14.4996 4.19856 14.5149 3.9229 14.5456L1.66666 14.7963L1.91735 12.54Z"
        style={hoverStyle}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
