import { BaseButton } from '../components/atomics/BaseButton.tsx'
import { useNavigate } from 'react-router-dom'
import { TheIconOlympeLogoWithText } from '../components/icons/TheIconOlympeLogoWithText.tsx'

type errorMessage = { message?: string }

export function ErrorPage({ message = 'Nous sommes désolés, une erreur est survenue.' }: Readonly<errorMessage>) {
  const navigate = useNavigate()

  function handleClick() {
    return navigate('/')
  }

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-bright">
      <TheIconOlympeLogoWithText className={'fill-primary'} />
      <div className="mb-8 mt-8 text-center text-base text-primary">
        <p>{message}</p>
      </div>
      <BaseButton label="Revenir à l'accueil" onClick={handleClick}>
        {"Revenir à l'accueil"}
      </BaseButton>
    </div>
  )
}
