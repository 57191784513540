import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx'
import { Button } from '@/components/ui/button.tsx'
import { cn } from '@/lib/utils.ts'
import { SourcesButton } from '@/components/SourcesButton.tsx'
import { Group, NestedCheckboxList } from '@/components/NestedCheckboxList.tsx'

interface Props {
  isOpen: boolean
  onOpenChange: () => void
  groups: Group[]
  selectedSourceCollections: string[]
  handleSelectedSourceCollectionsChange: (newSelectedSourceCollections: string[]) => void
  isUserConnected: boolean
}

export function SourcesPopover({
  isOpen,
  onOpenChange,
  groups,
  selectedSourceCollections,
  handleSelectedSourceCollectionsChange,
  isUserConnected,
}: Readonly<Props>) {
  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger className={'p-0'} asChild>
        <Button
          variant={'secondary'}
          className={cn('group w-fit justify-start gap-2 rounded-lg text-left font-normal')}
        >
          <SourcesButton label="Sources" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={'w-auto rounded-2xl p-0'}>
        <NestedCheckboxList
          title={'SOURCES'}
          className={
            'absolute bottom-20 right-0 z-20 flex max-h-halfVh w-80 flex-col gap-3 overflow-y-auto ' +
            'rounded border border-solid border-bInput bg-bright px-2 py-3 shadow-softOutline'
          }
          groups={groups}
          selectedItems={selectedSourceCollections}
          onSelectedItemsChange={handleSelectedSourceCollectionsChange}
          isUserConnected={isUserConnected}
        />
      </PopoverContent>
    </Popover>
  )
}
