import { BackButton } from '@/components/ds/BackButton.tsx'
import { useBackButtonContainerHook } from '@/components/hooks/use-BackButtonContainer.hook.tsx'

interface Props {
  label: string
}
export function BackButtonContainer({ label }: Readonly<Props>) {
  const { navigateBack } = useBackButtonContainerHook()
  return <BackButton navigateBack={navigateBack} label={label} />
}
