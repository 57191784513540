export function useIndustryItemHook(props: { checked: boolean; disabled: boolean }) {
  const style = {
    default: {
      background: { color: 'bg-bright' },
      border: { color: 'border-brightGray', thickness: 'border' },
      text: { color: 'text-label' },
    },
    hover: {
      backgroundColor: { color: 'bg-bright' },
      border: {
        color: 'border-mistyAzure',
        thickness: 'border',
      },
      text: { color: 'text-label' },
    },
    disabled: {
      border: {
        color: 'border-[#C1C1C1]',
        thickness: 'border',
      },
      text: { color: 'text-silver' },
      backgroundColor: { color: 'bg-bInput' },
    },
    checked: {
      background: { color: 'bg-brightGray' },
      border: {
        color: 'border-brightGray hover:border-label focus:border-label',
        thickness: 'border',
      },
      text: { color: 'text-primary' },
    },
  }

  function getBorderColor() {
    if (props.checked) return style.checked.border.color
    if (props.disabled) return style.disabled.border.color
    return `hover:${style.hover.border.color} ${style.default.border.color}`
  }

  function getBorderThickness() {
    if (props.checked) return style.checked.border.thickness
    if (props.disabled) return style.disabled.border.thickness
    return `enabled:hover:${style.hover.border.thickness} ${style.default.border.thickness}`
  }

  function getTextColor() {
    if (props.checked) return style.checked.text.color
    if (props.disabled) return style.disabled.text.color
    return `enabled:hover:${style.hover.text.color} ${style.default.text.color}`
  }

  function getBackgroundColor() {
    if (props.checked) return style.checked.background.color
    if (props.disabled) return style.disabled.backgroundColor.color
    return `${style.default.background.color}`
  }

  return {
    borderColor: getBorderColor(),
    borderThickness: getBorderThickness(),
    textColor: getTextColor(),
    backgroundColor: getBackgroundColor(),
  }
}
