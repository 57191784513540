import { TheDotAnimation } from '@/components/TheDotAnimation.tsx'
import { SourcesPopOverAndIconSubmission } from '@/components/SourcesPopOverAndIconSubmission.tsx'
import { TheIconArrowSubmission } from '@/components/icons/TheIconArrowSubmission.tsx'

interface Props {
  isQuestionLoading: boolean
  handleSend: () => void
  userInputLength: number
  messagesLength: number
}
export function SubmitButtonOrLoading({
  isQuestionLoading,
  handleSend,
  userInputLength,
  messagesLength,
}: Readonly<Props>) {
  if (isQuestionLoading) {
    return <TheDotAnimation />
  } else if (!messagesLength) {
    return (
      <SourcesPopOverAndIconSubmission
        onClick={handleSend}
        className={'flex items-center gap-3.5 fill-error'}
        iconStyle={`${userInputLength ? 'fill-primary hover:cursor-pointer' : 'fill-silver'}`}
      />
    )
  } else {
    return (
      <div className={'flex h-[40px] gap-4'}>
        <TheIconArrowSubmission onClick={handleSend} disabled={!userInputLength} />
      </div>
    )
  }
}
