import { FileInfos } from '../types/file'
import { TheIconError } from './icons/TheIconError.tsx'
import { TheIconSuccess } from './icons/TheIconSuccess.tsx'
import { TheIconSpinner } from './icons/TheIconSpinner.tsx'

type Props = { status: FileInfos['uploadStatus'] | undefined }
export default function TheLoadStatusIcon(props: Readonly<Props>) {
  if (props.status === 'UPLOADED' || props.status === undefined) {
    return <TheIconSuccess className={'w-4 fill-primary'} />
  } else if (props.status && props.status === 'IN_PROGRESS') {
    return <TheIconSpinner className={'w-4 animate-spin fill-primary text-white dark:text-white'} />
  } else if (props.status && props.status === 'ERROR') {
    return <TheIconError className={'w-4 fill-error'} />
  }
}
