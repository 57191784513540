import { cn } from '@/lib/utils.ts'
import React from 'react'

export function FullHeightLayout({
  children,
  ...props
}: Readonly<{ children: React.ReactNode }> & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={cn('h-full max-h-screen overflow-auto p-6', props.className)}>
      {children}
    </div>
  )
}
