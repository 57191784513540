import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS'
import { BaseButton } from '../../components/atomics/BaseButton'

function LoginRegisterInfoSection() {
  return (
    <div className="flex flex-col items-center justify-between gap-3 py-5">
      <div className="flex items-center justify-center gap-10">
        <Link to={ROUTE_PATHS.login}>
          <BaseButton color="tertiary" className="border border-black" label="Connexion"></BaseButton>
        </Link>
        <Link to={ROUTE_PATHS.register}>
          <BaseButton label="S'inscrire à Olympe"></BaseButton>
        </Link>
      </div>
      <p className="text-xs text-[#6E7682]">
        Si le contenu affiché sur cette page vous semble choquant ou illicite, vous pouvez nous en informer à&nbsp;
        <a href="mailto:support@olympe.legal">support@olympe.legal.</a>
      </p>
    </div>
  )
}

export default LoginRegisterInfoSection
