import { ReactElement } from 'react'

type Props = {
  children: ReactElement
  title: string
}
export function PromptExempleHeader({ title, children }: Readonly<Props>) {
  return (
    <div className={'flex items-center gap-2'}>
      {children}
      <span className={'text-sm font-semibold text-primary'}>{title}</span>
    </div>
  )
}
