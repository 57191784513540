import { resetFilters } from '@/store/articles/articlesSlice'
import { useAppDispatch } from '@/store/hooks'

export const LEGAL_WATCH_FILTERS = {
  DATE: 'date',
  REGION: 'region',
  TAG: 'tag',
}

export const useLegalWatchFiltersContainerHook = () => {
  const dispatch = useAppDispatch()
  const filters = [
    { label: LEGAL_WATCH_FILTERS.DATE },
    { label: LEGAL_WATCH_FILTERS.REGION },
    { label: LEGAL_WATCH_FILTERS.TAG },
  ]
  const onResetFilters = () => {
    dispatch(resetFilters())
  }
  return {
    filters,
    onResetFilters,
  }
}
