import { LegalWatchDrawer } from '@/components/LegalWatchDrawer.tsx'
import { useMessagesListHook } from '@/components/hooks/use-MessagesList.hook.tsx'
import { useLegalWatchDrawerHook } from '@/components/hooks/use-LegalWatchDrawer.hook.tsx'

export function LegalWatchDrawerContainer() {
  const { messages } = useMessagesListHook()
  const { isLegalWatchDrawerDisplayed } = useLegalWatchDrawerHook()
  if (messages.length > 0) return
  return <LegalWatchDrawer isLegalWatchDrawerDisplayed={isLegalWatchDrawerDisplayed} />
}
