import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { closeCalendlyModal, selectIsCalendlyModalOpen } from '@/store/modal/modalsSlice.ts'
import { InlineWidget } from 'react-calendly'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'

export function CalendlyModalContainer() {
  const dispatch = useAppDispatch()
  const isCalendlyModalOpen = useAppSelector(selectIsCalendlyModalOpen)
  const calendlyUrl = import.meta.env.VITE_OLYMPE_CALENDLY_URL

  return (
    <Dialog open={isCalendlyModalOpen}>
      <DialogContent className="max-w-2xl gap-0 rounded-2xl">
        <button
          className="absolute -right-[40px] -top-[50px] m-4 rounded-full bg-primary p-0.5 text-white hover:cursor-pointer sm:right-0 sm:top-0"
          onClick={() => dispatch(closeCalendlyModal())}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5" fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="text-center">
          <InlineWidget url={calendlyUrl} styles={{ height: '650px', width: '100%', padding: '20px' }} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
