import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconImportDocument(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3095_31456)">
        <path d="M4.94587 12.1702C4.339 12.176 3.73391 12.1831 3.13952 12.0169C1.10861 11.4488 -0.20493 9.5146 0.0263211 7.42042C0.24376 5.44966 1.79524 3.87902 3.7553 3.65089C3.86358 3.63841 3.88675 3.5845 3.91348 3.49761C4.36707 2.03659 5.31881 1.04831 6.77672 0.591601C9.04334 -0.118639 11.449 1.19357 12.1173 3.48068C12.153 3.60232 12.202 3.64198 12.3254 3.66114C14.2641 3.957 15.7113 5.43807 15.9648 7.37675C16.2705 9.716 14.5292 11.9202 12.1739 12.1346C11.4944 12.1965 10.8056 12.1675 10.1212 12.1649C9.83467 12.1635 9.61545 11.9029 9.62391 11.6186C9.63194 11.3339 9.85873 11.1053 10.1537 11.1022C10.6884 11.0968 11.2231 11.1031 11.7578 11.0999C13.266 11.091 14.531 10.0881 14.8848 8.62301C15.3584 6.66161 13.8649 4.72561 11.8473 4.6855C11.4285 4.67704 11.24 4.51663 11.166 4.10537C10.8929 2.58954 9.57089 1.48096 8.031 1.4765C6.4871 1.47205 5.15439 2.56993 4.87324 4.07819C4.78546 4.54871 4.63664 4.67481 4.15498 4.68595C2.57231 4.72249 1.20174 6.01331 1.07208 7.58929C0.936176 9.23968 2.03584 10.7355 3.62119 11.0287C3.95759 11.091 4.30692 11.0888 4.6509 11.0982C5.04612 11.1093 5.44179 11.0995 5.83701 11.1013C6.1752 11.1026 6.4167 11.3285 6.41491 11.6382C6.41313 11.9474 6.17208 12.168 5.83122 12.1698C5.53625 12.1711 5.24084 12.1698 4.94587 12.1698V12.1702Z" />
        <path d="M8.55338 6.5537V6.73059C8.55338 8.86354 8.55338 10.9965 8.55249 13.1294C8.55249 13.2177 8.54937 13.309 8.52576 13.3928C8.45536 13.6405 8.21698 13.7969 7.96523 13.7715C7.70947 13.7457 7.50362 13.5322 7.4858 13.2725C7.48179 13.2172 7.48401 13.1611 7.48401 13.1054C7.48401 10.9836 7.48401 8.86175 7.48401 6.73995V6.51627C7.41896 6.57865 7.37663 6.61697 7.33653 6.65707C6.80452 7.18864 6.2734 7.72065 5.74094 8.25132C5.58054 8.41084 5.38805 8.46208 5.17373 8.38722C4.97233 8.31682 4.85559 8.16622 4.8244 7.9568C4.79722 7.77322 4.86317 7.61906 4.99327 7.48895C5.86793 6.61519 6.74125 5.74008 7.61679 4.86721C7.86721 4.61725 8.17287 4.61992 8.42773 4.87256C8.94905 5.39076 9.46814 5.91163 9.98768 6.43161C10.3459 6.78985 10.7055 7.1472 11.0615 7.50766C11.2157 7.66406 11.2598 7.85476 11.188 8.06195C11.1185 8.2638 10.9684 8.38143 10.759 8.41262C10.5687 8.44113 10.4128 8.36851 10.2786 8.23394C9.74439 7.69703 9.20793 7.16235 8.67324 6.62588C8.64606 6.59825 8.62735 6.56261 8.60462 6.53053L8.55428 6.55281L8.55338 6.5537Z" />
      </g>
      <defs>
        <clipPath id="clip0_3095_31456">
          <rect width="16" height="13.3756" fill="white" transform="translate(0 0.398926)" />
        </clipPath>
      </defs>
    </svg>
  )
}
