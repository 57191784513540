import { Industry } from '@/store/stepper/steppersSlice'
import { PropsWithChildren, useState } from 'react'
import { JOB_TITLES } from '../registerSteps/jobTitle/right/use-jobTitle.hook'
import { useSidebarHook } from '../sidebar/use-sidebar.hook'
import { Dialog, DialogContent } from '../ui/dialog'
import ActivityPage from './ActivityPage'
import EndPage from './EndPage'
import FunctionPage from './FunctionPage'
import NamePage from './NamePage'
import StartPage from './StartPage'

export type State = {
  firstname: string
  lastname: string
  jobTitle: string
  industry: Industry
  otherJobTitle: string
  otherIndustry: string
}

export interface StepPageProps {
  onNext?: () => void
  onPrevious?: () => void
  onDone?: () => void
  state: State
  setState: (payload: State) => void
}

interface ProfilInfoContainerProps {
  onExit: () => void
}

const STEPS = [StartPage, NamePage, FunctionPage, ActivityPage, EndPage]
function ProfilInfoContainer({ onExit }: PropsWithChildren<ProfilInfoContainerProps>) {
  const { redirectToNewConversation } = useSidebarHook()
  const [step, setStep] = useState(0)
  const [state, setState] = useState<State>({
    firstname: '',
    lastname: '',
    jobTitle: JOB_TITLES[0].value as string,
    industry: { id: '' },
    otherJobTitle: '',
    otherIndustry: '',
  })
  const CurrentStep = STEPS[step]
  return (
    <Dialog open>
      <DialogContent withClose={false} className="min-h-[32.5rem] min-w-[43.75rem]">
        <CurrentStep
          state={state}
          setState={setState}
          onDone={async () => {
            onExit()
            await redirectToNewConversation()
          }}
          onNext={() => setStep(step + 1)}
          onPrevious={() => setStep(step - 1)}
        />
      </DialogContent>
    </Dialog>
  )
}
export default ProfilInfoContainer
