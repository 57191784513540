import { cn } from '@/lib/utils'

interface LabelAndIconsProps {
  leftIcon: React.FC
  rightIcon?: React.FC
  label: string
  disabled?: boolean
}

export function LabelAndIcons({ leftIcon, rightIcon, label, disabled }: Readonly<LabelAndIconsProps>) {
  return (
    <>
      {leftIcon({ className: 'h-[1.5rem] w-[1.5rem]' })}

      <span
        className={cn(
          'flex items-center justify-center gap-3 stroke-slate-100 text-sm text-bright',
          disabled && 'text-[#6E7682]',
        )}
      >
        {label}
        {rightIcon && rightIcon({ className: 'h-4 w-4  stroke-slate-100' })}
      </span>
    </>
  )
}
