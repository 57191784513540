import { useEffect, useState } from 'react'

export function useCountDown() {
  const [counter, setCounter] = useState(3)

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  return counter
}
