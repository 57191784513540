interface TheIconThumbUpProps extends React.SVGProps<SVGSVGElement> {
  isSelected?: boolean
}

export function TheIconThumbUp({ className, isSelected, ...rest }: Readonly<TheIconThumbUpProps>) {
  const strokeColorClass = isSelected ? 'stroke-primary' : 'stroke-[#6E7682]'
  return (
    <svg
      {...rest}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={`${strokeColorClass} stroke-[#6E7682] ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33317 18.8332V9.6665M2.1665 11.3332V17.1665C2.1665 18.087 2.9127 18.8332 3.83317 18.8332H15.0217C16.2556 18.8332 17.305 17.9329 17.4926 16.7133L18.3901 10.88C18.6231 9.36558 17.4514 7.99984 15.9191 7.99984H12.9998C12.5396 7.99984 12.1665 7.62674 12.1665 7.1665V4.22137C12.1665 3.0865 11.2465 2.1665 10.1116 2.1665C9.84095 2.1665 9.59565 2.32592 9.48572 2.57327L6.55312 9.17162C6.41937 9.47256 6.12093 9.6665 5.79161 9.6665H3.83317C2.9127 9.6665 2.1665 10.4127 2.1665 11.3332Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
