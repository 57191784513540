import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'

export const addLegalWatchArticleToFavorites = createAppAsyncThunk<void, LegalWatchArticle['id']>(
  'articles/addLegalWatchArticleToFavorites',
  async (articleId: LegalWatchArticle['id'], { extra: { legalWatchArticleGateway } }) => {
    try {
      return legalWatchArticleGateway.addLegalWatchArticleToFavorites(articleId)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
