import { TheSidebarDesktopHeader } from './TheSidebarDesktopHeader.tsx'
import { TheSidebarConversationList } from './TheSidebarConversationList.tsx'
import { ReactElement } from 'react'

type Props = {
  children: ReactElement
}

export function TheSidebarDesktop({ children }: Readonly<Props>) {
  return (
    <div className="col-span-2 h-full w-[240px] flex-col items-center justify-between gap-5 bg-primary pb-5 pl-4 pr-6 pt-4 sm:flex">
      <TheSidebarDesktopHeader />
      <TheSidebarConversationList />
      {children}
    </div>
  )
}
