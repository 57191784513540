import { FileData } from '../types/file.ts'
import { Tag } from '../types/tag.ts'
import { TheIconVisibleEye } from './icons/TheIconVisibleEye.tsx'
import { TheIconBin } from './icons/TheIconBin.tsx'
import { TheIconTripleDotsVertical } from './icons/TheIconTripleDotsVertical.tsx'
import { BaseOverlay } from './atomics/BaseOverlay.tsx'
import { TheTagsSelect } from './TheTagsSelect.tsx'

type Props = {
  displayFileAction: () => void
  deleteFileAction: () => void
  displayMovingFileAction: () => void
  item: FileData
  selectedItem: FileData | null
  toggleModalAction: () => void
  selectTagAction: (tag: Tag) => void
}

export function ReusableTableRowActions(props: Readonly<Props>) {
  return (
    <div className={'flex w-[100px] items-center justify-end gap-4'}>
      <div className={'h-full basis-1/4'}>
        <TheIconVisibleEye
          onClick={props.displayFileAction}
          className={'inline-block w-6 fill-label hover:fill-primary'}
        />
      </div>
      <div className={'h-full basis-1/4'}>
        <TheIconBin onClick={props.deleteFileAction} className={'inline-block w-4 fill-label hover:fill-primary'} />
      </div>
      <div className={'relative h-full basis-1/4'}>
        <div>
          <TheIconTripleDotsVertical
            onClick={props.displayMovingFileAction}
            itemId={props.item.id}
            width="11"
            height="22"
            className={'inline-block fill-label hover:fill-primary'}
          />
        </div>
        <BaseOverlay contentVisible={props.item.id === props.selectedItem?.id} onClick={props.toggleModalAction}>
          <TheTagsSelect file={props.item} onClick={props.selectTagAction} />
        </BaseOverlay>
      </div>
    </div>
  )
}
