import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconCheck(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg className={props.className} viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.75671 1.92352C9.51168 2.17325 9.25876 2.42211 9.01374 2.6632C8.91862 2.75653 8.8235 2.85028 8.72882 2.94404C7.28759 4.36939 5.8468 5.7943 4.40513 7.21922C4.21752 7.40457 3.99397 7.50049 3.76736 7.50049C3.64068 7.50049 3.51313 7.47068 3.38995 7.40976C3.30404 7.36698 3.21769 7.30477 3.14098 7.22916C2.16394 6.26956 1.18515 5.30479 0.273425 4.40481C0.00472802 4.13953 -0.0715415 3.78568 0.0696009 3.45818C0.20373 3.14797 0.511877 2.94793 0.855966 2.94793H0.859473C1.09705 2.94793 1.3035 3.03693 1.49023 3.22012C1.76813 3.49318 2.04647 3.76581 2.32437 4.03843C2.79733 4.5016 3.28607 4.98031 3.76473 5.45384C5.10514 4.12916 6.4701 2.78461 7.78992 1.48412L8.53946 0.745309C8.86865 0.420836 9.40779 0.418676 9.74049 0.740125C10.0802 1.06762 10.0872 1.58738 9.75671 1.92352Z" />
    </svg>
  )
}
