import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalResource } from '@/types/legalResource.ts'

export const getTextBySlug = createAppAsyncThunk<LegalResource, LegalResource['id']>(
  'texts/getTextBySlug',
  async (slug: LegalResource['slug'], { extra: { legalResourceGateway } }) => {
    try {
      const response = await legalResourceGateway.getTextBySlug(slug)
      return response[0]
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
