import { ROUTE_PATHS } from '@/ROUTE_PATHS'
import { generatePath, Link } from 'react-router-dom'
import { LegalResource } from '@/types/legalResource.ts'

interface LibraryItemProps {
  text: LegalResource
}

function LibraryItem({ text: { slug, title, excerpt } }: Readonly<LibraryItemProps>) {
  return (
    <Link to={generatePath(ROUTE_PATHS.libraryDetail, { slug: String(slug) })}>
      <div className="flex flex-col gap-2 rounded-2xl  border bg-[#FDFDFD] p-4 hover:bg-[#5FC999] hover:bg-opacity-30 md:max-w-3xl">
        <h2 className="text-sm font-semibold text-[#5FC999]">{title}</h2>
        <p className="text-base">{title === excerpt ? '' : excerpt}</p>
      </div>
    </Link>
  )
}

export default LibraryItem
