import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconRightArrowRounded(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_171_269)">
        <path
          d="M8.01209 15.9821C3.63453 16.0003 0.0136762 12.4195 4.17595e-05 8.0569C-0.0140471 3.61344 3.53863 0.0225827 7.99663 0.0175834C12.4083 0.0125841 15.9796 3.55799 16 7.98372C16.02 12.3881 12.4396 15.9635 8.01254 15.9821H8.01209ZM7.97755 14.5305C11.5957 14.5369 14.5312 11.6214 14.5457 8.00736C14.5602 4.43014 11.6243 1.4751 8.0489 1.46692C4.41305 1.45874 1.46211 4.36697 1.45665 7.96282C1.4512 11.5882 4.36669 14.5242 7.97755 14.5305Z"
          fill="#FDFDFD"
        />
        <path
          d="M10.9052 7.99992C10.9093 8.20444 10.8316 8.37623 10.6893 8.51849C9.50995 9.69741 8.3283 10.8741 7.1512 12.0548C7.07075 12.1352 7.03121 12.1162 6.96395 12.0475C6.69399 11.7721 6.42357 11.4972 6.14407 11.2317C6.04545 11.1381 6.05454 11.0863 6.14634 10.9954C7.10893 10.041 8.06516 9.08022 9.03002 8.12763C9.1391 8.01992 9.13092 7.97038 9.0273 7.86767C8.06334 6.91417 7.10711 5.95385 6.14407 4.99989C6.04772 4.90445 6.05317 4.85355 6.14679 4.76356C6.42539 4.49724 6.69717 4.22364 6.96622 3.94732C7.03712 3.8746 7.07621 3.86733 7.15347 3.94459C8.32694 5.12124 9.50405 6.29426 10.6802 7.46863C10.827 7.61498 10.9098 7.7895 10.9052 7.99992Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_171_269">
          <rect width="16" height="15.9646" fill="#FDFDFD" transform="translate(0 0.0175781)" />
        </clipPath>
      </defs>
    </svg>
  )
}
