import { Button } from '../ui/button'
import { StepPageProps } from './ProfilInfoContainer'

function StartPage({ onNext }: StepPageProps) {
  return (
    <div className="flex  flex-col items-center justify-center gap-9 ">
      <div className="flex flex-col items-center justify-center gap-3 ">
        <h1 className="text-4xl font-semibold">Dites nous en plus...</h1>
        <p className="text-center text-base text-[#6E7682]">
          Pour vous offrir une expérience personnalisée, <br />
          nous aimerions vous poser quelques questions.
        </p>
      </div>
      <Button onClick={onNext}>C&apos;est parti !</Button>
    </div>
  )
}
export default StartPage
