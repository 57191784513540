import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { format } from 'date-fns'
import { DateRange, SelectRangeEventHandler } from 'react-day-picker'
import { TheIconChevronFilled } from '@/components/icons/TheIconChevronFilled.tsx'
import { fr } from 'date-fns/locale'

interface Props {
  dates: DateRange | undefined
  onSetDate: SelectRangeEventHandler
  isOpen: boolean
  onOpenChange: () => void
}

export function DatePickerFilter({ dates, onSetDate, isOpen, onOpenChange }: Readonly<Props>) {
  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          size={'filter'}
          variant={'filter'}
          className={cn(
            'group w-fit justify-start gap-2 rounded-lg text-left font-normal',
            !dates?.from ? 'bg-silverLining hover:bg-gainsboroGray' : ' bg-softMint text-mintGreen hover:bg-softMint',
          )}
        >
          {dates?.from ? (
            dates.to && dates.from.getTime() === dates.to.getTime() ? (
              <span className={'text-mintGreen'}>{format(dates.from, 'dd MMMM', { locale: fr })}</span>
            ) : dates.to ? (
              <span className={'text-mintGreen'}>
                {format(dates.from, 'dd MMMM', { locale: fr })} - {format(dates.to, 'dd MMMM', { locale: fr })}
              </span>
            ) : (
              <span className={'text-mintGreen'}>{format(dates.from, 'dd MMMM', { locale: fr })}</span>
            )
          ) : (
            <span className={'text-label'}>Date</span>
          )}
          <TheIconChevronFilled className={`${dates?.from ? 'fill-label' : 'fill-silver group-hover:fill-label'}`} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={'w-auto rounded-2xl p-0'}>
        <Calendar
          locale={fr}
          mode="range"
          selected={dates}
          onSelect={onSetDate}
          defaultMonth={dates?.from}
          numberOfMonths={1}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
