import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { Tag } from '@/types/tag.ts'

export const deleteTag = createAppAsyncThunk('tags/deleteTag', async (tagId: Tag['id'], { extra: { tagGateway } }) => {
  try {
    return tagGateway.deleteTag(tagId)
  } catch (error) {
    throw new Error((error as Error).message)
  }
})
