import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectIsDrawerDisplayed, toggleIsDisplayed } from '@/store/articles/articlesSlice.ts'
import { ChevronDoubleRight } from '@/components/icons/ChevronDoubleRight.tsx'
import { ChevronDoubleLeft } from '@/components/icons/ChevronDoubleLeft.tsx'
import { ArticleNotificationsBadgeContainer } from '@/components/ArticleNotificationsBadgeContainer.tsx'

export function LegalWatchDrawerHeader() {
  const dispatch = useAppDispatch()
  const isDrawerDisplayed = useAppSelector(selectIsDrawerDisplayed)

  return (
    <div
      className={`flex w-full items-center justify-between gap-3.5 px-4 text-base font-semibold leading-5 text-primary  ${isDrawerDisplayed ? 'animation-slide-in w-[20rem] transform transition-all duration-300 ease-in-out' : ''}`}
    >
      <div className={'flex h-10 items-center gap-2'}>
        <span
          className={`text-base font-semibold leading-5 text-label ${isDrawerDisplayed ? 'transform transition-transform duration-300 ease-in-out' : ''}`}
        >
          Veille juridique
        </span>
        <ArticleNotificationsBadgeContainer />

        {isDrawerDisplayed && (
          <button
            onClick={() => dispatch(toggleIsDisplayed())}
            className={'text-xs font-normal text-silver underline hover:cursor-pointer hover:text-primary'}
          >
            <ChevronDoubleRight className={'stroke-label hover:stroke-primary'} />
          </button>
        )}
      </div>

      {isDrawerDisplayed ? (
        <button disabled={true} className={'text-xs font-normal text-silver underline'}>
          Personnaliser ma veille
        </button>
      ) : (
        <button onClick={() => dispatch(toggleIsDisplayed())}>
          <ChevronDoubleLeft className={'stroke-label hover:stroke-primary'} />
        </button>
      )}
    </div>
  )
}
