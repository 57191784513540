import { useForm } from 'react-hook-form'
import {
  passwordToValidations,
  RegistrationFormValues,
  registrationSchema,
} from '@/components/registerSteps/credentials/right/registrationSchema.ts'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form.tsx'
import { Input } from '@/components/ui/input.tsx'
import { PasswordRequirements } from '@/components/PasswordRequirements.tsx'
import { Button } from '@/components/ui/button.tsx'

interface Props {
  onSubmit: (values: RegistrationFormValues) => void
}

export function SignupForm({ onSubmit }: Readonly<Props>) {
  const form = useForm<RegistrationFormValues>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {
      email: '',
      password: '',
      acceptCGU: false,
    },
    mode: 'onBlur',
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className={'space-y-1'}>
              <FormLabel className={'text-label'}>Email</FormLabel>
              <FormControl>
                <Input placeholder="Renseignez votre email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className={'space-y-1'}>
              <FormLabel className={'text-label'}>Mot de passe</FormLabel>
              <FormControl>
                <Input type="password" placeholder="Choisissez un mot de passe" {...field} />
              </FormControl>
              {/* <FormMessage /> */}
            </FormItem>
          )}
        />
        {form.watch('password') && (
          <PasswordRequirements passwordErrors={passwordToValidations(form.watch('password'))} />
        )}
        <FormField
          control={form.control}
          name="acceptCGU"
          render={() => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
              <div className="flex flex-col gap-2">
                <div className={'flex flex-col items-center self-stretch'}>
                  {/* checkbox component */}
                  <div className={'flex items-center gap-3 self-stretch'}>
                    <FormControl>
                      <input
                        type={'checkbox'}
                        id={'cgu'}
                        className={'h-4 w-4 rounded border border-primary accent-primary'}
                        {...form.register('acceptCGU')}
                      />
                    </FormControl>
                    <p className={'flex shrink-0 text-sm font-semibold leading-5 text-primary md:grow md:basis-0'}>
                      {"J'accepte les"} &nbsp;
                      <a
                        href={import.meta.env.VITE_OLYMPE_CGU_URL}
                        target={'_blank'}
                        className={'underline'}
                        rel="noreferrer"
                      >
                        {"conditions générales d'utilisation."}
                      </a>
                    </p>
                  </div>
                  <p className={'text-sm leading-4 text-primary'}>
                    La mise à disposition de Olympe implique la mise en oeuvre de traitements de données à caractère
                    personnel. Pour en savoir plus à ce sujet, consultez&nbsp;
                    <a
                      href={import.meta.env.VITE_OLYMPE_PRIVACY_POLICY_URL}
                      target={'_blank'}
                      className={'text-sm leading-4 text-primary underline'}
                      rel="noreferrer"
                    >
                      {'la Politique de confidentialité de Olympe.'}
                    </a>
                  </p>
                </div>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <div className={'flex items-center justify-center'}>
          <Button type="submit" className={'rounded-lg px-3 py-6 text-sm leading-5 text-bright'}>
            Inscription
          </Button>
        </div>
      </form>
    </Form>
  )
}
