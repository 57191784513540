import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

type AdditionalProps = {
  isActive: boolean
}

type Props = AdditionalProps & (JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>)

export function TheIconFolder(props: Props) {
  const { isActive, ...rest } = props
  return (
    <svg {...rest} width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3095_32048)">
        <path
          d="M31.9998 1.91722V23.7944C31.958 23.8745 31.9222 23.9581 31.8728 24.0331C31.7321 24.2445 31.6103 24.4755 31.4372 24.6562C31.0732 25.0373 30.5856 25.0859 30.0895 25.0859C23.1744 25.0859 16.2594 25.0859 9.34434 25.0859C6.74097 25.0859 4.13759 25.0825 1.53422 25.0748C0.737183 25.0722 0.000668838 24.3425 0.000668838 23.5574C-0.00103606 17.7361 0.000668838 11.9147 0.00407863 6.09251C0.00407863 6.003 0.0253898 5.91094 0.0484059 5.82399C0.236797 5.1318 0.736331 4.78059 1.39442 4.60584C1.99198 4.60499 2.5904 4.60414 3.18797 4.60328C3.2502 4.60669 3.31242 4.61351 3.37465 4.61351C8.56861 4.61351 13.7626 4.61522 18.9557 4.61266C19.7578 4.61266 20.3068 4.19581 20.5702 3.41753C20.7765 2.87793 20.9828 2.33919 21.1891 1.79959L21.1831 1.77828C21.201 1.76293 21.2189 1.74673 21.2368 1.73139C21.5446 0.966746 22.1029 0.565244 22.9349 0.565244C25.3703 0.564391 27.8058 0.561834 30.2412 0.567801C30.693 0.568653 31.1491 0.656455 31.4551 1.01022C31.6827 1.27277 31.8208 1.6129 31.999 1.91893L31.9998 1.91722Z"
          fill={isActive ? '#44C3A0' : '#DBE2EB'}
        />
        <path
          d="M1.39385 4.6057C1.39215 3.96039 1.38107 3.31509 1.39044 2.67064C1.39897 2.11485 1.66152 1.84547 2.21305 1.78921C2.58046 1.7517 2.81318 1.48915 2.80806 1.11066C2.80209 0.69808 2.89416 0.323856 3.28458 0.116712C3.43887 0.0348769 3.63834 0.00589366 3.81736 0.00589366C6.98079 -0.000925914 10.1442 -0.000925913 13.3076 0.00248387C13.9265 0.00248387 14.1746 0.239464 14.2487 0.859193C14.3152 1.41669 14.727 1.78666 15.2904 1.78836C17.257 1.79262 19.2236 1.79518 21.1902 1.79859C20.9839 2.33819 20.7776 2.87693 20.5713 3.41653C20.4537 3.39693 20.3361 3.35942 20.2184 3.35942C14.6639 3.35686 9.10935 3.35686 3.5548 3.35686C3.44399 3.35686 3.33232 3.35686 3.18911 3.35686V4.60314C2.59154 4.60399 1.99312 4.60484 1.39556 4.6057H1.39385Z"
          fill={isActive ? '#3EA59A' : '#6e7682'}
        />
        <path
          d="M3.18945 4.60297V3.35669C3.33352 3.35669 3.44433 3.35669 3.55515 3.35669C9.1097 3.35669 14.6642 3.35669 20.2188 3.35925C20.3364 3.35925 20.4541 3.39675 20.5717 3.41636C20.3083 4.19464 19.7593 4.61064 18.9572 4.61149C13.7632 4.6149 8.56924 4.6132 3.37614 4.61234C3.31391 4.61234 3.25168 4.60552 3.18945 4.60211V4.60297Z"
          fill={'#E9EFF2'}
        />
      </g>
      <defs>
        <clipPath id="clip0_3095_32048">
          <rect width="32" height="25.0867" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
