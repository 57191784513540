import { useNavigate } from 'react-router-dom'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { removeLegalWatchArticleFromFavorites } from '@/store/articles/useCases/removeLegalWatchArticleFromFavorites.ts'
import { addLegalWatchArticleToFavorites } from '@/store/articles/useCases/addLegalWatchArticleToFavorites.ts'
import { useAppDispatch } from '@/store/hooks'

export const useLegalWatchArticlePreview = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  function readArticle(slug: LegalWatchArticle['slug']) {
    return navigate(`/legal-watch/s/${slug}`)
  }

  const toggleFavorite = (id: LegalWatchArticle['id'], isFavorite: LegalWatchArticle['isFavorite']) => {
    isFavorite ? dispatch(removeLegalWatchArticleFromFavorites(id)) : dispatch(addLegalWatchArticleToFavorites(id))
  }

  return {
    readArticle,
    toggleFavorite,
  }
}
