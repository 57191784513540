import { ClockIcon } from '@/components/icons/ClockIcon'
import { TheAuditIcon } from '@/components/icons/TheAuditIcon'
import { TheIconDocumentsFolder } from '@/components/icons/TheIconDocumentsFolder'
import { TheIconPlus } from '@/components/icons/TheIconPlus'
import { TheIconWithoutName } from '@/components/icons/TheIconWithoutName'
import { TheQuestionBubbleIcon } from '@/components/icons/TheQuestionBubbleIcon'
import { TheSideBarItem } from '@/components/sidebar/TheSideBarItem/TheSideBarItem'
import { FC } from 'react'
import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText'
import { TheSidebarFooter } from '../../components/sidebar/TheSidebarFooter'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

const withColor = (c: FC, color?: string) => () => c({ color: color ?? '#6E7682' })

function ConversationSnapshotSideBar() {
  const { isUserConnected } = useCurrentUserHook()
  return (
    <div className="flex h-screen flex-col items-center justify-between bg-[#212121] p-4">
      <div>
        <div className="flex items-center justify-center py-4">
          <TheIconOlympeLogoWithText className={'fill-bright'} />
        </div>

        <div className="flex w-full flex-col items-center gap-3 stroke-black">
          <TheSideBarItem disabled isDisabledButton label="Nouvelle Question" leftIcon={withColor(TheIconPlus)} />
          <TheSideBarItem disabled label="Mes documents" leftIcon={withColor(TheIconDocumentsFolder)} />
          <TheSideBarItem disabled label="Veille Juridique" leftIcon={withColor(ClockIcon)} />
          <TheSideBarItem disabled label="Aide à la rédaction" leftIcon={withColor(TheQuestionBubbleIcon)} />
          <TheSideBarItem
            label="Auditez votre site"
            href={import.meta.env.VITE_ORION_URL}
            leftIcon={withColor(TheAuditIcon, '#FFF')}
            rightIcon={withColor(TheIconWithoutName, '#FFF')}
          />
        </div>
      </div>
      <TheSidebarFooter isPrivate={false} isUserConnected={isUserConnected} />
    </div>
  )
}

export default ConversationSnapshotSideBar
