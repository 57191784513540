import { Conversation } from '../../../types/conversation'
import { createAppAsyncThunk } from '../../createAppThunk'

export const getConversationSnapshotById = createAppAsyncThunk(
  'conversationSnapshot/getSnapshotById',
  async (conversationId: Conversation['id'], { extra: { conversationSnaphotGateway } }) => {
    try {
      return await conversationSnaphotGateway.getConversationSnapshot(conversationId)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
