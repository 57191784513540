import { Tag, TagWithFileCountAndActiveStatus } from '../types/tag.ts'
import React from 'react'
import { TheDocumentsFolderHeader } from './ds/TheDocumentsFolderHeader.tsx'
import { TheDocumentsFolderContent } from './ds/TheDocumentsFolderContent.tsx'

type Props = {
  tag: TagWithFileCountAndActiveStatus
  onClick: (tag: Tag) => void
  activeTagContainerStyle: string
  filesLength: number
  documentLabel: string
  onRename: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: TagWithFileCountAndActiveStatus) => void
  onDelete: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: TagWithFileCountAndActiveStatus) => void
}

export function TheDocumentsFolder({
  tag,
  onClick,
  activeTagContainerStyle,
  filesLength,
  documentLabel,
  onRename,
  onDelete,
}: Readonly<Props>) {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => onClick(tag)}
      onClick={() => onClick(tag)}
      className={`${activeTagContainerStyle} flex w-[12.5rem] flex-none flex-col gap-4 rounded-lg px-4 pb-3 pt-5`}
    >
      <TheDocumentsFolderHeader onDelete={(e) => onDelete(e, tag)} tag={tag} onRename={(e) => onRename(e, tag)} />
      <TheDocumentsFolderContent
        tagActive={tag.isTagActive}
        name={tag.name}
        filesLength={filesLength}
        documentLabel={documentLabel}
      />
    </div>
  )
}
