import { DragEvent, FormEvent, useState } from 'react'
import { ALLOWED_FILE_TYPES_AS_STRING } from '../utils/FileTypes.ts'
import { TheIconUpload } from './icons/TheIconUpload.tsx'

type Props = {
  drop: ((e: DragEvent<HTMLDivElement>) => void) | undefined
  handleFileInputChange: ((event: FormEvent<HTMLInputElement>) => void) | undefined
  isClickable?: boolean
  label: string
}

export function TheFileUploader({ drop, handleFileInputChange, label, isClickable = true }: Readonly<Props>) {
  const [isDroppingActive, setIsDroppingActive] = useState(false)

  const borderStyle = () => {
    return isDroppingActive ? 'border-2 border-solid border-label' : 'border border-dotted border-bInput'
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (drop) {
      drop(e)
      setIsDroppingActive(!isDroppingActive)
    }
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    handleFileInputChange?.(e)
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDroppingActive(true)
  }
  return (
    <>
      <label htmlFor="fileInputRef" className={isClickable ? 'hover:cursor-pointer' : ''}>
        <span
          onDrop={handleDrop}
          onDragEnter={() => setIsDroppingActive(true)}
          onDragOver={handleDragOver}
          onDragLeave={() => setIsDroppingActive(false)}
          className={`${borderStyle()} mb-16 mt-6 hidden h-[110px] rounded-lg p-4 lg:mb-3 ${
            isDroppingActive || (isClickable && 'hover:border-2 hover:border-solid hover:border-label ')
          } sm:block lg:flex`}
        >
          <span className="m-auto flex w-3/4 flex-col items-center">
            <TheIconUpload />
            <span className="block text-center text-sm font-normal text-label">{label}</span>
          </span>
        </span>
      </label>
      <input
        onChange={handleChange}
        id={isClickable ? 'fileInputRef' : ''}
        accept={ALLOWED_FILE_TYPES_AS_STRING}
        className="hidden"
        type="file"
      />
    </>
  )
}
