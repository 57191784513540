import '../assets/scss/dot-animation.scss'

type Props = { size?: string }

export function TheDotAnimation({ size = 'large' }: Readonly<Props>) {
  const setSize = () => {
    return size === 'large' ? 'loading__large' : 'loading__small'
  }
  return (
    <div className="flex items-center justify-center">
      <span className={`loading ${setSize()}`}>...</span>
    </div>
  )
}
