import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { transformISOToDDMMYYYY } from '@/utils/date.ts'

export const getArticleBySlug = createAppAsyncThunk<LegalWatchArticle, LegalWatchArticle['slug']>(
  'articles/getArticleBySLug',
  async (slug: LegalWatchArticle['slug'], { extra: { legalWatchArticleGateway } }) => {
    try {
      const list = await legalWatchArticleGateway.getLegalWatchArticleBySlug(slug)
      const isoDateString = list[0]?.createdAt ? list[0].createdAt : new Date().toISOString()
      return { ...list[0], createdAt: transformISOToDDMMYYYY(isoDateString) }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
