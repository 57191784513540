import { cn } from '@/lib/utils'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronRight, type LucideIcon } from 'lucide-react'
import React from 'react'
import useResizeObserver from 'use-resize-observer'

interface TreeDataItem {
  id: string
  title: string
  subtitle?: string
  icon?: LucideIcon
  children?: TreeDataItem[]
  type: 'chapter' | 'article' | 'section'
  parent: string
  content: string
}

type TreeProps = React.HTMLAttributes<HTMLDivElement> & {
  data: TreeDataItem[] | TreeDataItem
  initialSlelectedItemId?: string
  onSelectChange?: (item: TreeDataItem | undefined) => void
  expandAll?: boolean
  folderIcon?: LucideIcon
  itemIcon?: LucideIcon
  path: string[]
}

const Tree = React.forwardRef<HTMLDivElement, TreeProps>(
  (
    { data, path, initialSlelectedItemId, onSelectChange, expandAll, folderIcon, itemIcon, className, ...props },
    ref,
  ) => {
    const [selectedItemId, setSelectedItemId] = React.useState<string | undefined>(initialSlelectedItemId)
    const handleSelectChange = React.useCallback(
      (item: TreeDataItem | undefined) => {
        setSelectedItemId(item?.id)
        if (onSelectChange) {
          onSelectChange(item)
        }
      },
      [onSelectChange],
    )

    const { ref: refRoot } = useResizeObserver()

    return (
      <div ref={refRoot} className={cn('h-full w-fit', className)}>
        <div className="relative">
          <TreeItem
            data={data}
            ref={ref}
            selectedItemId={selectedItemId}
            handleSelectChange={handleSelectChange}
            FolderIcon={folderIcon}
            ItemIcon={itemIcon}
            path={path}
            {...props}
          />
        </div>
      </div>
    )
  },
)

type TreeItemProps = TreeProps & {
  selectedItemId?: string
  handleSelectChange: (item: TreeDataItem | undefined) => void
  FolderIcon?: LucideIcon
  ItemIcon?: LucideIcon
}

const TreeItem = React.forwardRef<HTMLDivElement, TreeItemProps>(
  ({ className, path, data, selectedItemId, handleSelectChange, FolderIcon, ItemIcon, ...props }, ref) => {
    return (
      <div ref={ref} role="tree" className={className} {...props}>
        <ul>
          {data instanceof Array ? (
            data.map((item) => (
              <li key={item.id} className={'max-w-full'}>
                {item.type !== 'article' ? (
                  <AccordionPrimitive.Root type="single" collapsible>
                    <AccordionPrimitive.Item value={item.id}>
                      <AccordionTrigger
                        className={cn(
                          'before:absolute before:left-0 before:-z-10 before:h-[1.75rem] before:w-full before:bg-muted/80 before:opacity-0 hover:before:opacity-100',
                          selectedItemId === item.id &&
                            'text-accent-foreground before:border-l-2 before:border-l-accent-foreground/50 before:bg-accent before:opacity-100 dark:before:border-0',
                        )}
                        onClick={() => {
                          handleSelectChange(item)
                        }}
                      >
                        {item.icon && (
                          <item.icon className="mr-2 size-4 shrink-0 text-accent-foreground/50" aria-hidden="true" />
                        )}
                        {!item.icon && FolderIcon && (
                          <FolderIcon className="mr-2 size-4 shrink-0 text-accent-foreground/50" aria-hidden="true" />
                        )}
                        <div className={'flex w-full items-start justify-between'}>
                          <span
                            className={cn('w-fit text-left text-sm', {
                              'font-semibold': path.includes(item.id),
                            })}
                          >
                            {item.title}
                          </span>
                          <p className={cn('flex w-2/3 gap-1 text-left text-sm')}>
                            <span className={`${path.includes(item.id) && 'font-semibold'}`}>-</span>
                            <span className={`${path.includes(item.id) && 'font-semibold'}`}>
                              {item.subtitle && ` ${item.subtitle}`}
                            </span>
                          </p>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="pl-6">
                        <TreeItem
                          data={item.children ? item.children : item}
                          selectedItemId={selectedItemId}
                          handleSelectChange={handleSelectChange}
                          FolderIcon={FolderIcon}
                          ItemIcon={ItemIcon}
                          path={path}
                        />
                      </AccordionContent>
                    </AccordionPrimitive.Item>
                  </AccordionPrimitive.Root>
                ) : (
                  <Leaf
                    item={item}
                    isSelected={path.includes(item.id)}
                    onClick={() => {
                      handleSelectChange(item)
                    }}
                    Icon={ItemIcon}
                  />
                )}
              </li>
            ))
          ) : (
            <li>
              <Leaf
                item={data}
                isSelected={selectedItemId === data.id}
                onClick={() => handleSelectChange(data)}
                Icon={ItemIcon}
              />
            </li>
          )}
        </ul>
      </div>
    )
  },
)

const Leaf = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    item: TreeDataItem
    isSelected?: boolean
    Icon?: LucideIcon
  }
>(({ className, item, isSelected, Icon, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'flex cursor-pointer items-center py-2 before:absolute before:left-0 before:right-1 before:-z-10 before:h-[1.75rem] before:w-full before:bg-muted/80 before:opacity-0 hover:before:opacity-100',
        className,
        isSelected &&
          'font-semibold text-accent-foreground before:border-l-2 before:border-l-accent-foreground/50 before:bg-accent before:opacity-100 dark:before:border-0',
      )}
      {...props}
    >
      {item.icon && <item.icon className="mr-2 h-4 w-4 shrink-0 text-accent-foreground/50" aria-hidden="true" />}
      {!item.icon && Icon && <Icon className="mr-2 h-4 w-4 shrink-0 text-accent-foreground/50" aria-hidden="true" />}
      <div className={'flex w-full items-start'}>
        <span
          className={cn('w-1/3 text-left text-sm', {
            'font-semibold': isSelected,
          })}
        >
          {item.title}
        </span>
        <p className={cn('flex w-2/3 gap-1 text-left text-sm')}>
          <span className={`${isSelected && 'font-semibold'}`}>-</span>
          <span className={`${isSelected && 'font-semibold'}`}>{item.subtitle && ` ${item.subtitle}`}</span>
        </p>
      </div>
    </div>
  )
})

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex w-full flex-1 items-center gap-2 py-2 transition-all last:[&[data-state=open]>svg]:rotate-90',
        className,
      )}
      {...props}
    >
      {children}
      <ChevronRight className="ml-auto h-4 w-4 shrink-0 text-accent-foreground/50 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className,
    )}
    {...props}
  >
    <div className="pb-1 pt-0">{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Tree, type TreeDataItem }
