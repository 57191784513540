import { updateUser } from '@/store/auth/useCases/updateUser'
import { useAppDispatch } from '@/store/hooks'
import { IndustryId } from '@/store/stepper/steppersSlice'
import { UpdateUserRequest } from '@/types/user'
import { IndustryItem } from '../registerSteps/industry/right/industryList/IndustryItem'
import { INDUSTRIES } from '../registerSteps/industry/right/use-industry-step.hook'
import { Button } from '../ui/button'
import { StepPageProps } from './ProfilInfoContainer'

function ActivityPage({ onPrevious, onNext, state, setState }: StepPageProps) {
  const selectIndustry = (industry: string) => {
    setState({ ...state, otherIndustry: '', industry: { id: industry as IndustryId } })
  }
  const dispatch = useAppDispatch()

  const nextDisabled = (state.industry.id === 'OTHER' && !state.otherIndustry) || !state.industry.id

  return (
    <div className="flex  flex-col items-center justify-center gap-9 ">
      <div className="flex flex-col items-center justify-center gap-7 ">
        <h1 className="text-4xl font-semibold">Votre domaine d’activité ?</h1>
        <div className="flex w-full flex-col gap-6">
          <div
            className={
              'flex max-h-[24rem] flex-wrap content-start items-start justify-center gap-[0.50rem] self-stretch overflow-auto md:gap-[0.75rem]'
            }
          >
            {INDUSTRIES.map((industry) => (
              <IndustryItem
                onClick={() => selectIndustry(industry.value)}
                checked={state.industry.id === industry.value}
                label={industry.label}
                key={industry.value}
                value={industry.value}
              >
                {industry.icon}
              </IndustryItem>
            ))}
            <div className={'flex-start flex flex-col gap-[0.25rem]'}>
              <p className={'w-[7.5rem] text-[0.875rem] leading-[1.25rem] text-[#6E7682]'}>Autre</p>
              <div
                className={
                  'flex flex-col items-start justify-center gap-[0.25rem] rounded-[0.5rem] border border-[#E4E4E4] px-[1rem] py-[0.5rem] md:w-[16.6875rem]'
                }
              >
                <input
                  onChange={(e) =>
                    setState({
                      ...state,
                      industry: { id: 'OTHER', otherValue: e.target.value },
                      otherIndustry: e.target.value,
                    })
                  }
                  type={'text'}
                  className={'flex shrink-0 grow basis-0 text-[1rem] leading-[1.5rem] text-primary outline-none'}
                  placeholder={'Tourisme...'}
                  value={state.otherIndustry}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <Button variant={'ghost'} className="w-1/4" type="submit" onClick={onPrevious}>
              Précédent
            </Button>
            <Button
              className="w-1/4"
              variant={nextDisabled ? 'disabled' : 'default'}
              disabled={nextDisabled}
              onClick={async () => {
                try {
                  await dispatch(
                    updateUser({
                      firstname: state.firstname,
                      lastname: state.lastname,
                      jobTitle: state.jobTitle,
                      industry: state.industry,
                    } as UpdateUserRequest),
                  ).unwrap()

                  onNext && onNext()
                } catch (error) {}
              }}
            >
              Suivant
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ActivityPage
