import { Group } from '@/components/NestedCheckboxList.tsx'

export interface ListSourceCollectionsResponseData {
  collections: { id: string; label: string }[]
  label: string
  idAsSourceCollection?: string
}

/**
 * Retrieves the selected source collections based on the user's connection status.
 * If the user is not connected, collections that require authentication, such as `_user-files-all`, are excluded.
 * @param groups - Array of groups containing source collections.
 * @param isUserConnected - Boolean indicating if the user is connected.
 * @returns Array of selected source collection IDs.
 * @example
 * const groups = [
 *   { idAsItem: 'group-1', items: [{ id: 'item-1' }, { id: 'item-2' }] },
 *   { idAsItem: 'group-2', items: [{ id: 'item-3' }, { id: 'item-4' }] }
 * ];
 * const isUserConnected = true;
 * const selectedIds = getSelectedSourceCollections(groups, isUserConnected);
 * console.log(selectedIds); // ['group-1', 'item-1', 'item-2', 'group-2', 'item-3', 'item-4']
 */
export const getSelectedSourceCollections = (groups: Group[], isUserConnected: boolean): string[] => {
  return groups.flatMap((group) => {
    if (!isUserConnected && group.idAsItem === '_user-files-all') {
      return []
    }
    return getGroupItemIds(group)
  })
}

/**
 * Helper function to get all item IDs from a group.
 * It returns the group's `idAsItem` followed by the individual item IDs.
 * @param group - Group containing items.
 * @returns Array of item IDs including the group-level `idAsItem` if present.
 * @example
 * const group = { idAsItem: 'group-1', items: [{ id: 'item-1' }, { id: 'item-2' }] };
 * const itemIds = getGroupItemIds(group);
 * console.log(itemIds); // ['group-1', 'item-1', 'item-2']
 */
const getGroupItemIds = (group: Group): string[] => {
  const groupIds = group.idAsItem ? [group.idAsItem] : []
  const itemIds = group.items.map((item) => item.id)
  return [...groupIds, ...itemIds]
}

/**
 * Maps source collections to groups, converting each source collection into a corresponding group.
 * The source collection's `idAsSourceCollection` is mapped to the group's `idAsItem`.
 * @param sourceCollections - Array of source collections to map.
 * @returns Array of mapped groups.
 * @example
 * const sourceCollections = [
 *   { label: 'Group 1', collections: [{ id: 'item-1', label: 'Item 1' }], idAsSourceCollection: 'group-1' }
 * ];
 * const groups = getGroups(sourceCollections);
 * console.log(groups); // [{ label: 'Group 1', items: [{ id: 'item-1', label: 'Item 1' }], idAsItem: 'group-1' }]
 */
export const getGroups = (sourceCollections: ListSourceCollectionsResponseData[]): Group[] => {
  return sourceCollections.map(mapSourceCollectionToGroup)
}

/**
 * Helper function to map a single source collection to a group.
 * The source collection's `idAsSourceCollection` is mapped to the group's `idAsItem`.
 * @param sourceCollection - Source collection to map.
 * @returns Mapped group with items and optional group-level ID.
 * @example
 * const sourceCollection = { label: 'Group 1', collections: [{ id: 'item-1', label: 'Item 1' }], idAsSourceCollection: 'group-1' };
 * const group = mapSourceCollectionToGroup(sourceCollection);
 * console.log(group); // { label: 'Group 1', items: [{ id: 'item-1', label: 'Item 1' }], idAsItem: 'group-1' }
 */
const mapSourceCollectionToGroup = (sourceCollection: ListSourceCollectionsResponseData): Group => {
  return {
    label: sourceCollection.label,
    items: sourceCollection.collections,
    idAsItem: sourceCollection.idAsSourceCollection,
  }
}

/**
 * Filters out redundant source collection IDs from the selected source collections.
 * If a group's `idAsItem` is selected, all the group's items are automatically included.
 * @param groups - Array of groups containing source collections.
 * @param selectedSourceCollections - Array of selected source collection IDs.
 * @returns Array of filtered source collection IDs where redundant IDs are removed.
 * @example
 * const groups = [
 *   { idAsItem: 'group-1', items: [{ id: 'item-1' }, { id: 'item-2' }] }
 * ];
 * const selectedSourceCollections = ['group-1', 'item-1', 'item-2'];
 * const filteredIds = filterRedundantSourceCollectionIds(groups, selectedSourceCollections);
 * console.log(filteredIds); // ['group-1']
 */
export const filterRedundantSourceCollectionIds = (groups: Group[], selectedSourceCollections: string[]): string[] => {
  const selectedSet = new Set(selectedSourceCollections)
  const idsToRemove = getIdsToRemove(groups, selectedSet)

  // Ensure that all group items are selected if the group's `idAsItem` is selected
  const ensureGroupItemsSelected = (group: Group, selectedSet: Set<string>) => {
    if (group.idAsItem && selectedSet.has(group.idAsItem)) {
      group.items.forEach((item) => selectedSet.add(item.id)) // Add all items in the group if `idAsItem` is selected
    }
  }

  // Update the selectedSet for each group
  groups.forEach((group) => ensureGroupItemsSelected(group, selectedSet))

  // Filter out redundant IDs and return the updated selected items
  return Array.from(selectedSet).filter((id) => !idsToRemove.has(id))
}

/**
 * Helper function to get IDs to remove based on redundancy.
 * If a group's `idAsItem` is selected, its individual items are not removed.
 * Items in groups without `idAsItem` are removed if not present in `selectedSet`.
 * @param groups - Array of groups containing source collections.
 * @param selectedSet - Set of selected source collection IDs.
 * @returns Set of IDs to remove.
 * @example
 * const groups = [
 *   { idAsItem: 'group-1', items: [{ id: 'item-1' }, { id: 'item-2' }] }
 * ];
 * const selectedSet = new Set(['group-1', 'item-1', 'item-2']);
 * const idsToRemove = getIdsToRemove(groups, selectedSet);
 * console.log(idsToRemove); // Set { 'item-1', 'item-2' }
 */
export const getIdsToRemove = (groups: Group[], selectedSet: Set<string>): Set<string> => {
  const idsToRemove = new Set<string>()

  groups.forEach((group) => {
    if (group.idAsItem && selectedSet.has(group.idAsItem)) {
      // Group's idAsItem is selected, so we keep its individual items
      group.items.forEach((item) => {
        idsToRemove.delete(item.id) // Ensure group items are not removed
      })
    } else {
      // For groups without idAsItem, only mark items for removal if they are not selected
      group.items.forEach((item) => {
        if (!selectedSet.has(item.id)) {
          idsToRemove.add(item.id) // Add items that are not selected
        }
      })
    }
  })
  return idsToRemove
}
