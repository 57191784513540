import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconResizeModal(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_2342_8676)">
        <path
          d="M2.86719 11.1496C2.92859 10.9885 3.02217 10.8559 3.19615 10.7995C3.51342 10.6963 3.82338 10.9244 3.82484 11.2658C3.82728 11.8783 3.82582 12.4908 3.82582 13.1029C3.82582 13.2601 3.82582 13.4168 3.82582 13.5996C3.87553 13.5538 3.90964 13.5244 3.94132 13.4925C5.16653 12.2801 6.39077 11.0666 7.61939 9.85756C7.69786 9.78039 7.8041 9.71335 7.90937 9.68442C8.11357 9.62847 8.31631 9.72927 8.41963 9.90579C8.52052 10.0784 8.50053 10.3032 8.36164 10.457C8.26612 10.5627 8.16085 10.6606 8.05899 10.7609C6.90737 11.901 5.75526 13.0406 4.60364 14.1808C4.57829 14.2058 4.55441 14.2319 4.50909 14.2787C4.57245 14.2787 4.61338 14.2787 4.65432 14.2787C5.39705 14.2787 6.13978 14.2777 6.88202 14.2796C7.11108 14.2801 7.29433 14.4233 7.34891 14.6336C7.40301 14.8425 7.3148 15.0542 7.12619 15.1612C7.08184 15.1863 7.03408 15.2051 6.98778 15.2268C5.7421 15.2268 4.49642 15.2268 3.25074 15.2268C3.05726 15.1656 2.93006 15.0387 2.86767 14.8478C2.86767 13.615 2.86767 12.3823 2.86767 11.1496H2.86719Z"
          fill="#FDFDFD"
        />
        <path
          d="M13.4899 4.03796C13.4231 4.03796 13.3812 4.03796 13.3393 4.03796C12.6004 4.03796 11.8621 4.03989 11.1233 4.037C10.7894 4.03555 10.5647 3.74136 10.653 3.43221C10.7129 3.22242 10.8991 3.09027 11.1418 3.09027C11.9683 3.08931 12.7949 3.09027 13.621 3.09027C13.9524 3.09027 14.2838 3.08931 14.6152 3.09027C14.9319 3.09124 15.1313 3.28704 15.1313 3.60053C15.1322 4.74258 15.1318 5.88415 15.1313 7.0262C15.1313 7.3257 14.9324 7.54032 14.6561 7.54273C14.3778 7.54514 14.1741 7.3257 14.1736 7.02138C14.1731 6.26274 14.1736 5.50459 14.1736 4.71606C14.1254 4.76091 14.0932 4.79033 14.062 4.82071C12.831 6.03848 11.5999 7.25673 10.3688 8.4745C10.1792 8.66211 9.93703 8.69925 9.73965 8.57482C9.49939 8.4229 9.44285 8.10411 9.61879 7.88177C9.65047 7.84174 9.68848 7.80606 9.72503 7.7694C10.9415 6.56514 12.1584 5.36087 13.3753 4.1566C13.408 4.12429 13.4392 4.09053 13.4894 4.03796H13.4899Z"
          fill="#FDFDFD"
        />
        <path
          d="M16.4078 17.6845H1.59219C0.713976 17.6845 0 16.9774 0 16.1088V2.20746C0 1.33887 0.714464 0.631836 1.59219 0.631836H16.4078C17.286 0.631836 18 1.33887 18 2.20746V16.1088C18 16.9779 17.2855 17.6845 16.4078 17.6845ZM1.59219 1.59641C1.25153 1.59641 0.974712 1.87034 0.974712 2.20746V16.1088C0.974712 16.446 1.25153 16.7199 1.59219 16.7199H16.4078C16.7485 16.7199 17.0253 16.446 17.0253 16.1088V2.20746C17.0253 1.87034 16.7485 1.59641 16.4078 1.59641H1.59219Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_2342_8676">
          <rect width="18" height="17.0526" fill="white" transform="translate(0 0.631836)" />
        </clipPath>
      </defs>
    </svg>
  )
}
