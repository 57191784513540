import { UploadFileInConversationError } from '@/types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { UploadFileInConversationPayload } from '@/types/file.ts'
import { ALLOWED_FILE_TYPES } from '@/utils/FileTypes.ts'

export const uploadFileInConversation = createAppAsyncThunk(
  'conversations/uploadFileInConversation',
  async ({ conversationId, file, fileName }: UploadFileInConversationPayload, { extra: { conversationGateway } }) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''
    if (!ALLOWED_FILE_TYPES.includes('.' + fileExtension)) {
      throw new Error('File type not allowed')
    }
    const response = await conversationGateway.uploadFileInConversation({ conversationId, file, fileName })
    if (!response.ok) {
      const error: UploadFileInConversationError = await response.json()
      return Promise.reject(error.message)
    }
    const responseBody = { conversationId, fileName, file }
    return Promise.resolve(responseBody)
  },
)
