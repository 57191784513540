import { ArrowLeft, ArrowRight } from 'lucide-react'
import NavigationButton from './NavigationButton'
import './index.css'
import { LegalResourceSourceLink } from '@/components/LegalResourceSourceLink.tsx'

interface Props {
  article?: { title: string; subtitle?: string; content: string }
  setNextArticle: () => void
  setPreviousArticle: () => void
  updateDate: string
  hasPrevious: boolean
  hasNext: boolean
  source: string | undefined
}

function TextContent({ article, setNextArticle, setPreviousArticle, hasNext, hasPrevious, updateDate, source }: Props) {
  return (
    article && (
      <div className="flex flex-col gap-10  overflow-y-auto py-2">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold text-[#6E7682]"></h1>
          <div className="flex items-center">
            <h1 className="text-sm text-[#6E7682]">Ajouter à mes favoris</h1>
            <p className="relative bottom-2 rounded-lg border border-[#5FC999] px-1 py-0 text-xs text-[#5FC999]">
              Bientôt disponible
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-center text-2xl">{article.title}</h1>
          <h2 className="text-center text-xl">{article.subtitle}</h2>
          <div id="text" dangerouslySetInnerHTML={{ __html: article.content }} />
        </div>
        <LegalResourceSourceLink
          sourceLink={source && source === 'eurlex' ? 'https://eur-lex.europa.eu/' : 'https://www.legifrance.gouv.fr/'}
          label={
            source && source === 'eurlex'
              ? 'Texte reproduit avec l’autorisation de la Commission européenne sous licence'
              : ' Texte reproduit avec l’autorisation de la Direction de l’information légale et administrative (DILA) '
          }
          updateDate={updateDate}
          sourceLinkLabel={source && source === 'eurlex' ? 'EUR-lex' : 'Légifrance'}
          creativeCommonLicenceLabel={source && source === 'eurlex' ? 'Creative Commons (by) 4.0 International' : ''}
        />
        <div className="flex justify-between">
          {hasPrevious ? (
            <NavigationButton label="Précédent" Icon={ArrowLeft} onClick={setPreviousArticle} color="#5FC999" />
          ) : (
            <div></div>
          )}
          {hasNext ? (
            <NavigationButton label="Suivant" inverseOrder Icon={ArrowRight} onClick={setNextArticle} color="#5FC999" />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    )
  )
}
export default TextContent
