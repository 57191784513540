import { createSlice } from '@reduxjs/toolkit'
import { ReactElement } from 'react'
import { RootState } from './configureStore.ts'

export type ToasterType = 'success' | 'generic' | 'identified'
export type ToasterPosition = 'top' | 'bottom'
export type Toaster = {
  toasterMessage: string | ReactElement
  toasterType: ToasterType
  toasterPosition?: ToasterPosition
}

interface InitialState {
  toaster: Toaster
}

const initialState: InitialState = {
  toaster: {
    toasterMessage: '',
    toasterType: 'success',
  },
}

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    setToasterMessage: (state, action) => {
      state.toaster = action.payload
    },
    cloaseToaster: (state) => {
      state.toaster = {
        toasterMessage: '',
        toasterType: 'success',
      }
    },
  },
})

export const { setToasterMessage, cloaseToaster } = toasterSlice.actions

export const selectToasterMessage = (state: RootState) => state.entities.toaster.toaster
