import { WarningModal } from '../WarningModal.tsx'
import { useWarningModalHook } from '@/components/modals/hooks/use-warningModal.hook.tsx'

export function WarningModalContainer() {
  const { isOpen, onDeleteTag, onOpenChange, subtitle } = useWarningModalHook()
  return (
    <WarningModal
      isOpen={isOpen}
      onDelete={onDeleteTag}
      onOpenChange={onOpenChange}
      title={'Confirmez-vous ?'}
      subtitle={subtitle}
    />
  )
}
