import { DocumentForm } from '@/components/forms/DocumentForm.tsx'
import { z } from 'zod'
import { DocumentModal } from '../DocumentModal'
import { useRenameDocumentModal } from '@/components/modals/hooks/use-renameDocumentModal.hook.tsx'

export const documentSchema = z.object({
  name: z.string().min(1, { message: 'Veuillez renseigner un nom' }),
})

export type DocumentSchema = z.infer<typeof documentSchema>

export function RenameDocumentModalContainer() {
  const { isOpen, handleCancel, handleSubmit, form } = useRenameDocumentModal()
  return (
    <DocumentModal handleCancel={handleCancel} isOpen={isOpen}>
      <DocumentForm form={form} handleCancel={handleCancel} handleSubmit={handleSubmit} buttonLabel={'Renommer'} />
    </DocumentModal>
  )
}
