import { DiscussionPanelInputContainer } from '@/components/DiscussionPanelInputContainer.tsx'
import { MessagesListContainer } from '@/components/MessagesListContainer.tsx'

export function DiscussionPanel() {
  return (
    <div
      className={
        'hidden h-full w-[31.43rem] flex-col items-start gap-5 rounded-2xl border border-solid border-brightGray bg-bright p-5 lg:flex'
      }
    >
      <div className={'h-4/5 w-full overflow-auto'}>
        <MessagesListContainer />
      </div>
      <DiscussionPanelInputContainer />
    </div>
  )
}
