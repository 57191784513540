import { PromptRecommendationList } from '../../components/promptTutorial/PromptRecommendationList.tsx'
import { PromptTutorialHeader } from '../../components/promptTutorial/PromptTutorialHeader.tsx'
import { PromptTutorialContentLayout } from '../../components/promptTutorial/PromptTutorialContentLayout.tsx'
import { PromptCommonMistakesSection } from '../../components/promptTutorial/PromptCommonMistakesSection.tsx'
import { PromptConclusionSection } from '../../components/promptTutorial/PromptConclusionSection.tsx'
import { FullHeightLayout } from './FullHeightLayout.tsx'

export function PromptTutorialPage() {
  return (
    <FullHeightLayout>
      <PromptTutorialContentLayout>
        <PromptTutorialHeader />
        <PromptRecommendationList />
        <PromptCommonMistakesSection />
        <PromptConclusionSection />
      </PromptTutorialContentLayout>
    </FullHeightLayout>
  )
}
