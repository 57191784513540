import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconChevronDown(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666789 0.845373C0.728163 0.597852 0.826139 0.376017 1.02394 0.211003C1.35558 -0.0657073 1.83696 -0.0715451 2.16935 0.2075C2.23368 0.261597 2.29394 0.321142 2.35236 0.382244C3.97323 2.08804 5.59373 3.79383 7.21423 5.50041C7.25083 5.53894 7.28225 5.58253 7.35583 5.67243C7.39428 5.60393 7.4124 5.54789 7.44826 5.51014C9.07948 3.78955 10.7133 2.0713 12.3449 0.351109C12.6325 0.0479345 12.9616 -0.0793288 13.3616 0.0506588C13.9558 0.244083 14.1972 1.0423 13.8175 1.56108C13.7753 1.61868 13.7291 1.67434 13.6803 1.72571C11.8021 3.70354 9.92393 5.68099 8.04499 7.65805C7.69376 8.02738 7.25748 8.10327 6.86151 7.8577C6.76575 7.79854 6.68071 7.71526 6.60159 7.63197C4.77109 5.70745 2.94281 3.7806 1.11083 1.85764C0.907849 1.64437 0.726684 1.42448 0.666789 1.11975V0.845373Z"
        fill="black"
      />
    </svg>
  )
}
