import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconNew(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="37" height="37" rx="7.5" fill="#212121" />
      <path d="M19 12V26" stroke="#FDFDFD" strokeLinecap="round" />
      <path d="M26 19H12" stroke="#FDFDFD" strokeLinecap="round" />
      <rect x="0.5" y="0.5" width="37" height="37" rx="7.5" stroke="#FDFDFD" />
    </svg>
  )
}
