import TheLoadStatusIcon from './TheLoadStatusIcon'
import { FileInfos } from '../types/file'
import { TheIconCross } from '@/components/icons/TheIconCross.tsx'
import { deleteFilesInConversation } from '@/store/conversations/useCases/deleteFilesInConversation.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { useParams } from 'react-router-dom'
import { Conversation } from '@/types/conversation.ts'

type Props = {
  fileInfos: FileInfos | undefined
}

export function TheAddedDocumentTag({ fileInfos }: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const { conversationId } = useParams() as { conversationId: Conversation['id'] }
  function handleDelete() {
    dispatch(deleteFilesInConversation(conversationId))
  }

  return (
    <div className="flex w-full min-w-[200px] basis-1/4 items-center gap-2 rounded-md bg-[rgba(193,193,193,0.15)] px-3 py-2 sm:w-40">
      <TheLoadStatusIcon status={fileInfos?.uploadStatus} />
      <span className={'block w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm'}>
        {fileInfos?.title}
      </span>
      <button onClick={handleDelete}>
        <TheIconCross width={12} height={12} className={'fill-silver hover:fill-primary'} />
      </button>
    </div>
  )
}
