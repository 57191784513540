import { ROUTE_PATHS } from '@/ROUTE_PATHS'
import { TreeDataItem } from '@/components/ui/tree'
import { useAppDispatch } from '@/store/hooks'
import { getTextBySlug } from '@/store/texts/useCases/getTextBySlug'
import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FullHeightLayout } from '../prompt-tutorial/FullHeightLayout'
import TextContent from './TextContent'
import TextTreeView from './TextTreeView'
import { useArticlesHook } from './use-articles-hook'
import { LegalResource } from '@/types/legalResource.ts'

function LibraryDetailPage() {
  const navigate = useNavigate()
  const { slug } = useParams()
  const [text, setText] = useState<LegalResource>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getTextBySlug(slug!)).unwrap()
      setText(response)
    }
    fetchData()
  }, [dispatch, slug, setText])

  const { path, setNextArticle, setPreviousArticle, currentArticle, setCurrentArticle, hasPrevious, hasNext } =
    useArticlesHook(text!)

  return (
    <FullHeightLayout className="lg:w-100 bg-secondary lg:pt-0">
      {text ? (
        <div className="flex flex-col gap-4 py-8 pl-6 pr-7">
          <div
            className="flex cursor-pointer gap-3"
            onClick={() => {
              navigate(ROUTE_PATHS.library)
            }}
          >
            <ArrowLeft />
            <h1 className="text-base">Bibliothèque</h1>
          </div>
          <div className="flex h-screen flex-col gap-9 lg:flex-row">
            <div className={'lg:w-1/3'}>
              <TextTreeView
                currentArticleTitle={currentArticle?.title}
                recitals={text.recitals as unknown as TreeDataItem}
                content={text.content as unknown as TreeDataItem}
                title={text.title}
                setCurrentArticle={setCurrentArticle}
                ids={path}
              />
            </div>
            <div className="flex flex-1 flex-col gap-10 rounded-2xl bg-white p-8">
              <TextContent
                article={currentArticle as unknown as TreeDataItem}
                setNextArticle={setNextArticle}
                setPreviousArticle={setPreviousArticle}
                updateDate={text.createdAt}
                hasPrevious={hasPrevious}
                hasNext={hasNext}
                source={text.source}
              />
            </div>
          </div>
        </div>
      ) : (
        <p>Chargement ...</p>
      )}
    </FullHeightLayout>
  )
}

export default LibraryDetailPage
