import { KeyboardEvent } from 'react'

type Props = {
  handleClick: () => void
  handleKeyPress: (e: KeyboardEvent<HTMLSpanElement>) => void
}

export function RedirectionLink({ handleClick, handleKeyPress }: Readonly<Props>) {
  return (
    <div className={'text-sm leading-6 text-label'}>
      Si la redirection automatique ne fonctionne pas, cliquez sur{' '}
      <button
        onClick={handleClick}
        onKeyUp={handleKeyPress}
        className={'text-sm font-bold text-primary hover:cursor-pointer hover:opacity-70'}
      >
        ce lien
      </button>{' '}
      🔗.
    </div>
  )
}
