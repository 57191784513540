import React from 'react'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  handleCancel: () => void
}

export function DocumentModal({ children, isOpen, handleCancel }: Readonly<Props>) {
  return (
    <Dialog open={isOpen} onOpenChange={handleCancel}>
      <DialogContent className={'w-[15rem]'}>{children}</DialogContent>
    </Dialog>
  )
}
