import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheQuestionBubbleIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { color = '#FDFDFD', ...rest } = props
  return (
    <svg {...rest} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33333 7.54809C8.48016 7.13069 8.76998 6.77873 9.15144 6.55454C9.53291 6.33035 9.98141 6.24839 10.4175 6.3232C10.8536 6.398 11.2492 6.62473 11.5341 6.96323C11.8191 7.30173 11.975 7.73015 11.9744 8.17262C11.9744 9.42169 10.1008 10.0462 10.1008 10.0462M10.1249 12.5462H10.1333M8.25 16.4629L9.46667 18.0851C9.6476 18.3264 9.73807 18.447 9.84897 18.4901C9.94611 18.5279 10.0539 18.5279 10.151 18.4901C10.2619 18.447 10.3524 18.3264 10.5333 18.0851L11.75 16.4629C11.9943 16.1372 12.1164 15.9743 12.2654 15.85C12.4641 15.6842 12.6986 15.5669 12.9504 15.5075C13.1393 15.4629 13.3429 15.4629 13.75 15.4629C14.9149 15.4629 15.4973 15.4629 15.9567 15.2726C16.5693 15.0189 17.056 14.5322 17.3097 13.9196C17.5 13.4602 17.5 12.8777 17.5 11.7129V6.96289C17.5 5.56276 17.5 4.86269 17.2275 4.32791C16.9878 3.85751 16.6054 3.47506 16.135 3.23537C15.6002 2.96289 14.9001 2.96289 13.5 2.96289H6.5C5.09987 2.96289 4.3998 2.96289 3.86502 3.23537C3.39462 3.47506 3.01217 3.85751 2.77248 4.32791C2.5 4.86269 2.5 5.56276 2.5 6.96289V11.7129C2.5 12.8777 2.5 13.4602 2.6903 13.9196C2.94404 14.5322 3.43072 15.0189 4.04329 15.2726C4.50272 15.4629 5.08515 15.4629 6.25 15.4629C6.65715 15.4629 6.86072 15.4629 7.04959 15.5075C7.30141 15.5669 7.53593 15.6842 7.73458 15.85C7.88357 15.9743 8.00571 16.1372 8.25 16.4629Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
