import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconArrowUpAndDown(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3528 11.3051C11.2545 11.2146 11.1963 11.1641 11.1414 11.1098C10.6389 10.6086 10.1383 10.1048 9.63384 9.6055C9.2775 9.25303 8.81444 9.23104 8.49237 9.54471C8.16383 9.86483 8.18194 10.3408 8.54346 10.7043C9.53425 11.6983 10.527 12.691 11.521 13.6818C11.9103 14.0698 12.3449 14.0744 12.7317 13.6896C13.727 12.6994 14.719 11.7067 15.7098 10.712C16.072 10.3486 16.0959 9.8726 15.7758 9.55311C15.4505 9.22781 14.9836 9.25045 14.6175 9.61391C14.1137 10.1145 13.6131 10.6176 13.1106 11.1195C13.057 11.1732 12.9981 11.2223 12.9011 11.3109V10.9998C12.9011 7.67631 12.9011 4.35214 12.9011 1.02862C12.9011 0.923849 12.9037 0.818433 12.894 0.71431C12.8487 0.248667 12.3773 -0.0701689 11.9278 0.0585296C11.5469 0.167826 11.3528 0.458206 11.3528 0.930963C11.3522 4.27906 11.3528 7.62716 11.3528 10.9746V11.3051ZM3.04112 2.72175C3.06052 2.7308 3.07992 2.73921 3.09932 2.74826V3.0076C3.09932 6.33112 3.09932 9.65465 3.09932 12.9788C3.09932 13.0758 3.09674 13.1728 3.10514 13.2692C3.14524 13.7581 3.63611 14.0828 4.10304 13.9314C4.4665 13.8137 4.64823 13.5266 4.64823 13.061C4.64823 9.71286 4.64823 6.3654 4.64823 3.0173V2.6907C4.75106 2.78642 4.80927 2.83816 4.86489 2.89313C5.37321 3.40016 5.88089 3.90784 6.38792 4.41617C6.60328 4.63218 6.8568 4.72724 7.15818 4.64899C7.76545 4.49119 7.93489 3.77785 7.46925 3.30833C6.48623 2.31754 5.49738 1.33323 4.50983 0.346969C4.07911 -0.0837501 3.66262 -0.0831034 3.22996 0.34891C2.25405 1.32353 1.27879 2.29943 0.303527 3.27534C0.258256 3.32126 0.211045 3.36589 0.170948 3.41568C-0.193805 3.87033 0.0564775 4.55521 0.62883 4.66192C0.92309 4.7169 1.16432 4.60825 1.37127 4.4C1.92616 3.83929 2.48429 3.28117 3.04112 2.72175Z"
        fill="white"
      />
      <path
        d="M11.3528 11.3051V10.9746C11.3528 7.6265 11.3528 4.2784 11.3528 0.930942C11.3528 0.458832 11.5468 0.167805 11.9277 0.0585086C12.3765 -0.0701899 12.8486 0.248646 12.8939 0.714289C12.9042 0.818412 12.901 0.923828 12.901 1.0286C12.901 4.35212 12.901 7.67629 12.901 10.9998V11.3109C12.9987 11.2223 13.0569 11.1731 13.1106 11.1195C13.6131 10.6182 14.1136 10.1145 14.6174 9.61388C14.9835 9.25043 15.4504 9.22714 15.7757 9.55309C16.0952 9.87257 16.0719 10.3486 15.7097 10.712C14.719 11.7067 13.7269 12.6994 12.7316 13.6896C12.3448 14.0744 11.9102 14.0698 11.5209 13.6818C10.5269 12.691 9.53416 11.6983 8.54338 10.7043C8.18121 10.3415 8.16375 9.86481 8.49228 9.54468C8.81371 9.23102 9.27676 9.25237 9.63376 9.60548C10.1382 10.1047 10.6388 10.6085 11.1413 11.1098C11.1956 11.1641 11.2545 11.2145 11.3528 11.3051Z"
        fill="black"
      />
      <path
        d="M3.04112 2.72175C2.48429 3.28117 1.92681 3.83929 1.37127 4.4C1.16497 4.6089 0.92309 4.7169 0.62883 4.66193C0.0564775 4.55522 -0.193805 3.87098 0.170948 3.41569C0.211045 3.36524 0.258256 3.32062 0.303527 3.27535C1.27879 2.29944 2.25341 1.32353 3.22996 0.348913C3.66197 -0.0824534 4.07847 -0.0831002 4.50983 0.346973C5.49738 1.33323 6.48623 2.31755 7.46925 3.30833C7.93489 3.77721 7.76545 4.49119 7.15818 4.64899C6.8568 4.72725 6.60328 4.63218 6.38792 4.41617C5.88089 3.90785 5.37321 3.40017 4.86489 2.89313C4.80991 2.83816 4.75106 2.78642 4.64823 2.69071V3.0173C4.64823 6.3654 4.64823 9.71286 4.64823 13.061C4.64823 13.5266 4.46586 13.8137 4.10304 13.9315C3.63675 14.0828 3.14524 13.7581 3.10514 13.2692C3.09738 13.1728 3.09932 13.0758 3.09932 12.9788C3.09932 9.6553 3.09932 6.33177 3.09932 3.0076V2.74827C3.07992 2.73921 3.06052 2.7308 3.04112 2.72175Z"
        fill="black"
      />
    </svg>
  )
}
