import { LegalWatchArticlePreview } from '@/components/ds/LegalWatchArticlePreview.tsx'
import { useLegalWatchArticlePreview } from '@/components/legalWatch/use-legalWatchArticlePreview.tsx'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

interface Props {
  article: LegalWatchArticle
}
export function LegalWatchArticlePreviewContainer({ article }: Readonly<Props>) {
  const { readArticle, toggleFavorite } = useLegalWatchArticlePreview()
  const { isUserConnected } = useCurrentUserHook()

  return (
    <LegalWatchArticlePreview
      isUserConnected={isUserConnected}
      onToggleFavorite={toggleFavorite}
      onReadArticle={readArticle}
      {...article}
    />
  )
}
