import { FormEvent, KeyboardEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectConversationIds, selectConversationsTotal } from '../../store/conversations/conversationsSlice.ts'
import { createConversation } from '../../store/conversations/useCases/createConversation.ts'
import { deleteConversationById } from '../../store/conversations/useCases/deleteConversationById.ts'
import { updateConversation } from '../../store/conversations/useCases/updateConversation.ts'
import { useAppDispatch } from '../../store/hooks.ts'
import { Conversation } from '../../types/conversation.ts'
import BaseInput from '../atomics/BaseInput.tsx'
import { TheIconBin } from '../icons/TheIconBin.tsx'
import { TheIconPen } from '../icons/TheIconPen.tsx'

type Props = {
  conversation: Conversation
  isCurrent: boolean
}

/**
 * TheSidebarConversationListItem component displays a single conversation item in the sidebar.
 *
 * @param {Object} props - The component props.
 * @param {Conversation} props.conversation - The conversation object to display.
 */
export function TheSidebarConversationsListItem({ conversation, isCurrent }: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const [shouldUpdateName, setShouldUpdateName] = useState(false)
  const [inputValue, setInputValue] = useState(conversation.name)
  const navigate = useNavigate()
  const { conversationId } = useParams()

  function handleRename() {
    setInputValue(conversation.name)
    setShouldUpdateName(true)
  }

  /**
   * Handles input changes in the name editing mode.
   *
   * @param {FormEvent} e - The form event object.
   */
  function handleInput(e: FormEvent) {
    const inputElement = e.target as HTMLInputElement
    setInputValue(inputElement.value)
  }

  /**
   * Handles keydown events in the name editing mode.
   *
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  async function handleKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'Escape':
        setShouldUpdateName(false)
        break
      case 'Enter':
        await updateConversationName()
        break
      default:
        break
    }
  }

  /**
   * Updates the conversation name using the inputValue and exits name editing mode.
   */
  async function updateConversationName() {
    await dispatch(updateConversation({ ...conversation, name: inputValue }))
    setShouldUpdateName(false)
  }

  /**
   * Deletes the current conversation and exits name editing mode.
   */
  const conversationsTotal = useSelector(selectConversationsTotal)
  const conversationIds = useSelector(selectConversationIds)

  async function deleteTchat() {
    await dispatch(deleteConversationById(conversation.id))
    if (conversationsTotal === 1) {
      // If there is no more conversation, we create a use Cases one
      const newConversation = await dispatch(createConversation({ type: 'MESSAGE_ONLY' })).unwrap()
      if (conversationId) navigate(`/conversations/${newConversation.id}`)
    } else if (conversationId === conversation.id) {
      navigate(`/conversations/${conversationIds[1]}`)
    }
    setShouldUpdateName(false)
  }

  /**
   * Determines the CSS class for the current conversation item.
   *
   * @returns {string} The CSS class name for the current conversation item.
   */
  function isCurrentClasses(): string {
    return isCurrent ? 'bg-label' : ''
  }

  const handleBlur = async () => {
    if (inputValue) {
      await updateConversationName()
    }
    setShouldUpdateName(false)
  }

  const setHoverStyle = () => {
    return !shouldUpdateName && 'hover:cursor-pointer hover:fill-bright'
  }

  function handleChangeConversation() {
    navigate(`/conversations/${conversation.id}`)
  }

  return (
    <>
      {shouldUpdateName ? (
        // Name editing mode UI
        <div className={`mb-2 flex h-9 items-center justify-between gap-3 rounded-lg bg-payneGrey px-2 py-2 `}>
          <BaseInput
            autoFocus={true}
            onBlur={handleBlur}
            padding={'small'}
            onKeyDown={handleKeyDown}
            type="text"
            onInput={handleInput}
            value={inputValue}
          />
          <TheIconBin onClick={deleteTchat} className={`h-4 ${setHoverStyle} w-4 fill-none`} />
        </div>
      ) : (
        // Regular conversation item UI
        <button
          onKeyUp={handleChangeConversation}
          onClick={handleChangeConversation}
          data-testid="conversation-list-item"
          className={`group mb-2 flex h-9 w-full items-center justify-between gap-3
             rounded-lg py-2 pl-3.5 pr-2 hover:cursor-pointer hover:bg-payneGrey ${isCurrentClasses()}`}
        >
          <span className="text-overflow-ellipses h-full min-w-[70%] overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm text-bright">
            {conversation.name}
          </span>
          <div className={`${!isCurrent ? 'hidden' : ''} flex h-full items-center gap-1`}>
            <TheIconPen className="h-4 w-4" onClick={handleRename} />
            <TheIconBin onClick={deleteTchat} className="h-4 w-4" data-testid="delete-tchat" />
          </div>
        </button>
      )}
    </>
  )
}
