type useJobTitleItemHookProps = { size: 'large' | 'default'; checked: boolean; disabled: boolean }

export function useJobTitleItemHook(props: useJobTitleItemHookProps) {
  const width: Record<useJobTitleItemHookProps['size'], string> = {
    large: 'w-full',
    default: 'w-[16rem]',
  }
  const flex: Record<useJobTitleItemHookProps['size'], string> = {
    large: 'flex',
    default: 'inline-flex',
  }
  const style = {
    default: {
      border: { color: 'border-brightGray' },
      background: { color: 'bg-bright' },
      text: { color: { title: 'text-primary', subtitle: 'text-label' } },
    },
    hover: {
      border: { color: 'border-mistyAzure' },
      background: { color: 'bg-bright' },
      text: { color: { title: 'text-primary', subtitle: 'text-label' } },
    },
    disabled: {
      border: { color: 'border-silver' },
      background: { color: 'bg-bInput' },
      text: { color: { title: 'text-silver', subtitle: 'text-silver' } },
    },
    checked: {
      border: { color: 'border-brightGray hover:border-label' },
      background: { color: 'bg-brightGray' },
      text: { color: { title: 'text-primary', subtitle: 'text-label' } },
    },
  }

  function getBorderColor() {
    if (props.checked) return style.checked.border.color
    if (props.disabled) return style.disabled.border.color
    return `hover:${style.hover.border.color} ${style.default.border.color}`
  }

  function getBackgroundColor() {
    if (props.checked) return style.checked.background.color
    if (props.disabled) return style.disabled.background.color
    return `hover:${style.hover.background.color} ${style.default.background.color}`
  }

  function getTitleColor() {
    if (props.checked) return style.checked.text.color.title
    if (props.disabled) return style.disabled.text.color.title
    return `hover:${style.hover.text.color.title} ${style.default.text.color.title}`
  }

  function getSubtitleColor() {
    if (props.checked) return style.checked.text.color.subtitle
    if (props.disabled) return style.disabled.text.color.subtitle
    return `hover:${style.hover.text.color.subtitle} ${style.default.text.color.subtitle}`
  }

  function getFlex() {
    return flex[props.size]
  }

  function getWidth() {
    return width[props.size]
  }

  return {
    borderColor: getBorderColor(),
    backgroundColor: getBackgroundColor(),
    titleColor: getTitleColor(),
    subtitleColor: getSubtitleColor(),
    flex: getFlex(),
    width: getWidth(),
  }
}
