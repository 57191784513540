import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectDisplayTagModal, selectTagToUpdateOrDelete, setDisplayTagModal } from '@/store/tags/tagSlice.ts'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { updateTag } from '@/store/tags/useCases/updateTag.ts'
import { documentSchema, DocumentSchema } from '@/components/modals/containers/RenameDocumentModalContainer.tsx'
import { useEffect } from 'react'

export function useRenameDocumentModal() {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectDisplayTagModal)
  const tagToUpdate = useAppSelector(selectTagToUpdateOrDelete)

  const { ...form } = useForm<DocumentSchema>({
    resolver: zodResolver(documentSchema),
    defaultValues: {
      name: tagToUpdate?.name ?? '',
    },
    mode: 'onChange',
  })

  // Update form values when `tagToUpdate` changes
  useEffect(() => {
    if (tagToUpdate && isOpen) {
      form.reset({
        name: tagToUpdate.name,
      })
    }
  }, [tagToUpdate, isOpen, form.reset]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    dispatch(setDisplayTagModal())
    setTimeout(() => {
      form.reset()
    }, 1000)
  }

  const handleSubmit = (values: DocumentSchema) => {
    const name = values.name
    if (name.length === 0) return
    if (name.length === 0 || !tagToUpdate) return
    dispatch(updateTag({ tagId: tagToUpdate.id, fileTagName: name }))
    dispatch(setDisplayTagModal())
    setTimeout(() => {
      form.reset()
    }, 1000)
  }
  return { isOpen, handleCancel, handleSubmit, form }
}
