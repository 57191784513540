import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '../ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { StepPageProps } from './ProfilInfoContainer'

const nameSchema = z.object({
  lastname: z.string().min(1, { message: 'Veuillez renseigner votre nom' }),
  firstname: z.string().min(1, { message: 'Veuillez renseigner votre prénom' }),
})

function NamePage({ onNext, state, setState }: StepPageProps) {
  const form = useForm<z.infer<typeof nameSchema>>({
    resolver: zodResolver(nameSchema),
    defaultValues: {
      lastname: state.lastname,
      firstname: state.firstname,
    },
    mode: 'onChange',
  })

  async function onSubmit(values: z.infer<typeof nameSchema>) {
    const { lastname, firstname } = values
    setState({ ...state, lastname, firstname })
    onNext && onNext()
  }

  return (
    <div className="flex  flex-col items-center justify-center gap-9 ">
      <div className="flex flex-col items-center justify-center gap-7 ">
        <h1 className="text-4xl font-semibold">Quel est votre nom ?</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col gap-6">
            <div className="flex flex-col gap-6">
              <FormField
                control={form.control}
                name="firstname"
                render={({ field }) => (
                  <FormItem>
                    <Label>Votre prénom</Label>
                    <FormControl>
                      <Input placeholder="Indiquez votre prénom" {...field} />
                    </FormControl>
                    <FormMessage className="text-[#CB0505]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastname"
                render={({ field }) => (
                  <FormItem>
                    <Label>Votre nom</Label>
                    <FormControl>
                      <Input placeholder="Indiquez votre nom" {...field} />
                    </FormControl>
                    <FormMessage className="text-[#CB0505]" />
                  </FormItem>
                )}
              />
            </div>
            <Button className="mx-auto w-1/4" type="submit">
              Suivant
            </Button>
          </form>
        </Form>
      </div>
    </div>
  )
}
export default NamePage
