import { RegionPickerFilter } from './ds/RegionPickerFilter'
import { useRegionPickerFilterHook } from '@/components/hooks/use-RegionPickerFilter.hook.tsx'

export function RegionPickerFilterContainer() {
  const {
    regions,
    onSelectedRegions,
    onUnSelectedRegions,
    selectedRegions,
    isOpen,
    onChangeInput,
    onOpenChange,
    inputValue,
    onResetInput,
  } = useRegionPickerFilterHook()
  return (
    <RegionPickerFilter
      onChangeInput={onChangeInput}
      inputValue={inputValue}
      onResetInput={onResetInput}
      regions={regions}
      onUnSelect={onUnSelectedRegions}
      onSelect={onSelectedRegions}
      selectedRegions={selectedRegions}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    />
  )
}
