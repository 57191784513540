import { sendPasswordResetEmail } from '../../store/auth/useCases/sendPasswordResetEmail.ts'
import { EmailActionLayout } from '../sharedLayouts/EmailActionLayout.tsx'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'

export function ResetPasswordInfo() {
  const title = 'Consultez vos emails !'
  const initialMessage =
    "📬 Nous avons envoyé un email à l'adresse que vous avez fournie, avec les instructions de réinitialisation."
  const successMessage = 'Email renvoyé ✅'
  const actionFunction = sendPasswordResetEmail
  const redirectTo = ROUTE_PATHS.resetPassword

  return (
    <EmailActionLayout
      title={title}
      initialMessage={initialMessage}
      successMessage={successMessage}
      actionFunction={actionFunction}
      redirectTo={redirectTo}
    />
  )
}
