import { ReactNode } from 'react'

export type Props = {
  label: string
  onKeyDown: (e: { key: string; shiftKey: boolean }) => void
  children: ReactNode
}
export function DocumentsFolderGenerator(props: Readonly<Props>) {
  return (
    <div className={'flex items-center gap-3'}>
      <span className={'text-xl font-semibold text-primary'}>{props.label}</span>
      <button onKeyDown={props.onKeyDown}>{props.children}</button>
    </div>
  )
}
