import { selectShowAddTagModal, toggleAddTagsModal } from '@/store/tags/tagSlice.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { createTag } from '@/store/tags/useCases/createTag.ts'

const addDocumentSchema = z.object({
  name: z.string().min(1, { message: 'Veuillez renseigner un nom' }),
})

export type AddDocumentSchema = z.infer<typeof addDocumentSchema>

export const useCreateDocumentModal = () => {
  const dispatch = useAppDispatch()
  const isOpen: boolean = useAppSelector(selectShowAddTagModal)

  const { ...form } = useForm<AddDocumentSchema>({
    resolver: zodResolver(addDocumentSchema),
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
  })

  const handleCancel = () => {
    dispatch(toggleAddTagsModal())
    setTimeout(() => {
      form.reset()
    }, 1000)
  }

  const handleSubmit = (values: AddDocumentSchema) => {
    const name = values.name
    if (name.length === 0) return
    dispatch(createTag(name))
    dispatch(toggleAddTagsModal())
    setTimeout(() => {
      form.reset()
    }, 1000)
  }

  return { handleSubmit, handleCancel, isOpen, form }
}
