import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconPlus({ color, ...rest }: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const defaultColor = color ?? '#FDFDFD'
  return (
    <svg {...rest} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 7L1 7" stroke={defaultColor} strokeWidth="1.16667" strokeLinecap="round" />
      <path d="M7 1V13" stroke={defaultColor} strokeWidth="1.16667" strokeLinecap="round" />
    </svg>
  )
}
