import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function InfoIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <g clipPath="url(#clip0_4968_7995)">
        <path
          d="M13.0005 0.727539C6.22197 0.727539 0.727417 6.22209 0.727417 13.0006C0.727417 19.7791 6.22197 25.2736 13.0005 25.2736C19.779 25.2736 25.2735 19.7791 25.2735 13.0006C25.2735 6.22209 19.7783 0.727539 13.0005 0.727539ZM13.0005 23.3909C7.26203 23.3909 2.60947 18.739 2.60947 12.9999C2.60947 7.26151 7.26139 2.60895 13.0005 2.60895C18.7389 2.60895 23.3915 7.26086 23.3915 12.9999C23.3915 18.739 18.7389 23.3909 13.0005 23.3909Z"
          fill="#6E7682"
        />
        <path
          d="M13.9663 14.9109C13.9663 15.9407 13.9702 16.9704 13.965 17.9995C13.9624 18.468 13.6618 18.8383 13.2185 18.9467C12.8043 19.0473 12.3501 18.8531 12.1584 18.4647C12.0862 18.3189 12.0404 18.1434 12.0397 17.9808C12.032 15.9213 12.0326 13.8625 12.0365 11.803C12.0378 11.2443 12.4759 10.8094 13.0101 10.8165C13.5405 10.8236 13.9618 11.2475 13.965 11.7972C13.9708 12.8353 13.9663 13.8735 13.9663 14.9109Z"
          fill="#6E7682"
        />
        <path
          d="M11.6967 8.3236C11.6999 7.60355 12.2832 7.02868 13.0077 7.03126C13.7297 7.03384 14.3072 7.61646 14.3039 8.33844C14.3007 9.05332 13.7071 9.64175 12.9929 9.63723C12.2786 9.63207 11.6934 9.03913 11.6967 8.3236Z"
          fill="#6E7682"
        />
      </g>
      <defs>
        <clipPath id="clip0_4968_7995">
          <rect width="24.5455" height="24.5455" fill="white" transform="translate(0.727417 0.727539)" />
        </clipPath>
      </defs>
    </svg>
  )
}
