import { ListSourceCollectionsResponse, ListSourceCollectionsResponseData } from '../types/sourceCollection.ts'
import { SourceCollectionGateway } from './interface/sourceCollection.gateway.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'

export class WretchSourceCollectionGateway implements SourceCollectionGateway {
  private readonly endpoint = '/source-collections'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async listSourceCollections(): Promise<ListSourceCollectionsResponseData> {
    const url = this.endpoint
    const { data } = await this.olympeGptApi.url(url).get().json<ListSourceCollectionsResponse>()
    return data
  }
}
