import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function ClockIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { color = '#FDFDFD', ...rest } = props
  return (
    <svg {...rest} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5587_7191)">
        <path
          d="M12.0832 16.7966L13.7498 18.4632L17.4998 14.7132M18.3208 11.4214C18.329 11.2697 18.3332 11.117 18.3332 10.9632C18.3332 6.36084 14.6022 2.62988 9.99984 2.62988C5.39746 2.62988 1.6665 6.36084 1.6665 10.9632C1.6665 15.4927 5.28026 19.1782 9.78191 19.2938M9.99984 5.96322V10.9632L13.1151 12.5209"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5587_7191">
          <rect width="20" height="20" fill="white" transform="translate(0 0.962891)" />
        </clipPath>
      </defs>
    </svg>
  )
}
