import { TheDocumentsFolder } from '@/components/TheDocumentsFolder.tsx'
import { setActiveTag } from '@/store/tags/tagSlice.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { useDocumentsHook } from '@/pages/documents/use.documents.hook.tsx'
import { TagWithFileCountAndActiveStatus } from '@/types/tag'
import { useDocumentsFolderHook } from '@/components/hooks/use-DocumentsFolder.hook.tsx'

interface Props {
  tag: TagWithFileCountAndActiveStatus
}
export function TheDocumentsFolderContainer({ tag }: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const { setSelectedRows } = useDocumentsHook()
  const { activeTagContainerStyle, getFilesLength, getDocumentLabel, onRename, onDelete } = useDocumentsFolderHook({
    tag,
  })

  return (
    <TheDocumentsFolder
      onRename={(e, tag) => {
        onRename(tag)
        e.stopPropagation()
      }}
      onDelete={(e, tag) => {
        onDelete(tag)
        e.stopPropagation()
      }}
      onClick={() => {
        if (tag.isTagActive) return
        dispatch(setActiveTag(tag))
        setSelectedRows([])
      }}
      tag={tag}
      key={tag.id}
      activeTagContainerStyle={activeTagContainerStyle()}
      documentLabel={getDocumentLabel()}
      filesLength={getFilesLength()}
    />
  )
}
