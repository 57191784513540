import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { transformISOToDDMMYYYY } from '@/utils/date.ts'

export const getArticle = createAppAsyncThunk<LegalWatchArticle, LegalWatchArticle['id']>(
  'articles/getArticle',
  async (articleId: LegalWatchArticle['id'], { extra: { legalWatchArticleGateway } }) => {
    try {
      const { createdAt, ...article } = await legalWatchArticleGateway.getLegalWatchArticleById(articleId)
      return { ...article, createdAt: transformISOToDDMMYYYY(createdAt) }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
