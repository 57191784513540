import { useAppSelector } from '@/store/hooks.ts'
import { selectNewArticles } from '@/store/articles/articlesSlice.ts'

export const useArticleNotifications = () => {
  const newArticles = useAppSelector(selectNewArticles)

  return {
    totalNewArticlesLength: newArticles.length,
    displayNotifications: newArticles.length > 0,
  }
}
