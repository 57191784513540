function LeftScreenSection() {
  return (
    <div className={'flex h-full w-4/5 flex-col gap-9'}>
      <div className={'flex flex-col gap-4'}>
        <p className={'text-2xl font-normal text-label'}>Simplifiez votre quotidien grâce à Olympe</p>
        <p className={'text-sm font-normal'}>
          Trouvez rapidement les réponses dont vous avez besoin.
          <br />
          Prenez des décisions éclairées en un temps record.
        </p>
      </div>
      <div className={'relative h-3/4'}>
        <img
          src={'/assets/images/connexion-screenshot.png'}
          alt={'placeholder'}
          className={'h-auto w-auto max-w-full self-stretch rounded-xl border border-iceberg'}
        />
      </div>
    </div>
  )
}
export default LeftScreenSection
