import { Conversation } from '../../../types/conversation'
import { createAppAsyncThunk } from '../../createAppThunk'

export const createConversationSnapshotByConversationId = createAppAsyncThunk(
  'conversationSnapshot/createConversationSnapshotByConversationId',
  async (conversationId: Conversation['id'], { extra: { conversationSnaphotGateway } }) => {
    try {
      return await conversationSnaphotGateway.createConversationSnapshot(conversationId)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
