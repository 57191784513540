import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { FileData } from '../../../types/file.ts'
import { Tag } from '../../../types/tag.ts'

export const applyTags = createAppAsyncThunk(
  'tags/applyTags',
  async (
    { fileId, tagsToAdd, tagsToRemove }: { fileId: FileData['id']; tagsToAdd: Tag['id'][]; tagsToRemove: Tag['id'][] },
    { extra: { tagGateway } },
  ) => {
    try {
      return tagGateway.applyTags(fileId, tagsToAdd, tagsToRemove)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
