import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useState } from 'react'
import {
  selectLegalWatchTags,
  addTagToFilter,
  removeTagToFilter,
  selectTagsFilter,
} from '@/store/articles/articlesSlice.ts'

export type TagFilter = {
  value: string
}

export const useTagPickerFilter = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const dispatch = useAppDispatch()
  const selectedTags = useAppSelector(selectTagsFilter)
  const tags = useAppSelector(selectLegalWatchTags)
  const onSelectedTags = (value: string) => {
    if (!selectedTags) return
    const index = selectedTags.findIndex((selectedTag) => selectedTag.value === value)
    if (index < 0) {
      const tag = tags.filter((tag) => tag.value === value)[0]
      dispatch(addTagToFilter(tag))
    }
  }

  const onUnSelectedTags = (value: string) => {
    if (!selectedTags) return
    const index = selectedTags.findIndex((selectedTag) => selectedTag.value === value)
    if (index >= 0) {
      const tag = tags.filter((tag) => tag.value === value)[0]
      dispatch(removeTagToFilter(tag))
    }
  }

  const onOpenChange = () => {
    setIsOpen(!isOpen)
  }

  const onResetInput = () => {
    setInputValue('')
  }

  const onChangeInput = (value: string) => {
    setInputValue(value)
  }

  return {
    tags,
    selectedTags,
    isOpen,
    onOpenChange,
    onSelectedTags,
    onUnSelectedTags,
    onResetInput,
    inputValue,
    onChangeInput,
  }
}
