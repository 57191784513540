import { Message } from '../types/message'
import { TheIconAvatarAI } from './icons/TheIconAvatarAI'
import { TheIconAvatarHuman } from './icons/TheIconAvatarHuman'

function AvatarIcon({ type }: { type: Message['type'] }) {
  const Icon = type === 'HUMAN' ? TheIconAvatarHuman : TheIconAvatarAI
  return <Icon width="38" height="38" />
}

export default AvatarIcon
