import { ReactNode, useEffect, useState } from 'react'
import { FileData, FileDataWithExtraColumn, UploadedFile } from '../types/file.ts'
import { FilterKey, TheMobileFileFilterPanel } from './TheMobileFileFilterPanel.tsx'
import { TheIconTripleDotsVertical } from './icons/TheIconTripleDotsVertical.tsx'

type Props = {
  files: FileData[]
  emptyArrayMessage?: string
  sortFiles?: (inputValue: string, item: UploadedFile | FileDataWithExtraColumn | null) => void
  filterKey: FilterKey
  setFilterKey: (filterKey: FilterKey) => void
  toggleSingleFileActionsModal: (item: FileData) => void
}

export function TheFilesListMobile({
  files,
  setFilterKey,
  emptyArrayMessage,
  filterKey,
  toggleSingleFileActionsModal,
}: Readonly<Props>) {
  const [sortedFiles, setSortedFiles] = useState<FileData[]>(files)

  useEffect(() => {
    const newSortedFiles = sortFilesForMobile(files, filterKey)
    setSortedFiles(newSortedFiles)
  }, [files, filterKey])

  const handleClick = (item: FileData) => {
    toggleSingleFileActionsModal(item)
  }

  const sortFilesForMobile = (files: FileData[], filterKey: FilterKey) => {
    // Create a copy of the original array to avoid modifying it
    const sortedFiles = [...files]

    return sortedFiles.sort((a, b) => {
      if (filterKey === 'Alphabetic-Order') {
        return a.title.localeCompare(b.title)
      } else if (filterKey === 'Extension') {
        const getExtension = (filename: string) => {
          const parts = filename.split('.')
          return parts.length > 1 ? parts[parts.length - 1] : ''
        }
        return getExtension(a.title).localeCompare(getExtension(b.title))
      } else if (filterKey === 'Date') {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB.getTime() - dateA.getTime()
      } else {
        return a.title.localeCompare(b.title)
      }
    })
  }

  const renderList = (): ReactNode => {
    if (!sortedFiles?.length) return
    return sortedFiles?.map((file) => {
      return (
        <li key={file.id} className="mb-3 flex w-full justify-between gap-4 rounded-lg bg-bright p-3 font-normal">
          <div className={'flex w-4/5 flex-col'}>
            <span className={'overflow-hidden text-ellipsis whitespace-nowrap text-left text-base text-primary'}>
              {file?.title}
            </span>
            <span className={'text-sm text-label'}>{file?.createdAt}</span>
          </div>
          <button onClick={() => handleClick(file)}>
            <TheIconTripleDotsVertical width="16" className={'inline-block h-full fill-label hover:fill-primary'} />
          </button>
        </li>
      )
    })
  }

  return (
    <>
      {sortedFiles?.length ? (
        <>
          <ul className="max-h-halfVh w-full overflow-scroll sm:hidden">{renderList()}</ul>
          <TheMobileFileFilterPanel
            sortFiles={(filterKey: FilterKey) => {
              setFilterKey(filterKey)
              sortFilesForMobile(sortedFiles, filterKey)
            }}
          />
        </>
      ) : (
        <span className={'text-sm text-label sm:hidden'}>{emptyArrayMessage}</span>
      )}
    </>
  )
}
