import { TagGateway } from './interface/tag.gateway.ts'
import { FileData } from '../types/file.ts'
import {
  ApplyTagsResponse,
  ApplyTagsResponseData,
  CreateTagResponse,
  CreateTagResponseData,
  DeleteTagResponse,
  DeleteTagResponseData,
  ListTagsResponse,
  ListTagsResponseData,
  Tag,
  UpdateTagResponse,
  UpdateTagResponseData,
} from '../types/tag.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'

export class WretchTagGateway implements TagGateway {
  private readonly endpoint = '/files'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async createTag(label: string): Promise<CreateTagResponseData> {
    const body = {
      data: {
        name: label,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/tags`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<CreateTagResponse>()
    return response.data
  }

  async listTags(): Promise<ListTagsResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/tags`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListTagsResponse>()
    return response.data
  }

  async updateTag({ tagId, fileTagName }: { tagId: Tag['id']; fileTagName: string }): Promise<UpdateTagResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/tags/${tagId}`
    const body = {
      data: {
        fileTagName,
      },
    }
    const response = await this.olympeGptApi.url(requestInfoOrUrl).patch(body).json<UpdateTagResponse>()
    return response.data
  }

  async deleteTag(tagId: Tag['id']): Promise<DeleteTagResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/tags/${tagId}`
    return await this.olympeGptApi.url(requestInfoOrUrl).delete().json<DeleteTagResponse>()
  }

  async applyTags(
    fileId: FileData['id'],
    tagsToAdd: Tag['id'][],
    tagsToRemove: Tag['id'][],
  ): Promise<ApplyTagsResponseData> {
    const body = {
      data: {
        add: tagsToAdd,
        remove: tagsToRemove,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${fileId}/tags`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).patch(body).json<ApplyTagsResponse>()
    return response.data
  }
}
