import { DocumentsFolderGenerator } from '@/components/ds/DocumentsFolderGenerator.tsx'
import { TheIconCirclePlus } from '@/components/icons/TheIconCirclePlus.tsx'
import { DocumentsFolderList } from '@/components/DocumentsFolderList.tsx'
import { TheDocumentsFolderContainer } from '@/components/TheDocumentsFolderContainer.tsx'
import { useDocumentsFolderSectionHook } from '@/components/hooks/use-DocumentsFolderSection.hook.tsx'

export function DocumentsFolderSection() {
  const { handleKeyDown, onToggleAddTagModal, fileCountByTag } = useDocumentsFolderSectionHook()
  return (
    <div className={'hidden flex-col gap-4 sm:flex'}>
      <DocumentsFolderGenerator label={'Dossiers'} onKeyDown={handleKeyDown}>
        <TheIconCirclePlus
          onClick={onToggleAddTagModal}
          aria-label={'Ajouter un dossier'}
          className={'h-4 w-4 fill-lightBlue hover:cursor-pointer hover:fill-primary'}
        />
      </DocumentsFolderGenerator>
      <DocumentsFolderList>
        {fileCountByTag.map((tag) => (
          <TheDocumentsFolderContainer key={tag.id} tag={tag} />
        ))}
      </DocumentsFolderList>
    </div>
  )
}
