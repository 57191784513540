import React, { ChangeEvent, DragEvent, FormEvent, ReactNode } from 'react'
import { TheConversationMessageInputDesktop } from './TheConversationMessageInputDesktop.tsx'
import { TheConversationMessageInputMobile } from './TheConversationMessageInputMobile.tsx'
import { Conversation } from '../types/conversation.ts'
import { Message } from '../types/message.ts'

export type TheConversationMessageInputProps = {
  isQuestionLoading: boolean
  upLoadFile: (event: File) => void
  userInput: string
  onInput: (event: ChangeEvent<HTMLTextAreaElement>) => void
  handleSend: () => Promise<void>
  handleStopFileContext: () => void
  className?: string
  handleDropFile?: (e: DragEvent<HTMLDivElement>) => void
  handleKeyDown?: (e: { key: string; shiftKey: boolean }) => void
  handleFileInputChange?: (event: FormEvent<HTMLInputElement>) => void
  inputRef?: React.RefObject<HTMLInputElement>
  renderAddFileOrStopButton?: () => ReactNode
  handleInputFileOpening?: (ref: React.RefObject<HTMLInputElement>) => void
  currentConversation?: Conversation
  resize?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  toggleDisplaySourceFilter?: () => void
  messages?: Message[]
  handleDisplayFilterMobile?: () => void
}

export function TheConversationMessageInput({
  isQuestionLoading,
  upLoadFile,
  userInput,
  onInput,
  handleSend,
  handleStopFileContext,
  className = '',
  currentConversation,
  toggleDisplaySourceFilter,
  messages,
  handleDisplayFilterMobile,
}: Readonly<TheConversationMessageInputProps>) {
  return (
    <>
      <TheConversationMessageInputDesktop
        messages={messages}
        currentConversation={currentConversation}
        isQuestionLoading={isQuestionLoading}
        upLoadFile={upLoadFile}
        handleStopFileContext={handleStopFileContext}
        userInput={userInput}
        onInput={onInput}
        handleSend={handleSend}
        className={`hidden sm:flex ${className}`}
      />
      <TheConversationMessageInputMobile
        messages={messages}
        currentConversation={currentConversation}
        isQuestionLoading={isQuestionLoading}
        upLoadFile={upLoadFile}
        userInput={userInput}
        onInput={onInput}
        handleSend={handleSend}
        handleDisplayFilterMobile={handleDisplayFilterMobile}
        handleStopFileContext={handleStopFileContext}
        className={`flex sm:hidden ${className}`}
        toggleDisplaySourceFilter={toggleDisplaySourceFilter}
      />
    </>
  )
}
