type Props = {
  tagActive: boolean
  name: string
  filesLength: number
  documentLabel: string
}

export function TheDocumentsFolderContent(props: Readonly<Props>) {
  return (
    <div className={'flex flex-col gap-1'}>
      <h4
        className={`${
          props.tagActive ? 'text-tealBlue' : 'text-primary'
        } w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm font-semibold`}
      >
        {props.name}
      </h4>
      <p
        className={`text-left text-xs ${props.tagActive ? 'text-primary' : 'text-label'}`}
      >{`${props.filesLength} ${props.documentLabel}`}</p>
    </div>
  )
}
