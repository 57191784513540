import { ChangeEvent, FormEvent, useRef } from 'react'
import { ALLOWED_FILE_TYPES_AS_STRING } from '../utils/FileTypes.ts'
import { BaseSearchInput } from './atomics/BaseSearchInput.tsx'
import { TheIconImportDocument } from './icons/TheIconImportDocument.tsx'
import { TheIconTripleDots } from './icons/TheIconTripleDots.tsx'

type Props = {
  searchValue: string
  filterData: (e: ChangeEvent<HTMLInputElement>) => void
  handleClearSearchValue: () => void
  handleDisplayModal?: () => void
  handleFileInputChange: ((event: FormEvent<HTMLInputElement>) => void) | undefined
  title: string
  responsiveStyle: string
}

export function DocumentSearchBar({
  searchValue,
  handleDisplayModal,
  filterData,
  handleClearSearchValue,
  title,
  responsiveStyle,
  handleFileInputChange,
}: Readonly<Props>) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    handleFileInputChange?.(e)
  }

  const handleClick = () => {
    inputRef.current?.click()
  }
  return (
    <div className={`${responsiveStyle}`}>
      <h3 className="flex w-3/4 items-center gap-4 text-xl font-semibold text-mintGreen sm:text-base lg:px-3 lg:text-xl">
        {title}
        <TheIconTripleDots onClick={handleDisplayModal} className={'self-end sm:hidden'} />
      </h3>
      <div className={'flex items-center gap-4'}>
        <BaseSearchInput
          placeholder="Rechercher un document"
          onChange={filterData}
          onClear={handleClearSearchValue}
          value={searchValue}
        />
        <button
          className={
            'flex items-center gap-[0.5rem] rounded-[0.5rem] bg-primary pb-[0.5rem] pl-[0.5rem] pr-[0.75rem] pt-[0.5rem]'
          }
          onClick={handleClick}
        >
          <TheIconImportDocument className={'h-[1rem] w-[1rem] items-center justify-center fill-bright'} />
          <span className={'text-[0.875rem] leading-[1.25rem] text-bright'}>Importer</span>
        </button>
        <input
          ref={inputRef}
          onChange={handleChange}
          id={'fileInputRef'}
          accept={ALLOWED_FILE_TYPES_AS_STRING}
          className="hidden"
          type="file"
        />
      </div>
    </div>
  )
}
