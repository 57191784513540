import { LegalWatchArticleList } from '@/components/legalWatch/LegalWatchArticleList.tsx'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { LoadingSpinner } from '@/components/LoadingSpinner.tsx'
interface Props {
  articles: LegalWatchArticle[]
  isFetchingArticles?: boolean
}
export function LegalWatchArticleListContainer({ articles, isFetchingArticles }: Readonly<Props>) {
  if (isFetchingArticles) return <LoadingSpinner />

  if (articles.length === 0)
    return <span className={'text-lg font-normal text-label'}>Aucun article ne correspond à la recherche.</span>

  return <LegalWatchArticleList className={'gap-5 lg:grid-cols-2 xl:grid-cols-3'} articles={articles} />
}
