import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconOlympeLogoWithoutText(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M12.7767 25.82C14.0167 23.1667 16.3767 21.1633 19.25 20.3833C21.11 19.8467 23.9167 20.0667 25.6833 19.8567C29.1667 19.3533 31.6467 15.35 31.3267 12.3367C31.32 12.2433 31.1933 12.22 31.15 12.2933L30.7733 12.93V12.9367C28.94 16.0933 22.6 14.87 18.83 15.6567C15.32 16.5033 12.91 19.4067 12.4267 22.49C12.3167 23.0433 12.25 23.58 12.2267 24.0733C12.1267 25.6333 12.2933 26.61 12.2933 26.61C12.3267 26.7433 12.3867 26.7267 12.4367 26.5933C12.51 26.3933 12.5967 26.19 12.6867 26L12.78 25.8167L12.7767 25.82Z"
        fill="black"
      />
      <path
        d="M27.9234 20.7433C27.9234 20.6433 27.8067 20.5933 27.7334 20.66C26.2767 21.84 23.7334 21.7733 23.7334 21.7733C17.0434 21.3133 14.8167 25.59 14.42 26.5067C14.3867 26.58 14.4434 26.6733 14.53 26.6733C14.53 26.6733 18.4567 24.8067 22.2767 24.68C26.0934 24.5467 27.93 20.7433 27.93 20.7433H27.9234Z"
        fill="black"
      />
      <path
        d="M24.2733 13.7933C26.5167 13.2733 30.7067 13.4167 29.8833 10.1733C29.8667 10.09 29.7567 10.08 29.7167 10.15C28.3333 12.53 25.9233 10.4867 24.2067 13.6933C24.1833 13.7533 24.2233 13.81 24.2733 13.7933Z"
        fill="black"
      />
      <path
        d="M23.32 13.7433C22.34 8.58668 17.8167 4.83334 12.5667 4.83334C6.53 4.83334 1.63 9.74001 1.63 15.7667C1.63 21.26 5.73334 25.9167 11.17 26.62L11.5367 26.67L11.4867 26.3033C11.4267 25.91 11.3033 24.8633 11.3933 23.5233L11.41 23.28L11.1667 23.23C7.58334 22.55 4.98667 19.42 4.98667 15.77C4.98667 11.5833 8.39334 8.17334 12.5833 8.17334C16.0667 8.17334 19.0967 10.5333 19.9467 13.9167L20.0133 14.1667L20.2633 14.1233C20.2633 14.1233 20.8667 14.03 21.6467 14.0067L23.37 13.9233L23.32 13.74V13.7433Z"
        fill="black"
      />
    </svg>
  )
}
