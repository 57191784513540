export function PromptCommonMistakesSection() {
  return (
    <div className={'rounded-lg bg-brightGray p-3'}>
      <p className={'font semibold mb-4 text-2xl text-primary'}>Quelques erreurs fréquentes </p>
      <ul>
        <li className={'mb-4 flex gap-4'}>
          <p className={'text-base font-normal text-primary'}>
            <span className={'font-bold'}>Les références perdues :</span> Lorsque vous faites référence à une question
            ou à une réponse obtenue précédemment dans une discussion, citez le passage entre triple guillemets afin de
            faciliter l’inférence et réduire la marge d’erreur possible dans la formulation de la réponse à votre
            question subsidiaire.
          </p>
        </li>
        <li className={'mb-4 flex gap-4'}>
          <p className={'text-base font-normal text-primary'}>
            <span className={'font-bold'}>Le manque de pertinence contextuelle :</span> Effectuez un travail de
            pré-qualification des faits avant d’écrire votre question. Cela permet d’éviter d’ajouter des éléments de
            contexte inutile dans votre rédaction de prompt, ce qui aboutit à une réduction drastique des résultats
            incohérents.
          </p>
        </li>
        <li className={'mb-4 flex gap-4'}>
          <p className={'text-base font-normal text-primary'}>
            <span className={'font-bold'}>Parler à Olympe comme à un humain :</span> N’oubliez pas que l’I.A n’est pas
            une personne. C’est une chose, un programme, qui est conçu pour mimer une personne. Dès lors, votre
            réthorique ne peut être la même que lorsque vous vous adressez à un humain. Il s’agit notamment de
            contextualiser votre question, élaguer les éléments implicites et avoir en tête que chaque mot écrit est
            prédit en cohérence statistique avec les mots précédents. Il n’y a donc pas forcément de logique sémantique
            dans le résultat obtenu si le contexte est incohérent. Exemple : évitez les questions de type “Qu’en
            penses-tu ?” ou bien “est-ce que c’est une bonne approche ?”
          </p>
        </li>
      </ul>
    </div>
  )
}
