import { PasswordDetailedErrors, passwordDetailedErrors } from '@/utils/formValidation.ts'
import { z } from 'zod'

export const passwordSchema = z
  .string()
  .min(12, { message: passwordDetailedErrors.minLength })
  .refine((value) => /[A-Z]/.test(value), {
    message: passwordDetailedErrors.upperCase,
  })
  .refine((value) => /[a-z]/.test(value), {
    message: passwordDetailedErrors.lowerCase,
  })
  .refine((value) => /[0-9]/.test(value), { message: passwordDetailedErrors.numbers })
  .refine((value) => /[\W_]/.test(value), {
    message: passwordDetailedErrors.specialCharacters,
  })

export const registrationSchema = z.object({
  email: z.string({ required_error: 'Saisissez votre adresse email' }).email({ message: 'Adresse email invalide' }),
  password: passwordSchema,
  acceptCGU: z
    .boolean({ required_error: 'Merci de valider les CGU' })
    .refine((val) => val === true, { message: 'Vous devez accepter les CGU' }),
})
export type RegistrationFormValues = z.infer<typeof registrationSchema>

type PasswordDetailedErrorsKey = keyof typeof passwordDetailedErrors

export const passwordToValidations = (password: string): PasswordDetailedErrors => {
  const empty: PasswordDetailedErrors = {
    minLength: '',
    lowerCase: '',
    upperCase: '',
    specialCharacters: '',
    numbers: '',
  }
  const validatedPassword = passwordSchema.safeParse(password)
  if (validatedPassword.success) {
    return empty
  }

  return Object.keys(passwordDetailedErrors).reduce<PasswordDetailedErrors>(
    (acc, key) => ({
      ...acc,
      [key]: validatedPassword.error.errors.find(
        (i) => i.message === passwordDetailedErrors[key as PasswordDetailedErrorsKey],
      )
        ? passwordDetailedErrors[key as PasswordDetailedErrorsKey]
        : '',
    }),
    empty,
  )
}
