import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconSuccess(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_1824_11970)">
        <path d="M9.00421 2.71376e-08C6.59848 2.71376e-08 4.33835 0.935159 2.63924 2.63274C0.940517 4.33032 0.0030648 6.59083 7.47587e-06 8.99771C-0.00304985 11.3973 0.931727 13.6563 2.63236 15.3585C4.33415 17.0622 6.59427 18 8.99733 18H9.00039C11.405 17.9992 13.6655 17.0622 15.3657 15.3615C17.0656 13.6605 18.0015 11.4004 18 8.99694C17.997 4.03567 13.9617 -0.000382138 9.00421 2.71376e-08ZM9.01682 16.5096H8.99198C6.98103 16.5031 5.09275 15.7166 3.67568 14.2945C2.25822 12.8732 1.48243 10.9873 1.49045 8.9851C1.49848 6.97414 2.2865 5.08701 3.70931 3.67146C5.1237 2.26395 6.99631 1.49045 8.98625 1.49045H9.02179C11.0324 1.49962 12.918 2.28879 14.3316 3.71159C15.7448 5.13478 16.5184 7.02306 16.5096 9.02904C16.4908 13.1591 13.1324 16.5096 9.01682 16.5096Z" />
        <path d="M13.1297 7.1718C12.9161 7.39269 12.6956 7.61282 12.4819 7.82607C12.399 7.90862 12.3161 7.99155 12.2335 8.07448C10.977 9.33524 9.72079 10.5956 8.46385 11.856C8.30028 12.02 8.10538 12.1048 7.9078 12.1048C7.79735 12.1048 7.68614 12.0784 7.57876 12.0245C7.50385 11.9867 7.42857 11.9317 7.36169 11.8648C6.50984 11.016 5.65646 10.1626 4.86156 9.36658C4.62729 9.13193 4.56079 8.81894 4.68385 8.52925C4.80079 8.25486 5.06946 8.07792 5.36946 8.07792H5.37251C5.57965 8.07792 5.75965 8.15664 5.92245 8.31868C6.16474 8.56021 6.40742 8.80136 6.64971 9.0425C7.06207 9.45218 7.48818 9.87562 7.90551 10.2945C9.07417 9.12276 10.2642 7.93346 11.4149 6.78314L12.0684 6.12964C12.3554 5.84263 12.8255 5.84072 13.1156 6.12505C13.4117 6.41473 13.4179 6.87448 13.1297 7.1718Z" />
      </g>
      <defs>
        <clipPath id="clip0_1824_11970">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
