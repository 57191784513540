import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LegalResource } from '@/types/legalResource.ts'

export const listTexts = createAppAsyncThunk<LegalResource[]>(
  'texts/listTexts',
  async (_, { extra: { legalResourceGateway } }) => {
    try {
      const texts = await legalResourceGateway.getAllTexts()

      // Ensure each text has a unique ID by using the slug property.
      // This is necessary because we encountered cases where the IDs from Legifrance texts were the same as those from Eurlex texts.
      // This caused issues when trying to display the texts in the UI.
      return texts.map((text) => ({ ...text, id: text.slug }))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
