import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconClosingCross(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.904529 0.0111238C1.6006 0.184351 1.97393 0.853203 2.47973 1.3392C3.60212 2.41947 4.70042 3.5238 5.78187 4.64496C6.05163 4.92646 6.13833 4.69308 6.2708 4.56076C7.5931 3.2423 8.90577 1.91663 10.2329 0.602985C10.5412 0.297431 10.8326 -0.0947375 11.3649 0.0544308C11.6949 0.145856 11.9068 0.362391 11.9671 0.706441C12.073 1.30311 11.5817 1.56777 11.2589 1.89497C9.927 3.24471 8.57098 4.56797 7.227 5.90568C7.20051 5.93214 7.20533 6.03079 7.23182 6.05966C8.72754 7.55856 10.2353 9.04783 11.7238 10.5564C12.061 10.898 12.1067 11.3455 11.7647 11.7064C11.3818 12.1082 10.9193 12.0673 10.5388 11.6896C9.03583 10.2027 7.54734 8.69897 6.05403 7.20007C5.9047 7.0485 5.9047 7.2506 5.86135 7.2915C4.37286 8.76874 2.89641 10.2556 1.40551 11.7281C1.04423 12.0841 0.605867 12.0865 0.254217 11.7232C-0.114293 11.3407 -0.0661214 10.8956 0.295163 10.5275C1.73789 9.06949 3.18544 7.6163 4.64984 6.17995C4.85216 5.98026 4.7799 5.92492 4.63298 5.77816C3.21916 4.3779 1.81256 2.97043 0.403548 1.56536C0.112112 1.27424 -0.104659 0.963876 0.078392 0.525995C0.218089 0.193976 0.480622 0.0448072 0.904529 0.00390625V0.0111238Z"
        fill="#6E7682"
      />
    </svg>
  )
}
