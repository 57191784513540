import { Tree, TreeDataItem } from '@/components/ui/tree'
import { getFirstChildArticle } from './utils'
import { LegalResource } from '@/types/legalResource.ts'
import { Button } from '@/components/ui/button.tsx'

interface Props {
  content: TreeDataItem
  title: LegalResource['title']
  setCurrentArticle: (article: TreeDataItem) => void
  ids: string[]
  recitals?: { content: string }
  currentArticleTitle?: string
}

const RECITALS_ARTICLE_TITLE = 'Considérants'
function TextTreeView({ content, title, setCurrentArticle, ids, recitals, currentArticleTitle }: Readonly<Props>) {
  return (
    <div className="gap-4 overflow-auto rounded-lg border bg-white px-6 py-5">
      <h1 className="text-sm font-semibold">{title}</h1>
      <Button
        variant={'secondary'}
        className={`w-fit px-0 py-1 hover:text-label ${currentArticleTitle === RECITALS_ARTICLE_TITLE && 'text-primary hover:text-primary'}`}
        onClick={() => {
          if (!recitals) return
          const newItem: TreeDataItem = {
            id: '',
            title: 'Considérants',
            type: 'article',
            parent: '',
            content: recitals.content,
          }
          setCurrentArticle(newItem)
        }}
      >
        Considérants
      </Button>
      <Tree
        data={content}
        path={ids}
        onSelectChange={(item) => {
          const firstArticle = getFirstChildArticle(item!)
          if (!firstArticle || !firstArticle.children) return
          const newItem = { ...firstArticle, content: firstArticle.children[0].content }
          setCurrentArticle(newItem as TreeDataItem)
        }}
      />
    </div>
  )
}

export default TextTreeView
