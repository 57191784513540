import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconDocument(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3231_1278)">
        <path d="M6.28773 3.35352H3.27972C3.02114 3.35352 2.81152 3.56026 2.81152 3.81529C2.81152 4.07031 3.02114 4.27706 3.27972 4.27706H6.28773C6.54631 4.27706 6.75593 4.07031 6.75593 3.81529C6.75593 3.56026 6.54631 3.35352 6.28773 3.35352Z" />
        <path d="M8.12204 5.49365H3.27972C3.02114 5.49365 2.81152 5.70039 2.81152 5.95542C2.81152 6.21045 3.02114 6.41719 3.27972 6.41719H8.12204C8.38062 6.41719 8.59024 6.21045 8.59024 5.95542C8.59024 5.70039 8.38062 5.49365 8.12204 5.49365Z" />
        <path d="M8.12204 7.63379H3.27972C3.02114 7.63379 2.81152 7.84053 2.81152 8.09556C2.81152 8.35059 3.02114 8.55733 3.27972 8.55733H8.12204C8.38062 8.55733 8.59024 8.35059 8.59024 8.09556C8.59024 7.84053 8.38062 7.63379 8.12204 7.63379Z" />
        <path d="M10.3347 0.633789H1.72752C0.912984 0.633789 0.258789 1.28982 0.258789 2.09235V11.9201C0.258789 12.729 0.919397 13.3786 1.72752 13.3786H10.3347C11.1492 13.3786 11.8034 12.7226 11.8034 11.9201V2.09235C11.8098 1.28982 11.1492 0.633789 10.3347 0.633789ZM10.9119 11.6972C10.9119 12.0666 10.6105 12.3659 10.2385 12.3659H1.91352C1.54152 12.3659 1.24008 12.0666 1.24008 11.6972V2.24521C1.24008 1.87579 1.54152 1.57644 1.91352 1.57644H10.2385C10.6105 1.57644 10.9119 1.87579 10.9119 2.24521V11.6972Z" />
      </g>
      <defs>
        <clipPath id="clip0_3231_1278">
          <rect width="11.4933" height="12.7703" fill="white" transform="translate(0.316406 0.614746)" />
        </clipPath>
      </defs>
    </svg>
  )
}
