import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconTripleDots(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="2" fill="#212121" />
      <circle cx="18" cy="10.5" r="2" fill="#212121" />
      <circle cx="26" cy="10.5" r="2" fill="#212121" />
    </svg>
  )
}
