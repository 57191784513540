type Item = {
  [key: string]: string
}

interface FilteredItemsLabelProps {
  items: Item[]
  labelKey: string
}

// Generic component for displaying labels based on the selected items
export function FilteredItemsLabel({ items, labelKey }: Readonly<FilteredItemsLabelProps>) {
  if (items.length > 1) {
    // Display the first item's label and the count of additional items
    return <span className={'text-mintGreen'}>{`${items[0][labelKey]} + ${items.length - 1}`}</span>
  } else if (items.length === 1) {
    // Display the single selected item's label
    return <span className={'text-mintGreen'}>{`${items[0][labelKey]}`}</span>
  } else {
    // Return null when no items are selected
    return null
  }
}
