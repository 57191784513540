import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconAvatarAI(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props}>
      <circle cx="19" cy="19" r="19" fill="#212121"></circle>
      <path
        d="M13.0763 23.144C12.2203 23.144 11.4643 22.96 10.8083 22.592C10.1523 22.216 9.63625 21.704 9.26025 21.056C8.89225 20.4 8.70825 19.652 8.70825 18.812C8.70825 17.956 8.89225 17.204 9.26025 16.556C9.63625 15.9 10.1523 15.388 10.8083 15.02C11.4643 14.644 12.2203 14.456 13.0763 14.456C13.5243 14.456 13.9603 14.532 14.3843 14.684C14.8083 14.828 15.1963 15.028 15.5483 15.284C15.9003 15.54 16.1843 15.832 16.4003 16.16L15.5963 16.712C15.4363 16.456 15.2203 16.228 14.9483 16.028C14.6763 15.82 14.3763 15.66 14.0483 15.548C13.7283 15.436 13.4043 15.38 13.0763 15.38C12.4283 15.38 11.8523 15.528 11.3483 15.824C10.8443 16.112 10.4483 16.512 10.1603 17.024C9.87225 17.536 9.72825 18.132 9.72825 18.812C9.72825 19.468 9.86825 20.056 10.1483 20.576C10.4363 21.088 10.8323 21.492 11.3363 21.788C11.8483 22.084 12.4283 22.232 13.0763 22.232C13.6203 22.232 14.1003 22.12 14.5163 21.896C14.9403 21.664 15.2723 21.348 15.5123 20.948C15.7523 20.54 15.8723 20.072 15.8723 19.544L16.8443 19.472C16.8443 20.208 16.6843 20.852 16.3643 21.404C16.0443 21.948 15.6003 22.376 15.0323 22.688C14.4643 22.992 13.8123 23.144 13.0763 23.144ZM13.8083 19.592V18.716H16.8443V19.508L16.3643 19.592H13.8083ZM19.3494 19.916V18.992H21.3894C21.8294 18.992 22.1894 18.832 22.4694 18.512C22.7574 18.184 22.9014 17.768 22.9014 17.264C22.9014 16.92 22.8254 16.616 22.6734 16.352C22.5214 16.088 22.3094 15.884 22.0374 15.74C21.7734 15.588 21.4654 15.516 21.1134 15.524H19.3494V14.6L21.1254 14.588C21.6694 14.588 22.1494 14.704 22.5654 14.936C22.9894 15.16 23.3174 15.472 23.5494 15.872C23.7894 16.272 23.9094 16.736 23.9094 17.264C23.9094 17.776 23.8014 18.232 23.5854 18.632C23.3694 19.032 23.0734 19.348 22.6974 19.58C22.3214 19.804 21.8854 19.916 21.3894 19.916H19.3494ZM18.3894 23V14.6H19.4094V23H18.3894ZM27.0307 23V15.296H28.0387V23H27.0307ZM24.3547 15.524V14.6H30.7147V15.524H24.3547Z"
        fill="#FDFDFD"
      ></path>
    </svg>
  )
}
