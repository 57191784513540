import { TheIconCross } from '../icons/TheIconCross'

export type FilterTagProps = {
  className?: string
  contentColor: string
  title?: string
  label: string
  onIconCLick: () => void
}

export function ArticleFilterTag({ title, label, onIconCLick, contentColor, ...props }: Readonly<FilterTagProps>) {
  return (
    <div className={`flex w-fit items-center bg-mintGreen ${props.className}`}>
      <div>
        {title && <span className={`text-${contentColor}`}>{title} : </span>}
        <span className={`text-${contentColor}`}>{label}</span>
      </div>
      <TheIconCross onClick={onIconCLick} className={`h-4 w-4 fill-${contentColor} hover:cursor-pointer`} />
    </div>
  )
}
