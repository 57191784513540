type Props = { disabled?: boolean }

export function TheIconFilter(props: Readonly<Props>) {
  function getFill() {
    return props.disabled ? '#C1C1C1' : '#6E7682'
  }

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2162_2571)">
        <path
          d="M0 1.87226C0.12349 1.63257 0.329184 1.57492 0.586758 1.57528C2.70179 1.58072 4.81683 1.57855 6.9315 1.57855H7.09664C7.24789 0.953044 7.60667 0.49253 8.17699 0.207518C8.54125 0.0254881 8.92889 -0.0383312 9.34137 0.0233125C10.2332 0.157115 10.7882 0.664044 11.0505 1.50421C11.0797 1.59813 11.1393 1.57746 11.197 1.57746C11.9628 1.57855 12.7286 1.57818 13.4944 1.57818C13.6482 1.57818 13.7808 1.6217 13.8893 1.73954C14.0077 1.86863 14.0194 2.01622 13.966 2.16634C13.9105 2.32153 13.7907 2.42451 13.6215 2.44083C13.4816 2.45461 13.3391 2.44627 13.198 2.44663C12.5327 2.44663 11.8671 2.44772 11.2018 2.44555C11.1148 2.44555 11.0746 2.46186 11.0454 2.56013C10.8718 3.14647 10.5072 3.58559 9.94749 3.84196C9.20838 4.18027 8.50252 4.10267 7.8478 3.61823C7.49378 3.35606 7.25849 3.01049 7.13573 2.58986C7.12331 2.5478 7.11162 2.50574 7.09517 2.447H6.94173C4.79929 2.447 2.65722 2.44446 0.514784 2.45062C0.266343 2.45135 0.0993763 2.36577 0.000730708 2.14349V1.8719L0 1.87226ZM10.2306 2.02238C10.2829 1.38745 9.70161 0.888499 9.11229 0.865292C8.46927 0.840272 7.92014 1.39978 7.92453 2.01658C7.92928 2.64281 8.45393 3.1762 9.06553 3.17367C9.68005 3.17113 10.2632 2.67943 10.2303 2.02202L10.2306 2.02238Z"
          fill={getFill()}
        />
        <path
          d="M0 11.8229C0.12349 11.5833 0.329184 11.5256 0.586758 11.526C2.70179 11.5314 4.81683 11.5292 6.9315 11.5292H7.09664C7.24789 10.9037 7.60667 10.4432 8.17699 10.1582C8.54125 9.97617 8.92889 9.91235 9.34137 9.974C10.2332 10.1078 10.7882 10.6147 11.0505 11.4549C11.0797 11.5488 11.1393 11.5281 11.197 11.5281C11.9628 11.5292 12.7286 11.5289 13.4944 11.5289C13.6482 11.5289 13.7808 11.5724 13.8893 11.6902C14.0077 11.8193 14.0194 11.9669 13.966 12.117C13.9105 12.2722 13.7907 12.3752 13.6215 12.3915C13.4816 12.4053 13.3391 12.397 13.198 12.3973C12.5327 12.3973 11.8671 12.3984 11.2018 12.3962C11.1148 12.3962 11.0746 12.4125 11.0454 12.5108C10.8718 13.0972 10.5072 13.5363 9.94749 13.7926C9.20838 14.131 8.50252 14.0534 7.8478 13.5689C7.49378 13.3067 7.25849 12.9612 7.13573 12.5405C7.12331 12.4985 7.11162 12.4564 7.09517 12.3977H6.94173C4.79929 12.3977 2.65722 12.3951 0.514784 12.4013C0.266343 12.402 0.0993763 12.3165 0.000730708 12.0942V11.8226L0 11.8229ZM10.2306 11.9731C10.2829 11.3381 9.70161 10.8392 9.11229 10.816C8.46927 10.791 7.92014 11.3505 7.92453 11.9673C7.92928 12.5935 8.45393 13.1269 9.06553 13.1243C9.68005 13.1218 10.2632 12.6301 10.2303 11.9727L10.2306 11.9731Z"
          fill={getFill()}
        />
        <path
          d="M0 6.81196C0.122394 6.58569 0.308359 6.5041 0.569952 6.50846C1.34889 6.52187 2.12855 6.51317 2.90931 6.51317C3.07957 5.87099 3.4504 5.39162 4.05799 5.11059C4.41786 4.94416 4.796 4.90681 5.19278 4.96011C5.56105 5.00979 5.87855 5.14939 6.16937 5.37022C6.51536 5.63312 6.74078 5.97796 6.86135 6.38662C6.89277 6.49323 6.9304 6.51462 7.02905 6.51462C9.18098 6.51244 11.3325 6.51281 13.4845 6.51353C13.6529 6.51353 13.8045 6.55088 13.9119 6.70064C14.0121 6.83988 14.0289 6.98529 13.951 7.13142C13.8681 7.2877 13.7362 7.38198 13.5481 7.38198C11.3779 7.38162 9.20802 7.38198 7.03781 7.38017C6.93551 7.38017 6.89386 7.39685 6.85989 7.51252C6.71374 8.00603 6.41415 8.39294 5.96988 8.66562C5.69075 8.83713 5.3897 8.92634 5.06125 8.96042C4.47814 9.02098 3.99332 8.82517 3.56147 8.45712C3.2279 8.17283 3.01929 7.81458 2.91406 7.38162H2.40184C1.79097 7.38162 1.17973 7.36929 0.569221 7.38742C0.303244 7.39504 0.123124 7.30765 0 7.08319V6.8116L0 6.81196ZM3.74744 6.94903C3.72953 7.56474 4.26478 8.11337 4.8709 8.10793C5.55046 8.10213 6.05903 7.63255 6.06707 6.94758C6.07438 6.3373 5.60015 5.76656 4.79527 5.80572C4.22605 5.83328 3.72369 6.36849 3.74744 6.94866V6.94903Z"
          fill={getFill()}
        />
      </g>
      <defs>
        <clipPath id="clip0_2162_2571">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
