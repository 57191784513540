import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheAuditIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  const { color = '#FDFDFD', ...rest } = props
  return (
    <svg {...rest} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33333 2.96301H5.2C4.0799 2.96301 3.51984 2.96301 3.09202 3.181C2.71569 3.37275 2.40973 3.67871 2.21799 4.05503C2 4.48286 2 5.04291 2 6.16301V6.29635M5.33333 14.963H5.2C4.0799 14.963 3.51984 14.963 3.09202 14.745C2.71569 14.5533 2.40973 14.2473 2.21799 13.871C2 13.4432 2 12.8831 2 11.763V11.6297M14 6.29635V6.16301C14 5.04291 14 4.48286 13.782 4.05503C13.5903 3.67871 13.2843 3.37275 12.908 3.181C12.4802 2.96301 11.9201 2.96301 10.8 2.96301H10.6667M14 11.6297V11.763C14 12.8831 14 13.4432 13.782 13.871C13.5903 14.2473 13.2843 14.5533 12.908 14.745C12.4802 14.963 11.9201 14.963 10.8 14.963H10.6667M2 8.96301H2.00667M5 8.96301H5.00667M11 8.96301H11.0067M8 8.96301H8.00667M14 8.96301H14.0067"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
