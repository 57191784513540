import { cn } from '@/lib/utils'
import React, { ButtonHTMLAttributes, ReactElement } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement
  color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' // Color variant for the button.
  size?: 'small' | 'medium' | 'large' // Size variant for the button.
  label: string // Label of the button
  iconPosition?: 'left' | 'right'
}

const COLOR_CLASSES: Record<Exclude<ButtonProps['color'], undefined>, string> = {
  primary: 'font-normal bg-primary text-bright hover:bg-volcanicSand disabled:bg-bInput disabled:text-silver',
  secondary: 'font-normal bg-label text-bright hover:bg-lightGray disabled:bg-bInput disabled:text-silver',
  tertiary: 'font-normal bg-transparent text-primary hover:text-label disabled:text-silver',
  quaternary:
    'font-bold text-primary hover:text-bright hover:bg-primary disabled:bg-bInput disabled:font-normal disabled:text-silver',
} as const

// The hover is handled thanks to the tailwind group feature: https://tailwindcss.com/docs/hover-focus-and-other-states
const ICON_COLOR_CLASSES: Record<Exclude<ButtonProps['color'], undefined>, string> = {
  primary: 'fill-bright',
  secondary: 'fill-bright',
  tertiary: 'fill-bright',
  quaternary: 'fill-primary group-hover:fill-bright',
} as const

const FOCUS_CLASSES =
  'focus:decoration-solid focus:decoration-bright focus:decoration-1 focus:underline focus:underline-offset-2 focus:outline-0' as const

const SIZE_CLASSES: Record<Exclude<ButtonProps['size'], undefined>, string> = {
  small: 'py-2 px-4 text-sm',
  medium: 'px-6 py-3 text-sm',
  large: 'px-7 py-3 text-base w-full',
} as const

const ICON_SIZE_CLASSES: Record<Exclude<ButtonProps['size'], undefined>, string> = {
  small: 'w-3 h-3',
  medium: 'w-4 h-4',
  large: 'w-6 h-6',
} as const

/**
 * A customizable base button component.
 *
 * @param {ButtonProps} props - The props for the button.
 * @returns {ReactElement} A React element representing the base button.
 */
export function BaseButton({
  color = 'primary',
  label,
  size = 'medium',
  icon,
  iconPosition = 'right',
  ...rest
}: Readonly<ButtonProps>): ReactElement {
  /**
   * Determines the appropriate color style for the button.
   *
   * @returns {string} The CSS classes for the color style.
   */
  const colorClasses = (): string => {
    return COLOR_CLASSES[color]
  }

  /**
   * Determines the padding and font style for the button based on its context.
   *
   * @returns {string} The CSS classes for the padding and font style.
   */
  const sizeClasses = (): string => {
    return SIZE_CLASSES[size]
  }

  // The icon is a JSX element pass through a props
  // To apply specific classes from the parent BaseButton
  // we need to clone the JSX element and pass the props to that clone
  const renderIcon = () => {
    if (!icon) {
      return
    }
    return React.cloneElement(icon, {
      className: `${ICON_SIZE_CLASSES[size]} ${ICON_COLOR_CLASSES[color]} ${icon.props.className ?? ''}`,
    })
  }

  const renderedIcon = renderIcon()
  const iconPositionStyle = () => {
    return iconPosition === 'left' ? 'flex-row-reverse' : ' flex-row'
  }
  return (
    <button
      {...rest}
      className={cn(
        `group flex items-center justify-center gap-3 rounded-lg text-center hover:cursor-pointer  ${iconPositionStyle()} ${colorClasses()} ${sizeClasses()} ${FOCUS_CLASSES})`,
        rest.className,
      )}
    >
      {label}
      {renderedIcon}
    </button>
  )
}
